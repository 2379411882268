import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js'
import { Route, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
 
 export class EncryptService {

  constructor(private route: ActivatedRoute){

  }
 
 url:any;
 encriptedData:any;
 encryptedData:String;

  encryption(_data) {
    const urlParts = _data.split('?');
    const path = urlParts[0];
    const query = urlParts[1] || '';

    const encryptedPath = path.split('/').map((currentValue) => {
      return /^\d+$/.test(currentValue) || (/^[^a-z]*$/).test(currentValue) ? this.toEncrypt(currentValue) : currentValue;
    }).join('/');

    const encryptedQuery = query ? '?' + query.split('&').map((param) => {
      if(param == ''){
        return
      }
        const [key, value] = param.split('=');
        const values = value.split('"');
        if(values == "false" || values == "true"){
          return key + '=' + values;
        }
        else{
          const encryptedValue = values ? this.toEncrypt(values.toString()) : values;
          return key + '=' + encodeURIComponent(encryptedValue);
        }
        
      }).join('&')
      : '';
   
    return  this.encryptedData = encryptedPath + encryptedQuery;
    
  }
  
  toEncrypt(_data) {
    var pharase = 'c63a423527b24f65'
    var data = _data;
    var key  = CryptoJS.enc.Latin1.parse(pharase);
    var iv   = CryptoJS.enc.Latin1.parse(pharase);  
    var encrypted = CryptoJS.AES.encrypt(
      data,
      key,
      {iv:iv,mode:CryptoJS.mode.CBC,
        padding:CryptoJS.pad.ZeroPadding
    }).toString();

    var e64 = CryptoJS.enc.Base64.parse(encrypted);
    var eHex = e64.toString(CryptoJS.enc.Hex);

   return this.encriptedData = eHex
  }

}


