import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from "@angular/core";
import { RfeService } from "src/app/services/rfe.service";
import { ToastrService } from "ngx-toastr";
//import { FroalaEditorOptions } from '../../../../data/constants/froala-editor-options';
import { ClipboardService } from 'ngx-clipboard';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';
import { takeUntil } from "rxjs/operators";
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';
import { UploadImageDialogComponent } from "../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";



@Component({
  selector: 'app-rfe-response-builder',
  templateUrl: './rfe-response-builder.component.html',
  styleUrls: ['./rfe-response-builder.component.scss']
})
export class RfeResponseBuilderComponent implements OnInit {
  @ViewChild("singleresresponseupload") public singleresresponseupload: UploadImageDialogComponent;
  @ViewChild("itemresponselistupload") public itemresponselistupload: UploadImageDialogComponent;
  @Output() @ViewChild("singleresresponsekendoeditor") public singleresresponsekendoeditor: EditorComponent;
  @Output() @ViewChild("itemresponselistkendoeditor") public itemresponselistkendoeditor: EditorComponent;
  // public options = FroalaEditorOptions.EditorOptions;
  @Input() rfeTimelineModalInput: any;
  @Input() notificationResponses: any[] = [];
  // @Input() notificationDocuments: any[] = [];
  @Input() rfeNoticeId: any;
  @Input() responseNotificationids: any[] = [];
  @Output() changeSubStepStatus = new EventEmitter<any>();
  rfeDisplayResponse = [];
  copiedResponseContent;
  rfeResponseCompanyId;
  rfeResponseHeader: string;
  rfeResponseContent: string;
  rfeResponseFooter: string;
  rfePlaceholderList: any[] = [];
  petitionData: any[] = [];
  rfeTemplateList: any[];
  standardRfeTemplateList: any[] = [];
  legalRfeTemplateList: any[] = [];
  displayResponse: boolean = false;
  response: any[] = [];
  notificationIds: any[] = [];
  rfeCustomNotificationResponses: any[] = [];
  rfeTaskDetails: any;
  taskDetailsSubscription$ = new Subject();
  companyId;
  notificationObj: any = {};
  userType: string;

  constructor(private rfeService: RfeService, private toastr: ToastrService, private _clipboardService: ClipboardService, private store: Store<fromTask.State>) { }

  ngOnInit(): void {
    this.userType = sessionStorage.getItem('userTypeRole');
    // combineLatest([
    //   this.store.select(fromTask.getRFESubTaskDetails)
    // ]).subscribe(([getRFESubTask]) => {
    //   this.rfeTaskDetails = getRFESubTask;
    // });

    this.store.pipe(select(fromTask.getRFESubTaskDetails)).pipe(takeUntil(this.taskDetailsSubscription$)).subscribe((data: SubTaskDetails) => {
      // Entire sub task details
      // U push this obejct to utility service
      // this.taskUtility.setTaskDetails(data);
      this.rfeTaskDetails = data;
      this.companyId = this.rfeTaskDetails ? this.rfeTaskDetails['companyId'] : '';
      console.log(data);
    });
    this.getRfeResponse();
    this.getRfePlaceholder();
    this.getRFENotification(this.rfeNoticeId);
    this.getPetitonData()

    if (this.notificationResponses && this.notificationResponses.length > 0) {
      this.modifyData();
    }
  }

  ngOnChanges(e: SimpleChanges) {
    if (this.notificationResponses && this.notificationResponses.length > 0) {
      this.modifyData();
      this.getRfeResponseByRfenotice();
    }
  }

  public opensingleresresponseimageuploaddialog() {
    this.singleresresponseupload.open();
  }
  public openitemresponselistimageuploaddialog() {
    this.itemresponselistupload.open();
  }

  getPetitonData() {
    this.rfeService.getPetitonData(this.rfeNoticeId).subscribe((data) => {
      // console.log("getdata", data);
      this.petitionData = data && data.petitionData ? data.petitionData : [];
    });

  }

  getRFENotification(rfeNoticeId) {
    let type;
    let id;
    if (sessionStorage.getItem('userTypeRole') === "Petitioner") {
      type = "employee";
      id = sessionStorage.getItem("employeeId");
    } else if (sessionStorage.getItem('userTypeRole') === "Beneficiary") {
      type = "beneficiary";
      id = sessionStorage.getItem("beneficiaryId");
    }
    this.rfeService
      .getRfeNotification(rfeNoticeId, type, id)
      .subscribe((data) => {
        // console.log("getdata for documents", data);
        if (data) {
          // this.notificationComments = data['notificationComments'];
          //this.notificationDocuments = data['titleSheetDocDtoList'];
          this.notificationIds = data['notificationIds']

        }

      });

    this.rfeService
      .getRfeNotificationForResponse(rfeNoticeId, type, id)
      .subscribe((data) => {
        // console.log("getdata for response", data);
        this.notificationResponses = data['categoryList']
        this.modifyData();
        //this.notificationDocumentsForResponse = data['categoryList'];
      });
  }

  modifyData() {
    this.rfeCustomNotificationResponses = [];

    for (let item of this.notificationResponses) {
      if (item.responseList.length > 0) {
        this.rfeCustomNotificationResponses.push(item);
      } else {
        let temp: any = {};
        let singleRes: any = {};
        temp.notes = item.notes;
        singleRes.responseText = item.responseText ? item.responseText : '';
        singleRes.rfeNoticeElementId = 0;
        singleRes.rfeNoticeEvidenceId = 0;
        singleRes.rfeNoticeId = this.rfeNoticeId;
        singleRes.rfeResponseId = 0;
        singleRes.rfeNoticeCategoryId = item.categoryDtlId;
        temp.categoryName = item.categoryName;
        temp.responseList = [];
        temp.responseList.push(singleRes)
        if (item.categoryDtlId) {
          this.rfeCustomNotificationResponses.push(temp);
        }
      }
    }
    // console.log("$$$ rfeCustomNotificationResponses", this.rfeCustomNotificationResponses)
  }

  getRfeResponse() {
    this.rfeService
      .getRfeResponseBlock(parseInt(this.companyId))
      .subscribe((data) => {

        this.rfeTemplateList = data;
        if (data.length > 0) {
          this.standardRfeTemplateList = data.filter(
            item => item["responseTypeCode"] === "RFEDEFRESP");
          this.legalRfeTemplateList = data.filter(
            item => item["responseTypeCode"] === "RFELAWRESP");
        }
      });
  }

  getRfePlaceholder() {
    this.rfeService
      .getRfePlaceholder(this.rfeNoticeId)
      .subscribe((data) => {
        if (data.length != 0) {
          this.rfePlaceholderList = data;
        }
      });
  }

  view(item) {
    this.displayResponse = true;
    this.rfeDisplayResponse["responseTypeName"] = item["responseTypeName"];
    this.rfeDisplayResponse["responseText"] = item["responseText"];
    this.rfeDisplayResponse["responseTitle"] = item["responseTitle"];
  }

  viewPlaceholder(item) {
    this.displayResponse = true;
    this.rfeDisplayResponse["responseTypeName"] = "";

    this.rfeDisplayResponse["responseTitle"] = item["placeHolderDescription"];
    if (this.rfeDisplayResponse["responseTitle"] === "USCIS Office Address") {
      this.rfeDisplayResponse["responseText"] = item["serviceCenters"];
    } else {
      this.rfeDisplayResponse["responseText"] = item["placeHolderValue"];
    }
  }

  viewPetiton(item) {
    // console.log("$$$$$ item",item);
    this.displayResponse = true;
    this.rfeDisplayResponse["responseTypeName"] = item["description"];
    this.rfeDisplayResponse["responseText"] = item["value"];
    this.rfeDisplayResponse["responseTitle"] = "Petition Details";
  }

  // copyContent(item,val?){
  //   this.toastr.info(`${item.responseTitle} response copied`);
  //   // const strippedString = item.responseText.replace(/(<([^>]+)>)/gi, "");
  //   // this._clipboardService.copyFromContent(strippedString);

  //   if(item["responseTitle"] === "USCIS Office Address"){
  //     this._clipboardService.copyFromContent(val['address'])
  //   }else{
  //     //this._clipboardService.copyFromContent(item.responseText);
  //     document.addEventListener('copy', (e: ClipboardEvent) => {
  //       e.clipboardData.setData('text/html', item.responseText);
  //       e.preventDefault();
  //       document.removeEventListener('copy', null);
  //     });
  //     document.execCommand('copy');
  //   }
  //   this.displayResponse = false;
  // }

  copyContent(item, val?) {
    if (item["responseTitle"] === "USCIS Office Address") {
      this.toastr.info(`${item.responseTitle} response copied`);
      this.copyContentToListener(val['address'])
      //  this._clipboardService.copyFromContent(val['address'])
    } else if (item.responseText) {
      this.toastr.info(`${item.responseTitle} response copied`);
      this.copyContentToListener(item.responseText)
      // this._clipboardService.copyFromContent(item.responseText);
    }
    else {
      this.copyContentToListener('')
      this.toastr.info(`No data available to copy`);
    }
    this.displayResponse = false;
  }

  copyPlaceholderContent(item) {
    this.toastr.info(`${item.placeHolderDescription} response copied`);
    this._clipboardService.copyFromContent(item.placeHolderValue);
    this.displayResponse = false;
  }

  copyContentPetition(item) {
    if (item.value) {
      this.toastr.info(`${item.description} response copied`);
      this.copyContentToListener(item.value)

    } else {
      this.copyContentToListener('')
      this.toastr.info(`No data available to copy`);
    }
    this.displayResponse = false;
  }
  copyContentToListener(copiedContent) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/html', copiedContent);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  closeDisplayResponse() {
    this.displayResponse = false;
  }

  getRfeResponseByRfenotice() {
    this.rfeService.getRfeNotificationResponse(this.rfeNoticeId).subscribe((response: any) => {
      if (response) {
        // console.log("$$$$ get Responses",response);
        //  this.toastr.success(response.message);

        //this.changeSubStepStatus.emit(stepInfo)
        // this.getSupportingDocuments();
      }
    });
  }

  saveRfeResponse() {
    //console.log("$$$$$$ save response",this.rfeCustomNotificationResponses);
    let payload = [];

    for (let item of this.rfeCustomNotificationResponses) {
      payload.push(...item.responseList);
    }

    // console.log("$$$$$$ payload",payload);



    this.rfeService.saveRfeNoticationResponse(this.rfeNoticeId, payload).subscribe((response: any) => {


      if (response.status == 200) {
        this.toastr.success(response.message);
        this.changeStatus('Inprogress');
        this.getRfeResponseByRfenotice();
        this.getRFENotification(this.rfeNoticeId)
      } else {
        this.toastr.error(response.message);
      }
      //this.changeSubStepStatus.emit(stepInfo)
      // this.getSupportingDocuments();

    });

  }

  changeStatus(status) {
    let stepInfo: any = {};
    stepInfo.name = "Add Response";
    stepInfo.status = status;
    if (status === 'Complete') {
      this.updateNotificationIds(stepInfo);
    } else {
      this.changeSubStepStatus.emit(stepInfo)
    }
    //

  }


  updateNotificationIds(stepInfo) {
    let payload;
    this.notificationObj.action = true;
    this.notificationObj.beneficiaryId = sessionStorage.getItem('userTypeRole') === "Beneficiary" ? sessionStorage.getItem("beneficiaryId") : 0;
    this.notificationObj.delete = false;
    this.notificationObj.employeeId = sessionStorage.getItem('userTypeRole') === "Petitioner" ? sessionStorage.getItem("employeeId") : 0;
    this.notificationObj.favorite = false;
    this.notificationObj.read = true;
    if (this.responseNotificationids && this.responseNotificationids.length > 0) {
      payload = this.responseNotificationids.map(item => ({ ...this.notificationObj, notificationId: item }));
    }

    // console.log("id's", payload, this.responseNotificationids);
    if (this.responseNotificationids && this.responseNotificationids.length > 0) {
      this.rfeService.updateNotificationIds(payload).subscribe((response: any) => {
        if (response.status == 200) {
          //  this.toastr.success(response.message);
          this.changeSubStepStatus.emit(stepInfo)
          // this.getSupportingDocuments();
        }
      });
    } else {
      this.changeSubStepStatus.emit(stepInfo)
    }

  }

  ngOnDestroy() {
    this.taskDetailsSubscription$.next();
    this.taskDetailsSubscription$.complete();
  }

  public itemresponselistValueChange(value: string): void {
    this.notificationResponses[0].responseText = value;
  }

  public singleresresponseValueChange(value: string): void {
    this.rfeDisplayResponse["responseText"] = value;
  }
}
