import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse, User } from 'app-models';
import { BehaviorSubject, Subject, Subscription, Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { take } from 'rxjs/operators';
import { ImagilityWebStorage } from 'web-storage';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class EditWorkExperienceDetailsService {
  public isExpired = false;
  formChangesSubscription: Subscription;
  public statusChangeSubScriptionSubjectStep = new BehaviorSubject<number>(1);
  public formIsDirtySubject = new BehaviorSubject<{ dirty: boolean, formName: string; formNumber: number }>({ dirty: false, formName: '', formNumber: 1 });
  public isExperienceChanged = new Subject<any>();
  public onLifeStorySubTabs = new Subject();
  public deletedfile$ = new BehaviorSubject<any>({});
  public showBeneficiary = new BehaviorSubject<any>(false);
  public countryDataSource = new BehaviorSubject<any>({ listCountries: [] });
  public uploaded$ = new BehaviorSubject<any>({});
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(public http: HttpClient,
    public webStorage: ImagilityWebStorage) {
    this.currentUserSubject = new BehaviorSubject<any>(this.webStorage.getWebStorageItem('currentUser'));
    this.currentUser = this.currentUserSubject.asObservable();
  }


  checkValidity() {
    return this.isExpired;/////
  }


  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  savePetitionWorkExperience(caseId: number, type: any, payload: any, familyId?: number ) {   
    const profileFamilyString = familyId ? `?familyId=${familyId}` : '';  
    return this.http.post(`/gcCase/saveExperience/${caseId}/${type}${profileFamilyString}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }
 
  saveWorkExperience(caseId: number, type: any, payload: any, familyId?: number) {   
    const profileFamilyString = familyId ? `?familyId=${familyId}` : ''; 
    let url = `/petition/saveExperience/${caseId}${profileFamilyString}`;
    if(type == 'I130') {
      url =  `/gcCase/saveExperience/${caseId}/${type}${profileFamilyString}`;
    } else if(type === 'I485'){
      url = `/petition/saveExperience/${caseId}${profileFamilyString}`;
    }
    return this.http.post(url, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }



  getBeneExperienceInformation(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, isUsedForBenEdit: boolean, familyId?: number) {
    let url = `/${visatype}/${caseType}/${caseId}/${synctype}?superTaskId=${superTaskId}&taskId=${subTaskId}&isUsedForBenEdit=${isUsedForBenEdit}`;
    if(caseType === 'I485'){      
      url = `/GCFLY/I485/${caseId}/experience?superTaskId=${superTaskId}&taskId=${subTaskId}&familyId=${familyId}&isUsedForBenEdit=${isUsedForBenEdit}`;
    }
        return this.http.get(url).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  deletBeneWorkExperience(visaType: string, caseType: string, caseId: number, type: string, superTaskId: number, subTaskId: number, experienceId: number) {
    return this.http.delete(`/${visaType}/${caseType}/${caseId}/experience/${experienceId}?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    )
  }

  // genericGetUploadDocument(useCase: string, useCaseId: number, categoryName: string, entityId: any, useEntityId: boolean, familyId?: number) {
  //   const entityUrlString = (useEntityId) ? `/entity/${entityId}` : '';
  //   const profileFamilyString = familyId ? `?familyId=${familyId}` : '';
  //   return this.http.get(`/document/${useCase}/${useCaseId}/category/${categoryName}${entityUrlString}${profileFamilyString}`).pipe(
  //     map((response: ImagilityBaseResponse) => {
  //       if (response.status === 200) {
  //         response.data = response.data.filter(x => x.fileLocation !== null);
  //         return response;
  //       }
  //       return response;
  //     })
  //   );
  // }

  genericGetUploadDocument(petitionId: number, categoryCode: string, fileCategoryCode: any, entityId: any) {
    return this.http.get(`/document/category/fileCategory/${petitionId}/${categoryCode}/${fileCategoryCode}/${entityId}
    `).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          response.data = response.data.filter(x => x.fileLocation !== null);
          return response;
        }
        return response;
      })
    );
  }

  onUnSubscribe(subcriptions: Subscription[]) {
    subcriptions.forEach(subcription => {
      if (subcription) {
        subcription.unsubscribe();
      }
    });
  }

  checkBeneEditPermission() {
    const permission = JSON.parse(sessionStorage.getItem('currentUser'));
    return permission ? permission['permissions'].length > 0 && permission['permissions'].find(x => x === 'EDIT_BENEFICIARY') ? true : false : false;
    // return permission['permissions'].length>0 && permission['permissions'].find(x=> x === 'DELETE_BENEFICIARY')? true : false;
  }

  experienceChanged(data) {
    this.isExperienceChanged.next(data);
  }


  getStates(countryCode: string) {
    return this.http.get(`/countries/states?countryCode=${countryCode}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status) {
          return response.data;
        }
      })
    );
  }

  getCountry() {
    return this.http.get('/countries').pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.data) {
          this.countryDataSource.next({ listCountries: response.data });
          return response.data;
        }
      })
    );
  }


  getCachedCountryList() {
    return this.countryDataSource.asObservable();
  }

  downloadDocuments(path: string, fileName: string) {
    this.http.get(path, { responseType: "arraybuffer" })
    .pipe(take(1))
    .subscribe(pdf => {
      const blob = new Blob([pdf], { type: 'application/pdf' });
      saveAs(blob, fileName);
    });
  }

}
