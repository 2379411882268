import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { JobDescriptionService } from './../job-description/job-description.service';
import { TaskStep, PrimaryData, ImagilityBaseResponse } from 'app-models';
import { PlaceHolderDirective } from 'place-holder-library';
import { updateStepStatus, getStepDetails, getStepStatusUpdate, updateStepStatusToInitialState } from 'visa-store';
import { DirtyFormGuard, MarkCleanFormAction } from 'dirty-check-store';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { H1BEERPayload, ProvideEerService } from './provide-eer.service';
import { CacheCountryListService } from 'cache-country-list';
import { SyncDetailsService } from '../../utility/services/sync-details.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-provide-eer',
  templateUrl: './provide-eer.component.html',
  styleUrls: ['./provide-eer.component.scss']
})
export class ProvideEERComponent implements OnInit, OnDestroy {

  @ViewChild(PlaceHolderDirective, { static: true }) placeHolder: PlaceHolderDirective;

  isLastTab: boolean;
  selectedItem: number;
  disabledControls: boolean;
  typeOfButton: string; // This is required for status update button
  stepDetails: TaskStep;

  observableSubscription$ = new Subject();
  primaryData: PrimaryData;
  payload: H1BEERPayload;
  showProjectName: boolean;
  tabType: string;
  modifiedDate: string;
  editorName: string;
  editorSubName: string;
  editorLabel: string;
  // componentRef: any;
  countryList: any[];
  documentTypeDropdownCode: string;
  fileUploaderName: string;
  sectionCode = 'EER';
  workLocationTypeCode: string;
  selectedProject;
  ListOfProjects = [];
  ListOfComanyProjects = [];
  isSimplifyH1B = false;
  skipList: any;
  infoMsg: string;

  constructor(
    private store: Store<any>,
    private guard: DirtyFormGuard,
    private stepStatusUpdatePayloadConstruction: StepStatusUpdatePayloadService,
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private provideEerService: ProvideEerService,
    private cacheCountyService: CacheCountryListService,
    private jobDescriptionService: JobDescriptionService,
    private syncDetailsService: SyncDetailsService,
    private toastr: ToastrService,
  ) {
    this.disabledControls = false;
    this.primaryData = this.dynamicDialogConfig.data;
    this.infoMsg = 'Note that some of the steps are interdependent. Such steps will also be skipped when you skip the parent step.';
  }

  ngOnInit(): void {
    this.isSimplifyH1B = (sessionStorage.getItem('isSimplify') == 'CWF') ? true : false;
    if(this.isSimplifyH1B){
      this.onLoadSkip();
    }
    this.store.dispatch(new MarkCleanFormAction({
      dirty: false
    }));
    // Selector for step status update
    this.store.pipe(select(getStepStatusUpdate))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.sectionCode = null;
          setTimeout(() => {
            this.sectionCode = 'EER';
          }, 0);
        }
      });
    this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        this.stepDetails = data;
        this.typeOfButton = this.stepDetails.isPetitionerAction === 1 || this.stepDetails.isAttorneyAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
        this.modifiedDate = this.stepDetails.modifiedDate ? moment(new Date(this.stepDetails.modifiedDate)).format('MM/DD/YYYY') : null;
        this.toggleControlsStatus();
      });
    this.provideEerService.getSupervisorDetailsSubject.subscribe(() => {
      this.selectedProject = null;
      this.getSupervisorDetails();
    });
    this.getCountryList();
    this.handleSupervisor();
    this.loadProjectList_ddl();
    this.provideEerService.onMasterDataSave
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((id) => {
        this.provideEerService.getProjectList_ddl(this.primaryData.companyId)
          .pipe(take(1))
          .subscribe(res => {
            this.ListOfProjects = res;
            this.ListOfComanyProjects = [...this.ListOfProjects];
            this.ListOfComanyProjects.push({ projectName: 'Other' });
            if (this.ListOfComanyProjects && this.ListOfComanyProjects.length > 0) {
              this.selectedProject = this.ListOfComanyProjects.find(p => p.id === id);
              this.changeProject();
            }
          });
      });
    combineLatest([
      this.provideEerService.getSupervisorDetails(this.primaryData.visatype,
        this.primaryData.petitionType, this.primaryData.petitionId,
        this.primaryData.superTaskId, this.primaryData.subTaskId),
      this.jobDescriptionService.getJobDescription(this.primaryData.visatype, this.primaryData.caseType, this.primaryData.caseId)
    ]).pipe(take(1))
      .subscribe(response => {
        if (response[1] && response[1].status === 200) {
          this.workLocationTypeCode = response[1].data.workLocationTypeCode || null;
          this.setSupervisorDetails(response[0]);
        }
      });
  }

  onLoadSkip(){
    const palyload: any = this.primaryData;
    this.syncDetailsService.getSkipStepDetails(palyload.individualStepId,sessionStorage.getItem('customStepTaskId')).subscribe((res)=>{
      if(res){
        this.skipList = res;
        this.infoMessage(this.skipList);
      }
    });
  }

  infoMessage(skipList){
    this.skipList = skipList;
    this.skipList.map((data: any) => {
      let childStepInfo = '';
      let stepInfo = '';
      if (data.child.length === 1) {
          childStepInfo = data.child[0];
          stepInfo = 'step';
      } else if (data.child.length > 1) {
          stepInfo = 'steps';
          childStepInfo = data.child.slice(0, -1).join(', ') + ' and ' + data.child[data.child.length - 1];
      }
  
      data.infoMsg = `When you skip ${data.stepName} (Parent step), ${childStepInfo} (child ${stepInfo}) will automatically be skipped.`;
      
      return data;
    });
  }

  toggleControlsStatus() {
    this.disabledControls =
      this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';
  }

  setSupervisorDetails(response) {
    if (response) {
      const { clientDetails, vendorDetails, worklocationName, worklocationType, ...payload } = {
        ...response,
        clientDetails: response.clientDetails,
        vendorDetails: response.vendorDetails,
        worklocationName: response.worklocationName,
        worklocationType: response.worklocationType,
        projectId: this.selectedProject && this.selectedProject.id ? this.selectedProject.id : null
      };
      this.payload = Object.assign({}, payload);
      this.sectionCode = null;
      setTimeout(() => {
        this.sectionCode = 'EER';
      }, 0);
    }
  }

  loadProjectList_ddl() {
    this.provideEerService.getProjectList_ddl(this.primaryData.companyId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(res => {
        this.ListOfProjects = res;
        this.ListOfComanyProjects = [...this.ListOfProjects];
        this.ListOfComanyProjects.push({
          projectName: 'Other',
          additionalDetailsDescription: null,
          id: null,
          projectDescription: null,
          projectMilestoneDescription: null,
          projectStaffingDescription: null,
          requestFlag: null
        });
      });
  }

  changeProject() {
    const newPayload = {
      clientSuperviorEmail: this.payload.clientSuperviorEmail,
      clientSupervisorFirstName: this.payload.clientSupervisorFirstName,
      clientSupervisorLastName: this.payload.clientSupervisorLastName,
      clientSupervisorMiddleName: this.payload.clientSupervisorMiddleName,
      clientSupervisorPhoneCode: this.payload.clientSupervisorPhoneCode,
      clientSupervisorPhoneNo: this.payload.clientSupervisorPhoneNo,
      clientSupervisorRole: this.payload.clientSupervisorRole,
      petitionerEmail: this.payload.petitionerEmail,
      petitionerFirstName: this.payload.petitionerFirstName,
      petitionerLastName: this.payload.petitionerLastName,
      petitionerMiddleName: this.payload.petitionerMiddleName,
      petitionerPhoneCode: this.payload.petitionerPhoneCode,
      petitionerPhoneNo: this.payload.petitionerPhoneNo,
      petitionerSupervisorRole: this.payload.petitionerSupervisorRole,
      additionDetails: this.selectedProject && this.selectedProject.additionalDetailsDescription ?
        this.selectedProject.additionalDetailsDescription : null,
      projectDetails: this.selectedProject && this.selectedProject.projectDescription ? this.selectedProject.projectDescription : null,
      projectMilestones: this.selectedProject && this.selectedProject.projectMilestoneDescription ?
        this.selectedProject.projectMilestoneDescription : null,
      projectName: this.selectedProject && this.selectedProject.projectName !== 'Other' ? this.selectedProject.projectName : null,
      // projectName: this.selectedProject && this.selectedProject.projectName ? this.selectedProject.projectName : null,
      projectStaffing: this.selectedProject && this.selectedProject.projectStaffingDescription ?
        this.selectedProject.projectStaffingDescription : null,
      projectId: this.selectedProject && this.selectedProject.id ? this.selectedProject.id : null
    };
    this.payload = Object.assign({}, newPayload);
  }
  getSupervisorDetails() {
    this.provideEerService.getSupervisorDetails(this.primaryData.visatype,
      this.primaryData.petitionType, this.primaryData.petitionId,
      this.primaryData.superTaskId, this.primaryData.subTaskId)
      .pipe(take(1))
      .subscribe(response => {
        this.setSupervisorDetails(response);
      });
  }

  // To fetch  list of countries
  getCountryList() {
    this.cacheCountyService.getCountry()
      .pipe(take(1))
      .subscribe(data => {
        if (data.length > 0) {
          data = (data as any[]).map(x => {
            return {
              ...x,
              displayPhoneCode: `${x.phoneCode} (${x.countryName})`
            };
          });
          (data as any[]).unshift({
            countryCode: null,
            countryName: null,
            createdBy: null,
            modifiedBy: null,
            phoneCode: null,
            sequenceNumber: null,
            shortCountryCode: null,
            displayPhoneCode: 'Select'
          });
          this.countryList = data;
        }
      });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadConstruction.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.primaryData.caseType === 'I130' ?
        this.primaryData.caseType : this.primaryData.visatype,
      stepId: this.primaryData.stepId
    }));
  }

  // loadComponent(component) {
  //   const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
  //   const viewContainerRef = this.placeHolder.viewContainerRef;
  //   viewContainerRef.clear();
  //   const componentRef = viewContainerRef.createComponent<any>(componentFactory);
  //   componentRef.instance.primaryData = this.primaryData;
  //   componentRef.instance.stepDetails = this.stepDetails;
  // }

  handleSupervisor() {
    if (!this.isSimplifyH1B) {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
        this.isLastTab = false;
        this.selectedItem = 0;
        this.tabType = '';
      } else {
        return false;
      }
    });
  }
  }

  handleCustSupervisor(){
    this.selectedItem = 0;
  }

  handleDetails(tabIndex, isLastTab, showProjectName, editorName: string, editorSubName: string, editorLabel: string) { 
    if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
      return;
    }
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        if (!this.isSimplifyH1B) {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
        }
        this.isLastTab = isLastTab;
        this.selectedItem = tabIndex;
        this.showProjectName = showProjectName;
        switch (editorName) {
          case 'INHSE':
            editorName = 'In-House';
            break;
          case 'TPARTY':
            editorName = 'Third Party Site';
            break;
          case 'INHSECLT':
            editorName = 'Third Party Project Developed In-House';
            break;
          default:
            editorName = null;
            break;
        }
        this.editorName = editorName;
        this.editorSubName = editorSubName;
        this.editorLabel = editorLabel;
        this.tabType = 'editor';
      } else {
        return false;
      }
    });
  }

  handleDocuments(tabIndex, isLastTab, fileUploaderName: string, documentTypeDropdownCode: string) {
    if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
      return;
    }
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        if (!this.isSimplifyH1B) {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
        }
        this.isLastTab = isLastTab;
        this.selectedItem = tabIndex;
        this.fileUploaderName = fileUploaderName;
        this.documentTypeDropdownCode = documentTypeDropdownCode;
        this.tabType = 'fileUpload';
      } else {
        return false;
      }
    });
  }

  handleClose() {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  savePetition() {
    const { projectId, ...petitionPayload } = {
      ...this.payload,
      projectId: this.payload && this.payload.projectId ? this.payload.projectId : null,
    };
    this.provideEerService.postSupervisorDetails(this.primaryData.visatype,
      this.primaryData.petitionType, this.primaryData.petitionId,
      this.primaryData.superTaskId, this.primaryData.subTaskId, petitionPayload)
      .pipe(take(1))
      .subscribe(response => {
        if (response) {
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
          this.getSupervisorDetails();
        }
      });
  }

  saveMasterData() {
    const payload = {
      additionalDetailsDescription: this.payload && this.payload.additionDetails ? this.payload.additionDetails : null,
      id: this.payload && this.payload.projectId ? this.payload.projectId : 0,
      projectDescription: this.payload && this.payload.projectDetails ? this.payload.projectDetails : null,
      projectMilestoneDescription: this.payload && this.payload.projectMilestones ? this.payload.projectMilestones : null,
      projectName: this.payload ? this.payload.projectName : null,
      projectStaffingDescription: this.payload && this.payload.projectStaffing ? this.payload.projectStaffing : null,
      requestFlag: 'updatemasterdata'
    };
    const { projectId, ...petitionPayload } = {
      ...this.payload,
      projectId: this.payload && this.payload.projectId ? this.payload.projectId : null,
    };
    combineLatest([
      this.provideEerService.postSupervisorDetails(this.primaryData.visatype,
        this.primaryData.petitionType, this.primaryData.petitionId,
        this.primaryData.superTaskId, this.primaryData.subTaskId, petitionPayload),
      this.provideEerService.saveMasterData(this.primaryData.companyId, payload)
    ])
      .pipe(take(1))
      .subscribe((response: any[]) => {
        if (response[0]) {
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
          this.getSupervisorDetails();
        }
      });
  }

  handleSwitchChange(event, item){
    if(event && item){
      this.syncDetailsService.saveSkipStepDetails(item.taskStepId, event.checked).subscribe((res)=>{
        if(res.status == 200){
          this.skipList = res.data;
          this.infoMessage(this.skipList);
          this.toastr.success(res.message);
        } else {
          this.toastr.error(res.message);
        }
      });
    }
  } 

  // onClickAccordion(item){
  //   this.selectedItem = item;
  // }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }

}

