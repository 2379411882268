import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProvideEerService {
  getSupervisorDetailsSubject = new Subject();
  onMasterDataSave = new Subject<number>();

  constructor(
    private http: HttpClient,
    private toastr: ToastrService
  ) { }

  getSupervisorDetails(visaType: string, caseType: string, caseId: number, superTaskId: number, taskId: number) {
    return this.http.get(`/${visaType}/${caseType}/${caseId}/eerDetails?superTaskId=${superTaskId}&taskId=${taskId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 && response.data) {
          return response.data;
        } else {
          return null;
        }
      })
    );
  }

  postSupervisorDetails(visaType: string, caseType: string, caseId: number, superTaskId: number, taskId: number, payload) {
    return this.http.post(`/${visaType}/${caseType}/${caseId}/eerDetails?superTaskId=${superTaskId}&taskId=${taskId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && response.status && response.status === 200) {
          this.toastr.success(response.message);
          return true;
        } else {
          return false;
        }
      })
    );
  }


  getProjectList_ddl(companyId) {
    return this.http.get(`/company/${companyId}/masterData/projectDetail`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 && response.data) {
          return response.data;
        } else {
          return null;
        }
      })
    );
  }

  getMasterDataDocument(caseId, entityId, categoryCode) {
    return this.http.get(`/company/${caseId}/masterData/${entityId}/synch/${categoryCode}/projectDocuments`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 && response.data) {
          return response;
        } else {
          return null;
        }
      })
    );
  }


  saveMasterData(companyId, payload) {
    return this.http.post(`/company/${companyId}/masterData/petition/projectDetail`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && response.status && response.status === 200) {
          this.toastr.success(response.message);
          return response.data;
        } else {
          return null;
        }
      })
    );
  }

  onUpdateMaster(url) {
    return this.http.post(url, {})
      .pipe(
        map((response: ImagilityBaseResponse) => {
          if (response && response.status && response.status === 200) {
            this.toastr.success(response.message);
            return true;
          } else {
            return false;
          }
        })
      );
  }
}
export interface H1BEERPayload {
  additionDetails: string;
  clientSuperviorEmail: string;
  clientSupervisorFirstName: string;
  clientSupervisorLastName: string;
  clientSupervisorMiddleName: string;
  clientSupervisorPhoneCode: string;
  clientSupervisorPhoneNo: string;
  clientSupervisorRole: string;
  petitionerEmail: string;
  petitionerFirstName: string;
  petitionerLastName: string;
  petitionerMiddleName: string;
  petitionerPhoneCode: string;
  petitionerPhoneNo: string;
  petitionerSupervisorRole: string;
  projectDetails: string;
  projectMilestones: string;
  projectName: string;
  projectStaffing: string;
  projectId: string;
}



