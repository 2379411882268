import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-invited-collected-leads',
  templateUrl: './invited-collected-leads.component.html',
  styleUrls: ['./invited-collected-leads.component.scss']
})
export class InvitedCollectedLeadsComponent implements OnInit {
  urlParams: any;
  linkCode: any;
  companyData: any;
  attorneyLogo: string;
  invalidLink: boolean;
  leadSourcesList: { id: string; name: string; }[];
  leadForm: FormGroup;
  submitted = false;
  websiteLinkback: any;
  emailavailable: boolean;
  formSubmit: string;
  companies : any[]=[];
  selectedCompany:any;
  submitLoader: boolean;

  constructor(private http: HttpClient, private fb: FormBuilder, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.leadForm = this.fb.group({
      sourceId: ['none', Validators.required],
      leadTypeCode: ['individual', Validators.required],
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      phoneContacts: ['', [Validators.required,Validators.pattern('[- +()0-9]+')]],
      emailContacts: ['', [Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      companyName: [null, []],
      message: ['', Validators.required],
    });
    // this.leadForm.get("companyName").setValue({employerName:null});
    this.leadForm.get('leadTypeCode').valueChanges.subscribe(val => {
      if (val !== 'individual') {
        this.leadForm.controls['companyName'].setValidators([Validators.required]);
      } else {
        this.leadForm.controls['companyName'].clearValidators();
      }
      this.leadForm.controls['companyName'].updateValueAndValidity();
    });



    if (window.location.search !== '') {
      this.urlParams = window.location.search.split('?')[1].split('&');
      // if(this.urlParams[1]){
      //   this.websiteLinkback = this.urlParams[1].split('=')[0]==='website';
      // }
    }
    else {
      this.urlParams = ''
      this.invalidLink = true
    };
    this.linkCode = this.urlParams[0].split('=')[1];
    if (!this.invalidLink) {
      this.leadSourcesList = [{
        id: "WEBSITE",
        name: "Website"
      }, {
        id: "FACEBOOK",
        name: "Facebook"
      }, {
        id: "LINKEDIN",
        name: "LinkedIn"
      }]
      console.log(this.linkCode)
      this.http.get(`/marketing/lead/createCollectedLead/link/validate/${this.linkCode}`)
        .pipe(
          map((response: any) => response)
        )
        .subscribe(response => {
          // sourceId
          console.log(response);
          if (response.message == "Collected lead link is valid and returning the company obj. ") {
            this.companyData = response;
            this.attorneyLogo = 'data:image/png;base64,' + this.companyData.data.company.companyPic;
          } else {
            this.invalidLink = true;
          }
        });
    }
  }

  search(event) {
    this.http.get(`/marketing/lead/employeeMasterDataList/${event.query}`)
        .pipe(
          map((response: any) => response)
        )
        .subscribe(response => {
          if(response?.data !== null) {
            this.companies = response.data;
          }else{
            this.companies = [];
          }
    });
}


  //

  get f(): { [key: string]: AbstractControl } {
    return this.leadForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    this.submitLoader = true;

    if (this.leadForm.invalid) {
      this.submitLoader = false;
      return;
    }

    let reqObj = this.leadForm.value;
    reqObj.companyId = this.companyData.data.companyId;
    reqObj.createdId = this.companyData.data.createdId;
    reqObj.company = this.companyData.data.company;
    reqObj.leadCompanyId = this.leadForm.value.companyName?.id?this.leadForm.value.companyName?.id:null,
    reqObj.companyName = this.leadForm.value.companyName?.employerName?this.leadForm.value.companyName?.employerName:this.leadForm.value.companyName;
    reqObj.leadPriorityCode = "",
    reqObj.middleName = "",
    reqObj.status = "NEW",
    reqObj.id = 0;
    reqObj.isDel = 0;
    try {
      this.http.post(`/marketing/lead/createCollectedLead/company/${this.companyData.data.companyId}`, reqObj).pipe((response:any) => response).subscribe((res:any) => {
        
        this.emailavailable = false;
        if(res.data === true){
          this.toastr.warning('',res.message==="Email is available."?'Email account is already registered':res.message);
          this.emailavailable = true;
          this.formSubmit  = 'failed'
          this.submitLoader = false;
        }
        if(res.message === "Saved lead Successfully." && !this.emailavailable){
          this.toastr.success('',res.message);
        this.emailavailable = false;
        this.formSubmit  = 'success';
        this.submitLoader = false;
        this.leadForm.reset();
        this.submitted = false;
        }
        console.log(res);
      })
    } catch (error) {
      this.toastr.warning('','Something went wrong. Please try again.');
      this.emailavailable = false;
        this.submitLoader = false;
        this.submitted = false;
    }

  }

}
