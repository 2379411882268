<!-- <div *ngIf="loading">
  Loading...
</div> -->
<div *ngIf="!editDetails && !showAddIcon">
  <div class="row  mb-5" *ngIf="!isExternalEmployeeId">
    <div class="col-12 text-right">
      <ng-container *ngIf="!viewMode && showEditButton">
        <button class="back-button button-width-icon-left btn-pos-top-right" (click)="handleEdit()">
          <!-- <img src="../../../../../assets/images/edit.png" alt="" /> -->
          <i class="fa fa-pencil"></i>
          Edit
        </button>
      </ng-container>
    </div>
  </div>
  <div class="row row-cols-5">
    <div class="col mb-2">
      <p class="label-light m-0">User Name</p>
      <label class="label-dark">{{
        beneficiaryData?.userName ? beneficiaryData?.userName : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">First Name</p>
      <label class="label-dark">{{
        beneficiaryData?.fName ? beneficiaryData?.fName : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Middle Name</p>
      <label class="label-dark">
        {{
        beneficiaryData?.mName ? beneficiaryData?.mName : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Last Name</p>
      <label class="label-dark">{{
        beneficiaryData?.lName ? beneficiaryData?.lName : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Date of Birth</p>
      <label class="label-dark">{{
        this.datesStoredArrayForDisplay["dob"]
        ? (this.datesStoredArrayForDisplay["dob"] | ImgDateFormat)
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Gender</p>
      <label class="label-dark">{{
        beneficiaryData?.gender?.name
        ? beneficiaryData?.gender?.name
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Country Code</p>
      <label class="label-dark">{{
        beneficiaryData?.phoneContacts[0]?.countryCode?.countryCode
        ? beneficiaryData?.phoneContacts[0]?.countryCode
        ?.countryCode
        : "--"
        }}
        -
        {{
        beneficiaryData?.phoneContacts[0]?.countryCode?.phoneCode
        ? beneficiaryData?.phoneContacts[0]?.countryCode?.phoneCode
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Mobile No</p>
      <label class="label-dark">{{
        beneficiaryData?.phoneContacts[0]?.phoneNo
        ? beneficiaryData?.phoneContacts[0]?.phoneNo
        : "--"
        }}</label>
    </div>
    <div class="col mb-2" *ngIf="onlyFormBuilder !== 'FB'">
      <p class="label-light m-0">SSN</p>
      <label class="label-dark">{{
        beneficiaryData?.ssn ? maskify(beneficiaryData?.ssn) : "--"
        }}</label>
    </div>
    <div class="col-4 mb-2">
      <p class="label-light m-0">Email Id</p>
      <!-- IM-3619  : Added class for wrap email id. -->
      <label class="label-dark wrap-word">{{
        beneficiaryData?.emailContacts[0]?.email
        ? beneficiaryData?.emailContacts[0]?.email
        : "--"
        }}</label>
    </div>

  </div>
  <div class="row" *ngIf="onlyFormBuilder !== 'FB'">
    <div class="col-12">
      <p class="label-light m-0">Are you a Lawful Permanent Resident?</p>
      <label class="label-dark wrap-word">{{beneficiaryData?.isLpr ? 'Yes':'No'}}</label>
    </div>
  </div>

  <h5 class="labelText mt-2">Place of Birth</h5>
  <div class="row row-cols-5">
    <div class="col mb-2">
      <p class="label-light m-0">Country</p>
      <label class="label-dark">{{
        beneficiaryData?.countryCode
        ? (beneficiaryData?.countryCode?.countryName ? beneficiaryData?.countryCode?.countryName
        : "--") : '--'
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">State</p>
      <label class="label-dark" *ngIf="listStatesBirth.length > 0">{{
        beneficiaryData?.birthStateProvinceCode
        ? getStateName(
        beneficiaryData?.birthStateProvinceCode,
        "Birth"
        )
        : "--"
        }}</label>
      <label class="label-dark" *ngIf="listStatesBirth.length <= 0">{{
        beneficiaryData?.birthStateProvinceName
        ? beneficiaryData?.birthStateProvinceName
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">City</p>
      <label class="label-dark">{{
        beneficiaryData?.birthCity ? beneficiaryData?.birthCity : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Citizenship</p>
      <label class="label-dark">{{
        beneficiaryData?.ctznshipCountryCode?.countryName
        ? beneficiaryData?.ctznshipCountryCode?.countryName
        : "--"
        }}</label>
    </div>
  </div>
  <h5 class="labelText mt-2">Marital Status</h5>
  <div class="row">
    <div class="col-12">
      <p class="label-light m-0">What is your current marital status?</p>
      <label class="label-dark wrap-word">{{beneficiaryData?.maritalStatus?.name}}</label>
    </div>
  </div>
  <h5 class="labelText mt-2">Physical Address Details</h5>
  <div class="row row-cols-5">
    <div class="col mb-2 word-wrap">
      <p class="label-light m-0">Address 1</p>
      <label class="label-dark">{{
        beneficiaryData?.address[0]?.addressLine1
        ? beneficiaryData?.address[0]?.addressLine1
        : "--"
        }}</label>
    </div>
    <div class="col mb-2 word-wrap">
      <p class="label-light m-0">Address 2</p>
      <label class="label-dark">{{
        beneficiaryData?.address[0]?.addressLine2
        ? beneficiaryData?.address[0]?.addressLine2
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Area</p>
      <label class="label-dark">{{
        beneficiaryData?.address[0]?.locality
        ? beneficiaryData?.address[0]?.locality
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Country</p>
      <label class="label-dark">{{
        beneficiaryData?.address[0]?.countryCode?.countryName
        ? beneficiaryData?.address[0]?.countryCode?.countryName
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">State</p>
      <label class="label-dark" *ngIf="listStatesCurrent.length > 0">{{
        beneficiaryData?.address[0]?.stateProvinceCode
        ? getStateName(
        beneficiaryData?.address[0]?.stateProvinceCode,
        "Current"
        )
        : "--"
        }}</label>
      <label class="label-dark" *ngIf="listStatesCurrent.length <= 0">{{
        beneficiaryData?.address[0]?.stateProvinceName
        ? beneficiaryData?.address[0]?.stateProvinceName
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">City</p>
      <label class="label-dark">{{
        beneficiaryData?.address[0]?.city
        ? beneficiaryData?.address[0]?.city
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Zip / Postal code</p>
      <label class="label-dark">{{
        beneficiaryData?.address[0]?.postCode
        ? beneficiaryData?.address[0]?.postCode
        : "--"
        }}</label>
    </div>
  </div>
  <h5 class="labelText mt-2">Mailing Address Details</h5>
  <div class="row row-cols-5">
    <div class="col mb-2 word-wrap">
      <p class="label-light m-0">Address 1</p>
      <label class="label-dark">{{
        beneficiaryData?.address[1]?.addressLine1
        ? beneficiaryData?.address[1]?.addressLine1
        : "--"
        }}</label>
    </div>
    <div class="col mb-2 word-wrap">
      <p class="label-light m-0">Address 2</p>
      <label class="label-dark">{{
        beneficiaryData?.address[1]?.addressLine2
        ? beneficiaryData?.address[1]?.addressLine2
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Area</p>
      <label class="label-dark">{{
        beneficiaryData?.address[1]?.locality
        ? beneficiaryData?.address[1]?.locality
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Country</p>
      <label class="label-dark">{{
        beneficiaryData?.address[1]?.countryCode?.countryName
        ? beneficiaryData?.address[1]?.countryCode?.countryName
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">State</p>
      <label class="label-dark" *ngIf="listStatesMailing.length > 0">{{
        beneficiaryData?.address[1]?.stateProvinceCode
        ? getStateName(
        beneficiaryData?.address[1]?.stateProvinceCode,
        "Mailing"
        )
        : "--"
        }}</label>
      <label class="label-dark" *ngIf="listStatesMailing.length <= 0">{{
        beneficiaryData?.address[1]?.stateProvinceName
        ? beneficiaryData?.address[1]?.stateProvinceName
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">City</p>
      <label class="label-dark">{{
        beneficiaryData?.address[1]?.city
        ? beneficiaryData?.address[1]?.city
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Zip / Postal code</p>
      <label class="label-dark">{{
        beneficiaryData?.address[1]?.postCode
        ? beneficiaryData?.address[1]?.postCode
        : "--"
        }}</label>
    </div>
  </div>
  <h5 class="labelText mt-2">Foreign Address Details</h5>
  <div class="row row-cols-5">
    <div class="col mb-2 word-wrap">
      <p class="label-light m-0">Address 1</p>
      <label class="label-dark">{{
        beneficiaryData?.address[2]?.addressLine1
        ? beneficiaryData?.address[2]?.addressLine1
        : "--"
        }}</label>
    </div>
    <div class="col mb-2 word-wrap">
      <p class="label-light m-0">Address 2</p>
      <label class="label-dark">{{
        beneficiaryData?.address[2]?.addressLine2
        ? beneficiaryData?.address[2]?.addressLine2
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Area</p>
      <label class="label-dark">{{
        beneficiaryData?.address[2]?.locality
        ? beneficiaryData?.address[2]?.locality
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Country</p>
      <label class="label-dark">{{
        beneficiaryData?.address[2]?.countryCode?.countryName
        ? beneficiaryData?.address[2]?.countryCode?.countryName
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">State</p>
      <label class="label-dark" *ngIf="listStatesForeign.length > 0">{{
        beneficiaryData?.address[2]?.stateProvinceCode
        ? getStateName(
        beneficiaryData?.address[2]?.stateProvinceCode,
        "Foreign"
        )
        : "--"
        }}</label>
      <label class="label-dark" *ngIf="listStatesForeign.length <= 0">{{
        beneficiaryData?.address[2]?.stateProvinceName
        ? beneficiaryData?.address[2]?.stateProvinceName
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">City</p>
      <label class="label-dark">{{
        beneficiaryData?.address[2]?.city
        ? beneficiaryData?.address[2]?.city
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Zip / Postal code</p>
      <label class="label-dark">{{
        beneficiaryData?.address[2]?.postCode
        ? beneficiaryData?.address[2]?.postCode
        : "--"
        }}</label>
    </div>
  </div>
  <h5 class="labelText mt-2">Permanent Address Details</h5>
  <div class="row row-cols-5">
    <div class="col mb-2 word-wrap">
      <p class="label-light m-0">Address 1</p>
      <label class="label-dark">{{
        beneficiaryData?.address[3]?.addressLine1
        ? beneficiaryData?.address[3]?.addressLine1
        : "--"
        }}</label>
    </div>
    <div class="col mb-2 word-wrap">
      <p class="label-light m-0">Address 2</p>
      <label class="label-dark">{{
        beneficiaryData?.address[3]?.addressLine2
        ? beneficiaryData?.address[3]?.addressLine2
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Area</p>
      <label class="label-dark">{{
        beneficiaryData?.address[3]?.locality
        ? beneficiaryData?.address[3]?.locality
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Country</p>
      <label class="label-dark">{{
        beneficiaryData?.address[3]?.countryCode?.countryName
        ? beneficiaryData?.address[3]?.countryCode?.countryName
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">State</p>
      <label class="label-dark" *ngIf="listStatesPermanent.length > 0">{{
        beneficiaryData?.address[3]?.stateProvinceCode
        ? getStateName(
        beneficiaryData?.address[3]?.stateProvinceCode,
        "Permanent"
        )
        : "--"
        }}</label>
      <label class="label-dark" *ngIf="listStatesPermanent.length <= 0">{{
        beneficiaryData?.address[3]?.stateProvinceName
        ? beneficiaryData?.address[3]?.stateProvinceName
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">City</p>
      <label class="label-dark">{{
        beneficiaryData?.address[3]?.city
        ? beneficiaryData?.address[3]?.city
        : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Zip / Postal code</p>
      <label class="label-dark">{{
        beneficiaryData?.address[3]?.postCode
        ? beneficiaryData?.address[3]?.postCode
        : "--"
        }}</label>
    </div>
  </div>
  <h5 class="labelText mt-2" *ngIf="onlyFormBuilder !== 'FB'">Biographic Information</h5>
  <div class="row row-cols-5" *ngIf="onlyFormBuilder !== 'FB'">
    <div class="col mb-2">
      <p class="label-light m-0">Ethnicity</p>
      <label class="label-dark">{{
        beneficiaryData?.ethnicity?.name ? beneficiaryData?.ethnicity?.name : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Race</p>

      <label class="label-dark" *ngFor="let i of beneficiaryData?.race;let last = last">
        <span>{{i?.raceId?.name}}</span><span *ngIf="!last">,</span>
      </label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Hair Color</p>
      <label class="label-dark">{{
        beneficiaryData?.hairColor ? beneficiaryData?.hairColor : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Eyes Color</p>
      <label class="label-dark">{{
        beneficiaryData?.eyeColor ? beneficiaryData?.eyeColor : "--"
        }}</label>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Weight</p>
      <label class="label-dark">{{
        beneficiaryData?.weight ? beneficiaryData?.weight : "--"
        }}</label><small class="label-light pl-1" *ngIf="beneficiaryData?.weight">Pounds</small>
    </div>


  </div>
  <div class="row row-cols-5" *ngIf="onlyFormBuilder !== 'FB'">
    <div class="col mb-2">
      <p class="label-light m-0">Height</p>
      <label class="label-dark">{{
        beneficiaryData?.height ? beneficiaryData?.height : "--"
        }} </label><small class="label-light pl-1" *ngIf="beneficiaryData?.height">Inches</small>
    </div>
    <div class="col mb-2">
      <p class="label-light m-0">Marks of identification</p>
      <label class="label-dark">{{
        beneficiaryData?.identificationMark
        ? beneficiaryData?.identificationMark
        : "--"
        }}</label>
    </div>
  </div>

  <div class="row row-cols-5" *ngIf="onlyFormBuilder !== 'FB'">
    <h5 class="labelText mt-2">Passport Details</h5>
    <div class="col">
      <p class="label-light m-0">Passport No.</p>
      <label class="label-dark">{{
        beneficiaryData?.passport[0]?.passportNo
        ? beneficiaryData?.passport[0]?.passportNo
        : "--"
        }}</label>
    </div>
    <div class="col">
      <p class="label-light m-0">Issuing Authority</p>
      <label class="label-dark">{{
        beneficiaryData?.passport[0]?.issueCountryCode?.countryName
        ? beneficiaryData?.passport[0]?.issueCountryCode?.countryName
        : "--"
        }}</label>
    </div>
    <div class="col">
      <p class="label-light m-0">Date of Issue</p>
      <label class="label-dark">{{
        this.datesStoredArrayForDisplay["issueDate"]
        ? (this.datesStoredArrayForDisplay["issueDate"]
        | ImgDateFormat)
        : "--"
        }}</label>
    </div>
    <div class="col">
      <p class="label-light m-0">Date of Expiry</p>
      <label class="label-dark">{{
        this.datesStoredArrayForDisplay["expiryDate"]
        ? (this.datesStoredArrayForDisplay["expiryDate"]
        | ImgDateFormat)
        : "--"
        }}</label>
    </div>
  </div>

  <h5 class="labelText mt-2" *ngIf="onlyFormBuilder !== 'FB'">Additional Info</h5>

  <div class="col-md-4">
    <div class="form-group">
     
      <p class="label-light m-0">Visa Sponsor</p>
      <label class="label-dark">
        {{
          beneficiaryData?.visaSponsor ? beneficiaryData?.visaSponsor : "--"
          }}
        </label>
    </div>
  </div>

  <ng-container *ngIf="onlyFormBuilder !== 'FB' && (beneficiaryData && beneficiaryData.id > 0)">
    <h5 class="labelText mt-2">Signature</h5>
    <div class="row row-cols-12">
      <img *ngIf='!!signatureImage' alt="Signature" class="signatureImageBox" [src]="signatureImage">
    </div>
  </ng-container>

  <div *ngIf="detailsAbout.showDetailFor == 'self'">
    <div *ngFor="let ques of customQuestions?.sectionList">
      <div *ngIf="ques.name == 'Personal Details'">
        <div class="customSections" *ngFor="let item of ques.stakeHoldersSelected">
          <div *ngIf="(item?.key == 'QTPROFILEBEN') && item.selected == true">
            <app-custom-questions [isNewPortion]="false" [viewQuestions]="true" [loadedIn]="'Personal Details'"
              [beneficiaryId]="beneficiaryId" [isSection]="true" [familyId]="currentFamilyId"></app-custom-questions>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="detailsAbout.showDetailFor !== 'self'">
    <div *ngFor="let ques of customQuestions?.sectionList">
      <div *ngIf="ques.name == 'Personal Details'">
        <div class="customSections" *ngFor="let item of ques.stakeHoldersSelected">
          <div *ngIf="(detailsAbout.dependentType.code == item.key) && item.selected == true">
            <app-custom-questions [isNewPortion]="false" [viewQuestions]="true" [loadedIn]="'Personal Details'"
              [beneficiaryId]="beneficiaryId" [isSection]="true" [familyId]="currentFamilyId"></app-custom-questions>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Edit Mode -->
<div *ngIf="editDetails" class="edit-beneficiary-profile" [ngClass]="{disabledView : isViewMode}">
  <form [formGroup]="beneSelfDetailsForm" #f="ngForm"
    *ngIf="beneSelfDetailsForm && (beneficiaryData || onlyFormBuilder === 'FB')" (ngSubmit)="savePersonalData()">
    <!-- <div class="row">
      <div class="col-12">
        <h5 class="labelText">Personal Details</h5>
      </div>
    </div> -->
    <div class="row mt-2">
      <div class="col-3">
        <p class="label-cls">User Name</p>
        <input type="text" class="im-input" formControlName="userName" 
                placeholder="Enter your User Name" readonly />
      </div>
      <div class="col-3" *ngIf="iscaseRequest ? (currentItemDisplay.includes('NAME') ? true: false) : true">
        <p class="label-cls ddLabel">Title</p>        
        <p-dropdown [options]="listTitle" id="title" formControlName="title" optionLabel="name" optionValue="code"
          placeholder="Select Title"></p-dropdown>
      </div>
      <div class="col-3" *ngIf="iscaseRequest ? (currentItemDisplay.includes('NAME') ? true: false) : true">
        <p class="label-cls" *ngIf="!iscaseRequest" [class.required]="beneficiaryType==='self'">First Name</p>
        <p class="label-cls" *ngIf="iscaseRequest">First Name</p>
        <input type="text" class="im-input" [ngClass]="{
            'im-input-error':
              (beneSelfDetailsForm.get('fName').touched || submitted) &&
              beneSelfDetailsForm.get('fName').errors?.required && !iscaseRequest
          }" pattern="^[\D]*$" formControlName="fName" placeholder="Enter your First Name" />
        <span class="invalid-message" *ngIf="
            (beneSelfDetailsForm.get('fName').touched || submitted) &&
            beneSelfDetailsForm.get('fName').errors?.required && !iscaseRequest
          ">
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
        <span class="invalid-message" *ngIf="
            (beneSelfDetailsForm.get('fName').touched || submitted) &&
            beneSelfDetailsForm.get('fName').errors?.pattern
          ">
          {{ getGlobalErrorMessages("ALPHABETS_SPLCHARS") }}
        </span>
      </div>
      <div class="col-3" *ngIf="iscaseRequest ? (currentItemDisplay.includes('NAME') ? true: false) : true">
        <p class="label-cls">Middle Name</p>
        <input type="text" class="im-input" [ngClass]="{
            'im-input-error':
              (beneSelfDetailsForm.get('mName').touched || submitted) &&
              beneSelfDetailsForm.get('mName').errors?.pattern
          }" pattern="^[\D]*$" formControlName="mName" placeholder="Enter your Middle Name" />
        <span class="invalid-message" *ngIf="
            (beneSelfDetailsForm.get('mName').touched || submitted) &&
            beneSelfDetailsForm.get('mName').errors?.pattern
          ">
          {{ getGlobalErrorMessages("ALPHABETS_SPLCHARS") }}
        </span>
      </div>
      <div class="col-3" *ngIf="iscaseRequest ? (currentItemDisplay.includes('NAME') ? true: false) : true">
        <p class="label-cls" *ngIf="!iscaseRequest" [class.required]="beneficiaryType==='self'">Last Name</p>
        <p class="label-cls" *ngIf="iscaseRequest">Last Name</p>
        <input type="text" class="im-input" [ngClass]="{
            'im-input-error':
              (beneSelfDetailsForm.get('lName').touched || submitted) &&
              beneSelfDetailsForm.get('lName').errors?.required && !iscaseRequest
          }" pattern="^[\D]*$" formControlName="lName" placeholder="Enter your Last Name" />
        <span class="invalid-message" *ngIf="
            (beneSelfDetailsForm.get('lName').touched || submitted) &&
            beneSelfDetailsForm.get('lName').errors?.required && !iscaseRequest
          ">
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>
        <span class="invalid-message" *ngIf="
            (beneSelfDetailsForm.get('lName').touched || submitted) &&
            beneSelfDetailsForm.get('lName').errors?.pattern
          ">
          {{ getGlobalErrorMessages("ALPHABETS_SPLCHARS") }}
        </span>
      </div>
    </div>
    <div class="row mt-2" *ngIf="iscaseRequest ? (currentItemDisplay.includes('NAME') ? true: false) : true">
      <div class="col-12" *ngIf="editDetails">
        <mat-checkbox (change)="toggleAlias($event)" [checked]="showAliasName">
          <span>Do you have any other name?</span>
        </mat-checkbox>
      </div>
      <div class="w-100" *ngIf="showAliasName" formArrayName="aliasName">
        <div formGroupName="0" class="row">
          <div class="col-3 showAliasName">
            <mat-form-field>
              <p class="label-cls">Title</p>
              <mat-select formControlName="title">
                <mat-option *ngFor="let title of listTitle" [value]="title.name">
                  {{ title.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-9">
            <p class="label-cls">Alias Name</p>
            <input type="text" class="im-input mw-100" formControlName="fName" placeholder="Alias Name" />
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3" formGroupName="gender"
          *ngIf="iscaseRequest ? (currentItemDisplay.includes('GENDER') ? true: false) : true">
          <p class="label-cls ddLabel">Gender</p>          
        <p-dropdown [options]="listGender" formControlName="id" optionLabel="name" optionValue="id"
          placeholder="Select Gender"></p-dropdown>        
      </div>
      <div class="col-3" *ngIf="iscaseRequest ? (currentItemDisplay.includes('DOB') ? true: false) : true">
        <p class="label-cls">Date of Birth</p>        
        <mat-form-field appearance="outline" class="mt-0 date-field">
          <input formControlName="dob" matInput min="1920-12-31" [max]="currentDate" [matDatepicker]="dob"
            placeholder="MM/DD/YYYY" (click)="dob.open()" />
          <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
          <mat-datepicker #dob></mat-datepicker>
        </mat-form-field>          
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6" *ngIf="iscaseRequest ? (currentItemDisplay.includes('MOBLNO') ? true: false) : true">
        <div formArrayName="phoneContacts">
          <ng-container *ngFor="
              let x of beneSelfDetailsForm.get('phoneContacts')['controls'];
              index as i
            ">
            <div [formGroupName]="i" class="row row-with-negative-margin">
              <div class="col-6" formGroupName="countryCode">
                <p class="label-cls ddLabel">Country Code</p>                  
                <p-dropdown [options]="listCountry" formControlName="countryCode" optionValue="countryCode" optionLabel="labelWithCode" [filter]="true" filterBy="countryName" placeholder="Select"></p-dropdown>               
              </div>             

              <div class="col-6">
                <p class="label-cls">Mobile No.<small> (10 digits)</small></p>
                <input type="text" [ngClass]="{
                                'im-input-error':
                                  ((x.get('phoneNo').touched || submitted) &&
                                    x.get('phoneNo').hasError('pattern')) ||
                                  ((x.get('phoneNo').touched || submitted) && !iscaseRequest)
                              }" class="im-input col-md" formControlName="phoneNo" minlength="10" maxlength="10"
                  placeholder="Enter" />                
                <span class="invalid-message" *ngIf="
                            (x.get('phoneNo').touched || submitted) &&
                            x.get('phoneNo').hasError('pattern')
                          ">
                  {{ getGlobalErrorMessages("INVALID_NUMBER") }}
                </span>
                <span class="invalid-message" *ngIf="x.get('phoneNo').errors?.maxlength">
                  {{ getGlobalErrorMessages("MAXLENGTH") + "10" }}
                </span>
                <span class="invalid-message" *ngIf="x.get('phoneNo').errors?.minlength">
                  {{ getGlobalErrorMessages("MINLENGTH") + "10 Numbers" }}
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-3" formArrayName="emailContacts"
          *ngIf="iscaseRequest ? (currentItemDisplay.includes('EMAILID') ? true: false) : true">
          <p class="label-cls" *ngIf="!iscaseRequest" [class.required]="beneficiaryType==='self'">Email Id</p>
          <p class="label-cls" *ngIf="iscaseRequest">Email Id</p>
        <ng-container *ngFor="
            let x of beneSelfDetailsForm.get('emailContacts')['controls'];
            index as i
          ">
          <div [formGroupName]="i">
            <input type="text" class="im-input" [ngClass]="{
                'im-input-error':
                  ((x.get('email').touched || submitted) &&
                    x.get('email').hasError('pattern')) ||
                  ((x.get('email').touched || submitted) &&
                    x.get('email').hasError('required') && !iscaseRequest)
              }" formControlName="email" placeholder="Enter"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$" />
          </div>
          <span class="invalid-message" *ngIf="
              (x.get('email').touched || submitted) &&
              x.get('email').hasError('required') && !iscaseRequest
            ">
            {{ getGlobalErrorMessages("REQUIRED") }}
          </span>
          <span class="invalid-message" *ngIf="
              (x.get('email').touched || submitted) &&
              x.get('email').hasError('pattern')
            ">
            {{ getGlobalErrorMessages("INVALID_EMAIL") }}
          </span>
        </ng-container>
      </div>
      <div class="col-3" *ngIf="(onlyFormBuilder !== 'FB') && (iscaseRequest ? (currentItemDisplay.includes('SSN') ? true: false) : true)">
        <p class="label-cls">Social Security Number</p>
        <div class="ssn-field">
          <input type="text" class="im-input ssn-input" formControlName="ssn"
            [ngClass]="{ 'ssn-input-hide': !ssn_hide }" placeholder="SSN" />
          <mat-icon matSuffix matTooltip="Show/Hide" (click)="ssn_hide = !ssn_hide">
            {{ ssn_hide ? "visibility" : "visibility_off" }}</mat-icon>
        </div>
        <span class="invalid-message" *ngIf="
            (beneSelfDetailsForm.get('ssn').touched || submitted) &&
            beneSelfDetailsForm.get('ssn').errors?.pattern
          ">
          Please Enter 9 Digits
        </span>
      </div>
    </div>
    <div class="row mt-2" *ngIf="(onlyFormBuilder !== 'FB') && (iscaseRequest ? (currentItemDisplay.includes('GRNCARDDTLS') ? true: false) : true)">
      <div class="col-3">
        <div>
          <p class="label-cls mb-2">Are you a Lawful Permanent Resident?
          </p>
          <p-radioButton labelStyleClass="profileRadio" formControlName="isLpr" name="isLpr" [value]="1" label="Yes"
            (onClick)="handleIsLprChanges()" inputId="YES">
          </p-radioButton>
          <p-radioButton labelStyleClass="profileRadio" class="ml-2" formControlName="isLpr" name="isLpr" [value]="0"
            (onClick)="handleIsLprChanges()" label="No" inputId="NO">
          </p-radioButton>
        </div>
      </div>
      <div class="col-4" *ngIf="(beneSelfDetailsForm.get('isLpr').value == 1) && (iscaseRequest ? (currentItemDisplay.includes('GRNCARDDTLS') ? true: false) : true)">
        <p class="label-cls mb-2">Green Card Number <small>(Ex.XXX0000000000)</small></p>             
        <input type="text" class="im-input" [ngClass]="{
          'im-input-error':
            (beneSelfDetailsForm.get('greenCardNo').touched || submitted) &&
            beneSelfDetailsForm.get('greenCardNo').errors?.pattern
        }" formControlName="greenCardNo" maxlength="13" placeholder="Enter your Green Card No." />
        <span class="invalid-message" *ngIf="
          (beneSelfDetailsForm.get('greenCardNo').touched || submitted) &&
          beneSelfDetailsForm.get('greenCardNo').errors?.pattern
        ">
          {{ getGlobalErrorMessages("INVALID_FORMAT") }}
        </span>
      </div>

    </div>
    <div class="row" *ngIf="onlyFormBuilder !== 'FB' && beneSelfDetailsForm.get('isLpr').value == 1">
      <div class="col-12">
        <div class="p-grid p-my-2">
          <div class="p-col-11" class="label-cls mt-2 pl-2" name="question">
            Did you gain lawful permanent resident status through marriage to the U.S Citizen or Lawful
            Permanent resident?
          </div>
          <div class="p-col-1">
            <p-inputSwitch formControlName="lprThruMarriage" (onChange)="handleLawfulSwitchChange()">
            </p-inputSwitch>
          </div>
          <div class="mt-2 p-col-12">
            <p *ngIf="!viewMode" class="lbl-text-2">Add photocopy of your Greencard</p>
            <app-file-upload style="width: 100%;" [configurations]="fileUploadConfigurations">
            </app-file-upload>
          </div>

        </div>
      </div>
    </div>
    <!-- File upload -->
    <!-- <div class="row col-md-12 pl-0 pt-4">
      123

        <file-upload-library style="width: 100%;" [fileUploadConfigurations]="fileUploadConfigurations"></file-upload-library>
    </div> -->
    <div class="row mt-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('BIRTHDTLS') ? true: false) : true">
      <div class="col-12">
        <h5 class="labelText">Place of Birth</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-4" formGroupName="countryCode"
          *ngIf="iscaseRequest ? (currentItemDisplay.includes('BIRTHDTLS') ? true: false) : true">          
          <p class="label-cls ddLabel">Country</p>
        <p-dropdown [options]="listCountry" formControlName="countryCode" optionLabel="countryName" optionValue="countryCode" (onChange)="resetBirthStateOnCountryChange();
            handleCountryChange($event.value, 'Birth')" placeholder="Select" [filter]="true" filterBy="countryName"></p-dropdown>        
      </div>
      <div class="col-4" *ngIf="(iscaseRequest ? (currentItemDisplay.includes('BIRTHDTLS') ? true: false) : true) && listStatesBirth.length > 0">        
        <p class="label-cls ddLabel">State</p>
        <p-dropdown [options]="listStatesBirth" formControlName="birthStateProvinceCode" optionLabel="stateProvinceName"
          optionValue="stateProvinceCode" placeholder="Select State" [filter]="true" filterBy="stateProvinceName"></p-dropdown>        
      </div>

      <div class="col-4" *ngIf="(iscaseRequest ? (currentItemDisplay.includes('BIRTHDTLS') ? true: false) : true) && listStatesBirth.length <= 0">        
        <p class="label-cls">State</p>
        <input class="im-input" *ngIf="isBirthStateRequired" formControlName="birthStateProvinceName" type="text"
          placeholder="State" />
        <input class="im-input" *ngIf="!isBirthStateRequired" formControlName="birthStateProvinceName" type="text"
          placeholder="State" />
        <!--IM-3768 <input class="im-input"
                    [ngClass]="{'im-input-error':((beneSelfDetailsForm.get('birthStateProvinceName').touched
                    || submitted) && beneSelfDetailsForm.get('birthStateProvinceName').errors?.required)}"
                    formControlName="birthStateProvinceName" type="text" placeholder="State" />
                    <span class="invalid-message"
                    *ngIf="((beneSelfDetailsForm.get('birthStateProvinceName').touched || submitted) && beneSelfDetailsForm.get('birthStateProvinceName').errors?.required)">
                    {{getGlobalErrorMessages('REQUIRED')}}
                </span> ------IM-3768---->
      </div>
      <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('BIRTHDTLS') ? true: false) : true">        
        <p class="label-cls">City</p>
        <input class="im-input" formControlName="birthCity" type="text" placeholder="City" />        
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('BIRTHDTLS') ? true: false) : true">
        <div formGroupName="ctznshipCountryCode">          
          <p class="label-cls">Citizenship</p>
          <p-dropdown [options]="listCountry" formControlName="countryCode" optionLabel="countryName" optionValue="countryCode" (onChange)="updateCountry($event.value, 'issueCountryCode')" placeholder="Select" [filter]="true" filterBy="countryName"></p-dropdown>          
        </div>
      </div>
    </div>
    <div *ngIf="showCitizenShipAquiredInfo">
      <div class="row mt-2">
        <div class="col-12">
          <div class="selectionContainer" formGroupName="ctznAcqrTypeId">            
            <p class="label-cls mb-2" *ngIf="iscaseRequest">My Citizenship was acquired through:</p>
            <div *ngFor="let item of citizenshipAcquiredArr" class="col-3 mt-2 p-field-checkbox">
              <p-radioButton [inputId]="item.id" name="ctznAcqrId" [value]="item.id" formControlName="ctznAcqrId">
              </p-radioButton>
              <label [for]="item.id" class="label-cls">{{item.name}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="p-grid p-my-2 pl-2">
            <div class="p-col-9" class="label-cls mt-2" name="question">
              Have you obtained certificate of Naturalization or Certificate of Citizenship?
            </div>
            <div class="p-col-1">
              <p-inputSwitch formControlName="hasCtznCrtfct" (onChange)="handleCitizenshipSwitchChange()">
              </p-inputSwitch>
            </div>

          </div>
          <!-- <span class="invalid-message" *ngIf="
      (submitted ||
        beneSelfDetailsForm
          .get('hasCtznCrtfct')
          .touched) &&
      beneSelfDetailsForm
        .get('hasCtznCrtfct')
        .errors?.required
    ">
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span> -->
        </div>
      </div>
      <div class="row" *ngIf="beneSelfDetailsForm.get('hasCtznCrtfct').value == 1">
        <div class="col-4">          
            <p class="label-cls">Certificate Number</p>
          <input class="im-input" formControlName="ctznCrtfctNo" type="text" placeholder="Enter" maxlength="8" />
        </div>
        <div class="col-4">          
            <p class="label-cls">Place of Issuance</p>
            <input class="im-input" formControlName="ctznCrtfctIssPlace" type="text" placeholder="Enter" />          
        </div>
        <div class="col-4">          
            <p class="label-cls">Date of Issuance</p>
          <mat-form-field appearance="outline" class="mt-0 date-field">
            <input formControlName="ctznCrtfctIssDate" matInput [max]="currentDate" min="1920-12-12"
              [matDatepicker]="ctznCrtfctIssDate" placeholder="MM/DD/YYYY"
              (click)="ctznCrtfctIssDate.open()" />
            <mat-datepicker-toggle matSuffix [for]="ctznCrtfctIssDate"></mat-datepicker-toggle>
            <mat-datepicker #ctznCrtfctIssDate></mat-datepicker>
          </mat-form-field>          
        </div>
      </div>
    </div>
    <div *ngIf="showMarriageSection">
      <div class="row mt-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('MRGDTLS') ? true: false) : true">
        <div class="col-12">
          <h5 class="labelText">Marital Status</h5>
        </div>
      </div>
      <div class="row mt-2" *ngIf="iscaseRequest ? (currentItemDisplay.includes('MRGDTLS') ? true: false) : true">
        <div class="col-12 selectionContainer" formGroupName="maritalStatus">          
            <h5 class="label-cls">What is your current marital status?</h5>
          <div *ngFor="let item of maritalStatusArr" class="col-3 mt-2 p-field-checkbox">
            <p-radioButton [inputId]="item.id" name="id" [value]="item.id" formControlName="id"></p-radioButton>
            <label [for]="item.id" class="label-cls">{{item.name}}</label>
          </div>
        </div>
      </div>
      <div
        *ngIf="beneSelfDetailsForm.get('maritalStatus').get('id').value && beneSelfDetailsForm.get('maritalStatus').get('id').value != 10">
        <div class="row mt-2">
          <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('MRGDTLS') ? true: false) : true">
            <p class="label-cls">Date of Marriage</p>
            <mat-form-field appearance="outline" class="mt-0 date-field">
              <input formControlName="marriageDate" matInput [max]="currentDate" min="1920-12-12"
                [matDatepicker]="marriageDate" placeholder="MM/DD/YYYY" (click)="marriageDate.open()" />
              <mat-datepicker-toggle matSuffix [for]="marriageDate"></mat-datepicker-toggle>
              <mat-datepicker #marriageDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('MRGDTLS') ? true: false) : true">
          <div class="col-12">
            <h6 class="labelSubText">Place of Marriage</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-4" formGroupName="countryOfMarriage"
              *ngIf="iscaseRequest ? (currentItemDisplay.includes('MRGDTLS') ? true: false) : true">
            <p class="label-cls">Country of Marriage</p>
            <p-dropdown [options]="listCountryMarriage" formControlName="countryCode" optionLabel="countryName" optionValue="countryCode" (onChange)="handleCountryChange($event.value, 'Marriage')" placeholder="Select" [filter]="true" filterBy="countryName"></p-dropdown>            
          </div>
          <div class="col-4" *ngIf="(listStatesMarriage.length > 0) && (iscaseRequest ? (currentItemDisplay.includes('MRGDTLS') ? true: false) : true)">
            <p class="label-cls">State of Marriage</p>
            <p-dropdown [options]="listStatesMarriage" formControlName="marriageStateProvinceCode" optionLabel="stateProvinceName" optionValue="stateProvinceCode" (onChange)="handleStateChange($event.value, 1)" placeholder="Select State" [filter]="true" filterBy="stateProvinceName"></p-dropdown>            
          </div>
          <div class="col-4" *ngIf="(listStatesMarriage.length <= 0) && (iscaseRequest ? (currentItemDisplay.includes('MRGDTLS') ? true: false) : true)">
            <p class="label-cls">State of Marriage</p>
            <input class="im-input" formControlName="marriageStateProvinceName" type="text" placeholder="State" />
          </div>
          <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('MRGDTLS') ? true: false) : true">
            <p class="label-cls">City of Marriage</p>
              <input class="im-input" formControlName="cityOfMarriage" type="text" placeholder="City" />                        
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('CURRADR') ? true: false) : true">
      <div class="col-12">
        <h5 class="labelText">Physical Address Details</h5>
      </div>
    </div>
    <!-- Current Address -->
    <div formArrayName="address">
      <div [formGroupName]="getAddressGroup('CURR')"
          *ngIf="iscaseRequest ? (currentItemDisplay.includes('CURRADR') ? true: false) : true">
        <div class="row mt-2">
          <div class="col-8">            
              <p class="label-cls">Address 1</p>
              <input type="text" class="im-input col-md" formControlName="addressLine1" placeholder="Address 1" maxLength="150" />              
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-8">
            <p class="label-cls">Address 2 <i
                pTooltip='Enter Apartment, Suite, Floor number with the prefix Apt, Ste or Flr respectively. This will be automatically mapped to the Apartment, Suite and Floor fields in the forms'
                class="pi pi-info-circle"></i></p>
            <input type="text" class="im-input col-md" formControlName="addressLine2" placeholder="Address 2" maxLength="150" />              
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <p class="label-cls">Area</p>
            <input class="im-input" formControlName="locality" type="text" placeholder="Area" />
          </div>
          <div class="col-4" formGroupName="countryCode">            
            <p class="label-cls ddLabel">Country</p>
            <p-dropdown [options]="listCountry" formControlName="countryCode" optionLabel="countryName"
              optionValue="countryCode" (onChange)="handleCountryChange($event.value, 'Current');
              resetStateOnCountryChange(0)" placeholder="Select" [filter]="true" filterBy="countryName"></p-dropdown>            
          </div>
          <div class="col-4" *ngIf="listStatesCurrent.length > 0">            
            <p class="label-cls ddLabel">State</p>
            <p-dropdown [options]="listStatesCurrent" formControlName="stateProvinceCode"
              optionLabel="stateProvinceName" optionValue="stateProvinceCode"
              (onChange)="handleStateChange($event.value, 0)" placeholder="Select State" [filter]="true" filterBy="stateProvinceName"></p-dropdown>            
          </div>
          <div class="col-4" *ngIf="listStatesCurrent.length <= 0">            
            <p class="label-cls">State</p>
            <input class="im-input" formControlName="stateProvinceName" type="text" placeholder="State" />            
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">            
            <p class="label-cls">City</p>    
            <input class="im-input" formControlName="city" type="text" placeholder="City" />            
          </div>
          <div class="col-4">            
            <p class="label-cls">Zip / Postal Code</p>
            <input class="im-input" [ngClass]="{
              'im-input-error':
                ((submitted ||
                  beneSelfDetailsForm
                    .get('address')
                    ['controls']['0'].get('postCode').touched) &&
                  beneSelfDetailsForm
                    .get('address')
                    ['controls']['0'].get('postCode').errors?.pattern) ||
                (submitted  && !iscaseRequest)
            }" formControlName="postCode" type="text" placeholder="Zip Code" maxLength="10" />            
         <span class="invalid-message" *ngIf="
             (submitted ||
               beneSelfDetailsForm
                 .get('address')
                 ['controls']['0'].get('postCode').touched) &&
             beneSelfDetailsForm
               .get('address')
               ['controls']['0'].get('postCode').errors?.pattern
           ">
           {{ getGlobalErrorMessages("ZIPCODE_ONLY") }}
         </span>         
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('MAILADR') ? true: false) : true">
      <!--*ngIf="showforeignAddress"-->
      <div class="col-4">
        <h5 class="labelText">Mailing Address Details</h5>
      </div>
      <div class="col-4 pt-1">
        <p class="label-cls ddLabel">Copy From</p>
        <p-dropdown [options]="listCopyMailAddress" optionLabel="name"
            optionValue="code" (onChange)="copyAddress($event.value, 'Mailing')" placeholder="Select"></p-dropdown>
      </div>
      <!-- <div class="col-md-12 mb-2">
        <label class="mb-0 label-cls">
          <b>Note</b> : <span> For applicants whose current address is outside the US. <i
              pTooltip='If you are applying from your country of origin (i.e., outside the US), enter your "Non-US Address" details under "Foreign Address" also.'
              class="pi pi-info-circle" style="color: #01a0da;"></i></span>
        </label>
      </div> -->
    </div>
    <div formArrayName="address" *ngIf="iscaseRequest ? (currentItemDisplay.includes('MAILADR') ? true: false) : true">
      <div [formGroupName]="getAddressGroup('MAIL')"
          *ngIf="iscaseRequest ? (currentItemDisplay.includes('MAILADR') ? true: false) : true">
        <div class="row mt-2">
          <div class="col-8">            
              <p class="label-cls">Address 1</p>
              <input type="text" class="im-input col-md" formControlName="addressLine1" placeholder="Address 1" maxLength="150" />              
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-8">
            <p class="label-cls">Address 2 <i
                pTooltip='Enter Apartment, Suite, Floor number with the prefix Apt, Ste or Flr respectively. This will be automatically mapped to the Apartment, Suite and Floor fields in the forms'
                class="pi pi-info-circle"></i></p>
            <input type="text" class="im-input col-md" formControlName="addressLine2" placeholder="Address 2" maxLength="150" />              
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <p class="label-cls">Area</p>
            <input class="im-input" formControlName="locality" type="text" placeholder="Area" />
          </div>
          <div class="col-4" formGroupName="countryCode">            
            <p class="label-cls ddLabel">Country</p>
            <p-dropdown [options]="listCountry" formControlName="countryCode" optionLabel="countryName"
              optionValue="countryCode" (onChange)="handleCountryChange($event.value, 'Mailing');
              resetStateOnCountryChange(1)" placeholder="Select" [filter]="true" filterBy="countryName"></p-dropdown>            
          </div>
          <div class="col-4" *ngIf="listStatesMailing.length > 0">            
            <p class="label-cls ddLabel">State</p>
            <p-dropdown [options]="listStatesMailing" formControlName="stateProvinceCode"
              optionLabel="stateProvinceName" optionValue="stateProvinceCode"
              (onChange)="handleStateChange($event.value, 1)" placeholder="Select State" [filter]="true" filterBy="stateProvinceName"></p-dropdown>            
          </div>
          <div class="col-4" *ngIf="listStatesMailing.length <= 0">            
            <p class="label-cls">State</p>
            <input class="im-input" formControlName="stateProvinceName" type="text" placeholder="State" />            
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">            
            <p class="label-cls">City</p>    
            <input class="im-input" formControlName="city" type="text" placeholder="City" />            
          </div>
          <div class="col-4">            
            <p class="label-cls">Zip / Postal Code</p>
            <input class="im-input" [ngClass]="{
              'im-input-error':
                ((submitted ||
                  beneSelfDetailsForm
                    .get('address')
                    ['controls']['1'].get('postCode').touched) &&
                  beneSelfDetailsForm
                    .get('address')
                    ['controls']['1'].get('postCode').errors?.pattern) ||
                (submitted  && !iscaseRequest)
            }" formControlName="postCode" type="text" placeholder="Zip Code" maxLength="10" />            
         <span class="invalid-message" *ngIf="
             (submitted ||
               beneSelfDetailsForm
                 .get('address')
                 ['controls']['1'].get('postCode').touched) &&
             beneSelfDetailsForm
               .get('address')
               ['controls']['1'].get('postCode').errors?.pattern
           ">
           {{ getGlobalErrorMessages("ZIPCODE_ONLY") }}
         </span>         
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('FORADR') ? true: false) : true">
      <!--*ngIf="showforeignAddress"-->
      <div class="col-4">
        <h5 class="labelText">Foreign Address Details</h5>
      </div>
      <div class="col-4 pt-1">
        <p class="label-cls ddLabel">Copy From</p>
        <p-dropdown [options]="listCopyFornAddress" optionLabel="name"
            optionValue="code" (onChange)="copyAddress($event.value, 'Foreign')" placeholder="Select"></p-dropdown>
      </div>
      <div class="col-md-12 mb-2">
        <label class="mb-0 label-cls">
          <b>Note</b> : <span> For applicants whose current address is outside the US. <i
              pTooltip='If you are applying from your country of origin (i.e., outside the US), enter your "Non-US Address" details under "Foreign Address" also.'
              class="pi pi-info-circle" style="color: #01a0da;"></i></span>
        </label>
      </div>
    </div>
    <div formArrayName="address" *ngIf="iscaseRequest ? (currentItemDisplay.includes('FORADR') ? true: false) : true">
      <div [formGroupName]="getAddressGroup('FORN')">
        <div class="row mt-2">
          <div class="col-8">
            <p class="label-cls">
              Address 1
            </p>
            <input type="text" class="im-input col-md" formControlName="addressLine1" placeholder="Address 1" maxLength="150" />              
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-8">
            <p class="label-cls">Address 2 <i
                pTooltip='Enter Apartment, Suite, Floor number with the prefix Apt, Ste or Flr respectively. This will be automatically mapped to the Apartment, Suite and Floor fields in the forms'
                class="pi pi-info-circle"></i></p>
            <input type="text" class="im-input col-md" formControlName="addressLine2" placeholder="Address 2" maxLength="150" />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <p class="label-cls">Area</p>
            <input class="im-input" formControlName="locality" type="text" placeholder="Area" />
          </div>
          <div class="col-4" formGroupName="countryCode">
            <p class="label-cls ddLabel">Country</p>
            <p-dropdown [options]="listCountryForeignAdd" formControlName="countryCode" optionLabel="countryName" optionValue="countryCode" (onChange)="
            handleCountryChange($event.value, 'Foreign'); resetStateOnCountryChange(2)" placeholder="Select" styleClass="country-dd" [filter]="true" filterBy="countryName"></p-dropdown>            
          </div>
          <div class="col-4" *ngIf="listStatesForeign.length > 0">
            <p class="label-cls ddLabel">
              State
            </p>
            <p-dropdown [options]="listStatesForeign" formControlName="stateProvinceCode"
              optionLabel="stateProvinceName" optionValue="stateProvinceCode" placeholder="Select State" [filter]="true" filterBy="stateProvinceName"></p-dropdown>
          </div>
          <div class="col-4" *ngIf="listStatesForeign.length <= 0">
            <p class="label-cls">State</p>
            <input class="im-input" formControlName="stateProvinceName" type="text" placeholder="State" />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <p class="label-cls">City </p>
            <input class="im-input" formControlName="city" type="text" placeholder="City" />            
          </div>
          <div class="col-4">
            <p class="label-cls">Zip / Postal Code</p>
            <input class="im-input" [ngClass]="{
              'im-input-error':
                ((submitted ||
                  beneSelfDetailsForm
                    .get('address')
                    ['controls']['2'].get('postCode').touched) &&
                  beneSelfDetailsForm
                    .get('address')
                    ['controls']['2'].get('postCode').errors?.pattern) ||
                (submitted && !iscaseRequest)
            }" formControlName="postCode" type="text" placeholder="Zip Code" maxLength="10" />             
           <span class="invalid-message" *ngIf="
             (submitted ||
             beneSelfDetailsForm
               .get('address')
               ['controls']['2'].get('postCode').touched) &&
               beneSelfDetailsForm
                 .get('address')
                 ['controls']['2'].get('postCode').errors?.pattern
             ">
             {{ getGlobalErrorMessages("ZIPCODE_ONLY") }}
           </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('PERMADR') ? true: false) : true">
      <div class="col-4">
        <h5 class="labelText">Permanent Address Details</h5>
      </div>
      <div class="col-4 pt-1">
        <p class="label-cls ddLabel">Copy From</p>
        <p-dropdown [options]="listCopyPermAddress" optionLabel="name"
            optionValue="code" (onChange)="copyAddress($event.value, 'Permanent')" placeholder="Select"></p-dropdown>
      </div>
    </div>
    <div formArrayName="address">
      <div [formGroupName]="getAddressGroup('PERM')"
          *ngIf="iscaseRequest ? (currentItemDisplay.includes('PERMADR') ? true: false) : true">
        <div class="row mt-2">
          <div class="col-8">            
              <p class="label-cls">Address 1</p>
              <input type="text" class="im-input col-md" formControlName="addressLine1" placeholder="Address 1" maxLength="150" />              
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-8">
            <p class="label-cls">Address 2 <i
                pTooltip='Enter Apartment, Suite, Floor number with the prefix Apt, Ste or Flr respectively. This will be automatically mapped to the Apartment, Suite and Floor fields in the forms'
                class="pi pi-info-circle"></i></p>
              <input type="text" class="im-input col-md" formControlName="addressLine2" placeholder="Address 2" maxLength="150" />              
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <p class="label-cls">Area</p>
            <input class="im-input" formControlName="locality" type="text" placeholder="Locality" />
          </div>
          <div class="col-4" formGroupName="countryCode">            
            <p class="label-cls ddLabel">Country</p>
            <p-dropdown [options]="listCountry" formControlName="countryCode" optionLabel="countryName"
              optionValue="countryCode" (onChange)="handleCountryChange($event.value, 'Permanent');
              resetStateOnCountryChange(3)" placeholder="Select" [filter]="true" filterBy="countryName"></p-dropdown>            
          </div>
          <div class="col-4" *ngIf="listStatesPermanent.length > 0">            
              <p class="label-cls ddLabel">State</p>
              <p-dropdown [options]="listStatesPermanent" formControlName="stateProvinceCode" optionLabel="stateProvinceName" optionValue="stateProvinceCode" (onChange)="handleStateChange($event.value, 3)" placeholder="Select State" [filter]="true" filterBy="stateProvinceName"></p-dropdown>            
          </div>
          <div class="col-4" *ngIf="listStatesPermanent.length <= 0">            
              <p class="label-cls">State</p>
              <input class="im-input" formControlName="stateProvinceName" type="text" placeholder="State" />            
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">            
            <p class="label-cls">City</p>
            <input class="im-input" formControlName="city" type="text" placeholder="City" />            
          </div>
          <div class="col-4">            
            <p class="label-cls">Zip / Postal Code</p>
            <input class="im-input" [ngClass]="{
              'im-input-error':
                (submitted ||
                  beneSelfDetailsForm
                    .get('address')
                    ['controls']['3'].get('postCode').touched) && !iscaseRequest
            }" formControlName="postCode" type="text" placeholder="Zip Code" maxLength="10" />
         <span class="invalid-message" *ngIf="
             submitted &&
             beneSelfDetailsForm
               .get('address')
               ['controls']['3'].get('postCode').errors?.pattern
           ">
           {{ getGlobalErrorMessages("ZIPCODE_ONLY") }}
         </span>         
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4" *ngIf="onlyFormBuilder !== 'FB'">
      <div class="col-12" *ngIf="iscaseRequest ? (currentItemDisplay.includes('RACEDTLS') ? true: false) : true">
        <h5 class="labelText">Biographic Information </h5>
      </div>
    </div>

    <div class="row mt-2" *ngIf="onlyFormBuilder !== 'FB'">
      <div class="col-12">
        <div class="selectionContainer" formGroupName="ethnicity"
            *ngIf="iscaseRequest ? (currentItemDisplay.includes('RACEDTLS') ? true: false) : true">
          <p class="label-cls mb-2">Ethnicity</p>
          <div *ngFor="let ethinItem of ethinicityArr" class="col-3 mt-2 p-0 p-field-checkbox">
            <p-radioButton [inputId]="ethinItem.id" name="ethnicityId" [value]="ethinItem.id"
              formControlName="ethnicityId"></p-radioButton>
            <label [for]="ethinItem.id" class="label-cls">{{ethinItem.name}}</label>
          </div>
        </div>
      </div>
    </div>
    <div formArrayName="race" *ngIf="(onlyFormBuilder !== 'FB') && iscaseRequest ? (currentItemDisplay.includes('RACEDTLS') ? true: false) : true">
      <p class="label-cls pl-3">Race</p>
      <ng-container *ngFor="let x of raceArr; index as i">
        <div>
          <div class="row mt-2" style="display: block;">
            <div class="col-4" style="display: inline-block;">
              <!-- <p-checkbox name="group2" [label]="x.get('raceId').get('name').value"
                [formControl]="x.controls['raceId']" [binary]="x.get('raceId').get('checked').value" (onChange)="handleRaceSelection(x.get('raceId').get('id').value)"></p-checkbox>
              (onChange)="handleRaceSelection($event.checked,x.get('raceId').get('id').value,i)" -->
              <p-checkbox [label]="x.name" [formControlName]="i" binary="true"></p-checkbox>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row mt-2" *ngIf="onlyFormBuilder !== 'FB'">
      <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('RACEDTLS') ? true: false) : true">
        <p class="label-cls">Hair Color</p>
        <p-dropdown [options]="hairColors" formControlName="hairColor" optionLabel="name" optionValue="code" placeholder="Select"></p-dropdown>
        <!-- <input type="text" class="im-input" [ngClass]="{
            'im-input-error':
              (beneSelfDetailsForm.get('hairColor').touched || submitted) &&
              beneSelfDetailsForm.get('hairColor').errors?.required
          }" formControlName="hairColor" placeholder="Enter" /> -->
      </div>
      <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('RACEDTLS') ? true: false) : true">
        <p class="label-cls">Eyes Color</p>
        <!-- <input type="text" class="im-input" [ngClass]="{
            'im-input-error':
              (beneSelfDetailsForm.get('eyeColor').touched || submitted) &&
              beneSelfDetailsForm.get('eyeColor').errors?.required
          }" formControlName="eyeColor" placeholder="Enter" /> -->
        <p-dropdown [options]="eyeColors" formControlName="eyeColor" optionLabel="name" optionValue="code" placeholder="Select"></p-dropdown>
      </div>
      <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('RACEDTLS') ? true: false) : true">
        <p class="label-cls">Weight(in pounds)</p>
        <input type="text" class="im-input" [ngClass]="{
          'im-input-error':
            (beneSelfDetailsForm.get('weight').touched || submitted) 
        }" formControlName="weight" placeholder="Enter" />
         <span class="invalid-message" *ngIf="
         (beneSelfDetailsForm.get('weight').touched || submitted) &&
         beneSelfDetailsForm.get('weight').errors?.pattern
       ">
         {{ getGlobalErrorMessages("INVALID_FORMAT") }}
       </span>
        
      </div>
    </div>
    <div class="row mt-2" *ngIf="onlyFormBuilder !== 'FB'">
      <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('RACEDTLS') ? true: false) : true">
        <p class="label-cls">Height(inches)</p>
        <input type="text" class="im-input" [ngClass]="{
          'im-input-error':
            (beneSelfDetailsForm.get('height').touched || submitted) 
        }" formControlName="height" placeholder="Enter" />
        <span class="invalid-message" *ngIf="
        (beneSelfDetailsForm.get('height').touched || submitted) &&
        beneSelfDetailsForm.get('height').errors?.pattern
      ">
      {{ getGlobalErrorMessages("INVALID_FORMAT") }}
    </span>
      </div>
      <div class="col-8" *ngIf="iscaseRequest ? (currentItemDisplay.includes('RACEDTLS') ? true: false) : true">
        <p class="label-cls">Marks of identification</p>
        <input type="text" class="im-input mw-100" formControlName="identificationMark" placeholder="Enter" />
        <!---<span class="invalid-message" *ngIf="
            (beneSelfDetailsForm.get('identificationMark').touched ||
              submitted) &&
            beneSelfDetailsForm.get('identificationMark').errors?.required
          ">
          {{ getGlobalErrorMessages("REQUIRED") }}
        </span>--->       
      </div>
    </div>

    <div class="row mt-4" *ngIf="(onlyFormBuilder !== 'FB') && (iscaseRequest ? (currentItemDisplay.includes('PASPORTDTLS') ? true: false) : true)">
      <div class="col-12">
        <h5 class="labelText">Passport Details</h5>
      </div>
    </div>
    <div class="row mb-2" *ngIf="(onlyFormBuilder !== 'FB') && (iscaseRequest ? (currentItemDisplay.includes('PASPORTDTLS') ? true: false) : true)">
      <div class="col-12 has-passport-outer">
        <p-checkbox label="Ignore passport details" (onChange)="updatePassportFormControl($event)"
          formControlName="hasPassport" binary="true"></p-checkbox>
      </div>
    </div>

   

    <ng-container *ngIf="!beneSelfDetailsForm.get('hasPassport').value">
      <div formArrayName="passport">
        <ng-container *ngFor="
            let x of beneSelfDetailsForm.get('passport')['controls']; index as i">
          <div [formGroupName]="i">
            <div class="row mt-2">
              <div class="col-8" *ngIf="iscaseRequest ? (currentItemDisplay.includes('PASPORTDTLS') ? true: false) : true">
                <p class="label-cls" *ngIf="!iscaseRequest">Passport No.</p>
                  <p class="label-cls" *ngIf="iscaseRequest">Passport No.</p>
                <input type="text" class="im-input mw-100" formControlName="passportNo" placeholder="Passport #" maxlength="9" />
               
              </div>
              <div class="col-4" formGroupName="issueCountryCode" *ngIf="iscaseRequest ? (currentItemDisplay.includes('PASPORTDTLS') ? true: false) : true">                
                <p class="label-cls ddLabel">Issuing Authority</p>
                <!-- <select class="im-input col-md" formControlName="countryCode">
                  <option *ngFor="let code of listCountry" [value]="code.countryCode">
                    {{ code.countryName }}
                  </option>
                </select> -->
                <p-dropdown [options]="listCountry" formControlName="countryCode" optionLabel="countryName" optionValue="countryCode" placeholder="Select" [filter]="true" filterBy="countryName"></p-dropdown>                
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('PASPORTDTLS') ? true: false) : true">                
                <p class="label-cls" >Date of Issue</p>
                <mat-form-field appearance="outline" class="mt-0 date-field">
                  <input formControlName="issueDate" matInput [max]="currentDate" min="1920-12-12"
                    [matDatepicker]="issueDate" placeholder="MM/DD/YYYY" (click)="issueDate.open()" />
                  <mat-datepicker-toggle matSuffix [for]="issueDate"></mat-datepicker-toggle>
                  <mat-datepicker #issueDate></mat-datepicker>
                </mat-form-field>                
              </div>
              <div class="col-4" *ngIf="iscaseRequest ? (currentItemDisplay.includes('PASPORTDTLS') ? true: false) : true">                
                <p class="label-cls">Date of Expiry</p>
                <mat-form-field appearance="outline" class="mt-0 date-field">
                  <input placeholder="MM/DD/YYYY" [matDatepicker]="expiryDate" matInput formControlName="expiryDate"
                    max="9999-12-31" [min]="beneSelfDetailsForm.controls['passport']?.value[0] ?.issueDate" 
                      (change)="validateExpiry(beneSelfDetailsForm.controls['passport']?.value[0] ?.issueDate,
                        beneSelfDetailsForm.controls['passport']?.value[0] ?.expiryDate)"  
                        (click)="expiryDate.open()" />
                  <mat-datepicker-toggle matSuffix [for]="expiryDate"></mat-datepicker-toggle>
                  <mat-datepicker #expiryDate></mat-datepicker>
                </mat-form-field>               
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div class="row mt-4 mb-4">
      <div class="col-12">
        <div class="position-relative">
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="row mt-4" *ngIf="onlyFormBuilder !== 'FB'">
      <div class="col-12">
        <h5 class="labelText">Additional Info</h5>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label class="label-cls">Visa Sponsor</label>
          <input class="im-input m-0" formControlName="visaSponsor" type="text"  />
        </div>
      </div>
    </div>


    <div class="row mt-4 mb-4">
      <div class="col-12">
        <div class="position-relative">
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>

    <div class="row paddingTop10" *ngIf="beneficiaryData?.id > 0">
      <div class="col-4 linehgt10" *ngIf="onlyFormBuilder !== 'FB'">
        <label><b>Signature </b></label>
        <p><button type="button" (click)="uploadSignatureImage()" class="signaturePromptBtn btn btn-primary btn-lg"
            title=".png only">
            <span *ngIf='!signatureImage' class="paddingLeft10  paddingRight10">Upload Signature</span>
            <span *ngIf='signatureImage'>Change Signature</span>
          </button>
        </p>
      </div>
      <div class="col-8 linehgt10">
        <img *ngIf='!!signatureImage' alt="Signature" class="signatureImageBox" [src]="signatureImage">
      </div>
    </div>

    <div *ngIf="detailsAbout.showDetailFor == 'self'">
      <div *ngFor="let ques of customQuestions?.sectionList">
        <div *ngIf="ques.name == 'Personal Details'">
          <div class="customSections" *ngFor="let item of ques.stakeHoldersSelected">
            <div *ngIf="(item?.key == 'QTPROFILEBEN') && item.selected == true">
              <app-custom-questions [isNewPortion]="false" [viewQuestions]="false" [loadedIn]="'Personal Details'"
                [beneficiaryId]="beneficiaryId" [isSection]="true" [familyId]="currentFamilyId"
                (refreshList)="onRefreshPage($event)"></app-custom-questions>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="detailsAbout.showDetailFor !== 'self'">
      <div *ngFor="let ques of customQuestions?.sectionList">
        <div *ngIf="ques.name == 'Personal Details'">
          <div class="customSections" *ngFor="let item of ques.stakeHoldersSelected">
            <div *ngIf="(detailsAbout.dependentType.code == item.key) && item.selected == true">
              <app-custom-questions [isNewPortion]="false" [viewQuestions]="false" [loadedIn]="'Personal Details'"
                [beneficiaryId]="beneficiaryId" [isSection]="true" [familyId]="currentFamilyId"
                (refreshList)="onRefreshPage($event)"></app-custom-questions>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-right">
        <button class="back-button d-inline-flex align-items-center pr-3 pl-3 mr-3" *ngIf="beneUpdateProfile"
          (click)="backToTask()">
          <i class="fa fa-chevron-circle-left pr-1" aria-hidden="true"></i>
          Back
        </button>
        <!-- IM-19605 -->
        <!-- <lib-save-as-draft *ngIf="detailsAbout.showDetailFor === 'self'" class="mr-3"
          (onClickSaveAsDraft)="saveAsDraft($event)" [showSaveIcon]="true"
          [formToBeSaved]="beneSelfDetailsForm"></lib-save-as-draft> -->
        <button type="submit" class="btn btn-success button-width-icon-left mr-3">
          <i class="fa fa-floppy-o"></i>
          Save
        </button>
        <button *ngIf="!editModeVal" type="button" class="back-button button-width-icon-left"
          (click)="clearProfileData(f)">
          <i class="fa fa-times-circle"></i>
          Cancel
        </button>
      </div>
    </div>
  </form>
  <div class="row mt-4" *ngIf="onlyFormBuilder == 'FB' && beneficiaryId !== 0">
    <p *ngIf="!viewMode" class="lbl-text-2">Add Additional Documents</p>
    <app-file-upload style="width: 100%" [configurations]="documentFileUploadConfigurations"></app-file-upload>
  </div>
</div>

<div class="add-placeholder" *ngIf="!editModeVal && showAddIcon">
  <button mat-fab color="primary" class="btn" (click)="toggleEditView(true)" [disabled]="isDisabled">
    <mat-icon matTooltip="Create Profile">add_circle_outline</mat-icon>
  </button>
  <h5 class="label-dark mt-3">Create {{beneficiaryData?.relationShipType?.name}} Profile</h5>
</div>
