import { Component, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ToastrService } from 'ngx-toastr';
import { getStepDetails, getStepStatusUpdate, loadSubTaskDetails, updateStepStatus, updateStepStatusToInitialState, getVisaTypeCode } from 'visa-store';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/internal/operators/map';
import { first } from 'rxjs/internal/operators/first';
import { DirtyFormGuard, MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import { combineLatest } from 'rxjs';
import { ImagilityBaseResponse, PrimaryData, TaskStep } from 'app-models';
import { OccupationDeterminationService } from './occupation-determination.service';
import * as errorUtils from "error-message-utility";
import { AppServicesLibraryService } from 'app-services-library';

@Component({
  selector: 'app-occupation-determination',
  templateUrl: './occupation-determination.component.html',
  styleUrls: ['./occupation-determination.component.scss']
})
export class OccupationDeterminationComponent implements OnInit {

  @ViewChild('occupationDeterminationForm', { static: true }) occupationDeterminationForm: NgForm;

  disabledControls: boolean
  stepDetails: TaskStep;
  lcaCode: string;
  caseNumber: string;
  occupationDescription: string;
  lcaDuties: string;
  lcaCodeId: number;
  positionTitle: string;
  inputData: PrimaryData;
  typeOfButton: string; // This is required for status update button
  observableSubscription$ = new Subject();
  topOohLcaDutyList: any[];// get top OOh Lca duty list for mapping
  topMappedOohLcaDutyList: any[];// get top OOh Lca duty list for mapping
  positionDutyList: any[]; // Duties defined in position details
  selectedOohDuties: number[];
  selectedCities: string[];
  jobDescriptionId: number;

  constructor(private store: Store<any>,
    private apiService: OccupationDeterminationService,
    private toastr: ToastrService,
    private guard: DirtyFormGuard,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private appServicesLibraryService: AppServicesLibraryService) {
    this.disabledControls = false;
    this.inputData = this.dynamicDialogConfig.data;
    this.lcaCode = "";
    this.caseNumber = "";
    this.occupationDescription = "";
    this.lcaDuties = "";
    this.lcaCodeId = null;
    this.positionDutyList = [];
    this.topOohLcaDutyList = [];
    this.topMappedOohLcaDutyList = [];
  }

  ngOnInit(): void {
    this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId })).pipe(takeUntil(this.observableSubscription$)).subscribe((data: TaskStep) => {
      this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? "MARK_COMPLETE" : "MARK_SUBMIT";
      this.stepDetails = data;
      this.getOccupationDetails();
      this.getJobDescriptionDetails();


      this.toggleControlsStatus();
    });
    this.occupationDeterminationForm.form.valueChanges.pipe(
      map(() => this.occupationDeterminationForm.dirty),
    ).pipe(takeUntil(this.observableSubscription$)).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });
  }

  getOccupationDetails() {
    this.apiService.getOccupationDetails(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId).subscribe((response: ImagilityBaseResponse) => {
      if (response) {
        this.lcaCode = response.data.lca && `${response.data.lca.socCode} - ${response.data.lca.socTitle}` === `${response.data.lca.socCode} - ${response.data.lca.socTitle}` ?
          `${response.data.lca.socCode} - ${response.data.lca.socTitle}` : null;
        this.caseNumber = response.data.lca && response.data.lca.caseNumber === response.data.lca.caseNumber ? response.data.lca.caseNumber : null;
        this.occupationDescription = response.data && response.data.occupationDescription === response.data.occupationDescription ? response.data.occupationDescription : null;
        this.lcaCodeId = response.data.lcaCodeId === response.data.lcaCodeId ?
          response.data.lcaCodeId : null;
        if(this.lcaCodeId){
          this.getTopLcaOohDuties(); // get top 5 ooh duties
        }
      }
    })
  }



  getJobDescriptionDetails() {
    //get api call
    //success  ==>jobDescriptionId
    this.apiService.getJobDescriptionDetails(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId, false).subscribe((response: ImagilityBaseResponse) => {
      if (response) {
        this.jobDescriptionId = response.data.id;
        this.positionTitle = response.data.jobTitle;
        this.getListOfDuties(this.jobDescriptionId); // get job duties
      }
    });
  }

  getListOfDuties(jobDescriptionId: number) {
    this.apiService.getPositionDutyList(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId, jobDescriptionId).subscribe((response: ImagilityBaseResponse) => {
      if (response) {
        this.positionDutyList = response.data;
        this.getOccupationDetermination(); // to get job duties and ooh mapping
      }
    })
  }

  getTopLcaOohDuties() {
    debugger
    //get top 5 ooh duties for mapping
    this.apiService.getLcaOohDuties(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId, this.lcaCodeId, false).subscribe((response: ImagilityBaseResponse) => {
      if (response) {
        this.topOohLcaDutyList = response.data;
        this.topMappedOohLcaDutyList = response.data;
        this.topMappedOohLcaDutyList.push({ id: null, dutyDescription: 'None' });
      }
    });
  }

  getOccupationDetermination() {
    this.apiService.getOccupationDetermination(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId).subscribe((response: ImagilityBaseResponse) => {
      if (response) {
        this.positionDutyList.forEach((x) => {
          if (response.data['compareJobDuties']?.find(y => y.pbJobDutyId === x['id']) && response.data['compareJobDuties']?.find(y => y.pbJobDutyId === x['id'])['lcaDutyId'] === null) {
            x['selectedOohDuties'] = [null];
          }
          else {
            x['selectedOohDuties'] = response.data['compareJobDuties']?.find(y => y.pbJobDutyId === x['id'])['lcaDutyId']
          }
        });
      }
    })
  }

  postOccupationDetermination() {
    const payload = { compareJobDuties: [] };
    this.positionDutyList.forEach(x => {
      payload.compareJobDuties.push({ lcaDutyId: x['selectedOohDuties'], pbJobDutyId: x['id'] });
    });
    this.apiService.postOohJobDutiesMapping(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId, payload).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.toastr.success(response.message);
        this.occupationDeterminationForm.resetForm(this.occupationDeterminationForm.value);
        if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
          this.updateStepStatus('INPROGRESS');
        }
        else {
          this.getOccupationDetermination();
        }
      }
    });
  }

  onChange(e: any, item: any) {
    if (e['itemValue'] === null) {
      item['selectedOohDuties'] = [null];
    }
    else {
      item['selectedOohDuties'] = item['selectedOohDuties'].filter(x => x !== null);
      //item['selectedOohDuties'].push(e['itemValue']);
    }
  }

  //Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" || this.stepDetails.stepStatusDetails.stepStatusCode === "NEW"? true : false;
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.inputData.visatype,
      stepId: this.inputData.stepId
    }));
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(updateStepStatusToInitialState());
  }
}
