import {
  Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewEncapsulation, AfterContentInit,
  OnDestroy, AfterViewInit, Input, SimpleChanges, OnChanges
} from "@angular/core";
import { NgForm, Validators } from '@angular/forms';
import { map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { PrimaryData, ReferenceLibrary, ImagilityBaseResponse, TaskStep, SubTaskDetails, WorkExperienceDetailsResponse, BENEFICIARY } from 'app-models';
import { WorkExpDetails, Clients, JobDutiess, Tools, acceptedFileTypes } from "app-models";
import * as Roles from 'app-models';
import { EditWorkExperienceDetailsService } from './edit-work-experience-details.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DirtyFormGuard, MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import * as errorUtils from 'error-message-utility';
import { environment } from 'environment-configurations';
import { ReferenceLibraryService } from 'reference-library';
import { CacheCountryListService } from 'cache-country-list';
import { DeleteDialogLibraryComponent } from "delete-dialog-library";
import { Store } from '@ngrx/store';
import { Subject, Subscription } from "rxjs";
import { DialogService } from "primeng/dynamicdialog";

export const DeleteDialogConfigurations = {
  width: '50%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { "z-index": "1001" }
};
@Component({
  selector: 'edit-work-experience-details',
  templateUrl: 'edit-work-experience-details.component.html',
  styleUrls: ['edit-work-experience-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})


export class EditWorkExperienceDetailsComponent implements OnInit, AfterContentInit, OnDestroy, OnChanges, AfterViewInit {

  @Input() isViewMode: boolean;
  @Input() primaryData: any;
  @Input() type: string;
  @Input() userId: number;

  modeVal: boolean = false;
  beneficiaryWorkDetails = new WorkExpDetails({});
  client: Clients = {
    clientName: '',
    id: null,
    startDate: null,
    endDate: null
  };
  [x: string]: any;

  duty: JobDutiess = {
    duty: '',
    dutyId: null,
    sequenceNo: null,
    subDuties: [
      {
        subDutyDescription: '',
        subDutyId: null,
        sequenceNo: null
      }
    ]
  };

  tool: Tools = {
    id: null,
    skillName: ''
  };

  listToolsTechnology: Tools[] = [];
  envPath: any;
  jobEditable = false;
  hasUnsavedData: boolean = false;
  phoneTypeList: ReferenceLibrary[];
  currencyTypeList: ReferenceLibrary[];
  listEmploymentType: ReferenceLibrary[];
  listCountry: [];
  listStates: any[];
  //auto chips
  validation_pattern;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  hasID: boolean = false;
  isLinear: boolean = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  checked: boolean = false;
  expandedElement: any | null;
  maxDate: Date;
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  viewMode: boolean = false;
  destroy$ = new Subject<void>();

  @ViewChild('item', { static: true }) item: ElementRef;
  @ViewChild('benExpForm', { read: NgForm }) benExpForm: NgForm;


  showEditButton: boolean = true;
  beneficiaryDetails;
  updateBenProfile: Boolean = true;
  expandPanel = false;
  isListView = false;
  isTimeLineView = true;
  visibleSubSection = "WORKEXP";
  experienceData: any[];
  payload: any = null;
  toolsAndTechChip;
  columns = [
    { columnDef: 'toggle', header: '', columnType: 'toggleicon', customClass: '' },
    { columnDef: 'designation', header: 'Title', columnType: 'text', customClass: '' },
    { columnDef: 'companyName', header: 'Company Name', columnType: 'text', customClass: '' },
    { columnDef: 'employmentTypeValue', header: 'Employment Type', columnType: 'text', customClass: '' },
    { columnDef: 'startDate', header: 'From ', columnType: 'text', customClass: '' },
    { columnDef: 'endDate', header: 'To', columnType: 'text', customClass: '' },
    { columnDef: 'salary', header: 'Salary', columnType: 'text', customClass: '' },
    { columnDef: 'action', header: 'Action', columnType: 'icon', customClass: '' }
  ];

  experienceFileUploadConfigurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'BENWEDOC',
    entityId: 0,
    fileCategory: '',
    listUploadedFiles: [],
    documentTypeRequired: true,
    docummentTypeCode: 'BENWEDOC',
    useCase: 'beneficiary',
    useCaseId: 0,
    useEntityId: false,
    isDeleteEnabled: this.viewMode ? false : true,
    isUploadEnabled: this.viewMode ? false : true,
    familyId: null,
  };
  dirtyFormList = {
    form1Dirty: false,
    form2Dirty: false,
    form3Dirty: false,
    form4Dirty: false
  };
  dirtyFormName = {
    form1Name: '',
    form2Name: '',
    form3Name: '',
    form4Name: '',
  };
  dependentIdSubscription: Subscription;
  observableSubscription$ = new Subject();
  dependentId: any;
  beneficiaryType: any;
  familyId: any;
  subscriptions: Subscription[] = [];
  isSaveAsDraftDisabled: boolean = true;
  selectedUser: any;

  isExternalEmployeeId: string;
  isLocked: boolean = false;
  listCountryOfficePhone: any[] = [];
  isFBUser: boolean;
  inputData: any;
  selectedProfileType: string = 'simple';

  constructor(public store: Store<any>,
    public editWorkExperService: EditWorkExperienceDetailsService,
    public toastr: ToastrService,
    public changeDetector: ChangeDetectorRef,
    public route: ActivatedRoute,
    public router: Router,
    public guard: DirtyFormGuard,
    public cacheCountyService: CacheCountryListService,
    public referenceLibraryService: ReferenceLibraryService,
    public dialog: MatDialog,
    public dialogService: DialogService,

  ) {
    this.listCountry = [];
    this.listStates = [];
    this.phoneTypeList = [];
    this.maxDate = new Date();
  }


  ngOnInit() {
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
    this.viewMode = sessionStorage.getItem('bene-profile-viewMode') == 'true' ? true : false;
    this.editWorkExperService.currentUser.pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
      if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY || sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
        this.updateBenProfile = this.editWorkExperService.checkBeneEditPermission();
        this.viewMode = !this.updateBenProfile;
      }
    });
    this.referenceLibraryService.getReferenceDataWithoutAuthentication('PHON').pipe(takeUntil(this.observableSubscription$)).subscribe((response: ReferenceLibrary[]) => {
      this.phoneTypeList = response;
    });
    this.getCountryList();
    this.referenceLibraryService.getReferenceDataWithoutAuthentication('CURR').pipe(takeUntil(this.observableSubscription$)).subscribe((data: []) => {
      this.currencyTypeList = [{ code: "", name: "Select", id: 0, desc: '', group: '' }, ...data];
    });
    this.referenceLibraryService.getReferenceDataWithoutAuthentication('EMPTYP').pipe(takeUntil(this.observableSubscription$)).subscribe((empTypeResponse: []) => {
      this.listEmploymentType = empTypeResponse;
    });

    this.getBeneficiaryWorkDetails();

    this.envPath = environment.docs;
    this.editWorkExperService.deletedfile$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getBeneficiaryWorkDetails();
      }
    });
    this.editWorkExperService.uploaded$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getBeneficiaryWorkDetails();
      }
    });
    this.initializeFormMonitoring();
    this.showEditButton = !this.editWorkExperService.checkValidity();
  }

  getCountryList() {
    this.cacheCountyService.getCountry().pipe(takeUntil(this.observableSubscription$)).subscribe();
    this.cacheCountyService.getCachedCountryList().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.listCountries.length > 0) {
        this.listCountry = data.listCountries.map((c) => {
          c['labelWithCode'] = `${c.countryName} - ${c.phoneCode}`;
          return c;
        });
        this.listCountryOfficePhone = [{ countryCode: '', countryName: 'Select', labelWithCode: 'Select', phoneCode: '' }, ...this.listCountry];
      }
    });
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
    if (this.modeVal) {
      // this.showLifeStoryTimeLineSection = true;
      this.toggleExpansion();
    }
  }

  ngAfterContentInit() {
    this.displayedColumns = this.columns.map(c => c.columnDef);
  }

  ngOnChanges(changes: SimpleChanges): void { 
    if (changes.primaryData.currentValue) {
      this.inputData = changes.primaryData.currentValue;
    }
    if (this.type && this.type === 'family') {
      this.inputData.familyId = this.inputData.familyId || this.userId;
    }
  }

  initializeFormMonitoring() {
    let message;
    let formList;
    this.editWorkExperService.statusChangeSubScriptionSubjectStep.next(1);
    this.editWorkExperService.formIsDirtySubject.subscribe(response => {
      switch (response.formNumber) {
        case 1:
          this.dirtyFormList.form1Dirty = response.dirty;
          this.dirtyFormName.form1Name = response.formName;
          break;
        case 2:
          this.dirtyFormList.form2Dirty = response.dirty;
          this.dirtyFormName.form2Name = response.formName;

          break;
        case 3:
          this.dirtyFormList.form3Dirty = response.dirty;
          this.dirtyFormName.form3Name = response.formName;
          break;
        case 4:
          this.dirtyFormList.form4Dirty = response.dirty;
          this.dirtyFormName.form4Name = response.formName;
      }
      formList = this.dirtyFormName.form1Name ? this.dirtyFormName.form1Name + ',' : '' +
        this.dirtyFormName.form2Name ? this.dirtyFormName.form2Name + ',' : '' +
          this.dirtyFormName.form3Name ? this.dirtyFormName.form3Name + ',' : '' +
            this.dirtyFormName.form4Name ? this.dirtyFormName.form4Name + ',' : '';
      formList = formList.slice(0, -1);
      message = `If you leave before saving, all changes made on the ${formList} form will be lost. Do you wish to continue?`
      if (!this.dirtyFormList.form1Dirty &&
        !this.dirtyFormList.form2Dirty &&
        !this.dirtyFormList.form3Dirty &&
        !this.dirtyFormList.form4Dirty
      ) {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false,
          message: ''
        }));
      } else {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true,
          message: message
        }));
      }
    });
  }

  formCheck(benExpForm, clientForm, dutiesForm, toolsAndTech) {
    if (this.editWorkExperService.formChangesSubscription) {
      this.editWorkExperService.formChangesSubscription.unsubscribe();
    }
    this.benExpForm = benExpForm;
    benExpForm.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      this.editWorkExperService.statusChangeSubScriptionSubjectStep.next(1);
      if (benExpForm.dirty) {
        this.editWorkExperService.formIsDirtySubject.next({ dirty: true, formName: 'Employment', formNumber: 1 });
      }
      else {
        this.editWorkExperService.formIsDirtySubject.next({ dirty: false, formName: '', formNumber: 1 });
      }
    });

    this.subscriptions[0] = this.benExpForm.valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe((formValues) => {
      this.isSaveAsDraftDisabled = !Object.values(formValues).some((val) => !!val || val === 0);
    });

    clientForm.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      this.editWorkExperService.statusChangeSubScriptionSubjectStep.next(2);
      if (clientForm.dirty) {
        this.editWorkExperService.formIsDirtySubject.next({ dirty: true, formName: 'Client', formNumber: 2 });
      }
      else {
        this.editWorkExperService.formIsDirtySubject.next({ dirty: false, formName: '', formNumber: 2 });
      }
    });

    dutiesForm.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      this.editWorkExperService.statusChangeSubScriptionSubjectStep.next(3);
      if (dutiesForm.dirty) {
        this.editWorkExperService.formIsDirtySubject.next({ dirty: true, formName: 'Duties', formNumber: 3 });
      }
      else {
        this.editWorkExperService.formIsDirtySubject.next({ dirty: false, formName: '', formNumber: 3 });
      }
    });

    toolsAndTech.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      this.editWorkExperService.statusChangeSubScriptionSubjectStep.next(4);
      if (toolsAndTech.dirty) {
        this.editWorkExperService.formIsDirtySubject.next({ dirty: true, formName: 'Tools and Technology', formNumber: 4 });
      }
      else {
        this.editWorkExperService.formIsDirtySubject.next({ dirty: false, formName: '', formNumber: 4 });
      }
    });

  }

  // GET api call & Main Table
  getBeneficiaryWorkDetails() {
    this.dataSource = null;
    this.experienceData = [];
    this.editWorkExperService.getBeneExperienceInformation(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId, this.inputData.caseType == 'I130' ? 'FEXPERIENCE' : 'experience', this.inputData.superTaskId, this.inputData.subTaskId, true, this.inputData.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((beneficiary: WorkExpDetails[]) => {
      if (Array.isArray(beneficiary)) {
        beneficiary = beneficiary.map((experience: any) => {
          const clients = [];
          if (experience.clients && experience.clients.length > 0) {
            experience.clients.forEach(val => {
              clients.push(val.clientName);
            });
          }
          return {
            ...experience,
            employmentTypeValue: experience.employmentType ? experience.employmentType['name'] : '',
            'clientsList': clients.join(','),
            'startDate': experience['startDate'] ? moment(experience['startDate']).format('DD-MMM-YYYY') : '',
            'endDate': experience['endDate'] ? moment(experience['endDate']).format('DD-MMM-YYYY') : 'Present'
          };
        });

        this.hasUnsavedData = false;
        this.dataSource = new MatTableDataSource(beneficiary);
        this.experienceData = beneficiary;
        this.editWorkExperService.experienceChanged(this.experienceData)
      }
    });

  }

  // Update 
  updateView(id: number) {
    this.editWorkExperService.getBeneExperienceInformation(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId, this.inputData.caseType == 'I130' ? 'FEXPERIENCE' : 'experience', this.inputData.superTaskId, this.inputData.subTaskId, true, this.inputData.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((beneficiary: WorkExpDetails[]) => {
      beneficiary = beneficiary.map((experience) => {
        const clients = [];
        if (experience.clients && experience.clients.length > 0) {
          experience.clients.forEach(val => {
            clients.push(val.clientName);
          });
        }
        return {
          ...experience,
          employmentTypeValue: experience.employmentType ? experience.employmentType['name'] : '',
          'clientsList': clients.join(','),
          'startDate': experience['startDate'] ? moment(experience['startDate']).format('DD-MMM-YYYY') : '',
          'endDate': experience['endDate'] ? moment(experience['endDate']).format('DD-MMM-YYYY') : 'Present'
        };
      });
      this.hasUnsavedData = false;
      this.dataSource = new MatTableDataSource(beneficiary);
      this.handleEdit(beneficiary.find(x => x.id === id));
    });
  }

  // Save employment
  saveEmployment(benExpForm: NgForm, isDraft: boolean = false) {           
    this.payload = this.constructPayload(this.beneficiaryWorkDetails, isDraft);    

    this.editWorkExperService.saveWorkExperience(this.inputData.caseId, this.inputData.caseType, this.payload, this.inputData.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        benExpForm.reset(benExpForm.value)
        this.toastr.success(response.message);
        this.payload.id = response.data;
        this.updateView(this.payload.id);
        this.getBeneficiaryWorkDetails();
        this.hasID = true;
        this.isLinear = false;
      }
    });
  }

  constructPayload(input, isDraft: boolean = false) {
    this.payload = {
      id: input.id || null,
      addressLine1: input.addressLine1 || null,
      addressLine2: input.addressLine2 || null,
      city: input.city || null,
      companyName: input.companyName || null,
      employmentType: input.employmentType ? input.employmentType : null,
      country: input.countryCode || null,
      countryCode: input.countryCode || null,
      currency: input.currency || null,
      designation: input.designation || null,
      isCurrentRole: input.isCurrentRole ? 1 : 0,
      mobileCountryCode: null,
      mobileNo: null,
      officeCountryCode: input.officeCountryCode ? input.officeCountryCode : null,
      officeNo: input.officeNo || null,
      salary: parseInt(input.salary) || null,
      startDate: input.startDate ? moment(input.startDate).format('YYYY-MM-DD') : null,
      endDate: input.isCurrentRole ? null : (input.endDate ? moment(input.endDate).format('YYYY-MM-DD') : null),
      stateProvinceCode: input.stateProvinceCode || null,
      stateProvinceName: input.stateProvinceCode && this.listStates && this.listStates.length > 0 ?
        this.listStates.find((x: any) => x.stateProvinceCode === input.stateProvinceCode).stateProvinceName :
        (input.stateProvinceName ? input.stateProvinceName : null),
      workExpDetailId: input.id || null, //input.workExpDetailId,
      zipCode: input.zipCode || null,
      duty: input.duty || null,
      subDutyDescription: input.subDutyDescription || null,
      skillName: input.skillName || null,
      jobDuties: input.jobDuties || [],
      tools: input.tools || [],
      clients: input.clients || []
    };
    return this.payload;
  }

  callSaveAPI(payload) {
    this.editWorkExperService.saveWorkExperience(this.inputData.caseId, this.inputData.caseType, payload, this.inputData.familyId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        payload.id = response && response.data;
        this.updateView(payload.id);
      }
    });
  }

  saveAsDraft(event) {
    if (event) {
      this.saveEmployment(this.benExpForm, event);
    }
  }

  //clientForm: NgForm;
  resetClient(clientForm) {
    clientForm.resetForm();
  }

  addClientDetails(clientForm: NgForm) {
    // if (this.client.clientName && this.client.startDate) {
    this.beneficiaryWorkDetails.clients.push({
      id: null,
      clientName: this.client.clientName,
      startDate: (this.client.startDate) ? moment(this.client.startDate).format('YYYY-MM-DD') : '',
      endDate: (this.client.endDate) ? moment(this.client.endDate).format('YYYY-MM-DD') : ''
    }
    );
    this.payload = this.constructPayload(this.beneficiaryWorkDetails);
    this.callSaveAPI(this.payload);
    this.client.clientName = '';
    this.client.startDate = '';
    this.client.endDate = '';
    clientForm.resetForm(); // clear the form after add the client details
    // }
  }

  removeClientItem(id) {
    let index = this.beneficiaryWorkDetails.clients.findIndex(itm => itm.id === id);
    this.beneficiaryWorkDetails.clients.splice(index, 1);
    this.payload = this.constructPayload(this.beneficiaryWorkDetails);
    this.callSaveAPI(this.payload);
  }

  //dutiesForm: NgForm;
  resetJobDuties(dutiesForm) {
    dutiesForm.resetForm();
    this.duty = {
      duty: '',
      dutyId: null,
      sequenceNo: null,
      subDuties: [
        {
          subDutyDescription: '',
          subDutyId: null,
          sequenceNo: null
        }
      ]
    };
  }


  //Duty: NgForm;
  addJobDuties(duty) {
    if (!this.jobEditable) {
      this.beneficiaryWorkDetails.jobDuties.push({
        dutyId: duty && duty.dutyId ? duty.dutyId : null,
        sequenceNo: 0,
        duty: duty.duty,
        subDuties: duty.subDuties,
      });
    }
    this.jobEditable = false;
    this.payload = this.constructPayload(this.beneficiaryWorkDetails);
    this.callSaveAPI(this.payload);
  }

  addSubDuties() {
    this.duty.subDuties.push({
      sequenceNo: null,
      subDutyId: null,
      subDutyDescription: ''
    });
  }

  deleteSubDuties(index) {
    this.duty.subDuties.splice(index, 1);
  }

  removeJobDuty(id) {
    let index = this.beneficiaryWorkDetails.jobDuties.findIndex(itm => itm.dutyId === id);
    this.beneficiaryWorkDetails.jobDuties.splice(index, 1);
    this.payload = this.constructPayload(this.beneficiaryWorkDetails);
    this.callSaveAPI(this.payload);
  }

  editDuty(duty) {
    this.jobEditable = true;
    this.duty = duty;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.listToolsTechnology.push({
        skillName: value.trim(),
        id: null
      });
    }
    if (input) {
      input.value = '';
    }
  }

  // Tools & Technology
  pasteChips(event: ClipboardEvent) {
    event.preventDefault(); //Prevents the default action
    event.clipboardData
      .getData('Text') //Gets the text pasted
      .split(/,/) //Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach(value => {
        if (value.trim()) {
          this.listToolsTechnology.push({
            skillName: value.trim(),
            id: null
          }); //Push if valid
        }
      });
  }

  removeToolsChip(item) {
    const index = this.listToolsTechnology.indexOf(item);
    if (index >= 0) {
      this.listToolsTechnology.splice(index, 1);
    }
  }

  addToolsTechnologies(toolsAndTech) {
    this.beneficiaryWorkDetails.tools = this.listToolsTechnology.concat(this.beneficiaryWorkDetails.tools);
    this.payload = this.constructPayload(this.beneficiaryWorkDetails);
    this.callSaveAPI(this.payload);
    this.listToolsTechnology = [];
    toolsAndTech.reset(toolsAndTech.value);
    this.toolsAndTechChip = '';
  }

  removeToolsTechnology(id: number) {
    let index = this.beneficiaryWorkDetails.tools.findIndex(itm => itm.id === id);
    this.beneficiaryWorkDetails.tools.splice(index, 1);
    this.payload = this.constructPayload(this.beneficiaryWorkDetails);
    this.callSaveAPI(this.payload);
  }

  handleEdit(input) {
    this.expandPanel = true;
    this.editWorkExperService.getStates(input.countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
      this.listStates = states;
      this.beneficiaryWorkDetails = {
        id: input.id,
        addressLine1: input.addressLine1,
        addressLine2: input.addressLine2,
        city: input.city,
        companyName: input.companyName,
        employmentType: input.employmentType ? input.employmentType.code : null,
        country: input.countryCode,
        countryCode: input.countryCode,
        currency: input.currency,
        designation: input.designation,
        isCurrentRole: input.isCurrentRole == 1 ? true : false,
        mobileCountryCode: null,
        mobileNo: null,
        officeCountryCode: input.officeCountryCode,
        officeNo: input.employerOfficeNo,
        salary: input.salary,
        startDate: moment(input.startDate).toISOString(),
        endDate: input.isCurrentRole == 1 ? null : moment(input.endDate).toISOString(),
        stateProvinceCode: input.stateProvinceCode,
        stateProvinceName: input.stateProvinceCode ?
          this.listStates.find((x: any) => x.stateProvinceCode === input.stateProvinceCode).stateProvinceName :
          (input.stateProvinceName ? input.stateProvinceName : null),
        workExpDetailId: input.id,
        zipCode: input.zipCode,
        duty: input.duty,
        subDutyDescription: input.subDutyDescription,
        skillName: input.skillName,
        jobDuties: input.jobDuties,
        tools: input.tools,
        clients: input.clients,
        documentList: input.documents
      };
      this.hasID = true;
      this.isLinear = false;
      this.experienceFileUploadConfigurations.entityId = input.id;
      this.experienceFileUploadConfigurations.listUploadedFiles = input.documents ? input.documents : [];
      this.experienceFileUploadConfigurations = Object.assign({}, this.experienceFileUploadConfigurations);
      this.changeDetector.markForCheck();
    });
  }

  toggleExpansion() {
    this.expandPanel = !this.expandPanel;
    this.resetForm();
    this.hasID = false;
    this.isLinear = true;
  }


  handleCountryChange(countryCode: string) {
    this.editWorkExperService.getStates(countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
      this.listStates = states;
      if (this.listStates.length) {
        this.beneficiaryWorkDetails['stateProvinceCode'] = null;
      } else {
        this.beneficiaryWorkDetails['stateProvinceName'] = null;
      }
      this.changeDetector.markForCheck();
    });
  }

  handleDeleteExperience(input) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.editWorkExperService.deletBeneWorkExperience(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId, 'experience', this.inputData.superTaskId, this.inputData.subTaskId, input.id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          if (this.beneficiaryWorkDetails.id === input.id) {
            this.resetForm();
          }
          this.toastr.success(response.message);
          this.getBeneficiaryWorkDetails();
        });
      }
    });
  }

  // File Upload
  fileUploadSuccess() {
    this.getBeneficiaryWorkDetails(); // Relaod main table
  }

  downloadDoc(doc) {
    this.editWorkExperService.downloadDocuments(environment.docs + doc.fileLocation.substring(23), doc.fileLocation.substring(23));
  }

  handleEmploymentTypeChange(val, benExpForm: NgForm) {
    benExpForm.form.get('currency').clearValidators();
    benExpForm.form.get('salary').clearValidators();
    benExpForm.form.get('currency').updateValueAndValidity();
    benExpForm.form.get('salary').updateValueAndValidity();
  }

  onRefreshPage(event) {
    if (event) {
      this.ngOnInit();
    }
  }

  resetFormToInitial(form: NgForm) {
    if (form)
      form.reset();
  }

  resetForm() {
    this.experienceFileUploadConfigurations.listUploadedFiles = [];
    this.experienceFileUploadConfigurations = Object.assign({}, this.experienceFileUploadConfigurations);
    this.beneficiaryWorkDetails = new WorkExpDetails({});
    this.hasID = false;
    this.isLinear = true;
  }

  closeForm(ngFrm) {
    this.resetForm();
    this.toggleExpansion();
    ngFrm.reset(ngFrm.value);
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.editWorkExperService.onUnSubscribe(this.subscriptions);
    if (this.editWorkExperService.formChangesSubscription) {
      this.editWorkExperService.formChangesSubscription.unsubscribe();
    }
    if (this.dependentIdSubscription) {
      this.dependentIdSubscription.unsubscribe();
    }
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
  }


  onChangeProfile(event) {
    this.selectedProfileType = this.selectedProfileType === 'simple' ? 'complete': 'simple';
  }
}

