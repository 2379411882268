import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { combineLatest, Subject, Subscription } from "rxjs";
import { ReferenceLibraryService } from "src/app/services/referenceLibrary.service";
import {
  distinctUntilChanged,
  map,
  mergeMap,
  take,
  takeUntil,
} from "rxjs/operators";
import {
  CompanyDetails,
  ReferenceLibrary,
  TableValidationNumber,
} from "src/app/data/models/referenceLibrary.model";
import { Store } from "@ngrx/store";
import {
  MarkCleanFormAction,
  MarkDirtyFormAction,
} from "src/app/app-state/actions/dirty-form.actions";
import { PetitionerService } from "src/app/services/petitioner.service";

import * as errorUtils from "src/app/modules/utility/global-utils";
import { AttorneyService } from "src/app/services/attorney.service";
import { CreateTaskService } from "../../superTask/components/create-task/create-task.service";
import { DashboardWidgetsService } from "src/app/services/dashboard-widgets.service";
import { TaskAllocationService } from "src/app/services/task-allocation.service";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as RolesRouteMap from "app-models";
import { standardImmClassification, standardSubtasks, standardVisas } from "src/app/data/constants/standard-visas";

@Component({
  selector: "app-create-configure-time",
  templateUrl: "./create-configure-time.component.html",
  styleUrls: ["./create-configure-time.component.scss"],
})
export class CreateConfigureTimeComponent implements OnInit {
  // @Input() isConfigureTimeList: boolean;
  public stepTimeLineDetails: any;
  isShowStepsLines: boolean = false;
  congigureTimeForm: FormGroup;
  primaryCompanyId: number;
  beneficiaryList: any[] = [];
  userType = "";
  readonly stateEnum: typeof TableValidationNumber = TableValidationNumber;
  immigrantType = "";
  subTaskType: any[];
  visaTypes: ReferenceLibrary[] = [];
  immCategoryCode: any;
  immClassificationCode: any;
  isImmigrant = false;
  isDiplay: boolean = true;
  taskTypeList: ReferenceLibrary[] = [];
  saveDisable: boolean = false;
  public superTaskName: any;
  petitionTypes: ReferenceLibrary[] = [];
  companyListAssociatedWithAttorney: any[] = [];
  destroy$ = new Subject<void>();
  immigrationStatus: any[] = [];
  petitionerCompanyList: CompanyDetails[] = [];
  selectedSubTaskTypeName: string;
  selectedTaskType: any;
  selectedSubTask = "";
  visaType: any;
  petitionType: any;
  subTasktype: ReferenceLibrary;
  taskType = "RFE";
  disabledStepTask: boolean = false;
  editStepLinesDetails = null;
  fields: {
    disableIsOutsideImagility: boolean;
  } = {
      disableIsOutsideImagility: false,
    };

  visaId = "";

  subtask: any;
  constructor(
    private fb: FormBuilder,
    private referenceLibraryService: ReferenceLibraryService,
    protected store: Store<any>,
    private petitionerService: PetitionerService,
    private attorneyService: AttorneyService,
    private createTaskService: CreateTaskService,
    private dashboardWidgetsService: DashboardWidgetsService,
    private _taskAllocationService: TaskAllocationService,
    private toastr: ToastrService,
    public router: Router
  ) {
    this.editStepLinesDetails =
      this.router.getCurrentNavigation()?.extras?.state?.data || null;
    this.congigureTimeForm = this.fb.group({
      id: [null],
      beneficiaryId: [null],
      companyId: [null],
      petitionType: [null],
      visaType: [null],
      immCategoryCode: [null],
      immClassificationCode: [null],
      taskTypeCode: [null, Validators.required],
      superTaskTypeCode: ["NONIMMPET", Validators.required],
      isOutsideImagility: [false],
      subTaskType: [null, Validators.required],
      taskType: [null],
      taskTypeId: [null],
      visaId: [null]
    });
  }

  companyRequired(control: FormControl): { [s: string]: boolean } {
    if (
      this.congigureTimeForm &&
      (!this.congigureTimeForm.value.immCategoryCode ||
        (this.congigureTimeForm.value.immCategoryCode &&
          this.congigureTimeForm.value.immCategoryCode !== "GCFLY")) &&
      !control.value
    ) {
      return { companyIsRequired: true };
    }
    return null;
  }

  visaTemplates = [];

  ngOnInit() {
    this.primaryCompanyId = parseInt(sessionStorage.getItem("companyId"));
    this.userType = sessionStorage.getItem("userTypeRole");
    this.taskTypeValues();
    this.congigureTimeForm.valueChanges
      .pipe(
        map(
          () => this.congigureTimeForm.dirty && this.congigureTimeForm.enabled
        ),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((dirty) => {
        if (dirty) {
          this.store.dispatch(
            new MarkDirtyFormAction({
              dirty: true,
            })
          );
        } else {
          this.store.dispatch(
            new MarkCleanFormAction({
              dirty: false,
            })
          );
        }
      });
  }
  taskTypeValues() {
    if (this.editStepLinesDetails) {
      this.getEditDetailsSteps();
    } else {
      combineLatest([
        this.referenceLibraryService.getReferenceData("IMMTYP"),
        this.referenceLibraryService.getReferenceData("SUPTSKTYP"),
      ])
        .pipe(take(1))
        .subscribe((response: any[]) => {
          this.immigrationStatus = response[0];
          // this.immigrantType = this.immiStatus[0].code;
          this.immigrantType = "VISA";
          this.taskTypeList = response[1];
          if (this.congigureTimeForm.get("superTaskTypeCode").value) {
            this.getVisaType();
          }
        });
    }
  }


  getEditDetailsSteps() {
    this.disabledStepTask = true;
    if (this.editStepLinesDetails.superTaskType === "NONIMMPET") {
      this.isImmigrant = false;
      this.superTaskName = "Non Immigrant Petition";
    } else {
      this.isImmigrant = true;
      this.superTaskName = "Immigrant Petition";
    }
    this.taskTypeList = [
      {
        name: this.superTaskName,
        code: this.editStepLinesDetails.superTaskType,
        id: 0,
        desc: "",
        group: "",
      },
    ];
    if (this.editStepLinesDetails.visaName) {
      this.visaTypes = [
        {
          name: this.editStepLinesDetails.visaName,
          code: this.editStepLinesDetails.visaType,
          id: 0,
          desc: "",
          group: "",
        },
      ];
    } else {
      this.congigureTimeForm.patchValue({
        superTaskTypeCode: this.editStepLinesDetails.superTaskType,
      });
      this.getVisaType();
    }
    if (this.editStepLinesDetails.petitionName) {
      this.petitionTypes = [
        {
          name: this.editStepLinesDetails.petitionName,
          code: this.editStepLinesDetails.petitionType,
          id: 0,
          desc: "",
          group: "",
        },
      ];
    }
    this.petitionerCompanyList = [
      {
        companyName: this.editStepLinesDetails.companyName,
        id: this.editStepLinesDetails.companyId,
      },
    ];
    this.subTaskType = [
      {
        name: this.editStepLinesDetails.taskTypeName,
        code: this.editStepLinesDetails.taskType,
        id: 0,
        desc: "",
        group: "",
      },
    ];

    this.isDiplay = false;
    this.congigureTimeForm.patchValue({
      companyId: this.editStepLinesDetails.companyId,
      petitionType: this.editStepLinesDetails.petitionType,
      visaType: this.editStepLinesDetails.visaType,
      immCategoryCode: this.editStepLinesDetails.immigrationCategory,
      immClassificationCode:
        this.editStepLinesDetails.immigrationClassification,
      taskTypeCode: this.editStepLinesDetails.taskType,
      superTaskTypeCode: this.editStepLinesDetails.superTaskType,
      subTaskType: this.editStepLinesDetails.taskType,
      taskType: this.editStepLinesDetails.taskType,
      taskTypeId: this.editStepLinesDetails?.taskTypeId || "",
      visaId: parseInt(this.editStepLinesDetails?.taskTypeSaveJsonId) || ""
    });
    this.getVisaTemplates();
    this.visaId = this.editStepLinesDetails.visaId;
    if (!this.isImmigrant) {
      this.onDisplay();
    }
  }
  disableStepLineTable() {
    if (this.isShowStepsLines) {
      this.isShowStepsLines = false;
    }
  }
  getVisaType() {
    this.disableStepLineTable();
    this.congigureTimeForm.patchValue({
      visaType: null,
      petitionType: null,
      taskTypeCode: null,
      subTaskType: null,
      taskTypeId: null
    });
    this.selectedSubTaskTypeName = null;
    this.petitionTypes = [];
    this.petitionerCompanyList = [];
    this.companyListAssociatedWithAttorney = [];
    if (this.congigureTimeForm.get("superTaskTypeCode").value === "NONIMMPET") {
      this.isImmigrant = false;
      this.referenceLibraryService
        .getReferenceData("VISATYP")
        .subscribe((response: ReferenceLibrary[]) => {
          this.petitionTypes = [];
          this.visaTypes = response;
          this.onChangeFilterDropDown();
        });
    } else if (
      this.congigureTimeForm.get("superTaskTypeCode").value === "IMMPET"
    ) {
      this.isImmigrant = true;
      this.getImmigrantClassificationAndCategory();
    }
  }

  getImmigrantClassificationAndCategory() {
    this.congigureTimeForm.patchValue({
      visaType: null,
      petitionType: null,
      immCategoryCode: null,
      immClassificationCode: null,
      companyId: null,
      beneficiaryId: null,
      isOutsideImagility: false,
      taskTypeId: null
    });
    this.referenceLibraryService
      .getReferenceData("GCIMMCATG")
      .subscribe((response: any) => {
        this.visaTypes = (this.userType !== 'Attorney' && this.userType !== 'Platform') ? (response as any[]).filter(x => x.code !== 'GCFLY' && x.code !== 'ASYLUM') : response;
        //this.visaTypes = (response as any[]).filter((x) => x.code !== "GCFLY");
        //this.visaTypes = response;
        if (this.editStepLinesDetails) {
          // this.congigureTimeForm.patchValue({
          //   immCategoryCode: this.visaTypes[0]["code"],
          // });
          this.getImmigrationClassification();
        } else {
          this.onChangeFilterDropDown();
        }
      });
  }
  onChangeFilterDropDown() {
    const task: any = {};
    task.visaType = this.congigureTimeForm.get("visaType").value;
    task.petitionType = this.congigureTimeForm.get("petitionType").value;
    task.taskTypeCode = this.congigureTimeForm.get("taskTypeCode").value;
    if (task.visaType && task.petitionType && task.taskTypeCode) {
      this.petitionerService.detectTaskFilterChanges(task, this.immigrantType);
    }
    if (this.editStepLinesDetails.visaType) {
      this.getPetitionType();
    }
    // if (task.visaType && task.petitionType && task.taskTypeCode) {
    //   this.getVisaTemplates();
    // }
  }
  getPetitionType() {
    this.disableStepLineTable();
    this.congigureTimeForm.patchValue({
      petitionType: null,
      taskTypeCode: null,
      companyId: null,
      subTaskType: null,
      isOutsideImagility: false,
      taskTypeId: null
    });
    this.selectedSubTaskTypeName = null;
    let companyListReq;
    switch (this.congigureTimeForm.get("visaType").value) {
      case "H1-B":
        if (this.userType === "Attorney") {
          const reqJson = {
            searchText: "",
            searchCriteria: "",
            sortBy: "",
            sortOrder: "",
            pageNumber: 0,
            recordsPerPage: 0,
          };
          companyListReq = this.attorneyService.getClientsList(
            this.primaryCompanyId,
            reqJson
          );
        } else {
          companyListReq = this.petitionerService.getPetitionerClientList(
            this.primaryCompanyId
          );
        }
        combineLatest([
          this.createTaskService.getPetitionList(
            this.congigureTimeForm.get("visaType").value
          ),
          companyListReq,
        ])
          .pipe(take(1))
          .subscribe((response: any[]) => {
            this.petitionTypes = response[0].map((x) => {
              return {
                code: x.code,
                name: x.name,
                id: x.id,
                desc: x.description,
                group: x.group,
              };
            });
            if (this.editStepLinesDetails) {
              this.congigureTimeForm.patchValue({
                petitionType: this.editStepLinesDetails.petitionType,
              });
              this.getSubTaskType();
            }
            if (this.userType === "Attorney") {
              if (response[1] && response[1].petetionerList) {
                this.companyListAssociatedWithAttorney =
                  response[1].petetionerList;
              }
            } else {
              this.petitionerCompanyList = response[1];
            }
            if (!this.editStepLinesDetails) {
              this.onChangeFilterDropDown();
            }
          });
        break;
      default:
        this.fields.disableIsOutsideImagility = false;
        const req = [
          this.createTaskService.getPetitionList(
            this.congigureTimeForm.get("visaType").value
          ),
        ];
        if (this.userType === "Attorney") {
          const reqJson = {
            searchText: "",
            searchCriteria: "",
            sortBy: "",
            sortOrder: "",
            pageNumber: 0,
            recordsPerPage: 0,
          };
          companyListReq = this.attorneyService.getClientsList(
            this.primaryCompanyId,
            reqJson
          );
          req.push(companyListReq);
        } else {
          companyListReq = this.petitionerService.getPetitionerClientList(
            this.primaryCompanyId,
            "countryCode"
          );
          req.push(companyListReq);
        }
        combineLatest(req)
          .pipe(take(1))
          .subscribe((response: any[]) => {
            this.petitionTypes = response[0].map((x) => {
              return {
                code: x.code,
                name: x.name,
                id: x.id,
                desc: x.description,
                group: x.group,
              };
            });
            if (this.editStepLinesDetails) {
              this.congigureTimeForm.patchValue({
                petitionType: this.editStepLinesDetails.petitionType,
              });
              this.getSubTaskType();
            }
            if (this.userType === "Attorney") {
              if (response[1] && response[1].petetionerList) {
                this.companyListAssociatedWithAttorney =
                  response[1].petetionerList;
              }
            } else {
              this.petitionerCompanyList = response[1];
            }
            // this.onChangeFilterDropDown();
            if (!this.editStepLinesDetails) {
              this.onChangeFilterDropDown();
            }
          });
        break;
    }
  }
  getSubTaskType() {
    this.disableStepLineTable();
    this.congigureTimeForm.patchValue({
      taskTypeCode: null,
    });
    this.selectedSubTaskTypeName = null;
    if (this.congigureTimeForm.get("superTaskTypeCode").value) {
      this.dashboardWidgetsService
        .getSubTasksType(this.congigureTimeForm.get("superTaskTypeCode").value, this.congigureTimeForm.get("petitionType").value,
          this.congigureTimeForm.get("visaType").value)
        .subscribe((response: ReferenceLibrary[]) => {
          if (response) {
            if (
              !(
                this.userType === "Attorney" &&
                this.congigureTimeForm.get("visaType").value === "H1-B"
              )
            ) {
              response = response.filter((ele) => ele.code !== "RFE");
            }
            this.subTaskType = response;

            if (this.editStepLinesDetails) {
              this.congigureTimeForm.patchValue({
                subTaskType: this.editStepLinesDetails.taskType,
              });
            }
            if (!this.congigureTimeForm.get("isOutsideImagility").value) {
              this.congigureTimeForm.patchValue({
                taskTypeCode: "PETITION",
              });
              this.selectedSubTaskTypeName = this.subTaskType.find(
                (x) => x.code === "PETITION"
              ).name;
              if (!this.editStepLinesDetails) {
                this.onChangeSubTaskType();
              } else {
                this.onDisplay();
              }
            } else if (this.subTaskType && this.subTaskType.length === 1) {
              this.congigureTimeForm.patchValue({
                taskTypeCode: this.subTaskType[0].code,
              });
              this.selectedSubTaskTypeName = this.subTaskType[0].name;
              if (!this.editStepLinesDetails) {
                this.onChangeSubTaskType();
              } else {
                this.onDisplay();
              }
            }
          }
        });
    }
  }

  getVisaTemplates() {
    console.log(this.congigureTimeForm);
    this.visaTemplates = [];
    this.disableStepLineTable();
    this.congigureTimeForm.patchValue({
      taskTypeId: null,
    });
    const payload = {
      ... this.congigureTimeForm.value,
      formType: 'I129FORM',
      countryCode: 'USA',
      language: 'ENG',
      petitionType: this.congigureTimeForm.value.petitionType,
      subTaskType: this.congigureTimeForm.value.subTaskType,
      taskType: this.congigureTimeForm.value.superTaskTypeCode,
      visaType: this.congigureTimeForm.value.visaType,
      immCategory: this.congigureTimeForm.value.immCategoryCode,
      immClassification: this.congigureTimeForm.value.immClassificationCode
    };

    if (payload.taskType === 'NONIMMPET') {
      // payload.hasOwnProperty('property1')
      delete payload.immCategory;
      delete payload.immClassification;
    } else {
      delete payload.visaType;
      delete payload.petitionType;
    }

    this.createTaskService.getVisaTemplates(payload)
      .pipe(take(1))
      .subscribe((response: any[]) => {
        this.visaTemplates = response || [];
        if (standardVisas.includes(payload.visaType) || standardImmClassification.includes(payload.immClassification) || standardSubtasks.includes(payload.subTaskType)) {
          this.visaTemplates.push({ 'visaId': '', 'visaName': 'STANDARD WORKFLOW' });
        }
      });
  }

  onChangeVisaTemplate(e) {
    console.log(e);
    this.visaId = e.value;
    this.getVisaTemplateJson();
  }

  getVisaTemplateJson() {
    if (this.visaId) {
      this.createTaskService.getVisaTemplateJSON(this.visaId)
        .pipe(take(1))
        .subscribe((res: any) => {
          this.congigureTimeForm.get('taskTypeId').setValue(res.taskTypeId);
        });
    }
  }

  onChangeSubTaskType() {
    this.getVisaTemplates();
    this.disableStepLineTable();
    this.onChangeFilterDropDown();

  }
  onCompanyChangeID() {
    this.disableStepLineTable();
  }
  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }
  onChangeImmigrationClassification() {
    this.disableStepLineTable();
    this.congigureTimeForm.patchValue({
      taskTypeCode: null,
    });
    this.selectedSubTaskTypeName = null;
    this.immCategoryCode = this.congigureTimeForm.get("immCategoryCode").value;
    this.immClassificationCode = this.congigureTimeForm.get(
      "immClassificationCode"
    ).value;
    this.selectedSubTask = "";
    this.selectedTaskType = "";
    if (this.immCategoryCode === "GCFLY") {
      this.getSubTaskTypeListForFamilyImmigration();
    } else if (this.immCategoryCode === 'GCEB') {
      this.getSubTaskTypeListGCEB();
    } else {
      this.getSubTaskTypeList();
    }
  }

  public companyId: any = "";
  onDisplay() {
    this.companyId = parseInt(sessionStorage.getItem("companyId"));
    if (!this.congigureTimeForm.get('companyId').value) {
      this.congigureTimeForm.get('companyId').setValue(this.companyId);
    }
    this.stepTimeLineDetails = this.congigureTimeForm;
    this.isShowStepsLines = true;
  }
  public selectedValue: any;
  receiveMessage($event) {
    this.selectedValue = $event;
    if (this.saveDisable) {
      this.saveDisable = false;
    }
    this.selectedValue.data.stepList.forEach((ele) => {
      if (
        (ele.duration > this.stateEnum.taskTimeLineValidationNumber &&
          ele.durationUnit === "HOUR") ||
        ele.duration <= this.stateEnum.shouldNotLessThanZero ||
        ele.duration === this.stateEnum.shouldGraterThanZero
      ) {
        this.saveDisable = true;
      }
    });
  }
  saveTimeLine() {
    if (this.userType === "Platform" || (this.userType === "Attorney" && this.congigureTimeForm.get("immCategoryCode").value === 'GCFLY')) {
      this.companyId = parseInt(sessionStorage.getItem("companyId"));
    } else {
      this.companyId = this.congigureTimeForm.value.companyId;
    }
    let payload = {
      companyId: this.companyId,
      stepList: this.selectedValue["data"]["stepList"],
      taskTypeId: this.selectedValue["data"]["taskTypeId"],
    };
    this._taskAllocationService
      .saveTimeLineConfigured(
        this.congigureTimeForm.value.visaType,
        payload.taskTypeId,
        payload
      )
      .subscribe((response: any) => {
        if (response.status === 200) {
          this.toastr.success(response.message);
          this.congigureTimeForm.reset(this.congigureTimeForm.value);
          this.router.navigate([
            `${RolesRouteMap.userRoleRouteMapping[
            sessionStorage.getItem("userTypeRole")
            ]
            }/task-allocation/task-allocations`,
          ]);
        }
      });
  }

  cancel() {
    this.congigureTimeForm.reset(this.congigureTimeForm.value);
    this.router.navigate([
      `${RolesRouteMap.userRoleRouteMapping[
      sessionStorage.getItem("userTypeRole")
      ]
      }/task-allocation/task-allocations`,
    ]);
  }

  getSubTaskTypeListForFamilyImmigration() {
    this.dashboardWidgetsService
      .getSubTaskTypeListForFamilyImmigration(
        this.congigureTimeForm.get("superTaskTypeCode").value,
        this.immCategoryCode,
        this.immClassificationCode
      )
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.subTaskType = response;
          if (this.subTaskType && this.subTaskType.length > 0) {
            this.congigureTimeForm.patchValue({
              taskTypeCode: this.subTaskType[0].code,
            });
            this.selectedSubTaskTypeName = this.subTaskType[0].name;
          }
        }
        this.onChangeFilterDropDown();
        this.getSponsorList();
      });
  }
  getSubTaskTypeListGCEB() {
    this.dashboardWidgetsService
      .getSubTasksTypeListImmigrant(
        this.immCategoryCode,
        this.immClassificationCode
      )
      .subscribe((response) => {
        this.subTaskType = response.subTaskTypeList;
        if (this.subTaskType && this.subTaskType.length > 0) {
          this.congigureTimeForm.patchValue({
            taskTypeCode: this.subTaskType[0].code,
          });
          this.selectedSubTaskTypeName = this.subTaskType[0].name;
        }
        this.onChangeFilterDropDown();
      });

    //commented the above code to hard code i140 and Perm based on condition

    // if(this.immClassificationCode === 'EB1EXEC'){
    //   this.subTaskType = [{
    //     code: "I140",
    //     createdBy: 1,
    //     description: "I-140",
    //     group: "TASKTYP",
    //     id: 541,
    //     modifiedBy: null,
    //     name: "I-140",
    //     refGroupdesc: "Task Type"
    //   }]
    // }else{
    //   this.subTaskType = [{
    //     code: "I140",
    //     createdBy: 1,
    //     description: "I-140",
    //     group: "TASKTYP",
    //     id: 541,
    //     modifiedBy: null,
    //     name: "I-140",
    //     refGroupdesc: "Task Type"
    //   },{
    //     code: "PERM",
    //     createdBy: 1,
    //     description: "PERM",
    //     group: "TASKTYP",
    //     id: 540,
    //     modifiedBy: null,
    //     name: "PERM",
    //     refGroupdesc: "Task Type"
    //   },{
    //     name: "Prevailing Wage Determination",
    //     code: "PWD",
    //     group: "TASKTYP",
    //     desc: "Prevailing Wage Determination",
    //     id: 538
    //   },{
    //     name: "Recruitment Effort",
    //     code: "RECEFF",
    //     group: "TASKTYP",
    //     desc: "Recruitment Effort",
    //     id: 539
    //   }]
    // }
    // if (this.subTaskType && this.subTaskType.length > 0 ) {
    //     this.congigureTimeForm.patchValue({
    //       taskTypeCode: this.subTaskType[0].code,
    //     });
    //     this.selectedSubTaskTypeName = this.subTaskType[0].name;
    // }
    // this.onChangeFilterDropDown();
  }

  getSubTaskTypeList() {
    this.dashboardWidgetsService
      .getSubTasksTypeListImmigrant(
        this.immCategoryCode,
        this.immClassificationCode
      )
      .subscribe((response) => {
        this.subTaskType = response.subTaskTypeList;
        if (this.subTaskType && this.subTaskType.length > 0) {
          this.congigureTimeForm.patchValue({
            taskTypeCode: this.subTaskType[0].code,
          });
          this.selectedSubTaskTypeName = this.subTaskType[0].name;
        }
        this.onChangeFilterDropDown();
      });
  }

  getSponsorList() {
    this.createTaskService
      .getSponsorList(this.primaryCompanyId)
      .pipe(take(1))
      .subscribe((data) => {
        if (data && data.beneficiaryList && data.beneficiaryList.length > 0) {
          this.beneficiaryList = data.beneficiaryList.map((x) => {
            return {
              ...x,
              beneficiaryEmployeeDisplayName: `${x.beneficiaryEmployeeFName} ${x.beneficiaryEmployeeLName}`,
            };
          });
        }
      });
  }
  getImmigrationClassification() {
    this.congigureTimeForm.patchValue({
      visaType: null,
      petitionType: null,
      immClassificationCode: null,
      companyId: null,
      beneficiaryId: null,
      isOutsideImagility: false,
    });
    let companyListReq;
    this.petitionTypes = [];
    const requestArray = [];
    const immCategoryCode = this.congigureTimeForm.get("immCategoryCode").value;
    requestArray.push(
      this.referenceLibraryService.getReferenceData(
        this.congigureTimeForm.get("immCategoryCode").value
      )
    );
    //if (immCategoryCode && immCategoryCode !== "GCFLY") {
    if (this.userType === "Attorney") {
      const reqJson = {
        searchText: "",
        searchCriteria: "",
        sortBy: "",
        sortOrder: "",
        pageNumber: 0,
        recordsPerPage: 0,
      };
      companyListReq = this.attorneyService.getClientsList(
        this.primaryCompanyId,
        reqJson
      );
    } else {
      companyListReq = this.petitionerService.getPetitionerClientList(
        this.primaryCompanyId
      );
    }
    requestArray.push(companyListReq);
    //}

    combineLatest(requestArray).subscribe((response: any[]) => {
      // this.petitionTypes = (response[] as any[]).filter(
      //   (x) => x.code == "EB1EXEC"
      // );
      this.petitionTypes = response[0] || [];
      //if (immCategoryCode && immCategoryCode !== "GCFLY") {
      if (this.userType === "Attorney") {
        if (response[1] && response[1].petetionerList) {
          this.companyListAssociatedWithAttorney = response[1].petetionerList;
        }
        if (this.editStepLinesDetails) {
          this.congigureTimeForm.patchValue({
            immClassificationCode: this.editStepLinesDetails.immigrationClassification,
            companyId: this.editStepLinesDetails.companyId,
          });
          this.onDisplay();
        }
      } else {
        if (this.editStepLinesDetails) {
          this.congigureTimeForm.patchValue({
            immClassificationCode: this.editStepLinesDetails.immigrationClassification,
            companyId: this.editStepLinesDetails.companyId,
          });
          this.onDisplay();
        } else {
          this.petitionerCompanyList = response[1];
          this.congigureTimeForm.patchValue({  // hardcoded for immigrationClassifiaction
            immClassificationCode: this.editStepLinesDetails.immigrationClassification,
          });
          this.onChangeImmigrationClassification(); // hardcoded for immigrationClassifiaction
        }
      }
      //}
      if (!this.editStepLinesDetails) {
        this.onChangeFilterDropDown();
      }
    });
  }
}
