import { Component, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as errorUtils from "error-message-utility";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ImagilityBaseResponse, PrimaryData, TaskStep, I140FileUploadConfiguration, ReferenceLibrary } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { getStepDetails, getStepStatusUpdate, loadSubTaskDetails, updateStepStatus, updateStepStatusToInitialState, getVisaTypeCode } from 'visa-store';

import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { sync_petitioner_document_code } from '../../utility/constants/sync-code';
import { PetitionerDetailsService } from './petitioner-details.service';
//import { FroalaEditorOptions } from '../../../../data/constants/froala-editor-options';
//import * as  froalaService from 'src/app/modules/utility/froala-service';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { combineLatest } from 'rxjs';
import { VisaExtendedState } from '../../utility/interfaces/visa-extended-state';

import { environment } from 'environment-configurations';
import { I140FileUploadService } from 'src/app/services/i140-file-upload.service';
import { NoteDialogConfigurations, DeleteDialogConfigurations } from 'dialog-configuration-library';
import { NoteDialogComponent } from '../note-dialog/note-dialog.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { SyncDetailsService } from './../../utility/services/sync-details.service';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiServicesService } from '../../../beneficiary/services/api-services.service';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { ReferenceLibraryService } from '../../../../services/referenceLibrary.service';
import { UploadImageDialogComponent } from "../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
@Component({
  selector: 'app-petitioner-details',
  templateUrl: './petitioner-details.component.html',
  styleUrls: ['./petitioner-details.component.scss']
})
export class PetitionerDetailsComponent implements OnInit, OnDestroy {
  @ViewChild("petitionerdetailsimageupload") public petitionerdetailsimageupload: UploadImageDialogComponent;
@Output() @ViewChild("petitionerdetailskendoeditor") public petitionerdetailskendoeditor: EditorComponent;
  otherDocumentsFileUploadConfigurations: I140FileUploadConfiguration;

  disabledControls: boolean;
  typeOfButton: string; // This is required for status update button
  stepDetails: TaskStep;
  petitionerDetails;
  petitionerDocumentList = [];

  observableSubscription$ = new Subject();

  // public options = FroalaEditorOptions.EditorOptions;
  // uploadService: any;
  userType: string;
  documentTypeDropdownCode;
  groupcode;
  template: {
    body: string,
  };
  inputData: PrimaryData;
  fields: {
    showDividerBeforeEntitiesRelationshipdetails: boolean;
    showEntitiesRelationshipdetails: boolean;
    showPetitionOrganizationDetails: boolean;
    showUSCompanyTitle: boolean;
  } = {
      showDividerBeforeEntitiesRelationshipdetails: true,
      showEntitiesRelationshipdetails: true,
      showPetitionOrganizationDetails: true,
      showUSCompanyTitle: true
    };

  countryList: [];
  stateList: [];
  petitionerDetailsForm: FormGroup;
  peerGrpLabOrg: any[];
  statelistArrObj: {
    workAddress: {
      countryCode: string,
      stateList: any[]
    }[]
  } = {
      workAddress: []
    };

  constructor(
    private store: Store<VisaExtendedState>,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private petitionerDetailsService: PetitionerDetailsService,
    public dialogService: DialogService,
    private toastr: ToastrService,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private uploadService: I140FileUploadService,
    private syncDetailsService: SyncDetailsService,
    private petitionOrganizationDetailsBuilder: FormBuilder,
    private apiServiceState: ApiServicesService,
    private cacheCountyService: CacheCountryService,
    private referenceLibraryService: ReferenceLibraryService,
  ) {
    this.countryList = [];
    this.stateList = [];
    this.disabledControls = false;
    this.template = {
      body: ''
    };
    this.inputData = this.dynamicDialogConfig.data;
  }

  ngOnInit(): void {
    this.documentTypeDropdownCode = '';
    switch (this.inputData.visatype) {
      case 'E1':
      case 'E2':
      case 'E2C':
        this.fields = {
          showDividerBeforeEntitiesRelationshipdetails: false,
          showEntitiesRelationshipdetails: false,
          showPetitionOrganizationDetails: false,
          showUSCompanyTitle: true
        };
        this.documentTypeDropdownCode = 'COMPPETDOCUS';
        this.groupcode = 'COMPGENDOC';
        break;
      case 'L1A':
      case 'L1B':
        this.fields = {
          showDividerBeforeEntitiesRelationshipdetails: false,
          showEntitiesRelationshipdetails: false,
          showPetitionOrganizationDetails: false,
          showUSCompanyTitle: true
        };
        this.documentTypeDropdownCode = 'COMPPETDOCUS';
        this.groupcode = 'COMPGENDOC';
        break;
      case 'O1A':
      case 'O1B':
        this.fields = {
          showDividerBeforeEntitiesRelationshipdetails: false,
          showEntitiesRelationshipdetails: false,
          showPetitionOrganizationDetails: true,
          showUSCompanyTitle: false
        };
        this.documentTypeDropdownCode = 'COMPOTHDOC';
        this.groupcode = 'COMPGENDOC';
        this.createPetitionOrganizationDetailsForm();
        this.getCountries();
        // this.getpeerGrpLabOrg();
        break;
      case 'TN1':
      case 'TN2':
        this.fields = {
          showDividerBeforeEntitiesRelationshipdetails: false,
          showEntitiesRelationshipdetails: false,
          showPetitionOrganizationDetails: false,
          showUSCompanyTitle: false
        };
        this.documentTypeDropdownCode = 'COMPOTHDOC';
        this.groupcode = 'COMPGENDOC';
        break;
      default:
        this.fields = {
          showDividerBeforeEntitiesRelationshipdetails: false,
          showEntitiesRelationshipdetails: false,
          showPetitionOrganizationDetails: false,
          showUSCompanyTitle: false
        };
        this.documentTypeDropdownCode = 'COMPOTHDOC';
        this.groupcode = 'COMPGENDOC';
        break;
    }



    this.userType = sessionStorage.getItem('userTypeRole');
    //froalaService.insertPageBreak();
    this.getPetitionerDetails();
    this.getAdditionalInfoDetails();
    this.getPetitionerDocumentDetails();

    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? 'MARK_COMPLETE' : 'MARK_SUBMIT';
        this.stepDetails = data;

        // if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
        //   this.updateStepStatus('INPROGRESS');
        // }
        this.toggleControlsStatus();
      });

  }

  createPetitionOrganizationDetailsForm() {
    const visaType = this.dynamicDialogConfig.data.visatype;
    this.petitionerDetailsForm = this.petitionOrganizationDetailsBuilder.group({
      doesLabourOrgExist: [false],
      isManagementPetitionAvailable: [false],
      isAdvOpinionSubmt: [1, Validators.required],
      noLabOrgExplanation: [''],
      peerPetitionAdvOpinionOrgDtlResponseDTO: new FormGroup({
        id: new FormControl(null),
        petitionId: new FormControl(0),
        orgName: new FormControl(null),
        orgTypeId: new FormGroup({
          id: new FormControl(0),
          group: new FormControl(''),
          refGroupdesc: new FormControl(''),
          code: new FormControl('PEERGRPLABORG'),
          name: new FormControl(''),
          description: new FormControl(''),
        }),
        orgAddrId: this.createaddressItem(),
        sentDate: new FormControl(null),
        phoneNo: new FormControl('', Validators.pattern(/^-?(0|[0-9]\d*)?$/)),
      }),
      managementPetitionAdvOpinionOrgDtlResponseDTO: new FormGroup({
        id: new FormControl(null),
        petitionId: new FormControl(0),
        orgName: new FormControl(null),
        orgTypeId: new FormGroup({
          id: new FormControl(0),
          group: new FormControl(''),
          refGroupdesc: new FormControl(''),
          code: new FormControl('MNGMNTORG'),
          name: new FormControl(''),
          description: new FormControl(''),
        }),
        orgAddrId: this.createaddressItem(),
        sentDate: new FormControl(null),
        phoneNo: new FormControl('', Validators.pattern(/^-?(0|[0-9]\d*)?$/)),
      }),
    });
    this.getPetitionOrganizationDetails();
  }

  getPetitionOrganizationDetails() {
    this.petitionerDetailsService.getPetitionOrganizationDetails(
      this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId,
      7).subscribe((response: ImagilityBaseResponse) => {
        response = {
          ...response.data,
          doesLabourOrgExist: (response.data.doesLabourOrgExist !== null && response.data.doesLabourOrgExist !== 1) ? false : true,
          peerPetitionAdvOpinionOrgDtlResponseDTO: response.data.peerPetitionAdvOpinionOrgDtlResponseDTO !== null ? {
            ...response.data.peerPetitionAdvOpinionOrgDtlResponseDTO,
            sentDate: response.data.peerPetitionAdvOpinionOrgDtlResponseDTO.sentDate ? new Date(response.data.peerPetitionAdvOpinionOrgDtlResponseDTO.sentDate) : null,
            phoneNo: response.data.peerPetitionAdvOpinionOrgDtlResponseDTO.phoneNo
          } : null,
          managementPetitionAdvOpinionOrgDtlResponseDTO: response.data.managementPetitionAdvOpinionOrgDtlResponseDTO !== null ? {
            ...response.data.managementPetitionAdvOpinionOrgDtlResponseDTO,
            sentDate: response.data.managementPetitionAdvOpinionOrgDtlResponseDTO.sentDate ? new Date(response.data.managementPetitionAdvOpinionOrgDtlResponseDTO.sentDate) : null,
            phoneNo: response.data.managementPetitionAdvOpinionOrgDtlResponseDTO.phoneNo
          } : null,
        };
        this.handleCountryChange(null, 0, 'workAddress');
        this.handleCountryChange(null, 1, 'workAddress');
        this.petitionerDetailsForm.reset(response);
        if (this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO && this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgTypeId.code === "PEERGRPLABORG") {
          if (this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.countryCode !== null) {
            this.handleCountryChange(this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.countryCode, 0, 'workAddress');
          } else this.handleCountryChange(null, 0, 'workAddress')
        } else this.handleCountryChange(null, 0, 'workAddress')

        if (this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO && this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgTypeId.code === "MNGMNTORG") {
          if (this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.countryCode !== null) {
            this.handleCountryChange(this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.countryCode, 1, 'workAddress');
          } else this.handleCountryChange(null, 1, 'workAddress')
        } else {
          this.handleCountryChange(null, 1, 'workAddress')
        }

        if (this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO !== null) {
          this.petitionerDetailsForm.patchValue({
            isManagementPetitionAvailable: true
          });
        }
      });
    if (this.petitionerDetailsForm.value.isAdvOpinionSubmt === null || this.petitionerDetailsForm.value.isAdvOpinionSubmt === 1) {
      this.petitionerDetailsForm.patchValue({
        isAdvOpinionSubmt: 1,
      });
    }
  }

  handleCountryChange(countryCode: string, addressIndex: number, addressType: string) {
    const workAddressCountryCodeArr = this.statelistArrObj.workAddress
      .filter(x => x.countryCode).map(x => x.countryCode);
    const countryCodeArr = Array.from(new Set([
      ...Array.from(new Set(workAddressCountryCodeArr))
    ]));
    if (countryCodeArr && countryCodeArr.length > 0 && countryCodeArr.includes(countryCode)) {
      let stateList: any[];
      if (workAddressCountryCodeArr.includes(countryCode)) {
        stateList = this.statelistArrObj.workAddress.find(x => x.countryCode === countryCode).stateList;
      }
      this.statelistArrObj[addressType][addressIndex] = {
        countryCode,
        stateList
      };
    } else {
      this.apiServiceState.getStates(countryCode).pipe(takeUntil(this.observableSubscription$)).subscribe((states: []) => {
        this.statelistArrObj[addressType][addressIndex] = {
          countryCode,
          stateList: states
        };
      });
    }
  }

  createaddressItem(): FormGroup {
    const visaType = this.dynamicDialogConfig.data.visatype;
    return this.petitionOrganizationDetailsBuilder.group({
      id: [0],
      addressLine1: [''],
      addressLine2: [''],
      postCode: ['', [Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
      doorNo: null,
      streetNo: null,
      countryCode: [null],
      countryName: [''],
      stateProvinceCode: [null],
      stateProvinceName: [null],
      city: [''],
      type: [''],
      addressTypeCode: [''],
      beneficiaryId: [''],
      familyId: [''],
      companyId: [''],
      employeeId: [''],
      immigrationId: [''],
      addressLine2NoType: [''],
      state: [''],
      workLocationTypeId: [''],
      workLocationId: [''],
      workLocationTypeCode: [''],
      clientName: [''],
      clientEmail: [''],
      clientPhoneNo: [''],
      clientPhoneCode: [''],
      locality: "",
      sequenceNo: ""
    }) //[this.address1FieldRequired, this.countryCodeFieldRequired, this.cityProvinceCodeFieldRequired, this.postCodeProvinceCodeFieldRequired]
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  syncPetitionerDocuments() {
    this.petitionerDetailsService.syncProfileData(this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId,
      sync_petitioner_document_code.SYNC_TASK_TYPE_BENE_IMMIGRATION,
      this.groupcode,
      this.dynamicDialogConfig.data.superTaskId,
      this.dynamicDialogConfig.data.subTaskId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          this.toastr.success(response.message);
          this.getPetitionerDocumentDetails();
        }
      });
  }

  getPetitionerDetails() {
    this.petitionerDetailsService.getPetitionerDetails(
      this.dynamicDialogConfig.data.visatype,
      this.inputData.companyId,
      this.dynamicDialogConfig.data.superTaskId,
      this.dynamicDialogConfig.data.subTaskId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        this.petitionerDetails = response.data;
      });
  }

  getPetitionerDocumentDetails() {
    this.petitionerDetailsService.getPetitionerUploadDetails(
      this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId,
      this.groupcode,
      this.dynamicDialogConfig.data.superTaskId,
      this.dynamicDialogConfig.data.subTaskId).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
        if (response && response.data) {
          // this.toastr.success(response.message);
          // this.fileUploadConfigurations = Object.assign({}, this.fileUploadConfigurations);
          this.petitionerDocumentList = response.data;
        }
      });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.inputData.visatype
    }));
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls =
      this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';
    const catagoryCode = ['TN1', 'TN2'].includes(this.inputData.visatype) ? 'COMPOTHDOC' : sync_petitioner_document_code.SYNC_TASK_TYPE_Code_US;  //IM-8011 issue fix
    this.otherDocumentsFileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getDocTypeApiUrl: this.inputData.caseType === 'I485' ? `/${this.dynamicDialogConfig.data.caseType}/task/${this.dynamicDialogConfig.data.subTaskId}/group/${this.documentTypeDropdownCode}/documentTypes` :
        `/${this.dynamicDialogConfig.data.visatype}/petitionType/${this.dynamicDialogConfig.data.petitionType}/group/${this.documentTypeDropdownCode}/documentTypes`,

      getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${this.documentTypeDropdownCode}/`,
      postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${this.documentTypeDropdownCode}/fileCategory/`,
      deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
      documentTypeDropdownCode: this.documentTypeDropdownCode
    });
  }

  getAdditionalInfoDetails() {
    this.petitionerDetailsService.getAdditionalInfo(
      this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId,
      this.dynamicDialogConfig.data.superTaskId,
      this.dynamicDialogConfig.data.subTaskId).subscribe((response: ImagilityBaseResponse) => {
        if (response && response.data) {
          this.template.body = response.data.additionalText;
        }
      });
  }
  handleSave() {
    const payload = {
      // body: this.template.body,
      additionalText: this.template.body,
      infoName: 'Petition Letter List',
      infoType: 'PTLTRLIST',
      isDel: 0
    };
    this.petitionerDetailsService.postAdditionalInfo(
      this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId,
      payload).subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          // this.toastr.success(response.message);
          // this.getTemplateDetails();
          // this.redirectionComponent.emit('LetterPreview');
        }
      });
  }

  handleClose() {
    this.dynamicDialogRef.close();
  }
  
  openDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  download(file) {
    this.uploadService.downloadDocuments(environment.docs + file.fileLocation.substring(23), file.fileLocation.substring(23));
  }

  handleSendBack(item) {
    NoteDialogConfigurations.data = {
      title: `Rejected Document - ${item.fileCategory.name} `,
      payload: {
        // beneficiaryId: this.stepDetails.beneficiaryId ? this.stepDetails.beneficiaryId : null, //IM-7543 fix
        companyId: this.stepDetails.companyId ? this.stepDetails.companyId : null,
        employeeId: this.inputData.immSpecialistId,
        notificationType: 'DOCREJECT',
        text: null,
        title: null,
        data: JSON.stringify({
          petitionId: this.dynamicDialogConfig.data.i140CaseId,
          taskId: this.stepDetails.taskId || null,
          documentType: item.fileCategory.name,
          documentCategory: item.fileCategory.group
        }),
        channelType: '',
        notifyAllBeneficiaries: false,
        notifyAllEmployees: false,
        priority: '',
      }
    };
    this.dialogService.open(NoteDialogComponent, NoteDialogConfigurations);
  }

  handleNotification(item) {
    NoteDialogConfigurations.data = {
      title: `Missing Document - ${item.fileCategory.name} `,
      payload: {
        // beneficiaryId: this.stepDetails.beneficiaryId ? this.stepDetails.beneficiaryId : null,
        companyId: this.stepDetails.companyId ? this.stepDetails.companyId : null,
        employeeId: this.inputData.immSpecialistId,
        notificationType: 'DOCMISS',
        text: null,
        title: null,
        data: JSON.stringify({
          petitionId: this.dynamicDialogConfig.data.i140CaseId,
          taskId: this.stepDetails.taskId || null,
          documentType: item.fileCategory.name,
          documentCategory: item.fileCategory.group
        }),
        channelType: '',
        notifyAllBeneficiaries: false,
        notifyAllEmployees: false,
        priority: '',
      }
    };
    this.dialogService.open(NoteDialogComponent, NoteDialogConfigurations);
  }

  deleteImmigrationDocuments(item) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: boolean) => {
        if (response) {
          this.syncDetailsService.deleteDocuments(
            this.dynamicDialogConfig.data.visatype, this.dynamicDialogConfig.data.caseType,
            this.dynamicDialogConfig.data.caseId, item.id)
            .pipe(takeUntil(this.observableSubscription$))
            .subscribe((responseDeleteDocuments: ImagilityBaseResponse) => {
              this.toastr.success(responseDeleteDocuments.message);
              this.getPetitionerDocumentDetails();
            });
        }
      });
  }

  postpetitionerDetails() {
    const payload = {
      doesLabourOrgExist: this.petitionerDetailsForm.value.doesLabourOrgExist ? 1 : 0,
      isAdvOpinionSubmt: this.petitionerDetailsForm.value.isAdvOpinionSubmt,
      noLabOrgExplanation: this.petitionerDetailsForm.value.noLabOrgExplanation,
      peerPetitionAdvOpinionOrgDtlResponseDTO: {
        id: this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.id,
        petitionId: this.dynamicDialogConfig.data.caseId,
        orgName: this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgName,
        orgTypeCode: 'PEERGRPLABORG',
        country: this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.countryCode,
        postCode: this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.postCode,
        address1: this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.addressLine1,
        city: this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.city,
        address2: this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.addressLine2,
        state: this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceCode !== '' ? this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceCode : null,
        stateName: this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceName !== '' ? this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceName : null,
        phoneNo: this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.phoneNo,
        sentDate: this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.sentDate ? new Date(this.petitionerDetailsForm.value.peerPetitionAdvOpinionOrgDtlResponseDTO.sentDate) : null,
      },
      managementPetitionAdvOpinionOrgDtlResponseDTO: this.petitionerDetailsForm.value.isManagementPetitionAvailable ? {
        id: this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.id,
        petitionId: this.dynamicDialogConfig.data.caseId,
        orgName: this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgName,
        orgTypeCode: 'MNGMNTORG',
        country: this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.countryCode,
        postCode: this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.postCode,
        address1: this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.addressLine1,
        city: this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.city,
        address2: this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.addressLine2,
        state: this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceCode !== '' ? this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceCode : null,
        stateName: this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceName !== '' ? this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.orgAddrId.stateProvinceName : null,
        phoneNo: this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.phoneNo,
        sentDate: this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.sentDate ? new Date(this.petitionerDetailsForm.value.managementPetitionAdvOpinionOrgDtlResponseDTO.sentDate) : null,
      } : null
    }
    this.petitionerDetailsService.postPetitionOrganizationDetails(
      this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType,
      this.dynamicDialogConfig.data.caseId,
      payload).subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.toastr.success(response.message);
          this.getPetitionOrganizationDetails();
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
        }
      });
  }

  getCountries() {
    this.cacheCountyService.getCachedCountryList().pipe(takeUntil(this.observableSubscription$)).subscribe(data => {
      if (data.listCountries.length > 0) {
        this.countryList = data.listCountries;
      }
    });
  }
  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }

  getpeerGrpLabOrg() {
    this.referenceLibraryService.getReferenceData('PEERGRPLABORG').pipe(takeUntil(this.observableSubscription$)).subscribe((response: ReferenceLibrary[]) => {
      this.peerGrpLabOrg = response;
    });
  }

  handleCancel() {
    this.createPetitionOrganizationDetailsForm();
  }
  openpetitionerdetailsimageuploaddialog() {    
    this.petitionerdetailsimageupload.open();
  }
  public petitionerdetailseditorValueChange(value: string): void {
    this.template.body = value;
  }
  // address1FieldRequired(group: FormGroup): { [s: string]: boolean } {
  //   if (group.value.addressLine1 &&
  //     (!this.petitionerDetailsForm.value.isAdvOpinionSubmt || (this.petitionerDetailsForm.value.isAdvOpinionSubmt === 0))) {
  //     return { address1FieldRequired: true };
  //   }
  //   return null;
  // }
  // countryCodeFieldRequired(group: FormGroup): { [s: string]: boolean } {
  //   if (group.value.countryCode &&
  //     (!this.petitionerDetailsForm.value.isAdvOpinionSubmt || (this.petitionerDetailsForm.value.isAdvOpinionSubmt === 0))) {
  //     return { countryCodeFieldRequired: true };
  //   }
  //   return null;
  // }
  // cityProvinceCodeFieldRequired(group: FormGroup): { [s: string]: boolean } {
  //   if (group.value.city &&
  //     (!this.petitionerDetailsForm.value.isAdvOpinionSubmt || (this.petitionerDetailsForm.value.isAdvOpinionSubmt === 0))) {
  //     return { cityProvinceCodeFieldRequired: true };
  //   }
  //   return null;
  // }
  // postCodeProvinceCodeFieldRequired(group: FormGroup): { [s: string]: boolean } {
  //   if (group.value.postCode &&
  //     (!this.petitionerDetailsForm.value.isAdvOpinionSubmt || (this.petitionerDetailsForm.value.isAdvOpinionSubmt === 0))) {
  //     return { postCodeProvinceCodeFieldRequired: true };
  //   }
  //   return null;
  // }
}
