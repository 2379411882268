import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { SchedulingService } from 'src/app/services/scheduling.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from "rxjs";

interface City {
  name: string,
  code: string
}

@Component({
  selector: 'app-courtdate',
  templateUrl: './courtdate.component.html',
  styleUrls: ['./courtdate.component.scss']
})
export class CourtdateComponent implements OnInit, AfterViewInit {

  @Input('obj') courtDateObj;
  courtDateForm: FormGroup ;
  cities: any = [];
  userType: string;
  recurrencedropdown: any = [];
  recurrence: City;
  showRecurrnece: boolean = false;
  timedropdown: any = [];
  endTime: City;
  startTime: City;
  calendarItemId;
  minimumDate;
  obs: Subscription;
  // courtLocations :any;

  lang = [
    { name: "HTML" },
    { name: "ReactJS" },
    { name: "Angular" },
    { name: "Bootstrap" },
    { name: "PrimeNG" },
  ];
  courtLocations = [
    {
      displayName: "SANDIEGO",
      name: "SANDIEGO",
      code: 1067,
      type: "meeting",
      id: 1067,
    },
    {
      displayName: "SUNNYVALE",
      name: "SUNNYVALE",
      code: 1068,
      type: "event",
      id: 1068,
    },
    {
      displayName: "SANTA CLARA",
      name: "SANTA CLARA",
      code: 1069,
      type: "task",
      id: 1069,
    },
    {
      displayName: "FREMONT",
      name: "FREMONT",
      code: 1071,
      type: "reminder",
      id: 1071,
    },
  ];


  addAttendes: boolean = false;
  displayMaximizable: boolean = false;
  meetingForm: FormGroup;
  durationList: any[] = [{ 'type': 'WEEK', 'code': 'WEEK' },
  { 'type': 'BI-WEEK', 'code': 'BIWEEK' },
  { 'type': 'MONTH', 'code': 'MONTH' }, { 'type': 'YEAR', 'code': 'YEAR' }];

  daysList: any[] = [{ 'value': 1, 'day': 'Monday' },
  { 'value': 2, 'day': 'Tuesday' },
  { 'value': 3, 'day': 'Wednesday' },
  { 'value': 4, 'day': 'Thursday' },
  { 'value': 5, 'day': 'Friday' },
  { 'value': 6, 'day': 'Saturday' },
  { 'value': 7, 'day': 'Sunday' }];

  selectedDays: string[] = [];
  dateTimeToSend: Date;
  selectedTime: string;
  scheduleType: string = '';
  BIWeekly: string = 'FIRST WEEK';
  recurrences: any[] = [];
  weekNo;
  dayOfMonth;
  monthOfYear;
  selectedWeeks: string[] = [];
  values2: string[] = [];
  navUrl;
  privateMeeting: string;
  publicMeeting: string;

  filteredString: string = '';
  users = [{
    name: 'Leela',
    joinedDate: new Date(15, 2, 2016)
  },
  {
    name: 'Rama',
    joinedDate: new Date(17, 3, 2019)
  },
  {
    name: 'Krishna',
    joinedDate: new Date(20, 4, 2019)
  },
  ];
  // this.obs = this.mform.valueChanges
  // .pipe(debounceTime(500))
  // .subscribe((data) => {
  //   console.log("The data is :: ", data);
  //   if (data.name) this.getEmployeeDetails(data.name);
  // });

  
  
  constructor(private fb: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private schedulingService: SchedulingService) {
      this.courtDateForm = this.fb.group(
        {
          title : ['', Validators.required],
          location : ['',Validators.required],
          judgeName : [''],
          startTime: ['',Validators.required],
          endTime: ['',Validators.required],
          startDate: ['',Validators.required],
          endDate: ['',Validators.required],
          description: [],
          isPublic: [],
        }
      )
    this.userType = sessionStorage.getItem("userTypeRole");
    if (this.userType == "Petitioner") {
      this.navUrl = "/petitioner-landing/dashboard/petitioner-dashboard/scheduling/";
    } else if (this.userType == "Attorney") {
      this.navUrl = "/attorney-landing/dashboard/attorney-dashboard/scheduling/";
    }
    this.minimumDate = new Date();

   



  }



  ngOnInit(): void {
    if (this.courtDateObj) {
      this.calendarItemId = this.courtDateObj.id;
      this.courtDateForm = this.fb.group(
        {
          // title: [this.courtDateObj.title, Validators.required],
          title: [this.courtDateObj.courtDate.courtDateFor, Validators.required],
          location: [this.courtDateObj.location],
          judgeName: [this.courtDateObj.courtDate.courtJudgeName],
          startTime: [this.courtDateObj.startTime, Validators.required],
          endTime: [this.courtDateObj.endTime],
          startDate: [moment(this.courtDateObj.date).format('MM/DD/YYYY'), Validators.required],
          endDate: [moment(this.courtDateObj.date).format('MM/DD/YYYY')],
          isPublic: [this.courtDateObj.isPublic],
          // isPrivate: [],
          // startDate: [moment(this.courtDateObj.date).format('YYYY-MM-DD'), Validators.required],
          // endDate: [moment(this.courtDateObj.date).format('YYYY-MM-DD')],
          description: [this.courtDateObj.description]
        }
      )
      console.log('this.courtDateObj ::', this.courtDateObj);
      console.log('this.courtDateObj', this.courtDateObj)
    }
    else {
      this.courtDateForm = this.fb.group(
        {
          title: ['', Validators.required],
          location: ['', ],
          judgeName: [''],
          startTime: ['', Validators.required],
          endTime: ['', Validators.required],
          startDate: ['', Validators.required],
          endDate: ['', Validators.required],
          description: [],
          isPublic: []
        }
      )
      console.log('this.courtDateObj ::', this.courtDateObj);
    }
  }

  ngAfterViewInit() {
    let control = this.courtDateForm.get('isPublic');
    this.obs =  control.valueChanges.subscribe(e => { control.setValue(e, {emitEvent: false}); });
  }


  OnChange(ev) {
    this.showRecurrnece = false;
    if (ev.value.name === 'custom') {
      this.showRecurrnece = true;
    }
    //this.displayMaximizable = true;
  }

  // to save the court date.
  save(calendarItemId?) {

      let startT = this.courtDateForm.get("startTime")?.value;
      let endT = this.courtDateForm.get("endTime")?.value;
      let hour = startT.substring(0,2);
      let minutes = startT.substring(3);
      let ehour = endT.substring(0,2);
      let eminutes = endT.substring(3);
      
      // alert ('time :: ' + startT +'hour :: ' + hour + 'minutes :: ' + minutes + 'endTime ::' + endT +'ehour :: ' + ehour + 'eminutes :: ' + eminutes);

      // hour = parseInt(hour);
      // ehour = parseInt(ehour);
      // minutes = parseInt(minutes);
      // eminutes = parseInt(eminutes);

      // alert ('eminutes'+eminutes);
      // alert ('minutes'+minutes);

      // if(ehour >  hour) {

      // } else
       if( ehour < hour  ) {
        // alert('end hour  is  less than hour :: ' + (eminutes < minutes))
        this.courtDateForm.patchValue({
          endTime: ''
        })
        return;
      } else  if( ehour == hour && eminutes <  minutes) {
        // alert('end minutes are less than minutes :: ' + (eminutes < minutes))
        this.courtDateForm.patchValue({
          endTime: ''
        })
        return;
      }
      // let eminFirst =  eminutes.substring(0,1);
      // if ( eminFirst == 0 ) {
      //   alert ('minFirst :: ' + eminFirst);
      // } else {
      //   alert ('minFirst is not equal to zero' + eminutes);
      // }
      // let minlast =  minutes.substring(1,2);

      // alert ('eminutes'+eminutes);
      // alert ('minutes'+minutes);


      if(eminutes <  minutes) {
        // alert(eminutes   < minutes);
      }

      // alert ('parsedTime :: ' +  parseInt(hour))
      console.log('Form Value' + this.courtDateForm.value);

    


    // alert('Form Value' + this.courtDateForm.value);
    // alert(this.courtDateForm);


    /*
{
 
  "calItemTypeCode": "COURTDATE",
  "calendarItemId": 754,
  "calendarScheduleId": 0,
  "courtDateFor": "Fine for illegal parking ",
  "courtJudgeName": "new judge",
  "courtLocation": "Sandiego",
   "description": "Fine for illegal parking ",
  "endDate": "2022-08-17T00:00:00.000Z",
  "endTime": "10:30",
  "isAllday": 0,
  "isOptional": 0,
  "isPublic": 0,
  "isSeries": 0,
    "startDate": "2022-08-17T00:00:00.000Z",
  "startTime": "09:30",
  "subject": "string",
  "timeZoneCode": "string",
   "title" : "Fine for illegal parking "
}

    */


   console.log('Starg DATE :: ',this.courtDateForm.get("startDate")?.value)
   console.log('Starg DATE :: ',this.courtDateForm.get("startDate"))
    let payload = {
      "courtDateFor": this.courtDateForm.get("title")?.value,
      "calItemTypeCode": "COURTDATE",
      "calendarItemId": this.calendarItemId ? this.calendarItemId : 0,
      "calendarScheduleId": 0,
      "isAllday": 0,
      "isOptional": 0,
      // "isPublic": 0,
      "isPublic": this.courtDateForm.get("isPublic")?.value,
      "isSeries": 1,
      "courtLocation": this.courtDateForm.get("location")?.value,
      "courtJudgeName": this.courtDateForm.get("judgeName")?.value,
      "startDate": moment(this.courtDateForm.get("startDate")?.value).format('YYYY-MM-DD'),
      "startTime": this.courtDateForm.get("startTime")?.value,
      "endDate": moment(this.courtDateForm.get("endDate")?.value).format('YYYY-MM-DD'),
      "endTime": this.courtDateForm.get("endTime")?.value,
      "description": this.courtDateForm.get("description")?.value
    }

    // if(this.calendarItemId) {
    //   payload.calendarItemId = this.calendarItemId
    // }
    console.log('payload ::', payload)
    if (this.calendarItemId) {
      console.log('This.Calendaritemid :: ', this.calendarItemId)
      this.schedulingService.updateCalendarItem(payload).subscribe(response => {
        console.log('Update Calendar   Item :: ', response)
        this.toaster.success(response.message);
        this.router.navigate([this.navUrl]);
        // this.router.navigateByUrl(this.navUrl)
      },
        error => {
          this.toaster.error(error.message)
        }
      );
    }
    else {
      this.schedulingService.saveCalendarItem(payload).subscribe(response => {
        console.log('Save Calendar Item :: ', response)
        this.toaster.success(response.message);
        this.router.navigate([this.navUrl]);
        // this.router.navigateByUrl(this.navUrl)
      },
        error => {
          this.toaster.error(error.message)
        }
      );
    }
  }
  cancel() {
    // this.courtDateForm.reset(); 
    this.router.navigate([this.navUrl])
  }

  cancelCourtdate() {
    let payload = {
      // "calendarId": 0,
      "calendarItemId": this.calendarItemId,
      "calendarItemType": 1070,
      "isCancelAllItems": false
    };

    this.schedulingService.cancelCalendarItem(payload).subscribe(response => {
      console.log('Response :: ', response);
      this.toaster.success(response.message);
      this.router.navigate([this.navUrl]);
    },
      error => {
        console.log('Error while cancelling item', error);
        this.toaster.error(error.message)
      })
  }

  showValue(event) {
    console.log('showValue event', event);
    console.log('showValue event', this.publicMeeting)
  }

  setEndDate(ed){
    console.log('setEndDate', ed);
    // alert('setEndDate'+ ed);
  }

  checkEndDateTouched(ed,sd){

    console.log('checkEndDateTouched',sd)
    this.courtDateForm.patchValue({
      endDate: sd.value
    })

    // if(ed.value) {
    //   alert ('ed has value'  +ed.value);
    //   alert('startDate'+moment(sd.value));
    //   alert('startDate'+moment(sd.value).format('MM/DD/YYYY'));
    //   alert('endDate'+moment(ed.value));
    //   alert('endDate'+moment(ed.value).format('MM/DD/YYYY'));
    // } else {
    //   alert('startDate'+moment(sd.value).format('MM/DD/YYYY'));
    // }
  }

  setEndTime(et){
    // console.log('setEnd Time', et);
    // alert('setEnd Time' + et.value);
  }

  checkEndTimeTouched(et,st){
    // if(et.value) {
    //   alert ('ed has value'  +et.value);
    //   alert('startDate'+moment(et.value));
    //   alert('startDate'+moment(st.value).format('MM/DD/YYYY'));
    //   alert('endDate'+moment(et.value));
    //   alert('endDate'+moment(et.value).format('MM/DD/YYYY'));
    // } else {
    //   alert('startTime :: '+ st.value);
    // }
  }

  checkStartTimeExists(et,st){
    // if(st.value) {
    //   let startTime = st.value;
    //   let hour = startTime.substring(0,2);
    //   let minutes = startTime.substring(2);
    //   let endTime = et.value;
    //   let ehour = endTime.substring(0,2);
    //   let eminutes = endTime.substring(2);
    //   alert ('time ::' + startTime +'hour :: ' + hour + 'minutes' + minutes + 'endTime ::' + endTime +'ehour :: ' + ehour + 'eminutes' + eminutes);
    // }
  }
}
