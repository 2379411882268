import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { I140FileUploadService } from 'src/app/services/i140-file-upload.service';
import { I140FileUploadConfiguration, PrimaryData } from 'app-models';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { sync_petitioner_document_code } from '../../../utility/constants/sync-code';
//import { FroalaEditorOptions } from '../../../../../data/constants/froala-editor-options';
//import * as  froalaService from 'src/app/modules/utility/froala-service';
import { ToastrService } from 'ngx-toastr';
import { SyncDetailsService } from '../../../utility/services/sync-details.service';
import { PetitionerDetailsService } from '../../petitioner-details/petitioner-details.service';
import { VisaExtendedState } from 'src/app/modules/visa-base/utility/interfaces/visa-extended-state';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthorAndPublicationService } from './author-and-publication.service';
import { ImagilityBaseResponse, TaskStep } from 'app-models';
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs/internal/Subject';
import { combineLatest } from 'rxjs';
import { getStepDetails, updateStepStatus, loadSubTaskDetails, getSubTaskDetails, updateStepStatusToInitialState, getVisaTypeCode } from 'visa-store';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { DeleteDialogConfigurations } from 'base-education-details-library';
import { Subscription } from 'rxjs';
import * as DirtyForm from '../../../../../app-state/actions/dirty-form.actions';
import { UploadImageDialogComponent } from "../../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { AppServicesLibraryService } from 'app-services-library';
@Component({
  selector: 'app-author-and-publication',
  templateUrl: './author-and-publication.component.html',
  styleUrls: ['./author-and-publication.component.scss']
})
export class AuthorAndPublicationComponent implements OnInit {
  @ViewChild("authorandpublicationimageupload") public authorandpublicationimageupload: UploadImageDialogComponent;
  @Output() @ViewChild("authorandpublicationkendoeditor") public authorandpublicationkendoeditor: EditorComponent;
  subcriptions: Subscription[] = [];
  formDirtyFromDifferentControls = false;
  otherDocumentsFileUploadConfigurations: I140FileUploadConfiguration;
  fileUploadConfigurations: I140FileUploadConfiguration;
  // public dynamicDialogConfig: DynamicDialogConfig;
  // public options = {
  //   ...FroalaEditorOptions.EditorOptions,
  //   events: {
  //     contentChanged: () => {
  //       this.setFormDirty();
  //     }
  //   }
  // };
  formAuthorPublication: FormGroup;
  authorAndPublicationList = [];
  isEdit: boolean = false;
  btnText: string = 'Add';
  stepDetails: TaskStep;
  task: SubTaskDetails;
  id;
  userType: string;
  disabledControls: boolean;
  extrAblDocEvdCatg = [];
  template: {
    body: string,
  };
  stepStatus;
  showMarkComplete = false;
  markStep: string;

  observableSubscription$ = new Subject();
  inputData: PrimaryData;
  constructor(
    private store: Store<VisaExtendedState>,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private authorAndPublicationService: AuthorAndPublicationService,
    public dialogService: DialogService,
    private toastr: ToastrService,
    private stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private uploadService: I140FileUploadService,
    private syncDetailsService: SyncDetailsService,
    private fb: FormBuilder,
    private appServicesLibraryService: AppServicesLibraryService) {
    this.inputData = this.dynamicDialogConfig.data;
    this.disabledControls = false;
    this.template = {
      body: ''
    };
  }

  ngOnInit(): void {
    this.userType = sessionStorage.getItem('userTypeRole');
    //froalaService.insertPageBreak();

    console.log(this.dynamicDialogConfig.data);
    this.formAuthorPublication = this.fb.group({
      title: ['', Validators.required],
      id: []
    });
    this.LoadAuthorAndPublication();
    this.loadAdditionalInfo();

    combineLatest([
      this.store.pipe(select(getSubTaskDetails)),
      this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
    ]).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any[]) => {
        this.task = response[0];
        this.stepStatus = response[1].stepStatusDetails.stepStatusCode;
        let list = this.task.extrAblDocEvdList.sort((a, b) => (a['id'] > b['id']) ? 1 : -1);
        this.extrAblDocEvdCatg = this.task.extrAblDocEvdCatg;
        if (list[list.length - 1]['code'] == "SCHLRBOOKAUTHOR") {
          this.showMarkComplete = true;
        }
        this.stepDetails = response[1];
        this.fileUploadConfigurations = Object.assign({}, {
          disableControls: this.disabledControls,
          isDocumentTypeRequired: true,
          isDocumentUploadRequired: true,
          isViewEnabled: true,
          isDownloadEnabled: true,
          isDeleteEnabled: true,
          getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/AUTHORDOC/`,
          postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/AUTHORDOC/fileCategory/`,
          deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
          documentTypeDropdownCode: 'AUTHORDOC'
        });
        this.toggleControlsStatus();
      });

    this.subcriptions[this.subcriptions.length] = this.formAuthorPublication.statusChanges.subscribe(() => {
      if (this.formAuthorPublication.dirty) {
        this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
      } else {
        this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
      }
    });
    let userType = sessionStorage.getItem('userTypeRole');
    if (userType === 'Beneficiary') {
      this.markStep = 'Mark Submit ';
    } else {
      this.markStep = 'MARK STEP COMPLETE';
    }
  }

  authorandpublicationimageuploaddialog() {
    this.authorandpublicationimageupload.open();
  }
  setFormDirty() {
    this.formDirtyFromDifferentControls = true;
    this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
  }


  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;
    this.otherDocumentsFileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/AUTHORDOC/`,
      postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/AUTHORDOC/fileCategory/`,
      deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
      documentTypeDropdownCode: 'AUTHORDOC'
    });
  }



  ngAfterViewInit() {
    this.subcriptions[this.subcriptions.length] = this.formAuthorPublication.statusChanges.subscribe(() => {
      if (this.formAuthorPublication.dirty || this.formDirtyFromDifferentControls) {
        this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
      } else {
        this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
      }
    });
  }

  onSubmit() {
    if (this.formAuthorPublication.valid) {
      const Payload = {
        "title": this.formAuthorPublication.value.title,
        "id": this.id ? this.id : null
      };

      this.authorAndPublicationService.saveAuthorshipPublicationDetails(this.dynamicDialogConfig.data.petitionId, Payload).subscribe(res => {
        if (res && res['status'] === 200) {
          this.toastr.success(res['message']);
          this.LoadAuthorAndPublication();
          this.isEdit = false;
          this.formAuthorPublication.get('title').patchValue('');
          this.formAuthorPublication.reset();
          this.id = null;
          this.btnText = "Add";
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
        }
      });
    }
  }

  LoadAuthorAndPublication() {
    this.authorAndPublicationService.getAuthorshipPublicationDetails(this.dynamicDialogConfig.data.petitionId).subscribe(res => {
      if (res['data']) {
        this.authorAndPublicationList = res['data'];
      } else {
        this.authorAndPublicationList = [];
      }
    });
  }

  deleteAuthorAndPublication(id) {

    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.authorAndPublicationService.deleteAuthorshipPublicationDetails(id).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.isEdit = false;
          this.formAuthorPublication.get('title').patchValue('');
          this.formAuthorPublication.reset();
          this.id = null;
          this.LoadAuthorAndPublication();
          this.toastr.success(response.message);
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
        });
      }
    });

    // this.authorAndPublicationService.deleteAuthorshipPublicationDetails(id).subscribe(res=>{
    //   this.toastr.success(res['message']);
    //   this.LoadAuthorAndPublication();
    // })
  }

  EditAuthorAndPublication(publication) {
    this.isEdit = true;
    // this.btnText="Update";
    this.formAuthorPublication.get('title').patchValue(publication.title);
    this.id = publication.id;
  }

  Cancel() {
    this.isEdit = false;
    this.btnText = "Add";
    this.id = null;
    this.formAuthorPublication.get('title').patchValue('');
    this.formAuthorPublication.reset();

    // Object.keys(this.formAuthorPublication.controls).forEach(key => {
    //   this.formAuthorPublication.get(key).setErrors(null) ;
    // });
  }

  Save() {

    if (this.template.body != null && this.template.body != "") {
      const paylod = {
        "additionalText": this.template.body,
        "infoName": null,
        "infoType": "SCHLRBOOKAUTHOR",
        "isDel": 0
      };

      this.authorAndPublicationService.saveAdditionalInfo(this.dynamicDialogConfig.data, paylod).subscribe(res => {
        if (res && res['status'] === 200) {
          this.toastr.success(res['message']);
          this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
        }
      });
    }
  }

  loadAdditionalInfo() {
    this.authorAndPublicationService.getAdditionalInfo(this.dynamicDialogConfig.data, 'SCHLRBOOKAUTHOR').subscribe(res => {
      if (res) {
        this.template.body = res.additionalText;
      }
    });
  }

  onMarkComplete() {
    if (this.stepDetails.stepStatusDetails.stepStatusCode === 'INPROGRESS') {
      if (sessionStorage.getItem('userTypeRole') === 'Beneficiary') {
        this.updateStepStatus('SUBMIT');
      } else {
        this.updateStepStatus('COMPLETE');
      }
    }
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.inputData.visatype
    }));
    // this.toggleControlsStatus();
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }
  public authorandpublicationeditorValueChange(value: string): void {
    this.template.body = value;
    this.setFormDirty();
  }
}
