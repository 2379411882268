import { Component, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ToastrService } from 'ngx-toastr';
import { getStepDetails, updateStepStatusToInitialState } from 'visa-store';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/internal/operators/map';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import { ImagilityBaseResponse, PrimaryData, TaskStep } from 'app-models';
import { OccupationDetailsService } from './occupation-details.service';
import * as errorUtils from "error-message-utility";
import { UploadImageDialogComponent } from "../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { AppServicesLibraryService } from 'app-services-library';
@Component({
  selector: 'app-occupation-details',
  templateUrl: './occupation-details.component.html',
  styleUrls: ['./occupation-details.component.scss']
})
export class OccupationDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('occupationDetailsForm', { static: true }) occupationDetailsForm: NgForm;
  @ViewChild("occupationdetailsimageupload") public occupationdetailsimageupload: UploadImageDialogComponent;
  @Output() @ViewChild("occupationdetailskendoeditor") public occupationdetailskendoeditor: EditorComponent;
  disabledControls: boolean;
  stepDetails: TaskStep;
  lcaCode: string;
  caseNumber: string;
  occupationDescription: string;
  inputData: PrimaryData;
  typeOfButton: string; // This is required for status update button
  observableSubscription$ = new Subject();

  constructor(
    private store: Store<any>,
    private apiService: OccupationDetailsService,
    private toastr: ToastrService,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private appServicesLibraryService: AppServicesLibraryService
  ) {
    this.disabledControls = false;
    this.inputData = this.dynamicDialogConfig.data;
    this.lcaCode = "";
    this.caseNumber = "";
    this.occupationDescription = "";
  }

  ngOnInit(): void {
    this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId })).pipe(takeUntil(this.observableSubscription$)).subscribe((data: TaskStep) => {
      this.typeOfButton = data.isPetitionerAction === 1 || data.isAttorneyAction === 1 ? "MARK_COMPLETE" : "MARK_SUBMIT";
      this.stepDetails = data;
      console.log(this.stepDetails.stepStatusDetails.stepStatusCode, "coe");

      this.occupationDetailsForm.reset();
      this.getOccupationDetails();
      this.toggleControlsStatus();
    });
    this.occupationDetailsForm.form.valueChanges.pipe(
      map(() => this.occupationDetailsForm.dirty),
    ).pipe(takeUntil(this.observableSubscription$)).subscribe(dirty => {
      if (dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });
  }

  // ngAfterViewChecked() {
  //   let a = document.querySelector<HTMLElement>(".fr-element.fr-view");
  //   this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" ? a.contentEditable = "false" : a.contentEditable = "true";
  // }
  openoccupationdetailsimageuploaddialog() {
    this.occupationdetailsimageupload.open();
  }
  getOccupationDetails() {
    this.apiService.getOccupationDetails(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId).subscribe((response: ImagilityBaseResponse) => {
      if (response) {
        this.lcaCode = response.data.lca && `${response.data.lca.socCode} - ${response.data.lca.socTitle}` === `${response.data.lca.socCode} - ${response.data.lca.socTitle}` ?
          `${response.data.lca.socCode} - ${response.data.lca.socTitle}` : null;
        this.caseNumber = response.data.lca && response.data.lca.caseNumber === response.data.lca.caseNumber ? response.data.lca.caseNumber : null;
        this.occupationDescription = response.data && response.data.occupationDescription === response.data.occupationDescription ? response.data.occupationDescription : null;
      }
    })
  }

  postOccupationDetails() {
    const payload = {
      id: null,
      lcaCodeId: null,
      majorGroupCode: null,
      occupationDescription: this.occupationDescription,
      specialityOccupation: null,
    }
    this.apiService.postOccupationDetails(this.inputData.visatype, this.inputData.caseType, this.inputData.caseId, payload).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.toastr.success(response.message);
        this.occupationDetailsForm.resetForm(this.occupationDetailsForm.value);
        this.getOccupationDetails();
      }
    })
  }

  // //Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(updateStepStatusToInitialState());
  }
  public occupationdetailseditorValueChange(value: string): void {
    this.occupationDescription = value;
  }
}
