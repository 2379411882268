import { Subject, Subscription } from 'rxjs';
import { Component, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FileUpload, acceptedFileTypes } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environment-configurations';
import { ReferenceLibraryService } from 'reference-library';
import { ReferenceLibrary } from 'app-models';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { takeUntil } from 'rxjs/operators';
import { acceptedFileTypesText } from 'app-models';
import { ImmigrationFileUploadService } from './immigration-file-upload.service';

@Component({
  selector: 'immigration-file-upload',
  templateUrl: './immigration-file-upload.component.html',
  styleUrls: ['./immigration-file-upload.scss']
})

export class ImmigrationFileUploadComponent implements OnChanges, OnInit, OnDestroy {

  @Input()
  configurations: FileUpload;
  @Output()
  fileUploadSuccess = new EventEmitter();
  @Input()
  primaryData: any;
  observableSubscription$ = new Subject();
  documentTypes = [];
  files: File[] = [];
  listFiles: any = [];
  categoryName = 'BENEDUDOC';
  isMultiple: boolean;
  inputMultipleFlag: boolean;
  inputMultipleFlagState: boolean;
  inputMultipleFlagFed: boolean;
  acceptFileType: string;
  isFileListRequired: boolean;
  uploadedFilesData: FormData; // populated by ngfFormData directive
  displayedColumns: string[] = ['name', 'documentType', 'size'];
  dataSource = [];
  documentTypeRequired: boolean;
  docummentTypeCode: string;
  entityId: any;
  fileCategory = '';
  useCase: string;
  useCaseId: number;
  envPath: any;
  otherName = '';
  showSpecify = false;
  useEntityId = false;
  isEntityBasedDelete = false;
  selectedDocType = '';
  isViewEnabled = true;
  isDownloadEnabled = true;
  isDeleteEnabled = true;
  isUploadEnabled = true; // IM 3926
  petitionOutcomeId: string;
  singleFileTypeName: string;
  buttonOnlyMode: boolean;
  singleButtonUpload: boolean;
  subcriptions: Subscription[] = [];
  familyId: any;
  family: any;
  isFBUser: boolean;
  inputData: any;
  acceptedTypesText = acceptedFileTypesText;
  constructor(
    private uploadDocumentService: ImmigrationFileUploadService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private referenceLibraryService: ReferenceLibraryService,
  ) {
    this.files = [];
    this.isMultiple = false;
    this.acceptFileType = acceptedFileTypes; // Acceptable types: application/pdf, image/*
    this.isFileListRequired = true;
    this.documentTypeRequired = false;
    this.docummentTypeCode = null;
    this.useCase = '';
    this.useCaseId = 0;

    this.isFBUser = 'isFBUser' in sessionStorage ? sessionStorage.getItem('isFBUser') === 'true' : false;
  }

  ngOnInit() {
    this.envPath = environment.docs;
  }

  ngOnChanges(e: SimpleChanges) {
    if (e.configurations && e.configurations.currentValue) {
      this.isMultiple = false;
      this.singleFileTypeName = e.configurations.currentValue.singleFileTypeName ? e.configurations.currentValue.singleFileTypeName : 'Browse';
      this.buttonOnlyMode = e.configurations.currentValue.buttonOnlyMode ? e.configurations.currentValue.buttonOnlyMode : false;
      this.acceptFileType = e.configurations.currentValue.fileType;
      this.isFileListRequired = e.configurations.currentValue.isFileListRequired;
      this.categoryName = e.configurations.currentValue.categoryName;
      this.entityId = e.configurations.currentValue.entityId;
      this.inputMultipleFlag = e.configurations.currentValue.inputMultipleFlag;
      this.inputMultipleFlagState = e.configurations.currentValue.inputMultipleFlagState;
      this.inputMultipleFlagFed = e.configurations.currentValue.inputMultipleFlagFed;
      this.fileCategory = e.configurations.currentValue.fileCategory;
      this.listFiles = e.configurations.currentValue.listUploadedFiles ?
        e.configurations.currentValue.entityType ?
          e.configurations.currentValue.listUploadedFiles.filter(x => x[e.configurations.currentValue.entityType] == this.entityId) :
          e.configurations.currentValue.listUploadedFiles.filter(x => x.entityId == this.entityId) : [];
      this.documentTypeRequired = e.configurations.currentValue.documentTypeRequired;
      this.docummentTypeCode = e.configurations.currentValue.docummentTypeCode;
      this.useCase = e.configurations.currentValue.useCase;
      this.useCaseId = e.configurations.currentValue.useCaseId;
      this.useEntityId = (e.configurations.currentValue.useEntityId) ? e.configurations.currentValue.useEntityId : false;
      this.isEntityBasedDelete = (e.configurations.currentValue.isEntityBasedDelete) ?
        e.configurations.currentValue.isEntityBasedDelete : false;
      this.isViewEnabled = (e.configurations.currentValue.isViewEnabled === false) ? false : true;
      this.isDownloadEnabled = (e.configurations.currentValue.isDownloadEnabled === false) ? false : true;

      this.listFiles = this.listFiles.map((_e: any) => {
        _e.fileLocationName = (this.isFBUser && _e.fileLocation) ? _e.fileLocation.split('/').slice(-1)[0] : _e.fileLocation.substring(23);

        return _e;
      });

      this.isDeleteEnabled = (e.configurations.currentValue.isDeleteEnabled === false) ? false : true;
      this.isUploadEnabled = (e.configurations.currentValue.isUploadEnabled === false) ? false : true;
      this.petitionOutcomeId = e.configurations.currentValue.petitionOutcomeId ? e.configurations.currentValue.petitionOutcomeId : null;
      this.singleButtonUpload = e.configurations.currentValue.singleButtonUpload ? e.configurations.currentValue.singleButtonUpload : false;
      this.familyId = e.configurations.currentValue.familyId ? e.configurations.currentValue.familyId : null;
      this.family = e.configurations.currentValue.family ? e.configurations.currentValue.family : false;
      if (this.docummentTypeCode) {
        if (this.documentTypeRequired) {
          this.fileCategory = '';
        }
        this.getDocumentTypeList();
      }
      // Refresh the To be uploaded files list
      if (this.fileCategory === '') {
        this.files = [];
      }
    }
    if (e.primaryData && e.primaryData.currentValue) {
      this.inputData = e.primaryData.currentValue;
    }
  }

  getDocumentTypeList() {
    this.subcriptions[this.subcriptions.length] =
      this.referenceLibraryService.getReferenceDataWithoutAuthentication(this.docummentTypeCode)
        .subscribe((response: ReferenceLibrary[]) => {
          if (this.petitionOutcomeId) {
            switch (this.petitionOutcomeId) {
              case 'APPROVED':
                this.documentTypes = response.filter(x => x.code === 'APPROVAL');
                break;
              case 'RFE':
                this.documentTypes = response.filter(x => x.code === 'RFE');
                break;
              case 'NOID':
                this.documentTypes = response.filter(x => x.code === 'NOID');
                break;
              case 'DENIED':
                this.documentTypes = response.filter(x => x.code === 'DENIAL');
                break;
              case 'PETWITHDRAW':
                this.documentTypes = response.filter(x => x.code === 'WITHDRWACK' || x.code === 'WITHDRAW');
                break;
              case 'GET_RECEIPT':
                this.documentTypes = response.filter(x => x.code === 'RECNOTC');
                break;
              default:
                this.documentTypes = response;
                break;
            }
          } else {
            this.documentTypes = response;
          }
          if (this.documentTypes && this.documentTypes.length === 1) {
            this.fileCategory = this.documentTypes[0].code;
            this.selectedDocType = this.documentTypes[0].name;
          } else {
            this.selectedDocType = '';
          }
        });
  }

  uploadFiles() {    
    if (this.documentTypeRequired) {
      if (this.fileCategory) {
        if (this.files[this.files.length - 1].size <= 10485760) {
          if (this.categoryName === 'BANKDETAILSDOC' || this.categoryName === 'W4FORMS' || this.categoryName === 'W4FORMSSTATE' || this.categoryName === 'FORM16DETAILSDOC') {
            this.uploadFinancials();
          } else if (this.categoryName !== 'BENGREENCARD') {
            this.uploadDocument();
          }

          else {
            if (this.family && this.familyId == null) {
            } else {
              this.uploadDocument();
            }
          }
        }
        else {
          this.files = [];
          this.toastr.error('File Size is > 10MB');
        }
      }
      else {
        this.files = [];
        this.toastr.error('Select a document type');
      }
    }
    else {
      if (this.files[this.files.length - 1].size <= 10485760) {
        this.uploadDocument();
      }
      else {
        this.files = [];
        this.toastr.error('File Size is > 10MB');
      }
    }
  }

  uploadDocument() {
    if (this.categoryName === 'BENEDUDOC' || this.categoryName === 'BENTRGDOC' || this.categoryName === 'BENLICDOC') {
      this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.immigrationDocumentUpload(
        this.primaryData.i140CaseId,
        this.categoryName,
        this.fileCategory,
        this.entityId,
        this.primaryData.caseType,
        this.uploadedFilesData
      )
        .subscribe(response => {
          if (response.status === 200) {
            this.fileUploadSuccess.emit();
            this.toastr.success(response.message);
            this.files = [];
            //setting Document Type Name(Other field) empty
            this.setOtherNameEmpty();
            // if the html have dropdown for selecting the catagory only we clear the fileCategory
            if (this.documentTypeRequired && !this.selectedDocType) {
              this.fileCategory = '';
            }

            this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.immigrationGetDocumentUpload(
              this.primaryData.caseType,
              this.primaryData.i140CaseId,
              this.categoryName
            )
              .subscribe((responseGetUploadDocument: any) => {
                if (responseGetUploadDocument.status === 200) {
                  this.listFiles =
                    responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                      .map(x => {
                        return {
                          ...x,
                          fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                          fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                        };
                      });

                  // Bcz entity Id is sometime alphanumeric
                  // this code was added for only LCA Withdrawn
                  if (this.fileCategory === 'LCAWTDOC') {
                    this.listFiles = this.listFiles.filter(x => x.fileCategory.code === this.fileCategory).map(e => {
                      e.fileLocationName = (this.isFBUser && e.fileLocation) ? e.fileLocation.split('/').slice(-1)[0] : e.fileLocation.substring(23)
                      return e;
                    });
                  }
                  this.changeDetectorRef.detectChanges();

                }
                if (responseGetUploadDocument.status === 204) {
                  this.listFiles = [];
                  this.changeDetectorRef.detectChanges();
                }
              });
          } else {
            if (this.documentTypeRequired && !this.selectedDocType) {
              this.fileCategory = '';
            }
            this.files = [];

          }
        });
    }
    else if(this.categoryName === 'BENPASSDOC' || this.categoryName === 'BENGREENCARD'){
      this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericPassPortUploadDocument(
        'beneficiary', this.useCaseId, this.categoryName, this.entityId, this.fileCategory, this.otherName, this.uploadedFilesData, this.primaryData.familyId)
        .subscribe(response => {
          if (response.status === 200) {
            this.fileUploadSuccess.emit();
            this.toastr.success(response.message);
            this.files = [];
            //setting Document Type Name(Other field) empty
            this.setOtherNameEmpty(); 
            // if the html have dropdown for selecting the catagory only we clear the fileCategory
            if (this.documentTypeRequired && !this.selectedDocType) {
              this.fileCategory = '';
            }
  
            this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericGetPassportUploadDocument(
              'beneficiary', this.useCaseId, this.categoryName, this.entityId, this.useEntityId, this.primaryData.familyId)
              .subscribe((responseGetUploadDocument: any) => {
                if (responseGetUploadDocument.status === 200) {
                  this.listFiles =
                    responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                      .map(x => {
                        return {
                          ...x,
                          fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                          fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0]: x.fileLocation.substring(23)
                        };
                      });
  
                  // Bcz entity Id is sometime alphanumeric
                  // this code was added for only LCA Withdrawn
                  if (this.fileCategory === 'LCAWTDOC') {
                    this.listFiles = this.listFiles.filter(x => x.fileCategory.code === this.fileCategory).map(e => {
                      e.fileLocationName = (this.isFBUser && e.fileLocation) ? e.fileLocation.split('/').slice(-1)[0]: e.fileLocation.substring(23)
                      return e;
                    });
                  }
                  this.changeDetectorRef.detectChanges();
  
                }
                if (responseGetUploadDocument.status === 204) {
                  this.listFiles = [];
                  this.changeDetectorRef.detectChanges();
                }
              });
          } else {
            // this.toastr.error(response['message']);
            if (this.documentTypeRequired && !this.selectedDocType) {
              this.fileCategory = '';
            }
            this.files = [];
  
          }
        });
    }
    else if(this.inputData.caseType == 'I130' && this.categoryName === 'BENWEDOC') {
      this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericGetPetitionUploadDocument(
        this.inputData.caseId, this.inputData.caseType, this.categoryName, this.fileCategory, this.entityId, this.primaryData.caseType, this.otherName, this.uploadedFilesData, this.primaryData.familyId).subscribe(response => {
          if (response.status === 200) {
            this.fileUploadSuccess.emit();
            this.toastr.success(response.message);
            this.files = [];
            //setting Document Type Name(Other field) empty
            this.setOtherNameEmpty();
            // if the html have dropdown for selecting the catagory only we clear the fileCategory
            if (this.documentTypeRequired && !this.selectedDocType) {
              this.fileCategory = '';
            }


            let getURL = this.uploadDocumentService.genericPetitionGetUploadDocument(
              this.inputData.caseId,
              this.categoryName);

            if(this.categoryName == 'BENWEDOC') {
              getURL = this.uploadDocumentService.genericPetitionGetUploadDocumentI130(
                this.inputData.caseType || this.inputData.casetype,
                this.inputData.caseId,
                this.categoryName)
            }
              this.subcriptions[this.subcriptions.length] = getURL.subscribe((responseGetUploadDocument: any) => {
                  if (responseGetUploadDocument.status === 200) {
                    this.listFiles =
                      responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                        .map(x => {
                          return {
                            ...x,
                            fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                            fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                          };
                        });

                    // Bcz entity Id is sometime alphanumeric
                    // this code was added for only LCA Withdrawn
                    if (this.fileCategory === 'LCAWTDOC') {
                      this.listFiles = this.listFiles.filter(x => x.fileCategory.code === this.fileCategory).map(e => {
                        e.fileLocationName = (this.isFBUser && e.fileLocation) ? e.fileLocation.split('/').slice(-1)[0] : e.fileLocation.substring(23)
                        return e;
                      });
                    }
                    this.changeDetectorRef.detectChanges();

                  }
                  if (responseGetUploadDocument.status === 204) {
                    this.listFiles = [];
                    this.changeDetectorRef.detectChanges();
                  }
                });
            } else {
              if (this.documentTypeRequired && !this.selectedDocType) {
                this.fileCategory = '';
              }
              this.files = [];

          }
        });
    }
    else {
      this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericGetPetitionUploadDocument(
        this.inputData.caseId, this.inputData.caseType, this.categoryName, this.fileCategory, this.entityId, this.primaryData.caseType, this.otherName, this.uploadedFilesData, this.primaryData.familyId).subscribe(response => {
          if (response.status === 200) {
            this.fileUploadSuccess.emit();
            this.toastr.success(response.message);
            this.files = [];
            //setting Document Type Name(Other field) empty
            this.setOtherNameEmpty();
            // if the html have dropdown for selecting the catagory only we clear the fileCategory
            if (this.documentTypeRequired && !this.selectedDocType) {
              this.fileCategory = '';
            }

              this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericPetitionGetUploadDocument(
                this.inputData.caseId, this.categoryName)
                .subscribe((responseGetUploadDocument: any) => {
                  if (responseGetUploadDocument.status === 200) {
                    this.listFiles =
                      responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                        .map(x => {
                          return {
                            ...x,
                            fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                            fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                          };
                        });

                    // Bcz entity Id is sometime alphanumeric
                    // this code was added for only LCA Withdrawn
                    if (this.fileCategory === 'LCAWTDOC') {
                      this.listFiles = this.listFiles.filter(x => x.fileCategory.code === this.fileCategory).map(e => {
                        e.fileLocationName = (this.isFBUser && e.fileLocation) ? e.fileLocation.split('/').slice(-1)[0] : e.fileLocation.substring(23)
                        return e;
                      });
                    }
                    this.changeDetectorRef.detectChanges();

                  }
                  if (responseGetUploadDocument.status === 204) {
                    this.listFiles = [];
                    this.changeDetectorRef.detectChanges();
                  }
                });
            } else {
              if (this.documentTypeRequired && !this.selectedDocType) {
                this.fileCategory = '';
              }
              this.files = [];

          }
        });
    }
    

  }

  uploadFinancials() {
    if (this.categoryName === 'BANKDETAILSDOC') {
      this.uploadDocumentService.generiBankUploadDocument(
        this.categoryName, this.fileCategory, this.otherName, this.uploadedFilesData, this.configurations.entityId, this.familyId)
        .subscribe(response => this.handleBankUploadResponse(response));
    } else if (this.categoryName === 'FORM16DETAILSDOC') {
      this.uploadDocumentService.genericForm16UploadDocument(
        this.categoryName, this.fileCategory, this.otherName, this.uploadedFilesData, this.configurations.entityId, this.familyId)
        .subscribe(response => this.handleW4UploadResponse(response));
    } else if (this.categoryName === 'W4FORMS' || this.categoryName === 'W4FORMSSTATE') {
      this.uploadDocumentService.genericW4UploadDocument(
        this.categoryName, this.fileCategory, this.otherName, this.uploadedFilesData, this.configurations.entityId, this.familyId)
        .subscribe(response => this.handleW4UploadResponse(response));
    }
  }

  handleBankUploadResponse(response) {
    if (response.status === 200) {
      this.fileUploadSuccess.emit();
      this.toastr.success(response.message);
      this.inputMultipleFlag = null;

      this.files = [];
      // Setting Document Type Name(Other field) empty
      this.setOtherNameEmpty();
      // If the HTML has dropdown for selecting the category only, clear the fileCategory
      if (this.documentTypeRequired && !this.selectedDocType) {
        this.fileCategory = '';
      }

      this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericBankGetUploadDocument(
        this.useCase, this.configurations.entityId, this.categoryName, this.configurations.entityId, this.useEntityId, this.familyId)
        .subscribe((responseGetUploadDocument: any) => {
          if (responseGetUploadDocument.status === 200) {
            this.listFiles =
              responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                .map(x => {
                  return {
                    ...x,
                    fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                    fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                  };
                });

            this.changeDetectorRef.detectChanges();
          }
          if (responseGetUploadDocument.status === 204) {
            this.listFiles = [];
            this.changeDetectorRef.detectChanges();
          }
        });
    } else {
      if (this.documentTypeRequired && !this.selectedDocType) {
        this.fileCategory = '';
      }
      this.files = [];
    }
  }

  handleW4UploadResponse(response) {
    if (response.status === 200) {
      this.fileUploadSuccess.emit();
      this.toastr.success(response.message);
      this.inputMultipleFlagState = null;
      this.inputMultipleFlagFed = null;

      this.files = [];
      // Setting Document Type Name(Other field) empty
      this.setOtherNameEmpty();
      // If the HTML has dropdown for selecting the category only, clear the fileCategory
      if (this.documentTypeRequired && !this.selectedDocType) {
        this.fileCategory = '';
      }

      this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericW4GetUploadDocument(
        this.useCase, this.configurations.entityId, this.categoryName, this.configurations.entityId, this.useEntityId, this.familyId)
        .subscribe((responseGetUploadDocument: any) => {
          if (responseGetUploadDocument.status === 200) {
            this.listFiles =
              responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                .map(x => {
                  return {
                    ...x,
                    fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                    fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                  };
                });

            this.changeDetectorRef.detectChanges();
          }
          if (responseGetUploadDocument.status === 204) {
            this.listFiles = [];
            this.changeDetectorRef.detectChanges();
          }
        });
    } else {
      if (this.documentTypeRequired && !this.selectedDocType) {
        this.fileCategory = '';
      }
      this.files = [];
    }
  }

  deleteFiles(index: number, item) {

    const title = 'Confirm Deletion!';
    const message = 'Do you want to delete this record?';
    if (this.categoryName === 'BENIMMDOC') {
      this.dialogueInitializer(title, message).afterClosed().subscribe(result => {
        if (result) {
          if (this.categoryName !== 'BANKDETAILSDOC' && this.categoryName !== 'W4FORMS' && this.categoryName !== 'W4FORMSSTATE') {
            this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericDeleteUploadDocument(
              this.useCase, this.useCaseId, item.fileCategory.group, item.fileCategory.code,
              item.id, this.entityId, this.useEntityId, this.isEntityBasedDelete)
              .subscribe((response: any) => {
                if (response.status === 200) {
                  this.fileUploadSuccess.emit();
                  this.toastr.success(response.message);
                  this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericGetUploadDocument(
                    this.useCase, this.useCaseId, this.categoryName, this.entityId, this.useEntityId, this.familyId)
                    .subscribe((responseGetUploadDocument: any) => {
                      if (responseGetUploadDocument.status === 200) {

                        this.listFiles = responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                          .map(x => {
                            return {
                              ...x,
                              fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                              fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                            };
                          });

                        this.changeDetectorRef.detectChanges();
                      }
                      if (responseGetUploadDocument.status === 204) {
                        this.listFiles = [];
                        this.changeDetectorRef.detectChanges();
                      }
                    });
                } else {
                  this.toastr.error(response.message);
                }
              });
          }
          if (this.categoryName === 'BANKDETAILSDOC') {
            this.deleteBankDocumentHandler(item);
          } else if (this.categoryName === 'W4FORMS' || this.categoryName === 'W4FORMSSTATE') {
            this.deleteW4DocumentHandler(item);
          }
        }
      });
     
    } 
    else if(this.categoryName === 'BENPASSDOC' || this.categoryName === 'BENGREENCARD'){
      const title = 'Confirm';
      const message = 'Do you wish to Delete the File?';
      this.dialogueInitializer(title, message).afterClosed().subscribe(result => {
        if (result) {
          if (this.categoryName !== 'BANKDETAILSDOC' && this.categoryName !== 'W4FORMS' && this.categoryName !== 'W4FORMSSTATE') {
          this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericDeletePassportUploadDocument(
            'beneficiary', this.useCaseId, item.fileCategory.group, item.fileCategory.code,
            item.id, this.entityId, this.useEntityId, this.isEntityBasedDelete)
            .subscribe((response: any) => {
              if (response.status === 200) {
                this.fileUploadSuccess.emit();
                this.toastr.success(response.message);
                this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericGetPassportUploadDocument(
                  this.useCase, this.useCaseId, this.categoryName, this.entityId, this.useEntityId, this.familyId)
                  .subscribe((responseGetUploadDocument: any) => {
                    if (responseGetUploadDocument.status === 200) {
  
                      this.listFiles = responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                        .map(x => {
                          return {
                            ...x,
                            fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                            fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0]: x.fileLocation.substring(23)
                          };
                        });
  
                      this.changeDetectorRef.detectChanges();
                    }
                    if (responseGetUploadDocument.status === 204) {
                      this.listFiles = [];
                      this.changeDetectorRef.detectChanges();
                    }
                  });
              } else {
                this.toastr.error(response.message);
              }
            });
          }
          if (this.categoryName === 'BANKDETAILSDOC') {
            this.deleteBankDocumentHandler(item);
          } else if(this.categoryName === 'W4FORMS' || this.categoryName === 'W4FORMSSTATE') {
            this.deleteW4DocumentHandler(item);
          }
        }
      });

    }
    else {
      this.dialogueInitializer(title, message).afterClosed().subscribe(result => {
        if (result) {
          if (this.categoryName !== 'BANKDETAILSDOC' && this.categoryName !== 'W4FORMS' && this.categoryName !== 'W4FORMSSTATE') {
            this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericPetitionDeleteUploadDocument(
              this.inputData.visaType || this.inputData.visatype, this.inputData.caseType,  this.inputData.caseId, item.id)
              .subscribe((response: any) => {
                if (response.status === 200) {
                  this.fileUploadSuccess.emit();
                  this.toastr.success(response.message);
                  this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericPetitionGetUploadDocument(
                    this.inputData.caseId, this.categoryName)
                    .subscribe((responseGetUploadDocument: any) => {
                      if (responseGetUploadDocument.status === 200) {

                        this.listFiles = responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
                          .map(x => {
                            return {
                              ...x,
                              fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                              fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                            };
                          });

                        this.changeDetectorRef.detectChanges();
                      }
                      if (responseGetUploadDocument.status === 204) {
                        this.listFiles = [];
                        this.changeDetectorRef.detectChanges();
                      }
                    });
                } else {
                  this.toastr.error(response.message);
                }
              });
          }
          if (this.categoryName === 'BANKDETAILSDOC') {
            this.deleteBankDocumentHandler(item);
          } else if (this.categoryName === 'W4FORMS' || this.categoryName === 'W4FORMSSTATE') {
            this.deleteW4DocumentHandler(item);
          }
        }
      });
    }
  }

  openDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  trackItem(index, item) {
    return item.id;
  }

  dialogueInitializer(title: string, message: string) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '40%',
      data: {
        update: { title, message },
        buttons: ['No', 'Yes']
      }
    });
    return dialogRef;
  }

  deleteBankDocumentHandler(item) {
    this.uploadDocumentService.deleteBankFileFromDocumentList(this.configurations.entityId).pipe(takeUntil(this.observableSubscription$)).subscribe((data: any) => {
      this.toastr.success(data.message);
      this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericBankGetUploadDocument(
        this.useCase, this.configurations.entityId, this.categoryName, this.configurations.entityId, this.useEntityId, this.familyId)
        .subscribe((responseGetUploadDocument: any) => {
          if (responseGetUploadDocument.status === 200) {
            this.listFiles = responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
              .map(x => {
                return {
                  ...x,
                  fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                  fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                };
              });

            this.changeDetectorRef.detectChanges();
          }
          if (responseGetUploadDocument.status === 204) {
            this.listFiles = [];
            this.inputMultipleFlag = true;
            this.changeDetectorRef.detectChanges();
          }
        });
    });
  }

  deleteW4DocumentHandler(item) {
    this.uploadDocumentService.deleteW4FileFromDocumentList(this.configurations.entityId, this.categoryName, this.fileCategory).pipe(takeUntil(this.observableSubscription$)).subscribe((data: any) => {
      this.toastr.success(data.message);
      this.subcriptions[this.subcriptions.length] = this.uploadDocumentService.genericW4GetUploadDocument(
        this.useCase, this.configurations.entityId, this.categoryName, this.configurations.entityId, this.useEntityId, this.familyId)
        .subscribe((responseGetUploadDocument: any) => {
          if (responseGetUploadDocument.status === 200) {

            this.listFiles = responseGetUploadDocument.data.filter(x => x.entityId == this.entityId && x.fileLocation !== null)
              .map(x => {
                return {
                  ...x,
                  fileNameWithExtension: x.fileLocation.replace(/^.*[\\\/]/, ''),
                  fileLocationName: (this.isFBUser && x.fileLocation) ? x.fileLocation.split('/').slice(-1)[0] : x.fileLocation.substring(23)
                };
              });

            this.changeDetectorRef.detectChanges();
          }
          if (responseGetUploadDocument.status === 204) {
            this.listFiles = [];
            if (this.categoryName === 'W4FORMS') {
              this.inputMultipleFlagFed = true;
            } else if (this.categoryName === 'W4FORMSSTATE') {
              this.inputMultipleFlagState = true;
            }
            this.changeDetectorRef.detectChanges();
          }
        });
    });
  }

  onFileSelected(e) {
    if (e && e.length > 0) {
      setTimeout(() => {
        this.uploadFiles();
      }, 500);
    }
  }

  setOtherNameEmpty() {
    this.otherName = '';
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

}
