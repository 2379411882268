import { Component, OnInit, Input,ViewChild,Output, EventEmitter, } from '@angular/core';
import { RfeService } from 'src/app/services/rfe.service';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { environment } from '../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { stepStatus } from '../../../../data/constants/step-status';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';
import * as Roles from 'src/app/data/constants/roles';
import {TreeNode} from 'primeng/api';
import {RfeOrganizeDocumentsComponent} from '../rfe-organize-documents/rfe-organize-documents.component';
import {RfePrepareResponseStep1Component} from '../rfe-prepare-response-step1/rfe-prepare-response-step1.component';
import {RfePrepareResponseStep2Component} from '../rfe-prepare-response-step2/rfe-prepare-response-step2.component';

@Component({
  selector: 'app-rfe-prepare-response',
  templateUrl: './rfe-prepare-response.component.html',
  styleUrls: ['./rfe-prepare-response.component.scss']
})
export class RfePrepareResponseComponent implements OnInit {

  @Input() rfeTimelineModalInput;
  responseType: string;  
  attoreyResponseSteps:any[];
  displaySection: string;
  displaySectionDialog: boolean = false;
  hideFooter: boolean;
  supportingDocumentsTree: TreeNode[];
  allStepsComplete: boolean = false;
  rfeNoticeId;
  displayOrganizeDocuments:boolean = false;
  currentStepId;
  header:string;
  subheader:string;
  pdfSrc = null;
  pdfTimeStamp;
  SubTaskOverView;
  userType;
  @ViewChild(RfeOrganizeDocumentsComponent) rfeOrganizeDocumentsComponent: RfeOrganizeDocumentsComponent;
  @ViewChild(RfePrepareResponseStep1Component) rfePrepareResponseStep1Component: RfePrepareResponseStep1Component;
  @ViewChild(RfePrepareResponseStep2Component) rfePrepareResponseStep2Component: RfePrepareResponseStep2Component;
  disableFields;
  @Output () notify: EventEmitter<string> = new EventEmitter<string>();

 constructor(private petitionerService: PetitionerService,
    private store: Store<fromTask.State>,
    private rfeService:RfeService,private toastr: ToastrService) {
      this.userType = sessionStorage.getItem('userTypeRole');
  }

  ngOnInit() {
    
    this.responseType = "manual";
    this.getRFEResponseValue();
    if(this.rfeOrganizeDocumentsComponent != undefined)
        this.rfeOrganizeDocumentsComponent.supportingDocumentsTreeValue();
  }

  getRFEResponseValue(){
    this.displaySection = "";
    combineLatest([
      this.store.select(fromTask.getRFESubTaskDetails)
    ]).subscribe(([getRFESubTask]) => {
      this.SubTaskOverView = getRFESubTask;
      if(this.SubTaskOverView.taskStepDetails[2].stepStatusDetails.stepStatusCode == 'COMPLETE')
        this.disableFields = true;
      else
      this.disableFields = false;
    });

    if(this.userType === Roles.ATTORNEY){
    this.rfeService
    .getRfeAttorneySteps(this.SubTaskOverView['id'],this.rfeTimelineModalInput["parentStepId"])
    .subscribe((data) => {
      this.attoreyResponseSteps = data;
      this.attoreyResponseSteps.forEach( (element) => {
        switch(element["stepName"]){
          case "Request Supporting Documents":
            if(element["stepStatusDetails"]["stepStatusCode"] == 'NEW'){
              this.allStepsComplete = false;
              element["buttonLabel"] = "Request / Add Documents";  
            }
            else{
              this.allStepsComplete = true;
              element["buttonLabel"] = "Update Document";       
            }
            break;
          case "Request RFE Response Details":
            if(element["stepStatusDetails"]["stepStatusCode"] == 'NEW'){
              element['buttonLabel'] = "Prepare RFE Response Details";
              this.allStepsComplete = false;
            }
            else  {
              this.allStepsComplete = true;
              element["buttonLabel"] = "Resend Request"; 
            }
            break; 
          case "Prepare RFE Response":
            if(element["stepStatusDetails"]["stepStatusCode"] == 'NEW'){
              element['buttonLabel'] = "Prepare Response";
              this.allStepsComplete = false;
            }
              else  
              {
                this.allStepsComplete = true;
              element["buttonLabel"] = "Update Response";   
              }
            break;
          }
    });
    });
    
  this.rfeService
      .getRFEDetails(this.SubTaskOverView['id'])
      .subscribe((data) => {
        if(data != null){
        this.rfeNoticeId = data['id'];
        //this.rfePrepareResponseStep1Component.getStatus();
        //this.rfePrepareResponseStep2Component.getStatus();
        }
      });
    }
 }



  changeResponseType(){

  }

  proceedToResponseAction(item){
    this.currentStepId = item.id;
    this.notify.emit(stepStatus.Inprogress);
    this.displaySectionDialog = true;
    if(item.stepName == "Request Supporting Documents"){
      this.displaySection = 'Documents';
      this.header = "Supporting Documents";
      this.subheader = "Request or Add required Documents";
    }
    else if(item.stepName == "Request RFE Response Details"){
      this.displaySection = 'ResponseDetails';
      this.header = "Request RFE Response Details";
      this.subheader = "Request from Petitioner/Beneficiary for any Response details."
    }
    else{
      this.displaySection = 'PrepareResponse';
      this.header = "Prepare RFE Response";
      this.subheader = "Create RFE Response & Attach respective documents";
    }
  }

changeStatus(){
  const payload = {
    statusCode: stepStatus.Complete,
    stepNote: ''
  };
  const payload1 = {
    statusCode: stepStatus.Inprogress,
    stepNote: ''
  };
  this.petitionerService.stepStatusUpdateRFE(payload1, this.SubTaskOverView['id'], this.rfeTimelineModalInput["parentStepId"]).subscribe();
  this.petitionerService.stepStatusUpdateRFE(payload, this.SubTaskOverView['id'], this.currentStepId).subscribe((response: any) => {
    if (response.status == 200) {
      this.toastr.success(response.message);
      this.getRFEResponseValue();
      this.displaySection = "";
    }
  });

}

changeStatusToComplete(){
  this.notify.emit(stepStatus.Complete);
}

viewPdf(){
  window.open(this.pdfSrc, "_blank");
}

generatePdf(){
  this.rfeService.generatePdf(this.SubTaskOverView['id'], 1).subscribe((response: any) => {
    if (response.status == 200) {
      this.toastr.success(response.message);
      this.rfeService.getGeneratedPdf(this.SubTaskOverView['id'], 1).subscribe((data: any) => {
        if (data) {
          let dataSlice = data["path"].split('/var/imagility/uploads/');
          this.pdfSrc = environment.docs + dataSlice[1];
          this.pdfTimeStamp = data["createdDate"];
        }
      });
    } 
  });
  
}

displayOrganizeDocumentsDialog(){
  this.displayOrganizeDocuments = true;
  if(this.rfeOrganizeDocumentsComponent != undefined)
        this.rfeOrganizeDocumentsComponent.supportingDocumentsTreeValue();
}

}
