import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRoleService } from 'src/app/services/user-role.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-user-privacy-modal',
  templateUrl: './user-privacy-modal.component.html',
  styleUrls: ['./user-privacy-modal.component.scss']
})
export class UserPrivacyModalComponent implements OnInit {
  title: string;
  message: string;
  type: string;
  showClose: boolean;
  showButtons: boolean;
  getData;
  policyId;
  constructor(
    private userService: UserRoleService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public dialogRef: MatDialogRef<UserPrivacyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData
  ) {
    dialogRef.disableClose = true;
    this.populateData();
  }

  ngOnInit() {
    
  }

  populateData(){
    this.type = this.dialogData.update.type;
    this.getData = this.dialogData.update.getData;
    this.message = this.dialogData.update.dialogContent;
    this.policyId = this.dialogData.update.policyId;
    if(this.type == 'terms'){
      this.title = "Imagility Platform End User License Agreement";
      this.showButtons = false;
      this.showClose = true;
    }
    else
    {
      if(this.type == 'privacy'){
        this.title = "Privacy Policy";
        this.showButtons = true;
        this.showClose = false;
      }
  }
}

  rejectPolicy(){
    this.userService.rejectPrivacy(sessionStorage.getItem('userId'))
      .subscribe(response => {
        if (response.status === 200) {
          this.dialogRef.close('rejectPolicy');
          this.toastr.success('', response.message);
          this.authenticationService.clearUserData();
          this.router.navigate(['/login']);
        } else {
          this.toastr.error('', response.message);
        }
      });
  }

  acceptPolicy(){
    this.userService.acceptPrivacy(sessionStorage.getItem('userId'),this.policyId)
      .subscribe(response => {
        if (response.status === 200) {
          this.toastr.success('', response.message);
          this.dialogRef.close('acceptPolicy');
          localStorage.setItem('policyAcceptanceRequired', "false");
        } else {
          this.toastr.error('', response.message);
        }
      });
  }

}
