import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ReferenceLibrary, TaskStep } from 'app-models';
import * as errorUtils from 'error-message-utility';
import { ReferenceLibraryService } from 'reference-library';
import { Subject, combineLatest } from 'rxjs';
import { ApiServicesService } from 'src/app/modules/beneficiary/services/api-services.service';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { select, Store } from '@ngrx/store';
import { ImagilityBaseResponse, PrimaryData } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { DialogService } from 'primeng/dynamicdialog';
import { AdditionalInformationService } from './additional-information.service';
import { take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { getStepDetails, updateStepStatus } from 'visa-store';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';

export const DeleteDialogConfigurations = {
  width: '30%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { 'z-index': '1001' }
};


@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss']
})
export class AdditionalInformationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() primaryData: PrimaryData;
  beneficiaryPersonalDetails: any;
  additionalInfoForm: FormGroup;
  additionalInfo: any;
  addEditButtonText;
  disabledControls = false;
  observableSubscription$ = new Subject();
  numberOfTimeMarriedListOption = [];
  proceedingsTypesArr: ReferenceLibrary[];
  listMarriageStates: any = [];
  aosNotUsStateList: any = [];
  statesList: any = [];
  countryList: any;
  selectedCountryCode = {
    countryCode: 'USA',
    stateProvinceCode: null,
    stateProvinceName: null
  };
  addressReadOnly = true;
  stepDetails: TaskStep;
  addressFormDisabled: boolean;

  constructor(
    private referenceLibraryService: ReferenceLibraryService,
    private cacheCountryService: CacheCountryService,
    private apiServiceState: ApiServicesService,
    public store: Store<any>,
    public apiService: AdditionalInformationService,
    public toastr: ToastrService,
    public stepStatusUpdatePayloadCostructionService: StepStatusUpdatePayloadService,
    public dialogService: DialogService
  ) { }

  ngOnInit(): void {

    combineLatest([
      this.cacheCountryService.getCountry(),
      this.referenceLibraryService.getReferenceData('PROCDTYP'),
      this.apiServiceState.getStates('USA')
    ])
      .pipe(take(1))
      .subscribe(data => {
        if (data[0].length > 0) {
          this.countryList = data[0];
        }
        this.proceedingsTypesArr = data[1];
        this.statesList = data[2];
      });
  }

  ngOnChanges() {
    if (this.primaryData && !this.additionalInfoForm) {
      this.additionalInfoForm = new FormGroup({
        id: new FormControl(0),
        wasBenInImmProceeding: new FormControl(false),
        gcI130ProceedingDtlDTO: new FormArray([
          new FormGroup({
            city: new FormControl(null),
            stateProvinceCode: new FormControl(null),
            id: new FormControl(null),
            stateProvinceName: new FormControl(null),
            countryCode: new FormControl(null),
            proceedingDate: new FormControl(null),
            proceedingTypId: new FormControl(null),
          }, [
            this.proceedingStateProvinceCodeFieldRequired.bind(this),
            this.proceedingTypIdRequired.bind(this),
            this.proceedingCityRequired.bind(this),
            this.proceedingDateRequired.bind(this)
          ])
        ]),
        aosInUs: new FormControl(false),
        aosUsCity: new FormControl(null),
        aosUsStateProvinceCode: new FormControl(null),
        aosUsStateProvinceName: new FormControl(null),
        aosNonUsCity: new FormControl(null),
        aosNonUsStateProvinceCode: new FormControl(null),
        aosNonUsStateProvinceName: new FormControl(null),
        aosNonUsCountryCode: new FormControl(null)
      }, [
        this.aosUsCityRequired.bind(this),
        this.aosUsStateProvinceCodeRequired.bind(this),
        this.aosNonUsCityRequired.bind(this),
        this.aosNonUsStateProvinceCodeRequired.bind(this),
        this.aosNonUsStateProvinceNameRequired.bind(this),
        this.aosNonUsCountryCodeRequired.bind(this)
      ]);
      // Get Specific step details by passing step code
      this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((data: TaskStep) => {
          if (data && data.id) {
            this.stepDetails = data;
            this.toggleControlsStatus();
          }
        });
      // work around for primeng radio button
      const proceedingTypIdRadioButton = ((this.additionalInfoForm.get('gcI130ProceedingDtlDTO') as FormArray).controls[0].get('proceedingTypId') as FormControl);
      proceedingTypIdRadioButton.valueChanges.subscribe(e => {
        proceedingTypIdRadioButton.setValue(e, { emitEvent: false });
      });
      // work around for primeng radio button
      this.additionalInfoForm.get('wasBenInImmProceeding').valueChanges.subscribe(e => {
        this.additionalInfoForm.get('wasBenInImmProceeding').setValue(e, { emitEvent: false });
      });
      this.additionalInfoForm.valueChanges.subscribe(() => {
        if (this.additionalInfoForm.dirty) {
          this.store.dispatch(new MarkDirtyFormAction({
            dirty: true
          }));
        } else {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
        }
      });
    }
  }

  onAOSInUSChange() {
    if (this.additionalInfoForm.get('aosInUs').value) {
      this.additionalInfoForm.patchValue({
        aosNonUsCity: null,
        aosNonUsStateProvinceCode: null,
        aosNonUsStateProvinceName: null,
        aosNonUsCountryCode: null
      });
    } else {
      this.additionalInfoForm.patchValue({
        aosUsCity: null,
        aosUsStateProvinceCode: null
      });
    }
  }

  get proceedingsControls() {
    return (this.additionalInfoForm.get('gcI130ProceedingDtlDTO') as FormArray).controls[0];
  }

  aosNonUsCountryCodeRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.additionalInfoForm && !this.additionalInfoForm.value.aosInUs &&
      !group.value.aosNonUsCountryCode) {
      return { aosNonUsCountryCodeIsRequired: true };
    }
    return null;
  }

  aosNonUsStateProvinceCodeRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.additionalInfoForm && !this.additionalInfoForm.value.aosInUs &&
      this.aosNotUsStateList && this.aosNotUsStateList.length > 0 &&
      !group.value.aosNonUsStateProvinceCode) {
      return { aosNonUsStateProvinceCodeIsRequired: true };
    }
    return null;
  }

  aosUsStateProvinceCodeRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.additionalInfoForm && this.additionalInfoForm.value.aosInUs &&
      !group.value.aosUsStateProvinceCode) {
      return { aosUsStateProvinceCodeIsRequired: true };
    }
    return null;
  }

  aosUsCityRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.additionalInfoForm && this.additionalInfoForm.value.aosInUs &&
      (!group.value.aosUsCity || (group.value.aosUsCity && group.value.aosUsCity.trim() === ''))) {
      return { aosUsCityIsRequired: true };
    }
    return null;
  }

  aosNonUsStateProvinceNameRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.additionalInfoForm && !this.additionalInfoForm.value.aosInUs &&
      (!this.aosNotUsStateList || (this.aosNotUsStateList && this.aosNotUsStateList.length === 0)) &&
      (!group.value.aosNonUsStateProvinceName ||
        (group.value.aosNonUsStateProvinceName && group.value.aosNonUsStateProvinceName.trim() === ''))) {
      return { aosNonUsStateProvinceNameIsRequired: true };
    }
    return null;
  }

  aosNonUsCityRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.additionalInfoForm && !this.additionalInfoForm.value.aosInUs &&
      (!group.value.aosNonUsCity || (group.value.aosNonUsCity && group.value.aosNonUsCity.trim() === ''))) {
      return { aosNonUsCityIsRequired: true };
    }
    return null;
  }

  proceedingStateProvinceCodeFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.additionalInfoForm && this.additionalInfoForm.value.wasBenInImmProceeding &&
      !group.value.stateProvinceCode) {
      return { proceedingStateProvinceCodeFieldIsRequired: true };
    }
    return null;
  }

  proceedingTypIdRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.additionalInfoForm && this.additionalInfoForm.value.wasBenInImmProceeding &&
      !group.value.proceedingTypId) {
      return { proceedingTypIdIsRequired: true };
    }
    return null;
  }

  proceedingCityRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.additionalInfoForm && this.additionalInfoForm.value.wasBenInImmProceeding &&
      (!group.value.city || (group.value.city && group.value.city.trim() === ''))) {
      return { proceedingCityIsRequired: true };
    }
    return null;
  }

  proceedingDateRequired(group: FormGroup): { [s: string]: boolean } {
    if (this.additionalInfoForm && this.additionalInfoForm.value.wasBenInImmProceeding &&
      !group.value.proceedingDate) {
      return { proceedingDateIsRequired: true };
    }
    return null;
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    if (this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
      this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT') {
      this.disabledControls = true;
      this.additionalInfoForm.disable();
      this.addressFormDisabled = true;
    } else {
      this.disabledControls = false;
      this.additionalInfoForm.enable();
      this.addressFormDisabled = false;
    }
  }

  addAddressFormControlToParentForm(addressForm: FormGroup) {
    if (addressForm) {
      this.additionalInfoForm.addControl('address', addressForm);
      this.getAdditionalInformation();
    }
  }

  onImmigrationProceedingsChange() {
    if (!this.additionalInfoForm.get('wasBenInImmProceeding').value) {
      const gcI130ProceedingDtlDTO = (this.additionalInfoForm.get('gcI130ProceedingDtlDTO') as FormArray).controls[0];
      (this.additionalInfoForm.get('gcI130ProceedingDtlDTO') as FormArray).controls[0].patchValue({
        city: null,
        stateProvinceCode: null,
        id: gcI130ProceedingDtlDTO.value.id || null,
        stateProvinceName: null,
        countryCode: 'USA',
        proceedingDate: null,
        proceedingTypId: null
      });
    }
  }

  getAdditionalInformation() {
    this.apiService.getAdditionalInformation(this.primaryData.caseType,
      this.primaryData.caseId)
      .pipe(take(1))
      .subscribe((response: ImagilityBaseResponse) => {
        this.additionalInfo = response.data ? response.data : null;
        if (this.additionalInfo) {
          this.selectedCountryCode = {
            countryCode: 'USA',
            stateProvinceCode: this.additionalInfo.address ? this.additionalInfo.address.stateProvinceCode : null,
            stateProvinceName: this.additionalInfo.address ? this.additionalInfo.address.stateProvinceName : null
          };
          if (this.additionalInfo.aosNonUsCountryCode && this.additionalInfo.aosNonUsCountryCode.trim() !== '') {
            this.handleCountryChange(
              this.additionalInfo.aosNonUsCountryCode,
              this.additionalInfo.aosNonUsStateProvinceCode,
              this.additionalInfo.aosNonUsStateProvinceName);
          } else {
            this.aosNotUsStateList = [];
          }
          this.additionalInfoForm.reset({
            ...this.additionalInfo,
            aosInUs: this.additionalInfo.aosInUs ? this.additionalInfo.aosInUs : false,
            gcI130ProceedingDtlDTO: this.additionalInfo.gcI130ProceedingDtlDTO && this.additionalInfo.gcI130ProceedingDtlDTO[0] ? [{
              ...this.additionalInfo.gcI130ProceedingDtlDTO[0],
              proceedingTypId: this.additionalInfo.gcI130ProceedingDtlDTO[0].proceedingTypId.id,
              proceedingDate: new Date(this.additionalInfo.gcI130ProceedingDtlDTO[0].proceedingDate),
              countryCode: 'USA'
            }] : [{
              city: null,
              stateProvinceCode: null,
              id: null,
              stateProvinceName: null,
              countryCode: 'USA',
              proceedingDate: null,
              proceedingTypId: null
            }],
            address: this.additionalInfo.address ? {
              addressLine1: this.additionalInfo.address.addressLine1,
              addressLine2: this.additionalInfo.address.addressLine2,
              city: this.additionalInfo.address.city,
              stateProvinceCode: this.additionalInfo.address.stateProvinceCode,
              stateProvinceName: this.additionalInfo.address.stateProvinceName,
              countryCode: 'USA',
              postCode: this.additionalInfo.address.postCode
            } : {
              addressLine1: null,
              addressLine2: null,
              city: null,
              stateProvinceCode: null,
              stateProvinceName: null,
              countryCode: 'USA',
              postCode: null
            }
          });
        } else {
          this.additionalInfoForm.reset({
            id: 0,
            wasBenInImmProceeding: false,
            gcI130ProceedingDtlDTO: [
              {
                city: null,
                stateProvinceCode: null,
                id: null,
                stateProvinceName: null,
                countryCode: 'USA',
                proceedingDate: null,
                proceedingTypId: {
                  code: null,
                  id: null
                }
              }
            ],
            aosInUs: false,
            aosUsCity: null,
            aosUsStateProvinceCode: null,
            aosUsStateProvinceName: null,
            aosNonUsCity: null,
            aosNonUsStateProvinceCode: null,
            aosNonUsStateProvinceName: null,
            aosNonUsCountryCode: null,
            address: {
              addressLine1: null,
              addressLine2: null,
              city: null,
              stateProvinceCode: null,
              stateProvinceName: null,
              countryCode: 'USA',
              postCode: null
            }
          });
        }
        this.additionalInfoForm.reset(this.additionalInfoForm.value);
      });
  }

  saveAdditionalInfo() {
    const payload = {
      ... this.additionalInfoForm.value,
      gcI130ProceedingDtlDTO: this.additionalInfoForm.value.wasBenInImmProceeding ? [
        {
          ...this.additionalInfoForm.value.gcI130ProceedingDtlDTO[0],
          proceedingDate:
            this.additionalInfoForm.value.gcI130ProceedingDtlDTO[0].proceedingDate ?
              moment(this.additionalInfoForm.value.gcI130ProceedingDtlDTO[0].proceedingDate).format('YYYY-MM-DD') : null,
          proceedingTypId: this.additionalInfoForm.value.gcI130ProceedingDtlDTO[0].proceedingTypId ? {
            code: this.proceedingsTypesArr.find(x => x.id === this.additionalInfoForm.value.gcI130ProceedingDtlDTO[0].proceedingTypId).code,
            id: this.additionalInfoForm.value.gcI130ProceedingDtlDTO[0].proceedingTypId
          } : null,
          id: this.additionalInfo.gcI130ProceedingDtlDTO &&
            this.additionalInfo.gcI130ProceedingDtlDTO[0] &&
            this.additionalInfo.gcI130ProceedingDtlDTO[0].id ?
            this.additionalInfo.gcI130ProceedingDtlDTO[0].id : null
        }
      ] : [],
      address: {
        ...this.additionalInfoForm.value.address,
        addressTypeCode: 'USSTAY',
        id: this.additionalInfo.address ? this.additionalInfo.address.id : null
      }
    };
    this.apiService.postAdditionalInformation(this.primaryData.visatype,
      this.primaryData.caseType, this.primaryData.caseId, payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response) {
          this.toastr.success(response.message);
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
          this.getAdditionalInformation();
        }
      });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostructionService.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.primaryData.caseType,
      stepId: this.primaryData.stepId
    }));
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  handleCountryChange(countryCode: string, stateCode: string, stateName: string) {
    this.additionalInfoForm.patchValue({
      aosNonUsStateProvinceCode: null,
      aosNonUsStateProvinceName: null
    });
    this.apiServiceState.getStates(countryCode).subscribe((states: []) => {
      this.aosNotUsStateList = states;
    });
  }

  handleCancel() {
    this.getAdditionalInformation();
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }
}

