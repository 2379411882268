import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { pluck, map } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { ImagilityBaseResponse } from '../data/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private httpClient: HttpClient) { }

  getTemplate(userType, companyId, payload) {
    let url
    if (userType == 'company') {
      url = `/${userType}/${companyId}/documentTemplate/list`;
    }
    if (userType == 'attorney') {
      url = `/${userType}/${companyId}/documentTemplate`;
    }
    return this.httpClient.post(url, payload).pipe(
      pluck('data'),
      map((codes: Array<any>) => {
        return codes;
      })
    )
  }

  getEmailTemplateList(payload) {
    return this.httpClient.post("/emailTemplates/list/EMALTEMPLT", payload).pipe(
      pluck('data'),
      map((codes: Array<any>) => {
        return codes;
      })
    )
  }

  createTemplate(payload) {
    return this.httpClient.post(`/company/${payload.companyId}/documentTemplate/save`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  createEmailTemplate(payload) {
    return this.httpClient.post(`/emailTemplate/save`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }

  deleteTemplate(companyId, documentTemplateId) {
    return this.httpClient.delete(`/company/${companyId}/documentTemplate/${documentTemplateId}`).pipe(
      map((response: any) => {
        if (response.status === 200) {
          return true;
        }
      })
    )
  }

  deleteEmailTemplate(id) {
    return this.httpClient.delete(`/emailTemplates/${id}`).pipe(
      map((response: any) => {
        if (response.status === 200) {
          return true;
        }
      })
    )
  }

  // getPlaceholder(visaType, petitionType) {
  //   return this.httpClient.get(`/documentTemplate/placeHolder/visaType/${visaType}/petitionType/${petitionType}`).pipe(
  //     map((data) => {
  //       return data;
  //     })
  //   );
  // }

  // getPlaceholder(visaType, petitionType,payload) {
  //   return this.httpClient.post(`/documentTemplate/placeHolder/visaType/${visaType}/petitionType/${petitionType}`,payload).pipe(
  //     map((data) => {
  //       return data;
  //     })
  //   );
  // }
  getPlaceholder(payload){
    return this.httpClient.post(`/documentTemplate/placeHolder`,payload).pipe(
          map((data) => {
            return data;
          })
        );

  }


  fetchTemplate(companyId, payload) {
    return this.httpClient.post(`/company/${companyId}/documentTemplate/fetch`, payload).pipe(
      pluck('data'),
      map((data) => {
        return data;
      })
    )
  }

  fetchEmailTemplate(data) {
    return this.httpClient.get(`/emailTemplates/${data}`).pipe(
      pluck('data'),
      map((data) => {
        return data;
      })
    )
  }

  createOwnTemplate(payload, companyId, petitionId, letterType) {
    return this.httpClient.post(`/company/${companyId}/petition/${petitionId}/letter/${letterType}`, payload).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  generatePreviewForOwnTemplate(companyId, petitionId, letterType) {
    return this.httpClient.post(`/company/${companyId}/petition/${petitionId}/letter/${letterType}/preview`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  getLastUsedTemplate(companyId, petitionId) {
    return this.httpClient.get(`/company/${companyId}/petition/${petitionId}/templateInfo`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }
      })
    );
  }

  getTemplateDetails(companyId, petitionId, letterType) {
    return this.httpClient.get(`/company/${companyId}/petition/${petitionId}/letter/${letterType}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200 || response.status === 204) {
          return response;
        }
      })
    );
  }

  getAIGeneratedValue(searchQuery) {
    return this.httpClient.get(`/getAiLetterTemplate/${searchQuery}`).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }


  getEmailTemplate() {
    return this.httpClient.get(`/getPlaceHoldersForCaseAutomation`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

}
