import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import {
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
  EventApi,
  FullCalendarComponent,
} from "@fullcalendar/angular";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import { CALITEMS } from "src/app/data/models/calItems.model";
import { MatCalendar } from "@angular/material/datepicker/calendar";
import { Moment } from "moment";
import { SchedulingService } from "src/app/services/scheduling.service";
import { EventInput } from "@fullcalendar/angular";
import { Accordion } from "primeng/accordion";
import * as moment from "moment";
import { elementEventFullName } from "@angular/compiler/src/view_compiler/view_compiler";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { debounceTime, filter, take, takeUntil } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { format } from "path";
import { DialogService } from "primeng/dynamicdialog";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { MarkTaskCompleteComponent } from "../mark-task-complete/mark-task-complete.component";
import { GoogleApiService, UserInfo } from "../google-api.service";
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import rrulePlugin from "@fullcalendar/rrule";
import { RRule } from "rrule";
import { Dropdown } from "primeng/dropdown";

const GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0/";

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

@Component({
  selector: "app-imacalendar",
  templateUrl: "./imacalendar.component.html",
  styleUrls: ["./imacalendar.component.scss"],
  providers: [DialogService],
})
export class ImacalendarComponent implements OnInit, AfterViewInit {
  @ViewChild("cal") calendar: MatCalendar<Moment>;
  @ViewChild("cal") tabContentElement: ElementRef;
  @ViewChild("accord") private accord: Accordion;

  @ViewChild("userCalendar") userCalendar: FullCalendarComponent;

  // To Search the other employees in the organization , this name field is needed.
  mform: FormGroup = new FormGroup({
    name: new FormControl(),
  });

  @ViewChild('calItem')
  calItem: Dropdown;

  outlookCal: any;
  siddisplay: any = false;

  empDetailStatus: any;
  empDetailMessage: any;
  markTaskcompletePopup: boolean = false;
  TaskcompletePopup: boolean;
  taskdetails: any;
  taskTime: any;
  taskDate: any;
  recipients: any;
  comment: any;
  recipientsG: any;
  commentG: any;
  goingcomment: any;
  meetingEditDetails: any;
  optionalEmail: any = [];
  requiredEmails: any = [];
  requiredEmail: any = [];
  updatepopUp: boolean;
  optEmail: any = [];
  requiredImgEmails: any = [];
  optImgEmail: any = [];
  eventsForm: FormGroup;
  meetingId: string = "";
  observableSubscription$ = new Subject();

  // self means the logged in user's calendar will be shown
  // other means , the other employee's calendar will be shown.
  calendarMode: string = "self";
  obs: Subscription;
  otherUserObj;
  otherUserId;
  events: any = [];
  allevents: any[] = [];
  ref: DynamicDialogRef;
  otherUserName: string;

  // the logged in users calendar mettings, events, tasks details
  activities: any = [];

  // the other employees  calendar mettings, events, tasks details
  otherUserEvents: any = [];
  userId: any;
  calendarItemTypes: any = []; //{name:string,code:string}[];
  calendarItemCheckboxes : any = []
  filterApplied: boolean = false;

  //The item that the user has clicked in the calendar
  clickedItemType: any;
  userType: string;
  selectedCalendarItem: string;
  searchPeople: string;
  otherPeoples: any;
  currentURL: any;
  navUrl;
  selected: Date | null;
  companyId;
  startDate;
  endDate;
  month;
  selectedMonth;
  currentUserName;
  disableFilters: boolean; //= true;
  disableAll: boolean = true;
  monthBtn;
  weekBtn;
  dayBtn;
  todayBtn;
  prevBtn;
  nextBtn;
  myCals: any[] = [];

  // external login declarations

  mailSnippets: string[] = [];
  userInfo?: UserInfo;
  calendarList: any = { items: [] };
  selectedCalendars: any;
  localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  displayEvent: boolean = false;
  eventDetails: any;
  addEventDialog: boolean = false;
  addEventForm: FormGroup;

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  profile: any;
  outlookCalendars: any;
  outlookEvents: any;
  activeState: boolean[] = [true, false, false];
  outlookMeetingModal: boolean = false;
  eventDetailsRaw: EventApi;
  reminderBeforeText: string;
  forwardModalShow: boolean;
  editOutlookModal: boolean = false;
  forwardGmeetingShow: boolean;
  outlookCalendarsPickModal: boolean;
  googleCalendarsPickModal: boolean;
  googleMeetingModal: boolean;
  selectedOutlookCals: any[] = [];
  selectedGoogleCals: any;
  outlookSettingsMenu: any;
  googleSettingsMenu: any;
  goingSubmitShow: any;
  statusUpdateType: any;
  clonnedselectedOutlookCals: any;
  clonnedselectedGoogleCals: any;
  heightCanBe: any;
  reminders: any;
  gEventBody: any;
  isGcallogoutInProgress: boolean = false;
  forwardMeetinginProgress: boolean = false;
  forwardGMeetinginProgress: boolean = false;
  invalidEmails: any[] = [];
  googleCalColor: any;
  addGoogleMeetingModal: boolean;
  addOutlookMeetingModal: boolean;
  isScheduling: boolean;
  isNewOutlookTitle: boolean = false;
  editGoogleModal: boolean;
  isNewGoogleTitle: boolean;
  googleNewMeetingModal: boolean;
  constructor(
    private http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    private toaster: ToastrService,
    private schedulingService: SchedulingService,
    public dialogService: DialogService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private readonly googleApi: GoogleApiService,
    private fb: FormBuilder
  ) {
    this.userType = sessionStorage.getItem("userTypeRole");
    this.userId = sessionStorage.getItem("userId");
    this.companyId = sessionStorage.getItem("companyId");
    if (this.userType == "Petitioner") {
      this.navUrl = "/petitioner-landing/dashboard/petitioner-dashboard/";
    } else if (this.userType == "Attorney") {
      this.navUrl = "/attorney-landing/dashboard/attorney-dashboard/";
    }
    let currentUser: any = JSON.parse(sessionStorage.getItem("currentUser"));
    if (currentUser.firstname && currentUser.lastName) {
      this.currentUserName = currentUser.firstname + " " + currentUser.lastName;
    } else {
      if (currentUser.firstname) {
        if (currentUser.lastName) {
          this.currentUserName = currentUser.firstname + currentUser.lastName;
        } else {
          this.currentUserName = currentUser.firstname;
        }
      } else {
        if (currentUser.lastName) {
          this.currentUserName = currentUser.lastName;
        }
      }
    }
    this.heightCanBe = window.innerHeight - 102;
  }

  // calendar options object
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, rrulePlugin],
    initialView: "dayGridMonth",
    height: window.innerHeight - 300,
    headerToolbar: {
      start: "today,prev,next",
      center: "title",
      end: "dayGridMonth,timeGridWeek,timeGridDay",
    },
    timeZone: 'local',
    eventSources: [],
    eventTimeFormat: {
      hour: "numeric",
      minute: "2-digit",
      meridiem: true,
    },
    allDaySlot: false,
    weekends: true,
    editable: false,
    selectable: true,
    selectMirror: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventDidMount: (ev) => {
      if (ev.event._def.extendedProps.sourceType === "outlook") {
        if (
          ev.event.extendedProps.type === "singleInstance" &&
          ev.event.extendedProps.recurrence !== null
        ) {
          ev.el.classList.add("outlookEventSingle");
        } else if (
          ev.event._def.extendedProps.recurrence?.pattern.type ===
          "relativeMonthly"
        ) {
          ev.el.classList.add("outlookEventSingle");
        } else {
          ev.el.classList.add("outlookEvent");
        }
      } else if (ev.event._def.extendedProps.sourceType === "google") {
        if (ev.event._def.allDay) {
          ev.el.classList.add("googleEventAllDay");
        } else {
          ev.el.classList.add("googleEvent");
        }
      } else {
        ev.el.classList.add("imagilityEvent");
      }
    },
    customButtons: {
      custom1: {
        text: "custom 1  ",
        click: function (event) {
          //alert("clicked custom button 1!" + event);
        },
      },
      custom2: {
        text: "custom 2",
        click: function () {
          //alert("clicked custom button 2!");
        },
      },
      next1: {
        click: this.nextMonth.bind(this),
      },
    },
  };

  eventRender(vm) {}

  dateChanged(date) {
    //alert(`Selected Date: ${date}`);
  }

  monthSelected(date) {}

  yearSelected(date) {
    // alert(`Selected Year: ${date}`);
  }

  handleDateSelect(selectInfo: DateSelectArg) {}

  handleDayRender(arg) {
    let customHtml = "blocked";
    return customHtml;
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.clickedItemType = clickInfo.event._def.extendedProps;
    this.eventDetailsRaw = clickInfo.event;
    this.meetingId = this.eventDetailsRaw?.id;

    this.goingcomment = "";
    this.goingSubmitShow = false;

    if (this.calendarMode == "other") {
      return;
    }

    if (this.clickedItemType["etag"]) {
      this.forwardGmeetingShow = false;
      this.googleMeetingModal = true;
    } else if (this.clickedItemType["@odata.etag"]) {
      if (
        this.clickedItemType.reminderMinutesBeforeStart >= 1440 &&
        this.clickedItemType.reminderMinutesBeforeStart < 10080
      ) {
        this.reminderBeforeText =
          this.clickedItemType.reminderMinutesBeforeStart / 60 +
          " hours before";
      } else if (this.clickedItemType.reminderMinutesBeforeStart >= 10080) {
        this.reminderBeforeText =
          10080 / (1440 * 7) > 1
            ? 10080 / (1440 * 7) + " weeks before"
            : 10080 / (1440 * 7) + " week before";
      } else {
        this.reminderBeforeText =
          this.clickedItemType.reminderMinutesBeforeStart + " minutes before";
      }
      this.recipients = "";
      this.comment = "";
      this.outlookMeetingModal = true;
      this.forwardModalShow = false;
      // this.forwardmeetingShow = false;
    } else if (this.clickedItemType.type == "task") {
      if (this.clickedItemType.eleData.status == "ACTIVE") {
        this.markTaskcompletePopup = true;
        this.taskdetails = this.clickedItemType.eleData.description;
        this.taskDate = moment(this.clickedItemType.eleData.date).format(
          "dddd, MMMM Do"
        );
        this.taskTime = this.clickedItemType.eleData.startTime;
      }
    } else {
      this.router.navigateByUrl(this.navUrl + "scheduling/tabview", {
        state: {
          calendarItem: this.clickedItemType.type,
          elemData: this.clickedItemType.eleData,
        },
      });
    }
  }

  openFormModalCreate() {
    this.ref = this.dialogService.open(MarkTaskCompleteComponent, {
      header: "MarkTaskComplete",
      width: "70%",
      contentStyle: { "max-height": "800px", overflow: "auto" },
    });
  }

  onDateClick(res) {
    //alert("Clicked on date : " + res.dateStr);
  }
  ngOnInit(): void {
    this.calendarItemTypes = [];
    this.calendarItemCheckboxes = [];
    this.isIframe = window !== window.parent && !window.opener;
    this.googleApi.getUserProfile();
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    if (this.userType == "Petitioner") {
      this.calendarItemTypes = [
        {
          displayName: "Meeting",
          name: "meeting",
          code: 1067,
          type: "meeting",
          id: 1067,
        },
        {
          displayName: "Event",
          name: "event",
          code: 1068,
          type: "event",
          id: 1068,
        },
        {
          displayName: "Task",
          name: "task",
          code: 1069,
          type: "task",
          id: 1069,
        },
        {
          displayName: "Reminder",
          name: "reminder",
          code: 1071,
          type: "reminder",
          id: 1071,
        },
      ];
    } else if (this.userType == "Attorney") {
      this.calendarItemTypes = CALITEMS.slice();
    }
    this.calendarItemCheckboxes = this.calendarItemTypes.slice();
    this.myCals = [1067, 1068, 1069, 1070, 1071];
    let outlookAccounts = this.authService.instance.getAllAccounts();
    if (outlookAccounts.length > 0) {
      // this.getReminders();
      if (this.selectedOutlookCals === null) {
        this.outlookCalendarsPickModal = true;
        this.loginDisplay = false;
        this.outlookCalendars = this.selectedOutlookCals;
      } else {
        this.loginDisplay = true;
        this.calendarItemTypes.push({
          displayName: "Outlook Meeting",
          name: "Microsoft Meeting",
          code: 1073,
          type: "microsoftmeeting",
          id: 1073,
        });
        // this.myCals.push(1073);
      }
    }
    this.resetGoogleMeetings();
    if (this.loginDisplay) {
      this.getProfile();
    }
    this.getAllEvents();

    this.currentURL = this.router.url;
  }

  resetGoogleMeetings() {
    this.googleApi.userProfileSubject.pipe(take(1)).subscribe((info: any) => {
      this.userInfo = info;
      localStorage.setItem("userInfo", JSON.stringify(info));
      this.googleApi
        .getGoogleCalColors()
        .subscribe((colors) => (this.googleCalColor = colors));
      this.getCals();
      if (this.userInfo?.info) {
        this.calendarItemTypes.push({
          displayName: "Google Meeting",
          name: "Google Meeting",
          code: 1072,
          type: "googlemeeting",
          id: 1072,
        });
        // this.myCals.push(1072);
      }
    });
  }

  getMeetingDetails() {
    this.editOutlookModal = !this.editOutlookModal;
    console.log(this.clickedItemType);

    let calendarItemId = this.eventDetailsRaw._def.publicId;
    let series = 1;
    // this.UserToken = sessionStorage.getItem('UserToken');

    // this.schedulingService.getCalendarItemDetails(calendarItemId, series).subscribe(res => {
    //   this.meetingEditDetails = res['data'];
    //   this.optionalEmail = this.meetingEditDetails.optional.filter(val =>{
    //      return val.userId !== "";
    //   })
    //   this.requiredEmail = this.meetingEditDetails.required.filter(val =>{
    //     return val.userId !== "";
    //  })
    //   if (this.meetingEditDetails) {
    //     if(this.meetingEditDetails.recurrenceType.type ==='NOREPEAT'){
    //       // this.hideDate = false;
    //     }
    //     this.updatepopUp = true;
    //     this.requiredEmails = this.meetingEditDetails.required.map(val =>{
    //       return val.emailId;
    //     });
    //     this.optEmail = this.meetingEditDetails.optional.map(val =>{
    //       return val.emailId;
    //     })
    //     this.requiredImgEmails = this.requiredEmail.map(val =>{
    //       return{
    //       "name": val.firstName + ' ' + val.lastName,
    //         "emailId": val.emailId,
    //         "userId": val.userId,
    //       }
    //     });

    //     this.optImgEmail = this.optionalEmail.map(val =>{
    //       return{
    //       "name": val.firstName + ' ' + val.lastName,
    //         "emailId": val.emailId,
    //         "userId": val.userId,
    //       }
    //     });
    //     this.eventsForm = this.fb.group({
    //       subject: [this.meetingEditDetails.title, Validators.required],
    //       description: [this.meetingEditDetails.description],

    //       calItemTypeCode: [this.meetingEditDetails.calendarItemType],
    //       calendarItemId: [this.meetingEditDetails.id],
    //       calendarScheduleId: ['0'],
    //       meetingLocation: [this.meetingEditDetails.location,Validators.required],
    //       startTime: [this.meetingEditDetails.startTime, Validators.required],
    //       endTime: [this.meetingEditDetails.endTime, Validators.required],
    //       startDate: [moment(this.meetingEditDetails.startDate).format('MM/DD/YYYY'), Validators.required],
    //       endDate: [moment(this.meetingEditDetails.endDate).format('MM/DD/YYYY')],
    //       isAllday: 0,
    //       isPublic: this.meetingEditDetails.isPublic,
    //       isSeries: 0,
    //       meetingCompanyId: [this.meetingEditDetails.clientId],
    //       meetingCompanyName: [this.meetingEditDetails.companyName],
    //       timeZoneCode: [''],
    //       selectedWeeks: [this.meetingEditDetails.recurrenceType.weekDays],
    //       customselectedWeeks: [this.meetingEditDetails.recurrenceType.weekDays],
    //       dayOfMonth: this.meetingEditDetails.recurrenceType.day,
    //       customdayOfMonth: this.meetingEditDetails.recurrenceType.day,
    //       monthOfYear: [''],
    //       custommonthOfYear: [''],
    //       customWeekNo: this.meetingEditDetails.recurrenceType.weekNo,
    //       customday: this.meetingEditDetails.recurrenceType.weekDays ? this.meetingEditDetails.recurrenceType.weekDays[0] :'',//saving in arrary format so or display
    //       recuurenceType: [this.meetingEditDetails.recurrenceType.type],
    //       customInterval:[this.meetingEditDetails.recurrenceType.interval],
    //       customrecuurenceType:[this.meetingEditDetails.recurrenceType.subType],
    //       reqattendiesList:[this.meetingEditDetails.required.map((value )=> {
    //         return value.emailId
    //       }),Validators.required],
    //       optattendiesList:[this.meetingEditDetails.optional.map((value )=> {
    //         return value.emailId
    //       })],
    //       reqImagilityattendiesList:[this.meetingEditDetails.required ],
    //       optimagilityattendiesList:[this.meetingEditDetails.optional],
    //       recurrenceTypeDTO: this.fb.group({
    //         day: this.meetingEditDetails.recurrenceType.day,
    //         interval: 1,
    //         month: null,
    //         type: this.meetingEditDetails.recurrenceType.type,
    //         subtype: this.meetingEditDetails.recurrenceType.subType,
    //         weekDays: [null],
    //       }),
    //     });
    //     // this.scheduleType = this.meetingEditDetails.recurrenceType.type;
    //     // this.subrecurrenceType = this.meetingEditDetails.recurrenceType.subType;
    //     // this.selectedWeeks = this.meetingEditDetails.recurrenceType.weekDays;
    //     // console.log('this.meetingEditObj ::', this.meetingEditObj);
    //     // console.log('this.meetingEditObj', this.meetingEditObj)
    //   } else{
    //     this.eventsForm = this.fb.group({
    //       subject: ['', Validators.required],
    //       attendiesList: [''],
    //       optimagilityattendiesList: [''],
    //       calItemTypeCode: ['MEETING'],
    //       calendarItemId: ['0'],
    //       calendarScheduleId: ['0'],
    //       startTime: ['', Validators.required],
    //       endDate: ['', Validators.required],
    //       endTime: ['', Validators.required],
    //       startDate: ['', Validators.required],
    //       isAllday: 0,
    //       isPublic: [''],
    //       isSeries: 0,
    //       meetingCompanyId: [''],
    //       meetingCompanyName: [''],
    //       meetingLocation: ['',Validators.required],
    //       timeZoneCode: [''],
    //       description: [''],
    //       selectedWeeks: [],
    //       dayOfMonth: [''],
    //       monthOfYear: [''],
    //       customdayOfMonth:[''],
    //       customWeekNo: [''],
    //       customrecuurenceType: [''],
    //       customselectedWeeks: [''],
    //       customday: [''],
    //       recuurenceType: [''],
    //       optattendiesList:[''],
    //       reqattendiesList:['', Validators.required ],
    //       reqImagilityattendiesList:['',Validators.required],
    //       customInterval: [''],
    //      recurrenceTypeDTO: this.fb.group({
    //         day: null,
    //         interval: 1,
    //         month: null,
    //         type: null,
    //         weekDays: [],
    //         subType:null,
    //         weekNo: null
    //       }),
    //     });
    //   }

    // })
  }

  getReminders() {
    let startDateTime: any = new Date().setUTCHours(0, 0, 0, 0);
    startDateTime = new Date(startDateTime).toISOString().replace(/[.]\d+/, "");

    let endDateTime: any = new Date().setUTCHours(23, 59, 59, 999);
    endDateTime = new Date(endDateTime).toISOString().replace(/[.]\d+/, "");

    this.http
      .get(
        GRAPH_ENDPOINT +
          `me/reminderView(startDateTime=${startDateTime},endDateTime=${endDateTime})`
      )
      .subscribe((reminders: any) => {
        this.reminders = reminders;
      });
  }

  toggle(index: number) {
    this.activeState[index] = !this.activeState[index];
  }

  setLoginDisplay() {
    let outlookAccounts = this.authService.instance.getAllAccounts();
    this.loginDisplay = outlookAccounts.length > 0;
    if (outlookAccounts.length > 0) {
      this.getProfile();
    }
  }

  loginOutlook() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.getProfile();
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.getProfile();
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logoutOutlook() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri:
          "/attorney-landing/dashboard/attorney-dashboard/scheduling",
      });
    } else {
      this.authService.logoutRedirect({
        postLogoutRedirectUri:
          "/attorney-landing/dashboard/attorney-dashboard/scheduling",
      });
    }
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT + "me").subscribe((profile: any) => {
      this.profile = profile;
      this.getOutlookCals();
    });
  }

  getOutlookCals() {
    this.http.get(GRAPH_ENDPOINT + "me/calendars").subscribe((cals: any) => {
      let choosedCalIds = JSON.parse(
        sessionStorage.getItem("selectedOutlookCalendars")
      );
      this.initOutlookSettingsMenu(choosedCalIds);
      if (choosedCalIds === null) {
        this.outlookCalendarsPickModal = true;
      }
      this.outlookCalendars = cals.value;
    });
  }

  selectedOutlookCalendars(choosedCalIds) {
    this.outlookCalendarsPickModal = false;
    let existingEvSources = this.userCalendar
      .getApi()
      .getEventSources()
      .filter((x) => x.id.includes("outlook"));
    existingEvSources.forEach((source) => {
      let eventSource = this.userCalendar
        .getApi()
        .getEventSourceById(source.id);
      eventSource !== null ? eventSource.remove() : "";
    });
    sessionStorage.setItem(
      "selectedOutlookCalendars",
      JSON.stringify(choosedCalIds)
    );
    this.initOutlookSettingsMenu(choosedCalIds);
    choosedCalIds.forEach((calId) => this.getCalendarEvents(calId));
  }

  initGoogleSettingsMenu(data) {
    this.googleSettingsMenu = [
      {
        label: "Google Settings",
        items: [
          {
            label: "Calendars",
            icon: "pi pi-calendar",
            command: () => {
              this.clonnedselectedGoogleCals = JSON.parse(JSON.stringify(data));
              this.googleCalendarsPickModal = true;
            },
          },
          {
            label: "Remove Account",
            icon: "pi pi-times",
            command: () => {
              this.selectedGoogleCals = [];
              localStorage.removeItem("selectedGoogleCalendars");
              this.logoutGoogle();
            },
          },
        ],
      },
    ];
  }

  selectedGoogleCalendars(choosedCalIds) {
    this.googleCalendarsPickModal = false;
    let existingEvSources = this.userCalendar
      .getApi()
      .getEventSources()
      .filter((x) => x.id.includes("google"));
    existingEvSources.forEach((source) => {
      let eventSource = this.userCalendar
        .getApi()
        .getEventSourceById(source.id);
      eventSource !== null ? eventSource.remove() : "";
    });

    localStorage.setItem(
      "selectedGoogleCalendars",
      JSON.stringify(choosedCalIds)
    );
    this.initGoogleSettingsMenu(choosedCalIds);
    choosedCalIds.forEach((calId) => this.getGoogleCalEvents(calId));
  }

  deleteEventById(data){
    this.googleApi.deleteEventById(data._def.extendedProps.calId, data._def.publicId).subscribe(res=>{
      this.googleMeetingModal = false;
      this.getCals();
      this.toaster.success('Event Deleted Successfully')
    });
  }
  initOutlookSettingsMenu(data) {
    this.outlookSettingsMenu = [
      {
        label: "Outlook Settings",
        items: [
          {
            label: "Calendars",
            icon: "pi pi-calendar",
            command: () => {
              this.clonnedselectedOutlookCals = JSON.parse(
                JSON.stringify(data)
              );
              this.getProfile();
              this.outlookCalendarsPickModal = true;
            },
          },
          {
            label: "Remove Account",
            icon: "pi pi-times",
            command: () => {
              this.selectedOutlookCals = [];
              sessionStorage.removeItem("selectedOutlookCalendars");
              this.logoutOutlook();
            },
          },
        ],
      },
    ];
  }
  async getOutlookEvents() {
    this.selectedOutlookCals = JSON.parse(
      sessionStorage.getItem("selectedOutlookCalendars")
    );
    this.initOutlookSettingsMenu(this.selectedOutlookCals);
    this.selectedOutlookCalendars(this.selectedOutlookCals);
  }

  getGoogleEvents() {
    this.selectedGoogleCals = JSON.parse(
      localStorage.getItem("selectedGoogleCalendars")
    );
    this.initGoogleSettingsMenu(this.selectedGoogleCals);
    this.selectedGoogleCalendars(this.selectedGoogleCals);
  }

  async getCalendarEvents(calId) {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let start_datetime = new Date(y, m, 1).toISOString().split(".")[0] + "Z";
    let end_datetime = new Date(y, m + 1, 0).toISOString().split(".")[0] + "Z";

    this.http
      .get(
        `${GRAPH_ENDPOINT}me/calendars/${calId}/events` //?startDateTime=${start_datetime}&endDateTime=${end_datetime}
      )
      .subscribe((events: any) => {
        const currentPageEvents = events.value || []; 
        // Append the events to the overall list
        let allEvents = [];
        allEvents.push(...currentPageEvents);


        // Check if there are more pages
        const nextLink = events['@odata.nextLink'];

        if ('@odata.nextLink' in events) {
          this.http
            .get(
              decodeURIComponent(nextLink)
            )
            .subscribe((nextPageEvents: any) => {
              allEvents.push(...nextPageEvents);
            })
        }
        const selectedOutlookCals = JSON.parse(
          sessionStorage.getItem("selectedOutlookCalendars")
        );
        if (selectedOutlookCals && selectedOutlookCals.length > 0) {
          selectedOutlookCals.forEach((element) => {
            let eventSource = this.userCalendar
              .getApi()
              .getEventSourceById(element);
            eventSource !== null ? eventSource.remove() : "";
          });
        }
        this.outlookEvents = [];
        allEvents.map((event) => {
          event.color = "blue";
          event.title = event.subject;
          event.sourceType = "outlook";
          if (event.start?.dateTime) {
            event.originalstart = new Date(event.start.dateTime + 'Z').toISOString();
          }
          if (event.end?.dateTime) {
            event.originalend = new Date(event.end.dateTime + 'Z').toISOString();
          }
          // if (
          //   event.recurrence !== null &&
          //   event.recurrence.pattern?.type == "daily"
          // ) {
          //   //event.daysOfWeek = ['1', '2', '3', '4', '5'];
          //   event.startTime = new Intl.DateTimeFormat("default", {
          //     hour12: false,
          //     hour: "numeric",
          //     minute: "numeric",
          //   }).format(new Date(event.start.dateTime + "Z"));
          //   event.endTime = new Intl.DateTimeFormat("default", {
          //     hour12: false,
          //     hour: "numeric",
          //     minute: "numeric",
          //   }).format(new Date(event.end.dateTime + "Z"));
          //   event.startRecur = new Date(event.recurrence.range.startDate);
          //   event.endRecur = new Date(event.recurrence.range.endDate);
          // }

          // else if (
          //   event.recurrence !== null &&
          //   event.recurrence.pattern?.type == "weekly"
          // ) {
          //   event.daysOfWeek = [];
          //   var days = [
          //     "sunday",
          //     "monday",
          //     "tuesday",
          //     "wednesday",
          //     "thursday",
          //     "friday",
          //     "saturday",
          //   ];
          //   event.recurrence.pattern.daysOfWeek.forEach((day) => {
          //     event.daysOfWeek.push(days.indexOf(day));
          //   });

          //   event.startTime = new Intl.DateTimeFormat("default", {
          //     hour12: false,
          //     hour: "numeric",
          //     minute: "numeric",
          //   }).format(new Date(event.start.dateTime + "Z"));
          //   event.endTime = new Intl.DateTimeFormat("default", {
          //     hour12: false,
          //     hour: "numeric",
          //     minute: "numeric",
          //   }).format(new Date(event.end.dateTime + "Z"));
          // }
          if (
            event.recurrence !== null &&
            event.recurrence.pattern?.type == "daily"
          ) {
            event.rrule = {
              freq: RRule.DAILY,
              dtstart: new Date(event.recurrence.range.startDate),
              until: new Date(event.recurrence.range.endDate),
              interval: 1,
              wkst: RRule.SU,
              byweekday: [
                RRule.MO,
                RRule.TU,
                RRule.WE,
                RRule.TH,
                RRule.FR,
                RRule.SA,
                RRule.SU,
              ],
            };
          } else if (
            event.recurrence !== null &&
            event.recurrence.pattern?.type == "weekly"
          ) {
            let daysofWk = event.recurrence.pattern.daysOfWeek;
            let convertedDayOfWK = daysofWk.map((day) => {
              if (day == "sunday") return RRule.SU;
              if (day == "monday") return RRule.MO;
              if (day == "tuesday") return RRule.TU;
              if (day == "wednesday") return RRule.WE;
              if (day == "thursday") return RRule.TH;
              if (day == "friday") return RRule.FR;
              if (day == "saturday") return RRule.SA;
            });
            event.rrule = {
              freq: RRule.WEEKLY,
              dtstart: new Date(event.recurrence.range.startDate),
              interval: 1,
              wkst: RRule.MO,
              byweekday: convertedDayOfWK,
            };
          } else if (
            event.recurrence !== null &&
            event.recurrence.pattern?.type == "relativeMonthly"
          ) {
            event.rrule = {
              freq: RRule.MONTHLY,
              dtstart: new Date(event.recurrence.range.startDate),
              until: new Date(event.recurrence.range.endDate),
              interval: 1,
              wkst: RRule.SU,
            };
          } else if (
            event.recurrence !== null &&
            event.recurrence.pattern?.type == "absoluteMonthly"
          ) {
            event.rrule = {
              freq: RRule.MONTHLY,
              dtstart: new Date(event.recurrence.range.startDate),
              until: new Date(event.recurrence.range.endDate),
              interval: 1,
              wkst: RRule.SU,
            };
          } else if (
            event.recurrence !== null &&
            event.recurrence.pattern?.type == "absoluteYearly"
          ) {
            event.rrule = {
              freq: RRule.YEARLY,
              dtstart: new Date(event.recurrence.range.startDate),
              interval: 1,
              wkst: RRule.SU,
            };
          } else if (
            event.recurrence == null &&
            event.type == "singleInstance"
          ) {
            event.originalStart = new Date(event.start.dateTime + "Z");
            event.originalend = new Date(event.end.dateTime + "Z");
            event.rrule = {
              freq: RRule.DAILY,
              dtstart: event.start.dateTime + "Z",
              until: event.end.dateTime + "Z",
              interval: 1,
            };
          } else {
            event.start = new Date(event.start.dateTime + "Z");
            event.end = new Date(event.end.dateTime + "Z");
          }
        });
        this.outlookEvents = events.value;
        // this.calendarOptions.eventSources.push({events:this.outlookEvents, color: 'yellow', textColor: 'black'});
        this.userCalendar.getApi().addEventSource({
          events: this.outlookEvents,
          color: "#039be5",
          textColor: "black",
          id: "outlook-" + calId,
        });
      });
  }
  forwardMeeting(id) {
    this.forwardMeetinginProgress = true;
    const forward = {
      ToRecipients: [],
      Comment: "",
    };
    this.recipients.split(",").forEach((recipient) => {
      forward.ToRecipients.push({
        emailAddress: {
          address: recipient.trim(),
          name: "",
        },
      });
    });
    forward.Comment = this.comment;

    this.http
      .post(`${GRAPH_ENDPOINT}me/events/${id}/forward`, forward)
      .subscribe((response: any) => {
        this.forwardMeetinginProgress = false;
        this.recipients = null;
        this.comment = null;
        this.forwardModalShow = !this.forwardModalShow;
      });
  }

  //google meeting forward

  forwardGoogleMeeting(event) {
    this.googleApi
      .getEventById(event.extendedProps.calId, event.id)
      .subscribe((eventRaw: any) => {
        if (!eventRaw.attendees) {
          eventRaw.attendees = [];
        }
        this.forwardGMeetinginProgress = true;
        this.recipientsG.split(",").forEach((recipient) => {
          eventRaw.attendees.push({
            email: recipient,
            responseStatus: "needsAction",
          });
        });
        // forward.Comment = this.comment
        this.googleApi
          .patchEventById(event.extendedProps.calId, event.id, {
            attendees: eventRaw.attendees,
          })
          .subscribe((res: any) => {
            this.forwardGMeetinginProgress = false;
            this.recipientsG = null;
            this.commentG = null;
            this.forwardGmeetingShow = !this.forwardGmeetingShow;
          });
      });
  }

  cancelforwardMeeting() {
    this.forwardMeetinginProgress = false;
    this.recipients = "";
    this.comment = "";
    this.forwardModalShow = !this.forwardModalShow;
  }
  cancelforwardGoogleMeeting() {
    this.forwardGMeetinginProgress = false;
    this.recipientsG = "";
    this.commentG = "";
    this.forwardGmeetingShow = !this.forwardGmeetingShow;
  }

  going(statusUpdateType) {
    this.goingcomment = "";
    if (!this.goingSubmitShow) {
      this.goingSubmitShow = !this.goingSubmitShow;
    }
    this.statusUpdateType = statusUpdateType;
  }

  goingSubmit(statusUpdateType, calId) {
    const commonPayload = {
      comment: this.goingcomment,
      sendResponse: true,
    };

    this.http
      .post(
        `${GRAPH_ENDPOINT}me/events/${calId}/${statusUpdateType}`,
        commonPayload
      )
      .subscribe((response: any) => {
        this.goingcomment = "";
        if (statusUpdateType === "decline") {
          this.outlookMeetingModal = !this.outlookMeetingModal;
        }
        this.forwardModalShow = false;
        this.getAllEvents();
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  addNewGoogleAccount() {
    this.googleApi.login().then(() => {
      this.googleApi.getUserProfile();
      this.getCals();
    });
  }

  showEvent(info: any) {
    this.eventDetails = info.event;
    this.displayEvent = true;
  }

  changeTimeZone(date: any, tz): any {
    if (typeof date === "string") {
      return new Date(new Date(date).toLocaleString("en-US", { timeZone: tz }));
    }
  }

  //outlook
  isLoggedIn(): boolean {
    return this.googleApi.isLoggedIn();
  }

  logoutGoogle() {
    // this.googleApi.signOut()
    this.isGcallogoutInProgress = true;
    this.userCalendar
      .getApi()
      .getEventSources()
      .forEach((source) => {
        if (source.id.includes("google")) {
          source.remove();
        }
      });
    let localStorageElems = [
      "userInfo",
      "nonce",
      "access_token_stored_at",
      "access_token",
      "PKCE_verifier",
      "id_token_claims_obj",
      "session_state",
      "granted_scopes",
      "refresh_token",
      "expires_at",
      "id_token_expires_at",
      "selectedGoogleCalendars",
      "id_token_stored_at",
      "id_token",
      "session_end",
    ];
    localStorageElems.forEach((k) => localStorage.removeItem(k));
    this.userInfo = null;
    setTimeout(() => {
      this.isGcallogoutInProgress = false;
      window.location.reload();
    }, 2000);
  }

  async getCals() {
    this.googleApi
      .getCals()
      .pipe(take(1))
      .subscribe((response) => {
        let selectedGoogleCalendars = localStorage.getItem(
          "selectedGoogleCalendars"
        );
        this.selectedGoogleCals = JSON.parse(selectedGoogleCalendars);
        this.calendarList = response;
        console.log("getCals21");
        if (
          this.selectedGoogleCals &&
          this.selectedGoogleCals !== null &&
          this.selectedGoogleCals.length > 0
        ) {
          this.getGoogleEvents();
        } else {
          this.googleCalendarsPickModal = true;
        }
      });
  }

  afterCheck() {}

  convertTZ(date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: tzString }
      )
    );
  }

  async getGoogleCalEvents(calId: string) {
    if (!this.userInfo) {
      return;
    }
    console.log("getGoogleCalEventsBefore");
    this.googleCalendarsPickModal = false;
    this.googleApi
      .getEvents(calId)
      .pipe(take(1))
      .subscribe((response) => {
        console.log("getGoogleCalEvents");
        response.items.map((gEvent: any) => {
          if (!gEvent.start?.dateTime && gEvent.start?.date) {
            gEvent.allDay = true;
          }
          gEvent.calId = calId;
          gEvent.title = gEvent.summary;
          if (gEvent.colorId) {
            gEvent.color =
              this.googleCalColor.event[
                gEvent.colorId ? gEvent.colorId : 0
              ].background;
          }
          gEvent.sourceType = "google";
          if (gEvent.end?.dateTime) {
            gEvent.originalend = new Date(gEvent.end.dateTime).toISOString();
          }
          if (gEvent.recurrence) {
            // if (gEvent.recurrence[0].includes('DAILY')) {
            //   gEvent.eType = 'DAILY';
            //   gEvent.rrule = {
            //     freq: RRule.DAILY,
            //     dtstart: new Date(gEvent.start.dateTime).toISOString(),
            //     until: moment(new Date(gEvent.end.dateTime)).add(2, 'y').format(),
            //     // until: moment(gEvent.start.dateTime).format('MM/DD/YYYY') === moment(gEvent.end.dateTime).format('MM/DD/YYYY') ? moment(new Date(gEvent.end.dateTime)).add(2, 'y').format() : gEvent.end.dateTime,
            //     interval: 1
            //   }
            //   gEvent.start = gEvent.start.dateTime ? new Date(gEvent.start.dateTime).toISOString() : gEvent.start.date;
            //   gEvent.end = gEvent.end.dateTime ? new Date(gEvent.end.dateTime).toISOString() : gEvent.end.date;
            //   // gEvent.start = gEvent.start.dateTime;
            //   // gEvent.end = gEvent.end.dateTime;
            // }
            gEvent.calId = calId;
            gEvent.title = gEvent.summary;
            if (gEvent.colorId) {
              gEvent.color =
                this.googleCalColor.event[
                  gEvent.colorId ? gEvent.colorId : 0
                ].background;
            }
            gEvent.sourceType = "google";
            if (gEvent.end.dateTime) {
              gEvent.originalend = new Date(gEvent.end.dateTime).toISOString();
            }
            if (gEvent.recurrence[0].includes("DAILY")) {
              gEvent.eType = "DAILY";
              // gEvent.rrule = gEvent.recurrence;
              gEvent.rrule = {
                freq: RRule.DAILY,
                dtstart: new Date(gEvent.start.dateTime).toISOString(),
                until: moment(new Date(gEvent.end.dateTime))
                  .add(2, "y")
                  .format(),
                // until: moment(gEvent.start.dateTime).format('MM/DD/YYYY') === moment(gEvent.end.dateTime).format('MM/DD/YYYY') ? moment(new Date(gEvent.end.dateTime)).add(2, 'y').format() : gEvent.end.dateTime,
                interval: 1,
              };
              gEvent.start = gEvent.start.dateTime
                ? new Date(gEvent.start.dateTime).toISOString()
                : gEvent.start.date;
              gEvent.end = gEvent.end.dateTime
                ? new Date(gEvent.end.dateTime).toISOString()
                : gEvent.end.date;
              // gEvent.start = gEvent.start.dateTime;
              // gEvent.end = gEvent.end.dateTime;
            } else if (gEvent.recurrence[0].includes("WEEKLY")) {
              gEvent.eType = "WEEKLY";
              gEvent.rrule = {
                freq: RRule.WEEKLY,
                dtstart: new Date(
                  gEvent.start.dateTime
                    ? gEvent.start.dateTime
                    : gEvent.start.date
                ).toISOString(),
                // until: moment(new Date(gEvent.end.dateTime)).add(2, 'y').format(),
                interval: 1,
                byweekday: [],
                // byweekday: [RRule.SU,RRule.MO,RRule.TU, RRule.WE,RRule.TH,RRule.FR,RRule.SA]
              };
              gEvent.start = new Date(
                gEvent.start.dateTime
                  ? gEvent.start.dateTime
                  : gEvent.start.date
              ).toISOString();
              gEvent.end = new Date(
                gEvent.end.dateTime ? gEvent.end.dateTime : gEvent.end.date
              ).toISOString();
              gEvent.daysInWeek = [];
              var days = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ];
              let gDays = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
              gEvent.recurrence[0]
                .split("BYDAY=")[1]
                .split(",")
                .forEach((day) => {
                  let shortDayNMindex = gDays.indexOf(day);
                  gEvent.rrule.byweekday.push({
                    weekday: shortDayNMindex < 1 ? 6 : shortDayNMindex - 1,
                  });
                  gEvent.daysInWeek.push(days[shortDayNMindex]);
                });
              gEvent.daysInWeek = gEvent.daysInWeek.sort(
                (a, b) => days.indexOf(a) - days.indexOf(b)
              );
            } else if (gEvent.recurrence[0].includes("MONTHLY")) {
              gEvent.eType = "MONTHLY";
              gEvent.rrule = gEvent.recurrence[0];
              gEvent.start = gEvent.start.dateTime
                ? new Date(gEvent.start.dateTime).toISOString()
                : gEvent.start.date;
              gEvent.end = gEvent.end.dateTime
                ? new Date(gEvent.end.dateTime).toISOString()
                : gEvent.end.date;
            } else {
              if (gEvent.start.dateTime) {
                gEvent.startTime = new Intl.DateTimeFormat("default", {
                  hour12: true,
                  hour: "numeric",
                  minute: "numeric",
                }).format(new Date(gEvent.start.dateTime));
                gEvent.endTime = new Intl.DateTimeFormat("default", {
                  hour12: true,
                  hour: "numeric",
                  minute: "numeric",
                }).format(new Date(gEvent.end.dateTime));
                gEvent.startRecur = new Date(
                  gEvent.start.dateTime
                    ? gEvent.start.dateTime
                    : gEvent.start.date
                ).toISOString();
                gEvent.endRecur = new Date(
                  gEvent.end.dateTime ? gEvent.end.dateTime : gEvent.end.date
                ).toISOString();
              }
              gEvent.start = gEvent.start.dateTime
                ? new Date(gEvent.start.dateTime).toISOString()
                : gEvent.start.date;
              gEvent.end = gEvent.end.dateTime
                ? new Date(gEvent.end.dateTime).toISOString()
                : gEvent.end.date;
            }
          } else if (!gEvent.start.dateTime && gEvent.start.date) {
            // gEvent.allDay = true;
            gEvent.originalend = new Date(
              moment(gEvent.end.date).subtract(1, "days").format("YYYY-MM-DD")
            );
            gEvent.start = gEvent.start.date;
            gEvent.end = gEvent.end.date;
          } else {
            let start = this.changeTimeZone(
              gEvent.start.dateTime ? gEvent.start.dateTime : gEvent.start.date,
              gEvent.start.timeZone
            );
            gEvent.start = new Date(start).toISOString();
            let end = this.changeTimeZone(
              gEvent.end.dateTime ? gEvent.end.dateTime : gEvent.end.date,
              gEvent.end.timeZone ? gEvent.end.timeZone : "UTC"
            );
            gEvent.end = new Date(end).toISOString();
          }
        });
        this.userCalendar
          .getApi()
          .addEventSource({
            events: response.items,
            color: "#039be5",
            textColor: "black",
            id: "google-" + calId,
          });
        // getGoogleCalEventsSub.unsubscribe();
      });
  }

  async addNewEvent() {
    this.addEventDialog = true;
  }

  validateEmail(emails) {
    this.invalidEmails = [];
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emails.includes(",")) {
      emails.split(",");
      emails.forEach((eId) => {
        if (!regex.test(eId)) {
          this.invalidEmails.push(eId);
        }
      });
    } else {
      if (!regex.test(emails)) {
        this.invalidEmails.push(emails);
      }
    }
  }

  ngAfterViewInit(): void {
    var monthElem = document.getElementsByClassName("fc-dayGridMonth-button");
    var monthBtn = monthElem[0];
    this.monthBtn = monthBtn;
    monthBtn.addEventListener("click", (x) => {
      const calendarApi = this.userCalendar.getApi();
      calendarApi.currentClassNames;
      let date = calendarApi.getDate();
      let start = moment(date).startOf("month").format("YYYY-MM-DD");
      let end = moment(date).endOf("month").format("YYYY-MM-DD");
      this.startDate = start;
      this.endDate = end;
      this.getAllEvents();
    });

    var weekButton = document.getElementsByClassName("fc-timeGridWeek-button");
    var weekBtn = weekButton[0];
    this.weekBtn = weekBtn;
    weekBtn.addEventListener("click", (x) => {
      const calendarApi = this.userCalendar.getApi();
      calendarApi.currentClassNames;
      let date = calendarApi.getDate();
      let start = moment(date).startOf("week").format("YYYY-MM-DD");
      let end = moment(date).endOf("week").format("YYYY-MM-DD");
      this.startDate = start;
      this.endDate = end;
      this.getAllEvents();
    });

    var dayButton = document.getElementsByClassName("fc-timeGridDay-button");

    var dayBtn = dayButton[0];
    this.dayBtn = dayBtn;
    dayBtn.addEventListener("click", (x) => {
      const calendarApi = this.userCalendar.getApi();
      calendarApi.currentClassNames;
      let date = calendarApi.getDate();
      let start = moment(date).format("YYYY-MM-DD");
      let end = moment(date).format("YYYY-MM-DD");
      this.startDate = start;
      this.endDate = end;
      this.getAllEvents();
    });

    var todayButton = document.getElementsByClassName("fc-today-button");

    var todayBtn = todayButton[0];
    this.todayBtn = todayBtn;
    todayBtn.addEventListener("click", (x) => {
      const calendarApi = this.userCalendar.getApi();
      calendarApi.currentClassNames;
      let date = calendarApi.getDate();
      let start = moment().format("YYYY-MM-DD");
      let end = moment().format("YYYY-MM-DD");
      this.startDate = start;
      this.endDate = end;
      this.getAllEvents();
    });

    var elem = document.getElementsByClassName("fc-prev-button");
    var btn;
    btn = elem[0];
    this.prevBtn = btn;
    let month, firstDate, year;
    // let olddate , oldmonth, oldyear
    btn.addEventListener("click", (x) => {
      var monthButton = document.getElementsByClassName(
        "fc-dayGridMonth-button"
      );
      var weekButton = document.getElementsByClassName(
        "fc-timeGridWeek-button"
      );
      var dayButton = document.getElementsByClassName("fc-timeGridDay-button");
      const calendarApi1 = this.userCalendar.getApi();
      let date1 = calendarApi1.getDate();
      var weekClassNames = weekButton[0].className;
      var dayClassNames = dayButton[0].className;
      var classNames = monthButton[0].className;
      if (classNames.includes("fc-button-active")) {
        this.startDate = moment(date1).startOf("month").format("YYYY-MM-DD");
        this.endDate = moment(date1).endOf("month").format("YYYY-MM-DD");
      } else if (weekClassNames.includes("fc-button-active")) {
        this.startDate = moment(date1).startOf("week").format("YYYY-MM-DD");
        this.endDate = moment(date1).endOf("week").format("YYYY-MM-DD");
      } else if (dayClassNames.includes("fc-button-active")) {
        this.startDate = moment(date1).format("YYYY-MM-DD");
        this.endDate = moment(date1).format("YYYY-MM-DD");
      }

      this.events = [];
      this.otherUserEvents = [];
      this.getAllEvents();
    });

    var elem1 = document.getElementsByClassName("fc-next-button");
    var btn1;
    btn1 = elem1[0];
    this.nextBtn = btn1;
    btn1.addEventListener("click", (x) => {
      this.disableAll = true;
      var monthButton = document.getElementsByClassName(
        "fc-dayGridMonth-button"
      );
      var weekButton = document.getElementsByClassName(
        "fc-timeGridWeek-button"
      );
      var dayButton = document.getElementsByClassName("fc-timeGridDay-button");
      const calendarApi1 = this.userCalendar.getApi();
      let date1 = calendarApi1.getDate();

      var weekClassNames = weekButton[0].className;
      var dayClassNames = dayButton[0].className;
      var classNames = monthButton[0].className;
      if (classNames.includes("fc-button-active")) {
        this.startDate = moment(date1).startOf("month").format("YYYY-MM-DD");
        this.endDate = moment(date1).endOf("month").format("YYYY-MM-DD");
      } else if (weekClassNames.includes("fc-button-active")) {
        this.startDate = moment(date1).startOf("week").format("YYYY-MM-DD");
        this.endDate = moment(date1).endOf("week").format("YYYY-MM-DD");
      } else if (dayClassNames.includes("fc-button-active")) {
        this.startDate = moment(date1).format("YYYY-MM-DD");
        this.endDate = moment(date1).format("YYYY-MM-DD");
      }
      this.events = [];
      this.otherUserEvents = [];
      this.getAllEvents();
    });

    this.addButtons();
    this.calendarOptions.initialEvents = this.events;
    this.obs = this.mform.valueChanges
      .pipe(debounceTime(500))
      .subscribe((data) => {
        if (data.name) this.getEmployeeDetails(data.name);
      });
  }

  getAllEvents(calItemType?) {
    let start = moment().startOf("month").format("YYYY-MM-DD");
    let end = moment().endOf("month").format("YYYY-MM-DD");
    this.activities = [];
    this.events = [];
    this.otherUserEvents = [];
    let itemType;
    let payload: any;
    if (this.calendarMode == "self") {
      payload = {
        userId: this.userId,
        location: "",
        title: "",
        description: "",
        keyword: "",
        isPublic: null,
        participantEmailIds: [],
        participantIds: [],
        fromDate: this.startDate ? this.startDate : start,
        toDate: this.endDate ? this.endDate : end,
        isCalendarView: true,
      };
      if (this.filterApplied) {
        payload.calItemTypes = this.myCals;
      }
    }
    if (this.calendarMode == "other") {
      payload = {};
      this.otherUserObj = {
        userId: this.otherUserId,
        isPublic: null,
        fromDate: this.startDate ? this.startDate : start,
        toDate: this.endDate ? this.endDate : end,
        isCalendarView: true,
      };
      payload = this.otherUserObj;
    }
    this.activities = [];
    this.events = [];
    return this.schedulingService.getAllEvents(payload).subscribe(
      (response) => {
        if (this.loginDisplay) {
          this.getOutlookEvents();
        }
        // if (this.userInfo) {
        //   //get google cal and events
        //   this.getCals();
        // }

        if (response.status == 204) {
          // this.toaster.info(
          //   response.message === '"You do not have any engagements"'
          //     ? "Imagility calendar do not have any engagements"
          //     : response.message +
          //     " from " +
          //     payload.fromDate +
          //     " to " +
          //     payload.toDate
          // );
          this.events = [];
          return;
        }
        let calendar = this.userCalendar.getApi();
        let eventSource = calendar.getEventSourceById("imCalId");
        if (eventSource !== null) {
          eventSource.remove();
        }

        response.data.forEach((el) => {
          if (el.status != "CANCELLED") {
            let date = el.date; //.substring(0, el.date.indexOf(' '));
            date = date.replaceAll("/", "-");

            let time = el.date.substring(
              el.date.indexOf(" ") + 2,
              el.date.length
            );

            let newdate = date + time;
            date = moment(date).format("YYYY-MM-DD");

            let startDate;
            let endDate;
            if (el.startTime && el.startTime !== null) {
              startDate = date + "T" + el.startTime;
            }
            if (el.endTime && el.endTime !== null) {
              endDate = date + "T" + el.endTime;
            }

            if (this.calendarMode == "self")
              this.activities.push({
                id: el.id,
                title: el.title || el.description,
                start: startDate || date,
                end: endDate || date,
                type: el.calenderItemTypeName.toLowerCase(),
                eleData: el,
              });

            if (this.calendarMode == "other")
              this.otherUserEvents.push({
                id: el.id,
                title: el.isPublic ? el.title || el.description : "Blocked",
                start: startDate || date,
                end: endDate || date,
                type: el.calenderItemTypeName.toLowerCase(),
                eleData: el,
              });
          }
        });

        if (this.calendarMode == "self") {
          this.activities.forEach((elem) => {
            elem.textColor = "black";
            if (elem.type == "event") {
              elem.color = "rgb(139 146 226 / 40%)";
            } else if (elem.type == "task") {
              if (elem.eleData.status == "COMPLETED") {
                elem.classNames = ["strike"];
                elem.color = "rgb(0 200 0 / 35%)";
              } else elem.color = "rgb(0 200 0 / 35%)";
            } else if (elem.type == "reminder") {
              elem.color = "rgb(255 51 139 / 45%)";
            } else if (elem.type == "appointment" || elem.type == "meeting") {
              // elem.color = "#1C7CA3";
              elem.color = "rgb(45 167 213 / 35%)";
            } else if (elem.type == "court date" || elem.type == "courtdate") {
              elem.color = "rgb(255 188 85 / 40%)";
            }
          });
          this.activities = this.activities.filter((el) => {
            return this.myCals.find((element) => {
              return element === el.eleData.calenderItemType;
            });
          });
          this.events = this.activities;
        }

        if (this.calendarMode == "other") {
          this.otherUserEvents.forEach((elem) => {
            elem.textColor = "black";
            if (elem.type == "event") {
              elem.color = "#8B92E2";
            } else if (elem.type == "task") {
              if (elem.eleData.status == "COMPLETED") {
                elem.color = "#00C800";
                elem.classNames = ["strike"];
              } else elem.color = "#00C800";
            } else if (elem.type == "reminder") {
              elem.color = "#FF338B";
            } else if (elem.type == "appointment" || elem.type == "meeting") {
              elem.color = "#1C7CA3";
            } else if (elem.type == "court date" || elem.type == "courtdate") {
              elem.color = "#FEBC54";
            }
          });
          this.otherUserEvents = this.otherUserEvents.filter((el) => {
            return this.myCals.find((element) => {
              return element === el.eleData.calenderItemType;
            });
          });
          this.events = this.otherUserEvents;
        }

        calendar.addEventSource({
          events: this.events,
          color: "#039be5",
          textColor: "black",
          id: "imCalId",
        });
      },
      (error) => {
        this.toaster.info(error.message);
      },
      () => {}
    );
  }

  addButtons() {
    var elem = document.getElementsByClassName("fc-header-toolbar");
    var createNew = document.getElementById("createNew");
    createNew.style.visibility = "visible";
    elem[0].append(createNew);
  }

  getEvents() {
    if (this.events.length > 0) {
      this.calendarOptions.initialEvents = this.events;
    } else {
      this.calendarOptions.initialEvents = [];
    }
  }
  navigate(e) {
    let itemType = e.value.name.toLowerCase();
    switch (itemType) {
      case "meeting":
        this.router.navigate([this.navUrl + "scheduling/meeting"]);
        break;
      case "event":
        this.router.navigateByUrl(this.navUrl + "scheduling/event");
        break;
      case "court date":
        this.router.navigateByUrl(this.navUrl + "scheduling/courtdate");
        break;
      case "task":
        this.router.navigateByUrl(this.navUrl + "scheduling/task");
        break;
      case "reminder":
        this.router.navigateByUrl(this.navUrl + "scheduling/reminder");
        break;
      case "default":
        break;
    }
  }

  onCheckboxChange(event, item) {
    this.calendarMode = "self";
    this.mform.patchValue({ name: "" });
    this.otherPeoples = [];
    this.filterApplied = true;
    this.empDetailStatus = "";
    this.otherUserName = "";

    if (this.myCals.length > 0) {
      this.getAllEvents(this.myCals);
    } else {
      this.filterApplied = false;
      this.getAllEvents();
    }
  }

  findOtherPeople(searchTxt) {
    this.getEmployeeDetails(searchTxt);
  }

  navigateToTabView(e) {
    if (e && e.value) {
      let itemType = e.value.type.toLowerCase();
      let url = this.navUrl + "scheduling/tabview";
      if (itemType == "microsoftmeeting") {
        this.meetingId = "";
        this.outlookMeetingModal = true;
        this.editOutlookModal = true;
        this.isNewOutlookTitle = true;
      } else if (itemType == "googlemeeting") {
        this.meetingId = "";
        this.googleNewMeetingModal = true;
        this.editGoogleModal = true;
        this.isNewGoogleTitle = true;
      } else {
        this.router.navigateByUrl(this.navUrl + "scheduling/tabview", {
          state: { calendarItem: itemType },
        });
      }
    }
  }

  closeGoogleMeetingModal() {
    this.googleNewMeetingModal = false;
    this.editGoogleModal = false;
    this.isNewGoogleTitle = false;
    this.calItem.clear(null);
  }

  closeOutlookMeetingModal() {
    this.outlookMeetingModal = false;
    this.editOutlookModal = false;
    this.isNewOutlookTitle = false;
    this.calItem.clear(null);

  }

  forwardMeetingDialog() {
    this.comment = "";
    this.forwardModalShow = !this.forwardModalShow;
  }

  backtoDashboard() {
    this.router.navigate([this.navUrl]);
  }

  reverseString(str) {
    const arrayStrings = str.split("");
    const reverseArray = arrayStrings.reverse();
    const joinArray = reverseArray.join("");
    return joinArray;
  }

  getEmployeeDetails(searchTxt) {
    this.schedulingService
      .getEmployeeDetails(this.companyId, searchTxt)
      .subscribe(
        (response) => {
          if (response.status == 200) {
            this.otherPeoples = response.data;
            this.empDetailStatus = response.status;
          } else {
            this.empDetailStatus = response.status;
            this.empDetailMessage = response.message;
            this.otherPeoples = [];
          }
        },
        (err) => {}
      );
  }

  onCancelEvent($event) {
    this.closeOutlookMeetingModal();
    this.closeGoogleMeetingModal();
    this.getAllEvents();
  }

  // get the calendar events of other user
  getCalendar(event, item) {
    this.otherUserId = item.userId;

    if (event.checked) {
      this.calendarMode = "other";
      this.otherUserName = item.firstName + " " + item.lastName;
      this.otherUserObj = {
        userId: this.otherUserId,
        // isPublic: 0,
        isPublic: null,
        fromDate: this.startDate
          ? this.startDate
          : moment().startOf("month").format("YYYY-MM-DD"),
        toDate: this.endDate
          ? this.endDate
          : moment().endOf("month").format("YYYY-MM-DD"),
        isCalendarView: true,
      };
      this.getAllEvents();
    } else {
      this.calendarMode = "self";
      this.otherUserName = "";
      this.getAllEvents();
    }
  }

  nextMonth() {
    const calendarApi = this.userCalendar.getApi();
  }
  taskcomplete(task: string) {
    if (task === "yes") {
      this.TaskcompletePopup = true;
      this.markTaskcompletePopup = false;
    } else {
      this.router.navigateByUrl(this.navUrl + "scheduling/tabview", {
        state: {
          calendarItem: this.clickedItemType.type,
          elemData: this.clickedItemType.eleData,
        },
      });
    }
  }

  marktaskcomplete() {
    let payload = {
      taskId: this.clickedItemType.eleData.id,
      taskItemType: this.clickedItemType.eleData.calenderItemType,
    };
    this.schedulingService.getTaskComplete(payload).subscribe((res) => {
      if (res.status === 200) {
        this.TaskcompletePopup = false;
        this.markTaskcompletePopup = false;
        this.toaster.success(res.message);
        this.getAllEvents();
      }
    });
  }
  addGoogleEvent() {
    this.addGoogleMeetingModal = true;
  }
  addOutlookEvent() {
    this.addOutlookMeetingModal = true;
  }
  getTrimmedData(data) {
    return data.replace(/['"]+/g, '');
  }

  ngDestroy() {
    alert("destroyed");
    // this.destroy.next();
  }
}
