import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as errorUtils from 'src/app/modules/utility/global-utils';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FAMILYDATA, EMPLOYMENTDATA, UPCOMINGFAMILYDATA, UPCOMINGEMPLOYMENTDATA, DIVERSITYDATA, UPCOMINGDIVERSITYDATA } from '../../../data/constants/visa-bulletin';
import { VisaBulletinService } from './visa-bulletin.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-visa-bulletin',
  templateUrl: './visa-bulletin.component.html',
  styleUrls: ['./visa-bulletin.component.scss']
})
export class VisaBulletinComponent implements OnInit {
  startDate: Date;
  endDate: Date;
  endDateError: boolean = false;
  isPriorityDateTracker: boolean = false;
  confirmDropDatabaseDialogVisible = false;
  showMessageDialog: boolean = false;
  isMultiSelect:boolean =  false;
  maxEndDate: Date = new Date();
  uniqueArray = [];
  index = 0;
  subIndex = 0;
  innerTabIndex = 0;
  form: FormGroup;
  notifyBeneficiaryForm: FormGroup;
  dataSourceFamily: MatTableDataSource<any>;
  displayedColumns = ['sponsored', 'allChargeability', 'china', 'india', 'mexico', 'philippines'];
  familyData: any = [];
  dataSourceEmployment: MatTableDataSource<any>;
  displayedColumnsEmployment = ['sponsored', 'allChargeability', 'china', 'india', 'mexico', 'philippines'];
  employmentData: any = [];
  dataSourceDiversity: MatTableDataSource<any>;
  displayedColumnsDiversity = ['region', 'allChargeability', 'china'];
  diversityData: any = [];
  upComingDataSourceFamily: MatTableDataSource<any>;
  upComingDDisplayedColumns = ['sponsored', 'allChargeability', 'china', 'india', 'mexico', 'philippines'];
  upComingFamilyData: any = [];
  upComingDDataSourceEmployment: MatTableDataSource<any>;
  upComingDDisplayedColumnsEmployment = ['sponsored', 'allChargeability', 'china', 'india', 'mexico', 'philippines'];
  upComingEmploymentData: any = [];
  upcomingDataSourceDiversity: MatTableDataSource<any>;
  upcomingDisplayedColumnsDiversity = ['region', 'allChargeability', 'china'];
  upcomingDiversityData: any = [];
  dataSource: MatTableDataSource<any>;
  totalRecordCount = 0;
  pageIndex: number = 0;
  pageSize: number = 25;
  pageSizeOptions: number[] = [25, 50, 100, 200, 500];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  testdisplayedColumns: string[] = ['name', 'visaType', 'priorityDate'];
  displayedColumns1: string[] = ['select', 'name', 'visaType', 'priorityDate'];
  dataSource1 = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  selectedDownlod: any;
  downloadOptions = [
    { name: 'csv', format: 'CSV', path: 'assets/images/csv_icon.svg', fileName: 'individual-tracker.csv', fileHeaderType: 'text/csv' },
  ];

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private VisaBulletinService: VisaBulletinService, private toastr: ToastrService,) { 
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const dataVal = params['data'];
      if(dataVal==1){
        this.index = 0;
      } else {
        this.index = 1;
      }
    });

    this.onLoadForm();
    this.onLoadSendMsg();
    this.familyData = FAMILYDATA;
    this.employmentData = EMPLOYMENTDATA;
    this.diversityData = DIVERSITYDATA;
    this.upComingFamilyData = UPCOMINGFAMILYDATA;
    this.upComingEmploymentData = UPCOMINGEMPLOYMENTDATA;
    this.upcomingDiversityData = UPCOMINGDIVERSITYDATA;
    this.dataSourceFamily = this.familyData;
    this.dataSourceEmployment = this.employmentData;
    this.dataSourceDiversity = this.diversityData;
    this.upComingDataSourceFamily = this.upComingFamilyData;
    this.upComingDDataSourceEmployment = this.upComingEmploymentData;
    this.upcomingDataSourceDiversity = this.upcomingDiversityData;
  }

  onLoadForm(){
    this.form = this.formBuilder.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required]
    });
  }

  onLoadSendMsg(){
    this.notifyBeneficiaryForm = this.formBuilder.group({
      sendMessage: [null, [Validators.required]],
    });
    
    if(this.uniqueArray.length==1){
      this.isMultiSelect = false;
      this.notifyBeneficiaryForm.controls.sendMessage.setValue(`Your Visatype is ${this.uniqueArray[0].visaType} is under process and the Priority Date is ${this.uniqueArray[0].priorityDate}`);
    } else {
      this.isMultiSelect = true;
      this.notifyBeneficiaryForm.controls.sendMessage.setValue(`Hi <Name of Beneficiary>\n Your Visatype is <Visatype> is under process and the Priority Date is <Priority Date> \n\n Thanks & Regards\n Imagility Team`);
    }
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  validateDates() {
    if (this.startDate && this.endDate && (this.endDate < this.startDate)) {
      this.endDateError = true;
    } else {
      this.endDateError = false;
    }
  }

  checkStatus(data){
    let uId = sessionStorage.getItem('companyId');
    const payload = {
      "pageNumber": this.pageIndex+1,
      "pageSize": this.pageSize,
      "fromDate": moment(this.form.value.startDate).format('YYYY-MM-DD'),
      "toDate": moment(this.form.value.endDate).format('YYYY-MM-DD'),
    };
    this.isPriorityDateTracker = false;
    this.VisaBulletinService.getIndividualTracker(uId, payload).subscribe((res: any) => {
      if(res){
        this.isPriorityDateTracker = true;
        this.dataSource1 = new MatTableDataSource(res['data'].taskList);
        if(data==1){
          this.selection = new SelectionModel<any>(true, []);
        }
        if(this.selection.selected.length>0){
          this.selection.selected.forEach((item)=>{
            this.dataSource1.data.forEach((data)=>{
              if(item.id==data.id){
                this.selection.select(data);
              }
            });
          });
        }
        this.totalRecordCount = res['data'].totalElementCount;
      }
    });
  }
 
  paginationEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.checkStatus(2);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource1.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource1.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  removeDuplicates(array, property) {
    const uniqueMap = {};
    return array.filter((item) => {
      const key = item[property];
      if (!uniqueMap[key]) {
        uniqueMap[key] = true;
        return true;
      }
      return false;
    });
  }

  onClickMessage(){
    this.uniqueArray = this.removeDuplicates(this.selection.selected, 'id');
    this.notifyBeneficiaryForm.controls.sendMessage.setValue(null);
    if(this.uniqueArray.length==0){
      this.toastr.error('Please select any items from the list');
    } else {
      this.onLoadSendMsg();
      this.showMessageDialog = true;
    }
  }

  onExportClicked(selectedDownlodName) {
    const fileName = this.selectedDownlod.fileName;
    const fileHeaderType = this.selectedDownlod.fileHeaderType;
      const payload = {
      "pageNumber": this.pageIndex,
      "pageSize": this.totalRecordCount,
      "fromDate": moment(this.form.value.startDate).format('YYYY-MM-DD'),
      "toDate": moment(this.form.value.endDate).format('YYYY-MM-DD'),
    };
    this.VisaBulletinService.downloadDetails(selectedDownlodName.fileName, payload).subscribe((res: any) => {
      if(res){
        this.selectedDownlod = null;
        const path = res ? environment.docs + res.replace('/var/imagility/uploads/', '') : '';
        this.VisaBulletinService.downloadReport(path, fileName, fileHeaderType); 
      }
    });
  }

  notifyBeneficiaryFormSub(){
    let payloadArr = [];
    if(this.uniqueArray.length==1){
      payloadArr.push({
        individualEmail: this.uniqueArray[0].individualEmail,
        individualName: this.uniqueArray[0].name,
        emailBody: this.notifyBeneficiaryForm.controls.sendMessage.value
      });
    } else {
      this.uniqueArray.forEach(element => {
        payloadArr.push({
          individualEmail: element.individualEmail,
          individualName: element.name,
          emailBody: `Your Visatype is ${element.visaType} is under process and the Priority Date is ${element.priorityDate}`
        });
      });
    }
    this.VisaBulletinService.notifyBeneficiary(payloadArr).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message);
        this.showMessageDialog = false;
      }
    });
  }
}
