import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileUploadLibraryService } from 'file-upload-library';
import { SchedulingService } from 'src/app/services/scheduling.service';
import { EditorComponent } from "@progress/kendo-angular-editor";
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { Observable, Subscription, from, of } from 'rxjs';
import { concatMap, switchMap, takeUntil, toArray } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs'

import { ConfirmationModalComponent } from "src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component";
import { ReferenceLibraryService } from 'src/app/services/referenceLibrary.service';
import { EmailClientService } from '../../email-client/service/email-client.service';
import { GoogleApiService } from '../google-api.service';

interface City {
  name: string,
  code: string
}

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss']
})
export class EditEventComponent implements OnInit {
  @Output() removeElement: EventEmitter<number> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();
  @Input('obj') eventEditObj;
  @Input('meetingId') meetingId!: any;
  @Input('meetingBy') meetingBy: any;
  @ViewChild('eventpageDiv') eventpageDiv: ElementRef<HTMLElement>;
  obs: Subscription;
  eventsForm: FormGroup;
  cities: any = [];
  userType: string;
  recurrencedropdown: any = [];
  recurrence: City;
  showRecurrnece: boolean = false;
  timedropdown: any = [];
  endTime: City;
  startTime: City;
  privateMeeting: string;
  publicMeeting: string;
  addAttendes: boolean = false;
  navUrl: any;
  selectedRecurrence: '';
  eventAttendes: any;
  filteredeventAttendes: any;
  filtered: any;
  UserToken: string;
  calendarItemId: any;
  series: any;
  uploadedFile: any;
  public payload = new FormData();
  uploadedFiles: any = [];
  eventEditDetails: any;
  eventDoc: any = [];
  required: boolean;
  optional: boolean;
  empNotfound: boolean;
  optEmail: any = [];
  requiredEmails: any = [];
  calenscheduleId: any;
  optImgEmail: any = [];
  requiredImgEmails: any = [];
  companyId: any;
  minimumDate: any;
  eventpageLoaded: boolean = false;
  observableSubscription$ = new Subject();
  isPublic: any;
  eventdocId: any;
  selectedWeeks: string[] = [];
  recurrences: any[] = [];
  scheduleType: string = '';
  subrecurrenceType: any;
  hideDate: boolean;
  durationList: any[] = [{ 'type': 'WEEK', 'code': 'WEEK' },
  { 'type': 'BI-WEEK', 'code': 'BIWEEK' },
  { 'type': 'MONTH', 'code': 'MONTH' }, { 'type': 'YEAR', 'code': 'YEAR' }];

  daysList: any[] = [
    { 'value': 1, 'day': 'MONDAY' },
    { 'value': 2, 'day': 'TUESDAY' },
    { 'value': 3, 'day': 'WEDNESDAY' },
    { 'value': 4, 'day': 'THURSDAY' },
    { 'value': 5, 'day': 'FRIDAY' },
    { 'value': 6, 'day': 'SATURDAY' },
    { 'value': 7, 'day': 'SUNDAY' }];
  customRecurrence: any = [
    { "name": "days", "code": "DAILY" },
    { "name": "weeks", "code": "WEEKLY" },
    { "name": "months", "code": "MONTHLY" },
    { "name": "years", "code": "YEARLY" }
  ]
  weeKnumber: any = [
    { "name": "First", "code": 1 },
    { "name": "Second", "code": 2 },
    { "name": "Third", "code": 3 },
    { "name": "Fourth", "code": 4 },
    { "name": "Fifth", "code": 5 }
  ]
  loading = false;
  alreadyStoredFiles: any[] = [];
  attachFileList: any[] = [];
  uploadedFiles$: Observable<any>;
  acceptedFiles: string = ".pdf, .jpg, .png, .doc, .docx, .xls, .xlsx, .csv";

  constructor(private router: Router,
    private fb: FormBuilder,
    private schedulingservice: SchedulingService,
    private emailClientService: EmailClientService,
    private readonly googleApi: GoogleApiService,
    private toastr: ToastrService,
    private reflibrarayservice: ReferenceLibraryService,
    public dialog: MatDialog,
    private confirmationService: ConfirmationService,) {
    this.companyId = sessionStorage.getItem('companyId');
    this.eventsForm = this.fb.group({
      subject: ['', Validators.required],
      attendiesList: [''],
      calItemTypeCode: ['EVENT'],
      // calendarItemId: ['0'],
      // calendarScheduleId: ['0'],
      startTime: ['', Validators.required],
      endDate: ['', Validators.required],
      endTime: ['', Validators.required],
      startDate: ['', Validators.required],
      // isAllday: 0,
      // isPublic: [],
      // isSeries: 0,
      recuurenceType: ['', Validators.required],
      dayOfMonth: [''],
      selectedWeeks: [],
      monthOfYear: [''],
      custommonthOfYear: [''],
      customdayOfMonth: [''],
      customWeekNo: [''],
      customrecuurenceType: [''],
      customselectedWeeks: [],
      customday: [''],
      customInterval: [''],
      // meetingCompanyName: [''],
      meetingLocation: ['', Validators.required],
      description: [''],
      reqattendiesList: ['', Validators.required],
      optimagilityattendiesList: [''],
      optattendiesList: [''],
      reqImagilityattendiesList: ['']
    });
    this.userType = sessionStorage.getItem("userTypeRole");
    if (this.userType == "Petitioner") {
      this.navUrl = "/petitioner-landing/dashboard/petitioner-dashboard/scheduling/";
    } else if (this.userType == "Attorney") {
      this.navUrl = "/attorney-landing/dashboard/attorney-dashboard/scheduling/";
    }
    this.reflibrarayservice.getReferenceData('CALRECURTYP').subscribe(res => {     
      this.recurrencedropdown = res.filter(value => (value.code != 'CUSTOM' && value.code != 'NOREPEAT' && value.code != 'BIWEEKLY' && value.code != 'WEEKDAY'));
      // this.recurrencedropdown[1].name = 'Daily(EveryDay)'
    })
    this.minimumDate = new Date();
    this.eventpageLoaded = true;
  }



  ngOnInit(): void {
    this.UserToken = sessionStorage.getItem('UserToken');
    if (this.meetingId) {
      this.getOutLookMeetingDetails();
    }
  }

  getOutLookMeetingDetails() {
    this.emailClientService.getMeetingDetails(this.meetingId).subscribe((result: any) => {     
      let requiedAttendee = [];
      let optionalAttendee = [];
      result.attendees.forEach((value) => {
        if (value.type = "required") {
          requiedAttendee.push(value.emailAddress.address)
        }
      })
      result.attendees.forEach((value) => {
        if (value.type = "optional") {
          optionalAttendee.push(value.emailAddress.address)
        }
      })
      if (result.hasAttachments == true) {
        this.getAttachment(result.id);
      }
      let recurrenceType = result.recurrence.pattern.type;
      if (recurrenceType == 'absoluteYearly') {
        recurrenceType = 'YEARLY';
      }
      else if (recurrenceType == 'absoluteMonthly') {
        recurrenceType = 'MONTHLY';
      }
      else if (recurrenceType == 'daily') {
        recurrenceType = 'DAILY';
      }
      else if (recurrenceType == 'weekly') {
        recurrenceType = 'WEEKLY';
      }


      this.eventsForm.patchValue({
        subject: result.subject,
        description: result.body.content,
        requiredAttendiesList: [[]],
        optionalAttendiesList: [[]],
        meetingLocation: result.location.displayName,
        startTime: moment.utc(result.start.dateTime).local().format('HH:mm'),
        endTime: moment.utc(result.end.dateTime).local().format('HH:mm'),
        startDate: moment.utc(result.start.dateTime).local().format('MM/DD/YYYY'),
        endDate: moment.utc(result.recurrence.range.endDate).local().format('MM/DD/YYYY'),
        recuurenceType: recurrenceType,
        reqattendiesList: requiedAttendee,
        optattendiesList: optionalAttendee,
        dayOfMonth: result.recurrence.pattern.dayOfMonth,
        selectedWeeks: result.recurrence.pattern.daysOfWeek ? result.recurrence.pattern.daysOfWeek.map(day => day.toUpperCase()) : [],
        monthOfYear: result.recurrence.pattern.month + "/" + result.recurrence.pattern.dayOfMonth
      })

      this.selectedWeeks = result.recurrence.pattern.daysOfWeek ? result.recurrence.pattern.daysOfWeek.map(day => day.toUpperCase()) : [];
      let recType = result.recurrence.pattern.type
      this.scheduleType = recType.toUpperCase();
    });
  }


  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
      this.payload.append("file", this.uploadedFiles, this.uploadedFile.fileName);
    }
  }

  saveOutlookMeeting(formData) {
    let startDate = moment(this.eventsForm.value.startDate).format('YYYY-MM-DD') + 'T' + this.eventsForm.value.startTime;
    let endDate = moment(this.eventsForm.value.startDate).format('YYYY-MM-DD') + 'T' + this.eventsForm.value.endTime;
    let requiredEmailsObj = [];
    if (this.eventsForm.value.reqImagilityattendiesList && this.eventsForm.value.reqImagilityattendiesList.length > 0) {
      this.eventsForm.value.reqImagilityattendiesList.forEach(reqAttendies => {
        requiredEmailsObj.push({
          "emailAddress": {
            "address": reqAttendies.emailId,
            "name": reqAttendies.name,
          },
          "type": "required"
        })
      })
    }
    if (this.eventsForm.value.reqattendiesList && this.eventsForm.value.reqattendiesList.length > 0) {
      this.eventsForm.value.reqattendiesList.forEach(reqAttendies => {
        const index = requiredEmailsObj.findIndex(emailObj => emailObj.emailAddress.address == reqAttendies);
        if (index == -1) {
          requiredEmailsObj.push({
            "emailAddress": {
              "address": reqAttendies,
              "name": "",
            },
            "type": "required"
          })
        }
      })
    }
    let pattern = this.getRecurrencePattern(this.eventsForm.value)
    let outlookMessageObject = {
      "subject": this.eventsForm.value.subject,
      "body": {
        "contentType": "html",
        "content": this.eventsForm.value.description
      },
      "start": {
        "dateTime": moment(startDate, "YYYY-MM-DDTHH:mm").utc(),
        "timeZone": "UTC"
      },
      "end": {
        "dateTime": moment(endDate, "YYYY-MM-DDTHH:mm").utc(),
        "timeZone": "UTC"
      },
      "recurrence": {
        "pattern": {
          ...pattern
        },
        "range": {
          "type": "endDate",
          "startDate": moment(this.eventsForm.value.startDate).format('YYYY-MM-DD'),
          "endDate": moment(this.eventsForm.value.endDate).format('YYYY-MM-DD')
        }
      },
      "location": {
        "displayName": this.eventsForm.value.meetingLocation
      },
      "attendees": requiredEmailsObj,
      // "attendees":[
      //   {
      //     "emailAddress": {
      //       "address":'shelarrahul14@gmail.com',
      //       "name": 'Rahul Shelar',
      //     },
      //     "type": "required"
      //   }
      // ],
      "allowNewTimeProposals": true
    }

    if (this.meetingId) {
      this.saveAttachment().then((result) => {
        this.emailClientService.updateEvents(this.meetingId, outlookMessageObject).subscribe(response => {
          this.toastr.success("Outlook Meeting updated successfuly");
          this.cancelEvent.emit();
        },
          error => {           
            this.toastr.error("Failed to update outlook meeting");
          })        
      })
    }
    else {
      this.emailClientService.createEvents(outlookMessageObject).subscribe(response => {
        this.meetingId = response.body['id'];
        if (response.ok == true) {
          this.saveAttachment().then((result) => {
            this.toastr.success("Outlook Meeting addeed successfuly");
            this.cancelEvent.emit();
          })
        }
      },
        error => {          
          this.toastr.error("Failed to create outlook meeting");
        })
    }
  }

  
  saveGoogleMeeting() {
    let googleCalendarId = JSON.parse(localStorage.getItem('selectedGoogleCalendars'))[0];
    let startDate = moment(this.eventsForm.value.startDate).format('YYYY-MM-DD') + 'T' + this.eventsForm.value.startTime;
    let endDate = moment(this.eventsForm.value.startDate).format('YYYY-MM-DD') + 'T' + this.eventsForm.value.endTime;
    let requiredEmailsObj = [];
    if (this.eventsForm.value.reqImagilityattendiesList && this.eventsForm.value.reqImagilityattendiesList.length > 0) {
      this.eventsForm.value.reqImagilityattendiesList.forEach(reqAttendies => {
        requiredEmailsObj.push({'email': reqAttendies.emailId})
      })
    }
    if (this.eventsForm.value.reqattendiesList && this.eventsForm.value.reqattendiesList.length > 0) {
      this.eventsForm.value.reqattendiesList.forEach(reqAttendies => {
        const index = requiredEmailsObj.findIndex(emailObj => emailObj?.emailAddress?.address == reqAttendies);
        if (index == -1) {
          requiredEmailsObj.push({'email': reqAttendies});
        }
      })
    }
    let pattern = [this.getRecurrenceRule(this.eventsForm.value)]
    let googleMessageObject = {
      "summary": this.eventsForm.value.subject,
      "description": this.eventsForm.value.description,
      "start": {
        "dateTime": moment(startDate, "YYYY-MM-DDTHH:mm").utc(),
        "timeZone": "UTC"
      },
      "end": {
        "dateTime": moment(endDate, "YYYY-MM-DDTHH:mm").utc(),
        "timeZone": "UTC"
      },
      "recurrence": pattern,
      "location": this.eventsForm.value.meetingLocation,
      "attendees": requiredEmailsObj,
      "allowNewTimeProposals": true,
      "sendUpdates": 'all',
      "sendNotifications": true
    }

   
      this.googleApi.createEvent(googleCalendarId, googleMessageObject).subscribe(response => {
        this.meetingId = response['id'];
        if (response.status == 'confirmed') {
            this.toastr.success("Google Meeting addeed successfuly");
            this.eventsForm.reset();
            this.cancelEvent.emit();
        }
      },
        error => {          
          this.toastr.error("Failed to create Google meeting");
        })
  }

  saveAttachment() {
    return new Promise((resolve, reject) => {
      if (this.attachFileList && this.attachFileList.length > 0) {       
        from(this.attachFileList).pipe(concatMap((file: any) => {
          return this.emailClientService.addOutlookAttachment(this.meetingId, file)
        }), toArray())
          .subscribe((attachments: any) => {   
            resolve('File uploaded successfuly');
          },
            (error) => {              
              this.toastr.error("Failed to upload outlook attachment");
              reject('Failed to upload outlook attachment');
            })
      } else {
        resolve('No File to upload');
      }
    })
  }

  getAttachment(id: string) {
    this.emailClientService
      .getOutlookAttachment(id)
      .subscribe((attachments) => {       
        for (let attachment of attachments.value) {
          this.alreadyStoredFiles.push({
            "@odata.type": "#microsoft.graph.fileAttachment",
            name: attachment["name"],
            contentType: attachment["contentType"],
            contentBytes: attachment["contentBytes"],
          });
        }
      });
  }

  getRecurrenceRule(meetingFormData: any) {

    if (meetingFormData.recuurenceType == "MONTHLY") {
    return `RRULE:FREQ=${meetingFormData.recuurenceType};INTERVAL=${meetingFormData.customInterval?meetingFormData.customInterval:1};bymonthday=${meetingFormData.dayOfMonth}`
    }else if (meetingFormData.recuurenceType == "WEEKLY") {
      let BYDAY = [];
      meetingFormData.selectedWeeks.forEach(element => {
        BYDAY.push(element.slice(0, 2))
      });
      return `RRULE:FREQ=${meetingFormData.recuurenceType};INTERVAL=${meetingFormData.customInterval?meetingFormData.customInterval:1};WKST=MO;BYDAY=${BYDAY}`
  }else if (meetingFormData.recuurenceType == "DAILY") {
    return `RRULE:FREQ=${meetingFormData.recuurenceType};INTERVAL=1`
  }
  }
  getRecurrencePattern(meetingFormData: any) {
    let pattern = {};
    if (meetingFormData.recuurenceType == "MONTHLY") {
      return {
        "type": "absoluteMonthly",
        "interval": 1,
        "dayOfMonth": meetingFormData.dayOfMonth
      }
    }
    else if (meetingFormData.recuurenceType == "YEARLY") {
      let YearRecDay = meetingFormData?.monthOfYear?.getDate();
      let YearRecMonth = meetingFormData?.monthOfYear?.getMonth() + 1;
      return {
        "type": "absoluteYearly",
        "interval": 1,
        "dayOfMonth": YearRecDay,
        "month": YearRecMonth
      }
    }
    else if (meetingFormData.recuurenceType == "WEEKLY") {
      return {
        "type": "weekly",
        "interval": 1,
        "daysOfWeek": meetingFormData.selectedWeeks.length ? meetingFormData.selectedWeeks : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
        "firstDayOfWeek": "monday"
      }
    }
    else if (meetingFormData.recuurenceType == "WEEKDAY") {
      return {
        "type": "weekly",
        "interval": 1,
        "daysOfWeek": ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
        "firstDayOfWeek": "monday"
      }
    }
    // else if(meetingFormData.recuurenceType =="BIWEEKLY"){
    //   return  {
    //       "type": "weekly",
    //       "interval": 2,
    //       "daysOfWeek":['MONDAY','TUESDAY'],
    //       "firstDayOfWeek":"MONDAY"
    //   }
    // }
    else if (meetingFormData.recuurenceType == "DAILY") {
      return {
        "type": "daily",
        "interval": 1,
      }
    }
    else if (meetingFormData.recuurenceType == "CUSTOM") {
      if (meetingFormData.customrecuurenceType == "DAILY") {
        return {
          "type": "daily",
          "interval": meetingFormData.customInterval // 1,2, or 5 
        }
      }
      else if (meetingFormData.customrecuurenceType == "WEEKLY") {
        return {
          "type": "weekly",
          "daysOfWeek": meetingFormData.customselectedWeeks,
          "interval": meetingFormData.customInterval  // check again
        }
      }
      else if (meetingFormData.customrecuurenceType == "MONTHLY") {
        return {
          "type": "relativeMonthly",
          "daysOfWeek": [meetingFormData.customday],
          "index": "second", //make dynamic later ealier it 
          "interval": meetingFormData.customInterval  // check again
        }
      }
      else if (meetingFormData.customrecuurenceType == "YEARLY") {
        let YearRecDay = meetingFormData?.custommonthOfYear?.getDate()
        let YearRecMonth = meetingFormData?.custommonthOfYear?.getMonth() + 1
        return {
          "type": "absoluteYearly",
          "interval": meetingFormData.customInterval,
          "dayOfMonth": YearRecDay,
          "month": YearRecMonth,
        }
      }
    }
  }

  save() {
    let eventObj = this.eventsForm.value;
    this.saveOutlookMeeting(this.eventsForm.value);    
  }


  closeEmailpopup() {
    this.required = false;
    this.optional = false;
  }

  serachAttendes(event) {
    let query = event.query;
    this.schedulingservice.getAttendesList1(this.UserToken, query, this.companyId).subscribe(res => {
      this.eventAttendes = res['data'];
      if (res.status == 204) {
        this.empNotfound = true;
      }
      if (this.eventAttendes && this.eventAttendes.length) {
        this.empNotfound = false;
        this.filtered = this.eventAttendes.map(element => {
          var attendesObj = {
            "name": element.firstName + ' ' + element.lastName,
            "emailId": element.email,
            "userId": element.userId,
            "isOptional": 0,

          }
          return attendesObj;
        })
        this.filteredeventAttendes = this.filtered;
      }
    },
      error => {
        console.log(error)
      }
    )
  }



  cancel() {
    let cancelpayload = {
      "Comment": "This meeting is cancelled."
    }
    this.emailClientService.cancelEvents(this.meetingId, cancelpayload).subscribe(res => {
      if (res) {
        this.toastr.success("Meeting is cancelled");
        this.cancelEvent.emit();
      }
    })
  }

  eventcancel() {
    this.cancelEvent.emit();
  }

  addImagilityEmail() {
    this.optional = true;
    this.eventsForm.patchValue({
      optimagilityattendiesList: null
    });
    this.requiredImgEmails = [];
  }

  requiredImagilityEmail() {
    this.required = true;
    // this.eventsForm.patchValue({
    //   reqImagilityattendiesList: null
    // });
    // this.optImgEmail = [];
  }

  addEmail() {
    let reuiredemails = this.eventsForm.value.reqImagilityattendiesList.map(val => val.emailId)
    this.requiredEmails = [...this.eventsForm.value.reqattendiesList, ...reuiredemails]
    this.requiredEmails = [...new Set(this.requiredEmails)]

  }
  addoptEmail() {
    let optionalemails = this.eventsForm.value.optimagilityattendiesList.map(val => val.emailId);
    this.optEmail = [...this.eventsForm.value.optattendiesList, ...optionalemails];
    this.optEmail = [...new Set(this.optEmail)]
  }

  checkEndDateTouched(sd) {
    if (sd.value) {
      this.eventsForm.patchValue({
        endDate: sd.value
      })
    }
  }

  confirmDelete(uploadedFile) {
    this.deleteDoc(uploadedFile);
  }

  deleteDoc(item) {
    const title = 'Confirm';
    const message = 'Do you wish to Delete the File?';
    if (item) {
      this.dialogueInitializer(event, title, message).afterClosed().subscribe(result => {
        if (result) {
          this.schedulingservice.deleteEventDocument(this.eventdocId).subscribe((data: any) => {
            if (data['status'] === 200) {
              this.toastr.success(data.message);
              this.schedulingservice.getEventDoc(this.eventEditDetails.parentId).subscribe(res => {
                this.eventDoc = res['data'];
              })
            }
            this.removeElement.emit(item);
          })
        }
      })
    }
  }

  dialogueInitializer(event, title: string, message: string) {
    const cordinates: MouseEventInit = event;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: ['Cancel', 'Delete']
      }
    });
    return dialogRef;
  }

  onChangeWeekDays(day, event) {
    if (event.checked) {
      console.log('days', day)
    }
    else {
      var index = this.recurrences.findIndex(item => item.dayOfWeek === day.value);
      this.recurrences.splice(index, 1);
    }
  }


  recurrenceTypechanged(ev) {
    this.hideDate = true;
    this.scheduleType = ev.value;
    this.onChangeScheduleType(ev.value);
    // if(this.scheduleType === 'CUSTOM'){
    //   this.custompopup = true;
    // }
    if (this.scheduleType === 'NOREPEAT') {
      this.hideDate = false;
    }
  }

  customrecurrenceTypechanged(ev) {   
    this.subrecurrenceType = ev.value;
    this.onChangecustomScheduleType(ev.value);
  }

  onweekNochanged(ev) {
    console.log('weekno', ev.value)

  }

  onChangeScheduleType(revurrencetype: any) {
    switch (revurrencetype) {
      case 'DAILY':
        this.resetDurationValues();
        break;
      case 'WEEKLY':
        this.resetDurationValues();
        break;
      case 'MONTHLY':
        this.resetDurationValues();
        break;
      case 'YEARLY':
        this.resetDurationValues();
        break;
      case 'NOREPEAT':
        this.resetDurationValues();
        break;
      case 'BIWEEKLY':
        this.resetDurationValues();
        break;
      case 'CUSTOM':
        this.resetDurationValues();
    }
  }

  onChangecustomScheduleType(revurrencetype: any) {
    switch (revurrencetype) {
      case 'DAILY':
        this.resetCustomValue();
        break;
      case 'WEEKLY':
        this.resetCustomValue();
        break;
      case 'MONTHLY':
        this.resetCustomValue();
        break;
      case 'YEARLY':
        this.resetCustomValue();
        break;
    }
  }

  resetCustomValue() {
    this.eventsForm.value.customInterval.setValue(null);
    this.eventsForm.value.customrecuurenceType.setValue(null);
    this.eventsForm.value.customselectedWeeks.setValue(null);
    this.eventsForm.value.customdayOfMonth.setValue(null);
    this.eventsForm.value.customWeekNo.setValue(null);
  }

  resetDurationValues() {
    this.eventsForm.get('dayOfMonth').setValue(null);
    this.eventsForm.get('selectedWeeks').setValue(null);
    this.eventsForm.get('monthOfYear').setValue(null);
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  decodeBase64(file: any) {
    const linkSource = 'data:application/octet-stream;base64,' + file.contentBytes;
    const downloadLink = document.createElement('a');
    const fileName = file.name;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  Upload(event, fileUpload) {
    fileUpload.clear();
    const fileArr = Array.from(event.files);
    fileArr.forEach((file: any) => {
      this.getBase64(file).then((reader: string) => {
        this.attachFileList.push({
          "@odata.type": "#microsoft.graph.fileAttachment",
          name: file.name,
          contentType: file.type,
          contentBytes: reader.replace("data:", "").replace(/^.+,/, ""),
        });
      });
    });
    this.uploadedFiles$ = of(this.attachFileList);
  }

  deleteFile(index: number) {
    this.uploadedFiles$.subscribe((attachmentData) => {
      attachmentData.splice(index, 1);
      this.uploadedFiles$ = of(attachmentData);
      this.attachFileList = attachmentData;
    });
  }


}
