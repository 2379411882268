import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { SubTask, TaskStep } from 'app-models';
import moment from 'moment';
import { Router } from '@angular/router';
import { layout_code } from 'src/app/data/constants/layout-code';
import * as RolesRouteMap from '../../../data/constants/roles';
import { PetitionBuilderService } from 'src/app/services/petition-builder.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { stepStatus } from '../../../data/constants/step-status';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { HelpVideoOverlayComponent } from '../../shared/help-video-overlay/help-video-overlay.component';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';
import { ImagilityBaseResponse } from 'src/app/data/models/response.model';
import { GCI140State } from '../../petition-builder/store';
import { loadPetitionSubTaskDetails } from '../../petition-builder/store/actions/sub-task-details.actions';
import * as Task from '../../superTask/task-state/actions/task.actions';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})

export class TimelineComponent implements OnInit, OnChanges, AfterViewInit {

  stepList: TaskStep[]; // Holding step list passed by pa rent
  steps: TaskStep[];
  petitionType = '';
  visaType = '';
  immigrantType = '';
  userType = '';
  taskTypeCode = '';
  toolTipCustomClass: any;
  superTaskTypeCode = '';
  stepActionList = [];
  stepValue;
  userTypeActionable = false;
  isCreateAndEditPetition = false;
  @Input() taskType = '';  // create/edit
  @Input() timeLineType = '';  // petition/bene
  @Input() taskObj: TaskStep[];
  @Input() taskId: number;
  // @Input() selectedTaskType = "NONIMMPET";
  @Input() selectedTaskType;
  @Input() immigrationCategory;
  @Input() immigrationClassification;
  @Input() taskTypeDetails = null;
  @Input() petitionTypes = [];
  @Input() visaTypes = [];
  @Input() immigrationTypes = [];
  @Input() petitionId = '';
  @Input() userName = '';
  @Input() noticeNumber = '';
  @Input() beneficiaryConfirmationNumber = '';
  @Input() isOutsideImagility;
  @Input() superTaskId;
  @Input() receiptETADateRange;
  @Input() decisionETADateRange;
  @Input() loadingFromOnChanges = false;
  @Input() stepsCompanyId
  taskNumber: number;
  rfeDialogDisplay = false;
  stepName;
  stepName1 = new Subject();
  parentStepId;
  rfeTimelineModalInput = [];
  showNotifyBeneAckContent = false;
  companyId: number;
  @ViewChild(HelpVideoOverlayComponent, { static: false }) helpVideoComponent: HelpVideoOverlayComponent;

  @Output() responseAction: EventEmitter<any> = new EventEmitter(); // actions to be happened

  filter: any;
  @Input() dashboardTimeline = false;

  constructor(
    private petitionerService: PetitionerService,
    private apiService: PetitionBuilderService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private dashboardWidgetsService: DashboardWidgetsService,
    private store: Store<fromTask.State>,
    private pbStore: Store<GCI140State>,
    private toastr: ToastrService
  ) {
    this.stepList = [];
    this.userType = sessionStorage.getItem('userTypeRole');

  }

  ngOnInit() {
    this.isCreateAndEditPetition = false;
    this.taskNumber = this.taskId;
    this.authenticationService.currentUser.subscribe((data) => {
      data = data || { permissions: [] };
      if (data['permissions'].length > 0) {
        data['permissions'].forEach(el => {
          if (el === 'EDIT_PETITION') {
            this.isCreateAndEditPetition = true;
          }
        });
      }
    });
    if (!this.taskObj || (this.taskObj && this.taskObj.length === 0)) {
      this.petitionerService.taskFilters$.subscribe(filter => {
        this.filter = filter;
        this.getSteps();
      });
    }
  }

  ngAfterViewInit() {
    if (this.selectedTaskType === 'RFE') {
      if (this.userType !== 'Beneficiary') {
        this.helpVideoComponent.setTabValues('right', 'Building an RFE Response', 'Part 1: Building an RFE Response', 'Part 1: Building an RFE Response',
          'right', 'Part 2: Building an RFE Response', 'Part 2: Building an RFE Response'
        );
      }
    }
    else {
      if (this.userType !== 'Beneficiary') {
        this.helpVideoComponent.setValues('right', 'Next steps after Tasks creation', 'Next steps after Tasks creation');
      } else {
        this.helpVideoComponent.setValues('right', 'Using Timelines and Notifications for Beneficiary', 'Using Timelines and Notifications for Beneficiary');
      }
    }

  }

  getSteps() {
    this.taskObj = [];
    //  if(this.selectedTaskType == "NONIMMPET"){
    if (this.selectedTaskType && this.selectedTaskType === 'PETITION' && this.filter && this.filter.petitionType &&
      this.filter.visaType && this.filter.immigrantType && this.filter.taskTypeCode) {
      this.petitionType = this.filter.petitionType;
      this.visaType = this.filter.visaType;
      this.immigrantType = this.filter.immigrantType;
      this.taskTypeCode = this.filter.taskTypeCode;
      this.store.select(fromTask.getPetitionSubTask)
        .subscribe((getPetitionSubTask: SubTask) => {
          const SubTaskOverView = getPetitionSubTask;
          this.taskId = SubTaskOverView?.id;
          this.superTaskTypeCode = SubTaskOverView?.superTaskTypeCode;
          const taskId = SubTaskOverView?.id;
          if (taskId != null) {
            this.store.dispatch(new Task.SetActiveSubTaskIndex(taskId));
            this.petitionerService.getTask(taskId).subscribe(taskDetails => {
              if (taskDetails) {
                this.petitionType = taskDetails.taskTypeDetails.petitionType;
                this.visaType = taskDetails.taskTypeDetails.visaType;
                this.immigrantType = taskDetails.taskTypeDetails.immigrationType;
                this.taskTypeCode = SubTaskOverView.taskTypeCode;
                if (this.petitionType && this.visaType && this.immigrantType) {
                  this.petitionerService.getTimelineSteps(this.stepsCompanyId, this.petitionType, this.visaType, this.immigrantType, this.taskTypeCode)
                    .subscribe((data: Array<TaskStep>) => {
                      this.taskObj = data;
                      this.processTaskSteps();
                    });
                }
              }
            });
          } else if (this.petitionType && this.visaType && this.immigrantType) {
            this.petitionerService.getTimelineSteps(this.stepsCompanyId, this.petitionType, this.visaType,
              this.immigrantType, this.taskTypeCode).subscribe((data: Array<TaskStep>) => {
                this.taskObj = data;
                this.processTaskSteps();
              });
          }
        });
    } else if (this.selectedTaskType === 'RFE' || this.selectedTaskType === 'PERM' || this.selectedTaskType === 'I140') {
      this.petitionerService.getTimelineStepsForRFE(this.selectedTaskType).subscribe((data: Array<TaskStep>) => {
        this.taskObj = data;
        this.processTaskSteps();
      });
    } else if (this.selectedTaskType === 'I130' && this.immigrationCategory && this.immigrationClassification) {
      const payload = {
        immigrationCategory: this.immigrationCategory,
        immigrationClassification: this.immigrationClassification,
        immigrationType: '',
        parentStepId: 0,
        petitionType: '',
        taskType: this.selectedTaskType,
        visaType: ''
      };
      this.petitionerService.getTimelineStepsI130(this.selectedTaskType, payload).subscribe((data: Array<TaskStep>) => {
        this.taskObj = (data && data['stepList']) ? data['stepList'] : [];
        this.processTaskSteps();
      });
    }
  }

  processTaskSteps() {
    if (this.taskObj && this.taskObj.length > 0) {
      this.taskObj.forEach((item) => {
        item.isVisible = (this.userType === 'Attorney' && item.isAttorneyVisible === 1) ||
          (this.userType === 'Beneficiary' && item.isBeneficiaryVisible === 1) ||
          (this.userType === 'Petitioner' && item.isPetitionerVisible === 1);
        item.isEditable = (this.userType === 'Attorney' && item.isAttorneyEditable === 1 &&
          item.isAttorneyAction === 1) ||
          (this.userType === 'Beneficiary' && item.isBeneficiaryEditable === 1 &&
            item.isBeneficiaryAction === 1) ||
          (this.userType === 'Petitioner' && item.isPetitionerEditable === 1 &&
            item.isPetitionerAction === 1);
        if (item.stepName === 'Wait for USCIS Decision') {
          switch (item['USCISResponse']) {
            case 'APPROVED':
              item['USCISResponseClass'] = 'approved';
              break;
            case 'RFE':
            case 'NOID':
              item['USCISResponseClass'] = 'rfenoid';
              break;
            case 'DENIED':
              item['USCISResponseClass'] = 'denied';
              break;
            case 'PETWITHDRAW':
              item['USCISResponseClass'] = 'petwithdraw';
              break;
            case 'AWAITDEC':
            default:
              item['USCISResponseClass'] = 'awaitdec';
              break;
          }
        }
        if (item.stepName === 'Provide Additional Details (Petitioner Action)') {
          if (this.userType === 'Attorney') {
            item.isBeneficiaryAction = 1;
            item.isPetitionerAction = 0;
          }
        }
      }
      );

    }
  }

  ngOnChanges(e: SimpleChanges) {
    if (e.taskObj && e.taskObj.currentValue) {
      this.processTaskSteps();
    }
    if (e.taskId && e.taskId.currentValue) {
      this.taskId = e.taskId.currentValue;
    }
    if (!this.loadingFromOnChanges) {
      if (e.selectedTaskType && e.selectedTaskType.currentValue) {
        this.selectedTaskType = e.selectedTaskType.currentValue;
        this.getSteps();
      }
      if (e.immigrationCategory || e.immigrationClassification) {
        this.getSteps();
      }
    }
  }

  getStepStatusUpdate(item) {
    this.stepValue = item;
    this.stepActionList = this.stepValue['stepActionList'];
    this.userTypeActionable = (this.userType === 'Beneficiary' && this.stepValue['isBeneficiaryAction'] == 0) ? false : (this.userType === 'Attorney' && this.stepValue['isAttorneyAction'] == 0) ? false : (this.userType === 'Petitioner' && this.stepValue['isPetitionerAction'] == 0) ? false : true;
    if (this.dashboardTimeline) {
      this.getStepStatusUpdateModal(item);
    } else {
      if (item.stepStatusDetails.stepStatusCode === stepStatus.New &&
        layout_code[this.stepActionList[0].layoutCode] &&
        this.userTypeActionable) {
        if (this.stepActionList[0].layoutCode === 'REVIEW_DOCS') {
          const statusUpdate = {
            statusCode: '',
            stepNote: ''
          };
          const stepStatusCode = 'REVIEW_DOCS';
          statusUpdate.statusCode = stepStatus.Inprogress;
          this.apiService.saveReviewer(parseInt(sessionStorage.getItem('petitionId')), stepStatusCode, statusUpdate).subscribe((response) => {
            if (response.status === 200) {
              // if(layout_code[this.stepActionList[0].layoutCode] != "task")
              //  this.navigateActions(item, `${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/${this.superTaskId}/petition/${layout_code[this.stepActionList[0].layoutCode]}`);
              // else
              this.store.dispatch(new Task.SetTaskRouteId(this.superTaskId));
              this.navigateActions(item, `${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details`);
            }
          });
        }
        else {
          if (this.stepActionList && this.stepActionList.length > 0 && layout_code[this.stepActionList[0].layoutCode]) {
            // if(layout_code[this.stepActionList[0].layoutCode] != "task")
            //   this.navigateActions(item, `${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/${this.superTaskId}/${layout_code[this.stepActionList[0].layoutCode]}`);
            // else
            // console.log("route",layout_code[this.stepActionList[0].layoutCode]);
            this.store.dispatch(new Task.SetTaskRouteId(this.superTaskId));
            this.navigateActions(item, `${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/petition/${layout_code[this.stepActionList[0].layoutCode]}`);

          }
        }
      }
      else {
        this.getStepStatusUpdateModal(item);
      }
    }
  }

  navigateActions(item, routePath) {
    if (!this.petitionId && this.selectedTaskType !== 'I130') {
      const payload = {
        employeeId: parseInt(sessionStorage.getItem('employeeId')),
        immigrationType: this.immigrationTypes.find(x => x.code === this.taskTypeDetails.immigrationType).id,
        visaType: this.visaTypes.find(x => x.code === this.taskTypeDetails.visaType).id,
        taskId: this.stepValue['taskId']
      };
      if (this.isCreateAndEditPetition) {
        this.apiService.createPetition(
          this.stepValue['companyId'], this.stepValue['beneficiaryId'],
          this.petitionTypes.find(x => x.code === this.taskTypeDetails.petitionType).id,
          payload)
          .subscribe((response) => {
            if (response) {
              // New petition is created and updating the step to inprogress
              // this.updateStepStatus(item);
              this.store.dispatch(loadPetitionSubTaskDetails({ taskId: this.taskId }));
              sessionStorage.setItem('petitionId', response['PetitionId']);
              this.router.navigate([`${routePath}`]);
            }
          });
      }
    } else {
      // if (item && item.stepStatusDetails && item.stepStatusDetails.stepStatusCode && item.stepStatusDetails.stepStatusCode === 'NEW') {
      //   this.updateStepStatus(item)
      // }
      sessionStorage.setItem('petitionId', this.petitionId);
      this.router.navigate([`${routePath}`]);
    }

  }

  getStepStatusUpdateModal(item) {
    if (item.isEditable) {
      if (item.stepTypeDetails && item.stepTypeDetails.code) {
        switch (this.selectedTaskType) {
          case 'I130':
          case 'I485':
            this.store.dispatch(new Task.SetTaskRouteId(this.superTaskId));
            this.navigateActions(item, `${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/immigration/${this.selectedTaskType}/details`);
            break;
          default:
            this.store.dispatch(new Task.SetTaskRouteId(this.superTaskId));
            this.navigateActions(item, `${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/visa/${this.taskTypeDetails.visaType}/details`);
            break;
        }
        // this.getStepStatusUpdate(item);
        // this.dialog.open(TaskStatusDialogComponent, {
        //   width: '550px',
        //   minHeight: '200px',
        //   maxHeight: '650px',
        //   hasBackdrop: false,
        //   panelClass: 'custom-dialog-container',
        //   data: {
        //     stepData: {
        //       stepTypeCode: (item.stepTypeDetails) ? item.stepTypeDetails.code : null,
        //       responseCode: (item.stepResponseDetails) ? item.stepResponseDetails.stepResponseCode : null,
        //       responseNote: item.stepResponseNote,
        //       statusCode: (item.stepStatusDetails) ? item.stepStatusDetails.stepStatusCode : null,
        //       stepStatusName: (item.stepStatusDetails) ? item.stepStatusDetails.stepStatusName : '',
        //       modifiedDate: (item.modifiedDate) ? moment.utc(item.modifiedDate).format('MM-DD-YYYY') : null,
        //       stepNote: item.stepNote,
        //       stepActionList: item.stepActionList,
        //       taskTypeDetails: this.taskTypeDetails,
        //       petitionTypes: this.petitionTypes,
        //       visaTypes: this.visaTypes,
        //       immigrationTypes: this.immigrationTypes,
        //       isAttorneyNotify: item.isAttorneyNotify,
        //       isBeneficiaryNotify: item.isBeneficiaryNotify,
        //       isPetitionerNotify: item.isPetitionerNotify,
        //       isAttorneyAction: item.isAttorneyAction,
        //       isBeneficiaryAction: item.isBeneficiaryAction,
        //       isPetitionerAction: item.isPetitionerAction,
        //       isAttorneyVisible: item.isAttorneyVisible,
        //       isBeneficiaryVisible: item.isBeneficiaryVisible,
        //       isPetitionerVisible: item.isPetitionerVisible,
        //       isReadOnly: item.isReadOnly,
        //       stepReviewDetails: item.stepReviewDetails,
        //       userName: this.userName,
        //       lastModifiedBy: item.modifiedUserName,
        //       lastModifiedOn: item.modifiedDate,
        //       isAttorneyEditable: item.isAttorneyEditable,
        //       isBeneficiaryEditable: item.isBeneficiaryEditable,
        //       isPetitionerEditable: item.isPetitionerEditable,
        //       noticeNumber: item.receiptNumber,
        //       stepName: item.stepName,
        //       receiptDate: item.receiptDate,
        //       beneficiaryConfirmationNumber: item.beneficiaryConfirmationNumber,

        //     },
        //     taskId: this.taskId,
        //     stepId: item.id,
        //     companyId: item.companyId,
        //     beneficiaryId: item.beneficiaryId,
        //     petitionId: this.petitionId,
        //     superTaskId: this.superTaskId
        //   }
        // }).afterClosed().subscribe(result => {
        //   this.petitionerService.getTask(this.taskId).subscribe(response => {
        //     // let petitionId;
        //     // this.store.select(fromTask.getPetitionSubTask).subscribe((details)=>{
        //     //     petitionId = details.id;
        //     //    })
        //     this.petitionerService.getSuperTask(this.superTaskId).subscribe(responseSuperTask => {
        //       this.store.dispatch(new Task.SetTaskSummaryOverview(responseSuperTask));
        //     });
        //     this.pbStore.dispatch(loadPetitionSubTaskDetails({ taskId: this.taskId }));
        //     //  this.taskUtilityService.setTaskDetails(response);
        //     const stepsData = response['taskStepDetails'];
        //     const stepWaitForDecision = stepsData.find(x => x.stepName === 'Wait for USCIS Decision');
        //     const isVisible = (this.userType === 'Attorney' && stepWaitForDecision.isAttorneyVisible === 1) ||
        //       (this.userType === 'Beneficiary' && stepWaitForDecision.isBeneficiaryVisible === 1) ||
        //       (this.userType === 'Petitioner' && stepWaitForDecision.isPetitionerVisible === 1);
        //     const isEditable = (this.userType === 'Attorney' && stepWaitForDecision.isAttorneyEditable === 1) ||
        //       (this.userType === 'Beneficiary' && stepWaitForDecision.isBeneficiaryEditable === 1) ||
        //       (this.userType === 'Petitioner' && stepWaitForDecision.isPetitionerEditable === 1);
        //     if (stepWaitForDecision && isVisible && isEditable) {
        //       this.dashboardWidgetsService.getPetitionDecision(this.petitionId).subscribe((petitionDecisionData: any) => {
        //         stepWaitForDecision.USCISResponse = petitionDecisionData.outcomeId;
        //         this.taskObj = stepsData;
        //         this.processTaskSteps();
        //       });
        //     } else {
        //       stepWaitForDecision.USCISResponse = 'AWAITDEC';
        //       this.taskObj = stepsData;
        //       this.processTaskSteps();
        //     }
        //   });
        // });
      } else {
        //added else condition to redirect if custom step is clicked.
        this.navigateActions(item, `${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details/visa/${this.taskTypeDetails.visaType}/details`);
      }
    }
  }

  updateStepStatus(item) {
    const payload = {
      endDate: moment.utc().format('YYYY-MM-DD hh:mm:ss'), // moment(new Date(), "YYYY-mm-dd hh:mm:ss",true),
      responseCode: item['stepResponseDetails'] && item['stepResponseDetails']['stepResponseDetails'] && item['stepResponseDetails']['stepResponseDetails']['stepResponseCode'] ? item['stepResponseDetails']['stepResponseDetails']['stepResponseCode'] : null,
      responseNote: item['stepResponseDetails'] && item['stepResponseDetails']['stepResponseNote'] ? item['stepResponseDetails']['stepResponseNote'] : '',
      startDate: moment.utc().format('YYYY-MM-DD hh:mm:ss'),
      statusCode: stepStatus.Inprogress,
      stepNote: item['stepResponseDetails'] && item['stepResponseDetails']['stepNote'] ? item['stepResponseDetails']['stepNote'] : '',
      isAttorneyEditable: (item['stepData'] && item['stepData']['isAttorneyEditable'] === 1) ? 1 : 0,
      isBeneficiaryEditable: (item['stepData'] && item['stepData']['isBeneficiaryEditable'] === 1) ? 1 : 0,
      isPetitionerEditable: (item['stepData'] && item['stepData']['isPetitionerEditable'] === 1) ? 1 : 0
    };
    //   this.petitionerService.stepStatusUpdate(payload, item.taskId, item.id).subscribe();
    //   this.superTaskstore.select(fromTask.getPetitionSubTask).subscribe((details)=>{
    //     petitionId = details.id;
    //    })
    // this.pbStore.dispatch(loadSubTaskDetails({ taskId: petitionId }));

    this.petitionerService.stepStatusUpdate(payload, item.taskId, item.id).subscribe((response: ImagilityBaseResponse) => {
      if (response) {
        let petitionId;
        this.store.select(fromTask.getPetitionSubTask).subscribe((details) => {
          petitionId = details.id;
        });
        this.pbStore.dispatch(loadPetitionSubTaskDetails({ taskId: petitionId }));

      }
    });
  }

  updateStepStatusRFE(item, data) {
    let statusCodeVal = stepStatus.Inprogress;
    if (data) {
      statusCodeVal = data;
    }
    const payload = {
      statusCode: statusCodeVal,
      stepNote: ''
    };
    this.petitionerService.stepStatusUpdateRFE(payload, item.taskId, item.id).subscribe((response: any) => {
      if (response.status === 200) {
        if (statusCodeVal != stepStatus.Inprogress) {
          this.toastr.success(response.message);
        }
        this.petitionerService.getTask(this.taskId).subscribe(responseTask => {
          const stepsData = responseTask['taskStepDetails'];
          this.taskObj = stepsData;
          this.processTaskSteps();
          this.store.dispatch(new Task.SetRfeSubTaskDetails(responseTask));
        });
      }
    });
  }

  getRFEStepStatusUpdate(item) {
    this.stepValue = item;
    this.stepActionList = this.stepValue['stepActionList'];
    this.userTypeActionable = (this.userType === 'Beneficiary') ?
      false : (this.userType === 'Attorney' && this.stepValue['isAttorneyAction'] == 0) ?
        false : (this.userType === 'Petitioner' && this.stepValue['isPetitionerAction'] == 0) ?
          false : true;
    this.userTypeActionable = (this.userType === 'Beneficiary' && this.stepValue['isBeneficiaryAction'] == 0) ? false : (this.userType === 'Attorney' && this.stepValue['isAttorneyAction'] == 0) ? false : (this.userType === 'Petitioner' && this.stepValue['isPetitionerAction'] == 0) ? false : true;
    if (this.userTypeActionable) {
      this.getRFEStepStatusUpdateModal(item);
    }
  }

  getRFEStepStatusUpdateModal(item) {
    //  if (item.isReadonly == 0) {
    this.rfeTimelineModalInput['stepName'] = item.stepName;
    this.rfeTimelineModalInput['taskNumber'] = item.taskId;
    this.rfeTimelineModalInput['parentStepId'] = item.id;
    this.rfeTimelineModalInput['userType'] = sessionStorage.getItem('userTypeRole');
    this.rfeTimelineModalInput['isOutsideImagility'] = this.isOutsideImagility;
    this.rfeTimelineModalInput['stepStatus'] = item.stepStatusDetails.stepStatusCode;
    this.rfeDialogDisplay = true;
    this.stepName = item.stepName;
    this.stepName1.next(item.stepName);
    // }
  }

  parentListener(data: string) {
    if (data != 'INPROGRESS') {
      this.rfeDialogDisplay = false;
    }
    if (data.length != 0) {
      this.updateStepStatusRFE(this.stepValue, data);
    }
  }
  notifyBeneForLcaAck(item) {
    this.companyId = parseInt(item.companyId);
    this.showNotifyBeneAckContent = true;

  }
  cancelNow() {
    this.showNotifyBeneAckContent = false;
  }
  sendNotificationForBeneLcaAck() {
    this.dashboardWidgetsService.lcaAssignedNotify(this.companyId, this.petitionId, {}).pipe(take(1)).subscribe((response) => {
      if (response.status === 200) {
        this.toastr.success(response.message);
        this.showNotifyBeneAckContent = false;
      } else {
        this.toastr.error(response.message);
      }
    }
    );

  }

  updateNoteAvailability(e, task) {
    task.notesAvailable = e?.notesAvailable || false;
    task.notes.noteText = e.noteText || '';
  }
  mouseEnter(param) {
    this.toolTipCustomClass = param;
  }
}
