import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse, PrimaryData } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EnterProcessingInformationService {

  constructor(
    private http: HttpClient,
    public toastrService: ToastrService
  ) { }

  onGetEnterProcessingInformationDetails(primaryData: PrimaryData) {
    return this.http
      .get(`/${primaryData.visatype}/${primaryData.caseType}/${primaryData.caseId}/processingInfo`)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200 && response.data) {
            return response.data;
          } else {
            return null;
          }
        })
      );
  }

  onSaveEnterProcessingInformationDetails(primaryData: PrimaryData, payload: any) {
    return this.http
      .post(`/${primaryData.visatype}/${primaryData.caseType}/${primaryData.caseId}/processingInfo`, payload)
      .pipe(
        map((response: ImagilityBaseResponse) => {
          if (response.status === 200) {
            this.toastrService.success(response.message);
            return true;
          } else {
            return false;
          }
        })
      );
  }

}
