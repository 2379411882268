import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BillingInvoiceService } from "src/app/services/billing-invoice.service";
import * as RolesRouteMap from "app-models";
import { DialogService } from "primeng/dynamicdialog";
// import { DeleteDialogLibraryComponent } from "delete-dialog-library";
import { ReferenceLibraryService } from "src/app/services/referenceLibrary.service";
import { FileUploadLibraryService } from "file-upload-library";
import { environment } from "src/environments/environment";
// import { DeleteDialogConfigurations } from '../../utility/';
import {
  DeleteDialogConfigurations,
  ViewInvoiceConfigurations,
} from "../../utility/dialog.configuration";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ImagilityBaseResponse } from "app-models";
import { ViewInvoiceComponent } from "../view-invoice/view-invoice.component";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
// import { PreviewComponent } from "../preview/preview.component";
import { DatePipe } from "@angular/common";
import moment from "moment";
import { DeleteDialogLibraryComponent } from "delete-dialog-library";
import { NgxSpinnerService } from "ngx-spinner";
import { I } from "@angular/cdk/keycodes";

interface City {
  name: string;
  code: string;
}
// import { I } from "@angular/cdk/keycodes";

@Component({
  selector: "app-billing-invoice",
  templateUrl: "./billing-invoice.component.html",
  styleUrls: ["./billing-invoice.component.scss"],
  providers: [DialogService],
})
export class BillingInvoiceComponent implements OnInit {
  clientPaymentForm: FormGroup;
  // dashboardUrl;
  isFilterstatus: boolean = false;
  pipe = new DatePipe("en-US");
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  searchInvoice: any;
  companies: any;
  registerForm: FormGroup;
  companyId: number = 0;
  pageIndex = 1; // default
  pageSize = 10;
  toggleStatus = "all";
  isDraft: boolean = false;
  registrationStatus: boolean = false;
  totalAllElementCount = 0;
  pageSizeOptions = [10, 15, 25, 50];
  payload = {
    companyId: 0,
    pageNumber: this.pageIndex,
    pageSize: this.pageSize,
  };
  filter = {
    clientName: "",
    status: "",
    StartDate: "",
    endDate: "",
  };
  public fliterStatus: string = "";
  public fliterClientId: any = "";
  public getAllDraftData: any;
  public isactiveTimeLine: string = "activeTimeLine";
  public isInactiveTimeLine: string = "";
  public invoiceListData: any;
  public totalDraftElementCount = 0;
  public totalListElementCount = 0;
  public totalDraftListElementCount = 0;
  observableSubscription$ = new Subject();
  getAllTabsDataRecordCount: number;
  getAllDraftDataRecordCount: number;
  minimumDate: any;
  // minimumDate = new Date();
  stepsTypeCols: any;
  resendInvoiceDialog: boolean = false;
  resendcancelInvoiceDialog: boolean;
  updatePaymentStatusDialog: boolean;
  deletePaymentDialog: boolean = false;
  updateDeleteHeader: string = "";
  viewInvoiceDialog: boolean = false;
  Updatepayment: any;
  viewPaymentpage: boolean = true;
  cities: City[];
  selectedCity: City;
  paymentmodeType: any;
  public popInvoiceId: any;
  public proofofPaymentImg: any;
  paymentMode: any;
  paymentDate: any;
  referenceNumber: number;
  downloadInvoiceDialog: boolean = false;
  invoiceId: number;
  cancelInvoiceDialog: boolean = false;
  resentCount: number;
  StartDate: any;
  endDate: any;
  sendInvoiceTitle: string;
  notificationId: number;
  clientData: any;
  dashboardUrl: string;
  // cashMode: boolean = true;
  cashMode: boolean = false;
  merchandUrl: any;
  merchandUrlSignUp: string;
  affinipayDialog: boolean=false;
  succesResponse: boolean=false;
  waitingResponse: boolean=false;
  failureResponse: boolean=false;
  merchantMsg: any;
  payment = false;
  beninvoiceId: any=[];
  reciveFrom = [
    { name: "Lumpsum", code: "Lumpsum" },
    { name: "Allianz", code: "Allianz" }
  ];
  paymentAttorneyHistory: any;
  srearchRecivedFrom: any
  searchAmount: any;
  searchTransactionId: any;
  paymentCol: { field: string; header: string; }[];
  getAll: boolean;
  getAllPaymentHis:any;
  clonedPaymentHistory: string;
  dates: string[];
  constructor(
    public router: Router,
    public dialogService: DialogService,
    private toastr: ToastrService,
    private billingInvoiceService: BillingInvoiceService,
    private rfelibraryservice: ReferenceLibraryService,
    private uploadservice: FileUploadLibraryService,
    private fb: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private invoiceservice: BillingInvoiceService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // this.merchandUrlSignUp="https://secure.affinipay.com/oauth/authorize?redirect_uri=https://qa8.mytestsdummy.co.in/attorney-landing/attorney-invoice/billing-invoices&client_id=f8cae1757af41b4b99f3a0c7619daa8080359ad322227d90df3f2961b4e7a58b&scope=payments&response_type=code";
    this.merchandUrlSignUp = `${environment.affiniPayUrl}?redirect_uri=${environment.appUrl}/attorney-landing/attorney-invoice/billing-invoices&client_id=${environment.affiniPayClientId}&scope=payments&response_type=code`;
    this.clientPaymentForm = this.fb.group({
      paymentDate: ["", Validators.required],
      paymentMode: ["", Validators.required],
      referenceNumber: ["",Validators.required],
    });
    this.beninvoiceId.push('snsxsb')
    if (history.state.isnaviGated === true) {
      this.updatePaymentStatusDialog = true;
      this.updateDeleteHeader = "Payment status update";
      this.invoiceId = history.state.invoiceId;
      this.notificationId = history.state.id;
    }
    this.Updatepayment = 1;
    this.rfelibraryservice
      .getReferencePaymentModeData("PAYMODE")
      .subscribe((res) => {
        this.paymentmodeType = res;
      });
    this.companyId = parseInt(sessionStorage.getItem("companyId"));
    if (this.route.snapshot.queryParams['code']) {
      const payload = {
          code: this.route.snapshot.queryParams['code'],
          id: this.companyId
      };
      this.billingInvoiceService.getLoginToken(payload).subscribe(
        (res: any) => {
          if(res.status==200){
            this.toastr.success(res.message);
          }
          else{
            this.toastr.error(res.message);
          }

        }
      );
    }
    this.cities = [
      { name: "New York", code: "NY" },
      { name: "Rome", code: "RM" },
      { name: "London", code: "LDN" },
      { name: "Istanbul", code: "IST" },
      { name: "Paris", code: "PRS" },
    ];

    this.getAllTabsDetails("all");
    this.getDraftTabsDetails("draft");
    this.paymentHistoryAttorney('payment')
    this.getTableHeadersData();
    this.getMerchandId();
    if (sessionStorage.getItem("userTypeRole") !== 'Beneficiary') {
      this.getstatusMerchant();
    }
    // this.getPaymentHistory();

  }

  getAllTabsDetails(tab) {
    let filterParams;
    if (this.filterSearch) {
      filterParams = this.filterSearch;
    } else {
      filterParams = null;
    }
    let req = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      status: tab,
      filterSearch: filterParams,
    };
    this.getAllTabDetails(req, tab);
  }
  getDraftTabsDetails(tab) {
    let req = {
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      status: tab,
    };
    this.getAllTabDetails(req, tab);
  }
  public clientNameFilterList = [];
  public paymentStatus = [];
  getAllTabDetails(req, tab) {
    this.clientNameFilterList = [];
    this.paymentStatus = [];
    this.SpinnerService.show();
    this.billingInvoiceService
      .getListStepsConfigured(this.companyId, req)
      .subscribe((data) => {
        if (data.status === 200) {
          if (tab === "all") {
            this.SpinnerService.hide();
            this.getAllTabsData = data.data.attorneyClientListResponseDto;
            this.getAllTabsData.forEach((ele) => {
              let obj = { clientName: ele.clientName, clientId: ele.clientId };
              if (ele.paymentStatus === "Paid") {
                ele.statusId = "PAID";
              } else if (ele.paymentStatus === "Pending") {
                ele.statusId = "PEND";
              } else if (ele.paymentStatus === "Overdue") {
                ele.statusId = "OVERDUE";
              }
              let statusPbj = {
                status: ele.paymentStatus,
                statusId: ele.statusId,
              };
              this.clientNameFilterList.push(obj);
              this.clientNameFilterList.forEach((val) => {
                if (!val.clientId) {
                  val.clientId = val.clientName;
                }
              });
              this.paymentStatus.push(statusPbj);
            });
            this.paymentStatus = [
              ...new Map(
                this.paymentStatus.map((item) => [item.status, item])
              ).values(),
            ];
            if (data.data["allRecordCount"]) {
              this.totalAllElementCount = data.data["allRecordCount"];
              this.getAllTabsDataRecordCount = this.totalAllElementCount;
            }
          } else {
            this.SpinnerService.hide();
            this.getAllDraftData = data.data.attorneyClientListResponseDto;
            if (data.data["allRecordCount"]) {
              this.totalDraftElementCount = data.data["allRecordCount"];
              this.getAllDraftDataRecordCount = data.data["allRecordCount"];
            }
          }
        } else {
          this.SpinnerService.hide();
        }
      });
  }
  handleStatusChange(status) {
    if (status) {
      this.isFilterstatus = false;
    }
    this.fliterStatus = status;
  }
  onClientName(item) {
    this.fliterClientId = item;
  }
  public toDate: any;
  onStartDate(even) {
    this.minimumDate = even;
  }

  onEndDate(even) {
    this.toDate = even;
  }
  singUpMerchantpage(){
    const payload={
      // reference:"26",
      email:localStorage.getItem('userEmail'),
      plan:"affinipay_std",
      attorneyId:this.companyId
    }
    const data="Bearer"+" "+environment.secretKey;
    this.billingInvoiceService.singUpMerchant(data,payload).pipe(take(1)).subscribe((response)=>{
      // console.log(response)

    this.merchandUrl=response['redirect_uri'];
    window.location.href = this.merchandUrl;

  //this response need for future enhancecement
    })
  }
  getMerchandId(){
    const id="2xDpm5sITPmGC73UjpxgjgTb64KWnqd4KRJ6Uc94YC919GL7oGaQyeQfSwMFNdRG";
    this.billingInvoiceService.getMerchant(id).pipe(take(1)).subscribe((response)=>{
  //this response need for future enhancecement
    })
  }

  handleFilterClear(filterpopup) {
    filterpopup.hide();
    this.filter.clientName = "";
    this.filter.status = "";
    this.filter.StartDate = "";
    this.filter.endDate = "";
    this.isFilterstatus = false;
    this.fliterClientId = "";
    this.filterSearch = null;
    this.ngOnInit();
  }

  filterCancel(filterpopup) {
    this.isFilterstatus = false;
    filterpopup.hide();
  }

  public filterSearch: any;
  handleFilterChange(param, filterpopup) {
    if (!param) {
      this.isFilterstatus = true;
    } else {
      let startDate = this.pipe.transform(this.minimumDate, "yyyy-MM-dd");
      let endDate = this.pipe.transform(this.toDate, "yyyy-MM-dd");
      if (!startDate) {
        startDate = "";
      }
      if (!endDate) {
        endDate = "";
      }
      this.filterSearch = {
        clientId: this.fliterClientId,
        endDate: endDate,
        startDate: startDate,
      };
      let payload = {
        pageNumber: this.pageIndex,
        pageSize: this.pageSize,
        status: this.fliterStatus,
        filterSearch: this.filterSearch,
      };
      this.SpinnerService.show();
      this.billingInvoiceService
        .getAllTabDataList(this.companyId, "list", payload)
        .subscribe((data) => {
          this.SpinnerService.hide();
          this.getAllTabsData = data.data.attorneyClientListResponseDto;
          this.totalAllElementCount = data.data.allRecordCount;
          this.getAllTabsDataRecordCount = this.totalAllElementCount;
        });
      this.isFilterstatus = false;
      filterpopup.hide();
    }
  }
  allTabspaginationEvent(event, tab) {
    let req = {
      pageNumber: event.page + 1,
      pageSize: event.rows,
      status: tab,
    };
    this.getAllTabDetails(req, tab);
  }
  public getAllTabsData: any = [];
  getTableHeadersData() {
    this.getAll=true;
    this.payment=false;
    this.isDraft = false;
    this.stepsTypeCols = [
      { field: "clientName", header: "Client Name" },
      { field: "communicationEmail", header: "Communication Email" },
      { field: "phoneNo", header: "Phone No." },
      { field: "dueDate", header: "Due Date" },
      { field: "paymentStatus", header: "Payment Status" },
      // {},
    ];
  }

  public clientPay: any;
  clientRow(item, paginationPayload, tab, invoiveList) {
    if (tab === "all") {
      if (!item?.invoiceListData) {
        if (!paginationPayload) {
          let payload = {
            companyId: this.companyId,
            pageNumber: this.pageIndex,
            pageSize: this.pageSize,
            status: tab,
          };
          this.clientRowServiceData(item, payload, tab, invoiveList);
        } else {
          this.clientRowServiceData(item, paginationPayload, tab, invoiveList);
        }
      }
    } else {
      if (!item?.invoiceListData) {
        if (!paginationPayload) {
          let payload = {
            companyId: this.companyId,
            pageNumber: this.pageIndex,
            pageSize: this.pageSize,
            status: tab,
          };
          this.clientRowServiceData(item, payload, tab, invoiveList);
        } else {
          this.clientRowServiceData(item, paginationPayload, tab, invoiveList);
        }
      }
    }
  }

  paginationEvent(event, items, tab, invoiveList) {
    let payload = {
      companyId: this.companyId,
      pageNumber: event.page + 1,
      pageSize: event.rows,
      status: tab,
    };
    this.clientRowServiceData(items, payload, tab, invoiveList);
  }

  clientRowServiceData(item, payload, tab, invoiveList) {
    this.clientData = item;
    if (invoiveList) {
      let clientData: any;
      if (!item.clientId) {
        clientData = item.clientName;
      } else {
        clientData = item.clientId;
      }
      this.SpinnerService.show();
      this.billingInvoiceService
        .getClientInvoiceList(clientData, "list", payload)
        .subscribe((data) => {
          this.SpinnerService.hide();
          if (tab === "all") {
            if (data.status === 200) {
              this.getAllTabsData.forEach((ele) => {
                if (ele.clientName === item.clientName) {
                  ele.invoiceListData = data.data.clientInvoiceListResponseDto;
                }
              });
              if (data.data["allRecordCount"]) {
                this.totalListElementCount = data.data["allRecordCount"];
              }
            }
          } else {
            if (data.status === 200) {
              this.getAllDraftData.forEach((ele) => {
                if (ele.clientName === item.clientName) {
                  ele.invoiceListData = data.data.clientInvoiceListResponseDto;
                }
              });
              if (data.data["allRecordCount"]) {
                this.totalDraftListElementCount = data.data["allRecordCount"];
              }
            }
          }
        });
    } else {
      this.billingInvoiceService
        .getAllTabDataList(item.clientId, "list", payload)
        .subscribe((data) => {
          this.SpinnerService.hide();
          if (tab === "all") {
            if (data.status === 200) {
              this.getAllTabsData.forEach((ele) => {
                if (ele.clientName === item.clientName) {
                  ele.invoiceListData = data.data.clientInvoiceListResponseDto;
                }
              });
              if (data.data["allRecordCount"]) {
                this.totalListElementCount = data.data["allRecordCount"];
              }
            }
          } else {
            if (data.status === 200) {
              this.getAllDraftData.forEach((ele) => {
                if (ele.clientName === item.clientName) {
                  ele.invoiceListData = data.data.clientInvoiceListResponseDto;
                }
              });
              if (data.data["allRecordCount"]) {
                this.totalDraftListElementCount = data.data["allRecordCount"];
              }
            }
          }
        });
    }
  }
  getDraftTab() {
    this.isDraft = true;
    this.payment = false;
    this.getAll = false;

    this.stepsTypeCols = [
      { field: "clientName", header: "Client Name" },
      { field: "communicationEmail", header: "Communication Email" },
      { field: "phone", header: "Phone" },
    ];
  }
  paymentHistory() {
    this.payment = true;
    this.isDraft = false;
    this.getAll = false;
    this.paymentCol = [
      { field: "invoiceid", header: "Invoice ID" },
      { field: "invoiceFrom", header: "received from" },
      { field: "paymentDate", header: "Received Date" },
      { field: "transactionId", header: "Transaction reference Number" },
      { field: "amount", header: "Amount" },
    ];
    this.getPaymentHistory();
  }
  showproofofPaymentDialog(invoiceId) {
    this.popInvoiceId = invoiceId.proofPaymentLocation;
    this.openDoc(this.popInvoiceId)
  }

  openDoc(fileLocation) {
    const docPath = fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, "_blank");
  }
  public stepConfigurListDetails = [];
  editStepsTimeConfigured(item) {
    this.router.navigate(
      [
        `${RolesRouteMap.userRoleRouteMapping[
        sessionStorage.getItem("userTypeRole")
        ]
        }/task-allocation/configure-time`,
      ],
      { state: { data: item } }
    );
  }
  onDownloadInvoive(item) {
    const deleteDialogRef = this.dialogService.open(
      DeleteDialogLibraryComponent,
      DeleteDialogConfigurations
    );
    deleteDialogRef.onClose
      .pipe(takeUntil(this.observableSubscription$))
      .pipe(takeUntil(this.observableSubscription$))
      .pipe(takeUntil(this.observableSubscription$))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: boolean) => {
        if (response) {
          this.billingInvoiceService
            .deleteListStepsConfigured("NA", item.companyId, item.taskTypeId)
            .pipe(takeUntil(this.observableSubscription$))
            .subscribe((response: ImagilityBaseResponse) => {
              if (response.status == 200) {
                this.toastr.success(response.message);
                // this.getStepsConfiguredList();
              }
              {
                this.toastr.error(response.message);
              }
            });
        }
      });
  }
  public clientDetails: any;
  onViewInvoice(item) {
    this.viewInvoiceDialog = true;
    this.clientDetails = item;
    this.invoiceId = item.invoiceId;
    this.sendInvoiceTitle = "View Invoice";
    this.resendInvoiceDialog = false;
  }
  resendInvoice(item) {
    this.invoiceId = item.invoiceId;
    this.resentCount = item.reSentCount;
    this.sendInvoiceTitle = "Resend Invoice";
    if (item.paymentStatus === "Draft") {
      this.router.navigateByUrl(
        "/attorney-landing/attorney-invoice/generate-invoice",
        {
          state: {
            receiptStep: 1,
            invoiceId: item.invoiceId,
            sendDraftItemId: item.invoiceId,
            sendDraftInvoiceNumber: item.invoiceNumber,
          },
        }
      );
    } else {
      this.resendcancelInvoiceDialog = true;
      this.clientDetails = item;
      //this.getAllTabsDetails("all");
    }
  }
  updateStatus(item) {
    this.updatePaymentStatusDialog = true;
    this.deletePaymentDialog = false;
    if (!this.deletePaymentDialog) {
      this.updateDeleteHeader = "Payment status update";
    } else {
      this.updateDeleteHeader = "Delete Invoice";
    }
    this.invoiceId = item.invoiceId;
  }
  public deleteDueDate: string = "";
  public grandTotalAmount:any;
  public deleteInvoiceId: number;
  deleteInvoiceList(items) {
    this.deleteInvoiceId = items.invoiceId;
    this.grandTotalAmount = items.grandTotal;
    if (items.dueDate) {
      this.deleteDueDate = this.pipe.transform(items.dueDate, "dd MMM yyyy");
    } else {
      this.deleteDueDate = "";
    }
    this.deletePaymentDialog = true;
    this.updatePaymentStatusDialog = true;
    if (this.deletePaymentDialog) {
      this.updateDeleteHeader = "Delete Invoice";
    } else {
      this.updateDeleteHeader = "Payment status update";
    }
  }
  deleteCancel(btn) {
    this.updatePaymentStatusDialog = false;
    if (btn === "delete") {
      this.billingInvoiceService
        .deleteInvoice(this.deleteInvoiceId)
        .subscribe((res: any) => {
          if (res) {
            this.toastr.success(res.message);
            // this.getDraftTabsDetails("draft");
            this.resendInvoiceDialog = false;
            // this.getAllTabsDetails("all");
            this.toggleMode('all')
            this.ngOnInit();
          } else {
            this.ngOnInit();
            this.toggleMode('all')

          }
        });
    }
  }

  resend() {
    this.viewInvoiceDialog = true;
    this.resendcancelInvoiceDialog = false;
    this.resendInvoiceDialog = true;
  }
  resentInvoice(invoiveList) {
    let payload = {
      companyId: this.companyId,
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      status: "all",
    };
    let reqObj = {
      body: null,
      subject: null,
      toMails: null,
    };
    this.billingInvoiceService.sendInvoice(this.invoiceId, reqObj).subscribe(
      (resp: ImagilityBaseResponse) => {
        if (resp.status == 200) {
          this.toastr.success(resp.message);
          this.router.navigate([
            "/attorney-landing/attorney-invoice/billing-invoices",
          ]);
          this.viewInvoiceDialog = false;
          this.clientRowServiceData(
            this.clientData,
            payload,
            "all",
            invoiveList
          );
          //this.router.navigate(["/attorney-landing/attorney-invoice"]);
        } else {
          //this.toastr.warning(resp.message);
        }
      },
      (err) => {
        this.toastr.error(err.message);
      }
    );
    this.clientRowServiceData(this.clientData, payload, "all", invoiveList);
  }

  cancelInvoice(item) {
    this.cancelInvoiceDialog = true;
    this.invoiceId = item.invoiceId;
  }
  invoiceCancel() {
    let list = 'invoiveList'
    let payload = {
      companyId: this.companyId,
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      status: "all",
    };
    this.billingInvoiceService.cancelInvoice(this.invoiceId).subscribe(
      (res: any) => {
        this.toastr.success(res.message);
        this.cancelInvoiceDialog = false;
        this.clientRowServiceData(this.clientData, payload, "all", list);
      },
      (err) => {
        this.toastr.error(err.message);
      }
    );
  }
  updatechangeType() {
    this.viewPaymentpage = !this.viewPaymentpage;
  }
  updatepaymnetStatus() {
    let updatepaymentstatusPayload = {
      invoiceId: this.invoiceId,
      modeOfPayment: this.paymentMode,
      paymentDate: moment(
        this.clientPaymentForm.get("paymentDate")?.value
      ).format("YYYY-MM-DD"),
      rcvdPayment: parseInt(this.Updatepayment),
      transactionId: this.referenceNumber,
    };
    if (this.notificationId !== undefined) {
      this.billingInvoiceService
        .clientupdatePaymentInvoice(
          updatepaymentstatusPayload,
          this.invoiceId,
          this.notificationId
        )
        .subscribe(
          (resp: any) => {
            if (resp.status == 200) {
              this.toastr.success(resp.message);
            }
          },
          (err) => {
            this.toastr.error(err.message);
          }
        );
      this.router.navigateByUrl(
        "/attorney-landing/attorney-invoice/invoice-receipt",
        {
          state: {
            invoiceId: this.invoiceId,
          },
        }
      );
    } else {
      this.billingInvoiceService
        .updatePaymentInvoice(updatepaymentstatusPayload, this.invoiceId)
        .subscribe((resp: any) => {
          this.toastr.success(resp.message);
        });
      this.router.navigateByUrl(
        "/attorney-landing/attorney-invoice/invoice-receipt",
        {
          state: {
            invoiceId: this.invoiceId,
          },
        }
      );
    }
  }
  cancelpaymentStatus() {
    this.router.navigate([
      "/attorney-landing/attorney-invoice/billing-invoices",
    ]);
    this.resendcancelInvoiceDialog = false;
    this.viewInvoiceDialog = false;
  }
  notifyClient() {
    let updatepaymentstatusPayload = {
      invoiceId: this.invoiceId,
      modeOfPayment: null,
      paymentDate: null,
      rcvdPayment: parseInt(this.Updatepayment),
      transactionId: null,
    };
    this.billingInvoiceService
      .updatePaymentInvoice(updatepaymentstatusPayload, this.invoiceId)
      .subscribe(
        (res: any) => {
          this.toastr.success(res.message);
          this.updatePaymentStatusDialog = false;
          // this.router.navigate(['/billing-invoices']);
        },
        (err) => {
          this.toastr.error(err.message);
        }
      );
  }
  toggleMode(mode) {
    this.toggleStatus = mode;
  }
  // invoiceCancel(){
  //   this.router.navigate(['/billing-invoices']);
  // }
  downloadInvoice(item) {
    this.invoiceId = item.invoiceId;
    this.downloadInvoiceDialog = true;
  }

  taskcomplete(task: string) {
    if (task === "yes") {
      this.billingInvoiceService
        .downloadInvoice(this.invoiceId)
        .subscribe((res) => {
          this.download(res);
          this.downloadInvoiceDialog = false;
        });
      this.downloadInvoiceDialog = false;
    } else {
    }
  }
  download(file) {
    //let file1  ='http://3.235.178.82:5651//docs/var/imagility/uploads/documents/attorney/invoice/163/163_invoicePdf.pdf'
    this.uploadservice.downloadDocuments(
      environment.docs + file.substring(23),
      file.substring(23)
    );
    this.downloadInvoiceDialog = false;
  }

  editInvoice(order) {
    // this.navUrl =
    // "/attorney-landing/dashboard/attorney-dashboard/scheduling/";
    let navUrl = "/attorney-landing/attorney-invoice/generate-invoice";
    this.router.navigateByUrl(navUrl, {
      state: { invoiceItem: order },
    });
  }

  backtoDashboard() {
    this.dashboardUrl = "attorney-landing/dashboard/attorney-dashboard";
    // this.dashboardUrl = "/attorney-landing/attorney-invoice/";
    this.router.navigate([this.dashboardUrl]);
  }

  backtoMangaeBilling() {
    this.dashboardUrl = "/attorney-landing/attorney-invoice/billing-invoices";
    this.router.navigate([this.dashboardUrl]);
  }

  paymentmodeTypechanged(event: any){
   if(event.value =='CASH'){
    this.clientPaymentForm.get("referenceNumber").clearValidators();
    this.clientPaymentForm.get("referenceNumber").updateValueAndValidity();
     this.cashMode = true;

    } else{
     this.cashMode = false;
    this.clientPaymentForm.get("referenceNumber").setValidators([Validators.required]);
    this.clientPaymentForm.get("referenceNumber").updateValueAndValidity();
    }
   }

   showAffinipay(){
    this.affinipayDialog=true;
   }
   getstatusMerchant(){
    this.billingInvoiceService.getmerchantStatus(this.companyId).subscribe((response)=>{
      if (response.status == 200) {
        this.registrationStatus = response.data;

        if(response['data']==="Success"){
          this.succesResponse=true;
          this.merchantMsg="Successfully Registered with Merchant"
        }
        else if(response['data']==="Failure"){
          this.failureResponse=true;
          this.merchantMsg="Merchant Registration Failed"
        }
        else{
          this.waitingResponse=true;
          this.merchantMsg="Waiting for AffiniPay Response"
        }
      }
    })
   }
   paymentHistoryAttorney(tab){

   }

   getPaymentHistory(){
    this.getAllPaymentHis = []
    this.dates = []
    this.invoiceservice.getAttorneyPaymentHistory(this.companyId).subscribe((response)=>{
      this.paymentAttorneyHistory=response;
      this.paymentAttorneyHistory.forEach((res, i)=> {
        if(res.paymentStatus === 1612) {
          // console.log(res,"eeeeeeeeeeeeee")
          this.dates.push(res);
        }
        if(i === this.paymentAttorneyHistory?.length-1) {
          this.dates.sort((a, b) => moment(b['transactionTime'], 'D MMM YYYY HH:mm:ss').diff(moment(a['paymentDate'], 'D MMM YYYY HH:mm:ss')));
         this.getAllPaymentHis = this.dates;
        }
      }
      )

      this.clonedPaymentHistory = JSON.stringify(this.getAllPaymentHis);

    })
   }
// overlay filter start
   invoiceFilter(eve: any) {
    if(eve.length){
      this.getAllPaymentHis=JSON.parse(this.clonedPaymentHistory).filter(val => val.invoiceid.includes(eve))
      } else {
      this.getAllPaymentHis=JSON.parse(this.clonedPaymentHistory);

      }
   }
   recivedFromFilter(eve: any) {
    this.getAllPaymentHis=JSON.parse(this.clonedPaymentHistory).filter(val => val.invoiceFrom===eve.value.code)
   }

   transactionIdFilter(event: any) {
    if(event.length){
      this.getAllPaymentHis=JSON.parse(this.clonedPaymentHistory).filter(val => {
        return val.transactionId?.includes(event)
      } )
      }
      else{
        this.getAllPaymentHis=JSON.parse(this.clonedPaymentHistory);
      }
   }
   amountFilter(eve: any) {
    if(eve.length){
      this.getAllPaymentHis=JSON.parse(this.clonedPaymentHistory).filter(val => val.amount?.includes(eve))
      } else {
      this.getAllPaymentHis=JSON.parse(this.clonedPaymentHistory);

      }
   }
   //overlay filter end
}
