import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'src/app/app-state/actions/dirty-form.actions';
import { ImagilityBaseResponse } from 'src/app/data/models/response.model';
import { DirtyFormGuard } from 'src/app/dirty-form.guard';
import { PetitionBuilderService } from 'src/app/services/petition-builder.service';
import { updateStepStatusToInitialState } from '../../store/actions/step-status-update.actions';

@Component({
  selector: 'note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.scss']
})
export class NoteDialogComponent implements OnInit, AfterViewInit {
  reason: string;
  title: string;
  @ViewChild(NgForm) noteDialogForm: NgForm;
  observableSubscription$ = new Subject();

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private store: Store<any>,
    private dynamicDialogConfig: DynamicDialogConfig,
    private guard: DirtyFormGuard,
    private toastService: ToastrService,
    private pbApiService: PetitionBuilderService,
  ) {
    this.reason = '';
    this.title = '';
  }

  ngOnInit(): void {
    this.title = this.dynamicDialogConfig.data.title;
  }

  ngAfterViewInit() {
    this.noteDialogForm.form.valueChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      if (this.noteDialogForm.dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      } else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });
  }

  handleSubmit() {
    this.dynamicDialogConfig.data.payload.title = this.title;
    this.dynamicDialogConfig.data.payload.text = this.reason;
    this.pbApiService.getNotificationDocListBeneQua(this.dynamicDialogConfig.data.payload).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.toastService.success(response.message);
        this.dynamicDialogRef.close();
      }
    });
  }

  handleClose() {
    this.guard.canDeactivate().pipe(first()).subscribe((response: boolean) => {
      if (response) {
        this.dynamicDialogRef.close();
      }
      else {
        return false;
      }
    });
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }

}
