import { Component, OnInit, Input, ViewChild,Output,EventEmitter } from '@angular/core';
import { RfeService } from 'src/app/services/rfe.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { AttorneyProfileService } from 'src/app/services/attorney-profile.service';
import { LookupService } from 'src/app/services/lookup-data.service';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { USER_PERMISSIONS } from 'src/app/data/constants/permissions';
import { forkJoin } from 'rxjs';
import { stepStatus } from '../../../../data/constants/step-status';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';
import * as Roles from 'src/app/data/constants/roles';

@Component({
  selector: 'app-rfe-review-response',
  templateUrl: './rfe-review-response.component.html',
  styleUrls: ['./rfe-review-response.component.scss']
})
export class RfeReviewResponseComponent implements OnInit {
  @Input() rfeTimelineModalInput;
  pdfSrc = null;
  pdfTimeStamp;
  supportingDocuments:any[];
  users;
  selectedAttorneyFirm;
  selectedAttorney;
  companyId;
  reviewUsersList;
  preparerAttorneyId;
  rfeNotice;
  reviewer;
  reviewerId;
  attorneys = [];
  attorneyId: '';
  rfeTaskDetails;
  loggedInUserId;
  commentFromReviewer;
  reviewerResponse;
  preparer;
  @ViewChild('op', {static: false}) model;
  userType;
  SubTaskOverView;
  displayGetMoreData: boolean = false;
  disableFields;
  initialReviewer: [];
  @Output () notify: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private petitionerService: PetitionerService,
    private attorneyProfileService: AttorneyProfileService,
    private lookupService: LookupService,
    private store: Store<fromTask.State>,
    private rfeService:RfeService,
    private toastr: ToastrService) { 
      this.userType = sessionStorage.getItem('userTypeRole');
    }

  ngOnInit() {
    this.displayGetMoreData = false;
    this.getContent();
  }

  getContent(){
    combineLatest([
      this.store.select(fromTask.getRFESubTaskDetails)
    ]).subscribe(([getRFESubTask]) => {
      this.SubTaskOverView = getRFESubTask;
      if(this.SubTaskOverView.taskStepDetails[5].stepStatusDetails.stepStatusCode == 'COMPLETE')
        this.disableFields = true;
      else
        this.disableFields = false;
    });


    //this.getTaskDetails();
    if (this.userType === Roles.ATTORNEY) {
      this.generatePdf();
      this.getSupportingDocuments();
      this.getData();
      this.attorneyProfileService.getEmployeeDetails(parseInt(sessionStorage.getItem('companyId')), parseInt(sessionStorage.getItem('employeeId'))).subscribe(data => {
        //this.selectedAttorneyFirm = data;
        this.preparerAttorneyId = data['id'];
      });
      this.loggedInUserId = parseInt(sessionStorage.getItem('employeeId'));
    }
  }

  generatePdf(){
    this.rfeService.viewGeneratePdf(this.SubTaskOverView['id'], 1).subscribe((response: any) => {
      if (response) {
        if(response["isExist"]){
            let dataSlice = response["path"].split('/var/imagility/uploads/');
            this.pdfSrc = environment.docs + dataSlice[1];
            this.pdfTimeStamp = response["createdDate"];
            }
            else {
              this.generateAgain();
            }
          }
      else{
        this.generateAgain();
      }    
    });
 
  }


generateAgain(){
  this.rfeService.generatePdf(this.SubTaskOverView['id'], 1).subscribe((response: any) => {
    if (response.status == 200) {
      this.toastr.success(response.message);
      this.rfeService.viewGeneratePdf(this.SubTaskOverView['id'], 1).subscribe((response: any) => {
        if (response) {
              let dataSlice = response["path"].split('/var/imagility/uploads/');
              this.pdfSrc = environment.docs + dataSlice[1];
              this.pdfTimeStamp = response["createdDate"];
            }
      });
    } 
  });
}

getData(){
  let taskList = this.petitionerService.getTask(this.SubTaskOverView['id']);
  let rfeDetails = this.rfeService.getRFEDetails(this.SubTaskOverView['id']);
  let getAttorney = this.lookupService.getActiveAttorneyCompanyList(this.companyId);
  forkJoin([taskList, rfeDetails]).subscribe(results => {
    if (results){
      this.rfeTaskDetails = results[0];
    this.companyId = results[0]['companyId'];
    this.attorneyId =  results[0]['attorneyId'];
    this.getAttorney();
    if(results[1] != null){
      this.rfeNotice = results[1];
      if(results[1]["reviewer"] != null)
        this.reviewerId = results[1]["reviewer"]["id"];
      this.reviewerResponse = results[1]["reviewerComment"];
    }
    }
  });
}
  getTaskDetails(){
        this.petitionerService.getTask(this.SubTaskOverView['id']).subscribe((response: any) => {
          if (response.status == 200){
            this.companyId = response['companyId']; 
            //this.getRFEDetails(); 
            //this.getAttorney();
            this.attorneyId =  response['attorneyId'];
            
          }
        });
  }

  getRFEDetails(){
    this.rfeService
      .getRFEDetails(this.SubTaskOverView['id'])
      .subscribe((data) => {
        if(data != null){
        this.rfeNotice = data;
        this.reviewerId = data["reviewer"]["id"];
        this.getReviewerUsers();
       // console.log("RFE NOTICE",data);
        }
      });
  }


  getAttorney() {  //IM-3916
    this.lookupService.getActiveAttorneyCompanyList(this.companyId).subscribe((data: Array<any>) => {
      this.attorneys = [];
      data.forEach((element) => {
        if(element["attorneyId"] == this.attorneyId){
          this.attorneys.push(element);
          this.selectedAttorneyFirm = element;
          
        }
          this.getReviewerUsers();
      });
    });

  }

  getReviewerUsers() {
    if (this.selectedAttorneyFirm && this.selectedAttorneyFirm !== "" && this.selectedAttorneyFirm.attorneyId) {
    this.lookupService.getReviewersUser(this.selectedAttorneyFirm.attorneyId, USER_PERMISSIONS.PETITION_REVIEWER).subscribe((data: Array<any>) => {
      this.reviewUsersList = data;
      this.reviewUsersList.forEach((element) => {
        if(element["id"] == this.reviewerId){
          this.reviewer = element;
          this.initialReviewer = element;
        }
      });
    });
  }
  }

  updateReviewerInfo(){
    if(this.reviewer == null || this.reviewer.length == 0){
      this.toastr.error("Please select a reviewer");
    }
    else{
    this.rfeService.updateReviewer(this.SubTaskOverView['id'], this.reviewer.id).subscribe((response: any) => {
      if (response.status == 200) {
        this.initialReviewer = this.reviewer;
        this.toastr.success(response.message);
        this.notify.emit(stepStatus.Inprogress);
        this.getRFEDetails();
      } 
    });
  }
  }

  getSupportingDocuments(){
    this.rfeService
      .getSupportingDocuments(this.SubTaskOverView['id'])
      .subscribe((data) => {
        this.supportingDocuments = data; 
      });
  }

  viewDocument(item) {
    const docPath = item.substring(23);
    window.open(`${environment.docs}${docPath}`, "_blank");
  }

  notifyReviewer(){
    if(this.reviewer == null || this.reviewer.length == 0){
      this.toastr.error("Please select/update the reviewer info");
    }
    else{
      if(this.initialReviewer == undefined || this.initialReviewer.length == 0) {
        this.toastr.error("Please select/update the reviewer info");
      }
      else{
    this.rfeService.notifyReviewer(this.SubTaskOverView['id']).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message);
        this.notify.emit(stepStatus.Inprogress);
      } 
    });
  }
  }
  }

  notifypreparer(){
    this.rfeService.notifyPreparer(this.SubTaskOverView['id']).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message);
        this.notify.emit(stepStatus.Inprogress);
      } 
    });
  }

  sendCommentFromReviewer(){
    this.rfeService.sendCommentFromReviewer(this.SubTaskOverView['id'],this.commentFromReviewer).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message);
        this.notify.emit(stepStatus.Inprogress);
        this.model.hide();
      } 
    });
  }

  changeStatusToComplete(){
    this.notify.emit(stepStatus.Complete);
  }

  attorneySelected() {
    if (this.selectedAttorneyFirm && this.selectedAttorneyFirm !== "") {
      // get attorney company users  attorneyUsers
      this.initialReviewer = [];
      this.reviewer = [];
      this.lookupService.getUsersOfCompany(this.selectedAttorneyFirm.attorneyId).subscribe((data: Array<any>) => {
        this.reviewUsersList = data;
        this.reviewUsersList.forEach((element) => {
          if(element["id"] == this.reviewerId){
            this.reviewer = element;
            this.initialReviewer = element;
          }
        });
      });
    }
    else {
      this.reviewUsersList = [];
    }
  }



}
