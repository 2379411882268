import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OccupationDeterminationService {

    constructor(private httpClient: HttpClient) { }

    getOccupationDetails(visaType, caseType, caseId) {
        return this.httpClient.get(`/${visaType}/${caseType}/${caseId}/occupationDetails`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getLcaOohDuties(visaType, caseType, caseId, lcaCodeId, full) {
        debugger
        return this.httpClient.get(`/${visaType}/${caseType}/${caseId}/lca/${lcaCodeId}/duties?full=${full}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getPositionDutyList(visaType, caseType, caseId, positionId) {
        return this.httpClient.get(`/${visaType}/jobduty/${caseType}/${caseId}/position/${positionId}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    getOccupationDetermination(visaType, caseType, caseId) {
        return this.httpClient.get(`/${visaType}/${caseType}/${caseId}/JobDutyOOHDuty`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

    postOohJobDutiesMapping(visaType, caseType, caseId, payload) {
        return this.httpClient.post(`/${visaType}/${caseType}/${caseId}/JobDutyOOHDuty`, payload).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response && response.status === 200) {
                    return response;
                }
            })
        );
    }

    getJobDescriptionDetails(visaType, caseType, caseId, isThisPastJob){
        return this.httpClient.get(`/${visaType}/${caseType}/${caseId}/jobDescription?isThisPastJob=${isThisPastJob}`).pipe(
            map((response: ImagilityBaseResponse) => {
                if (response.status === 200) {
                    return response;
                }
            })
        );
    }

}