import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BillingInvoiceService } from 'src/app/services/billing-invoice.service';

@Component({
  selector: 'app-send-receipt',
  templateUrl: './send-receipt.component.html',
  styleUrls: ['./send-receipt.component.scss']
})
export class SendReceiptComponent implements OnInit {

  sendReceiptForm: FormGroup;
  @Input() invoiceId: number;
  ToMails: any = [];
  invoiceData: any;
  toMails: any ;
  invoiveNumber: number;
  clientName: string;
  paymentAmount: number;
  bodyline: string;
  body: string;
  comment: string;

  // bodyline: string ="Dear XXXX(Client Name) we have received your payment against the Invoice number XXXX for $XXXX(amount). Thank you."

  constructor(  public fb: FormBuilder,
    private invoiceservice: BillingInvoiceService,
    private toaster: ToastrService,
    private router: Router) {
      this.sendReceiptForm = this.fb.group({
        subject: ["Payment Received"],
        body: [""],
        toMails: [""]

  });
}

  ngOnInit(): void {

  // console.log( 'invoice',this.invoiceId);
  this.invoiceservice.getPreviewInvoiceDetails(this.invoiceId).subscribe((res: any) =>{
    this.invoiceData = res;
    this.clientName = res.billedTocompany.companyName;
    this.paymentAmount = res.grandTotal;
    this.invoiveNumber = res.invoiceNumber;
    // this.sendReceiptForm.patchValue({
    //   body:"Dear XXXX ${this.clientName} we have received your payment against the Invoice number XXXX for $XXXX(amount). Thank you."]
    // })
    this.body="Dear "+"" +this.clientName+","+'\r\n'+"  We have received your payment against the Invoice number "+ this.invoiveNumber+" for "+"$"+this.paymentAmount+'\r\n'+" Thank you.";
   this.toMails = this.invoiceData.emailRecipients.map(val =>{
    return val;
   });
    // console.log('mail', this.invoiceData.emailRecipients, this.toMails);
  })

  //this.toMails = this.invoiceData.emailRecipients;
  }
  sendReceipt(){
    // console.log('test', this.sendReceiptForm.value);
    // this.ngZone.run(() => {
    //   this.isClicked = true;
    // });

    let payload = this.sendReceiptForm.value;
    // console.log("SendInvoice", this.sendReceiptForm.value);

    let reqObj = {
      body: this.sendReceiptForm.get("body").value,
      subject: this.sendReceiptForm.get("subject").value,
      toMails: this.sendReceiptForm.get("toMails").value,
    };
    // console.log("invoiceId", this.invoiceId);
    // console.log("this.sendInvoiceForm.value", this.sendReceiptForm.value);
    // console.log("SendInvoice reqObj", reqObj);
    this.invoiceservice.sendReceipt(this.invoiceId, reqObj).subscribe(
      (resp: any) => {
        // console.log("sendInvoice", resp);
        // console.log("sendInvoice :: status+", resp.status + "///");

        if (resp.status == 200) {
          this.toaster.success(resp.message);
          this.router.navigate(["/attorney-landing/attorney-invoice"]);
        } else {
          this.toaster.warning(resp.message);
        }
      },
      (err) => {
        this.toaster.error(err.message);
        console.log("sendInvoice" + "Error while sending invoice" + err);
      }
    );
  }

}
