import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileUploadLibraryService } from 'file-upload-library';
import { SchedulingService } from 'src/app/services/scheduling.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs'

import { ConfirmationModalComponent } from "src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component";
import { acceptedFileTypes } from 'app-models';
interface City {
  name: string,
  code: string
}

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  @Output() removeElement: EventEmitter<number> = new EventEmitter();
  @Input('obj') eventEditObj;
  @ViewChild('eventpageDiv') eventpageDiv: ElementRef<HTMLElement>;
  obs: Subscription;
  eventsForm: FormGroup;
  cities: any = [];
  userType: string;
  recurrencedropdown: any = [];
  recurrence: City;
  showRecurrnece: boolean = false;
  timedropdown: any = [];
  endTime: City;
  startTime: City;
  privateMeeting: string;
  publicMeeting: string;
  addAttendes: boolean = false;
  navUrl: any;
  eventAttendes: any;
  filteredeventAttendes: any;
  filtered: any;
  UserToken: string;
  calendarItemId: any;
  series: any;
  uploadedFile: any;
  public payload = new FormData();
  uploadedFiles: any = [];
  eventEditDetails: any;
  eventDoc: any = [];
  required: boolean;
  optional: boolean;
  empNotfound: boolean;
  optEmail: any = [];
  requiredEmails: any = [];
  calenscheduleId: any;
  optImgEmail: any = [];
  requiredImgEmails: any = [];
  companyId: any;
  minimumDate: any;
  eventpageLoaded: boolean = false;
  observableSubscription$ = new Subject();
  isPublic: any;
  eventdocId: any;
  acceptedTypes = acceptedFileTypes;



  constructor(private router: Router,
    private fb: FormBuilder,
    private schedulingservice: SchedulingService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private confirmationService: ConfirmationService,) {
    this.companyId = sessionStorage.getItem('companyId');
    this.eventsForm = this.fb.group({
      subject: ['', Validators.required],
      attendiesList: [''],
      calItemTypeCode: ['EVENT'],
      calendarItemId: ['0'],
      calendarScheduleId: ['0'],
      startTime: ['', Validators.required],
      endDate: ['', Validators.required],
      endTime: ['', Validators.required],
      startDate: ['', Validators.required],
      isAllday: 0,
      isPublic: [],
      isSeries: 0,
      meetingCompanyName: [''],
      meetingLocation: ['', Validators.required],
      description: [''],
      reqattendiesList: ['', Validators.required],
      optimagilityattendiesList: [''],
      optattendiesList: [''],
      reqImagilityattendiesList: ['']
    });
    this.userType = sessionStorage.getItem("userTypeRole");
    if (this.userType == "Petitioner") {
      this.navUrl = "/petitioner-landing/dashboard/petitioner-dashboard/scheduling/";
    } else if (this.userType == "Attorney") {
      this.navUrl = "/attorney-landing/dashboard/attorney-dashboard/scheduling/";
    }
    this.minimumDate = new Date();
    this.eventpageLoaded = true;
  }



  ngOnInit(): void {
    this.UserToken = sessionStorage.getItem('UserToken');
    console.log('event', this.eventEditObj)
    if(this.eventEditObj){
    this.calendarItemId = this.eventEditObj.id;
    this.series = this.eventEditObj.isSeries;
    this.schedulingservice.getCalendarItemDetails(this.calendarItemId, this.series).subscribe(res => {
      this.eventEditDetails = res['data']
      this.schedulingservice.getEventDoc(this.eventEditDetails.parentId).subscribe(res => {
        this.eventDoc = res['data'];
        this.eventdocId = res.data[0].id;
        console.log('doc', this.eventDoc)
      })
      if (this.eventEditDetails) {
        if (this.eventEditDetails) {
          this.requiredEmails = this.eventEditDetails.required.map(val => {
            return val.emailId;
          });
          this.optEmail = this.eventEditDetails.optional.map(val => {
            return val.emailId;
          })
          this.eventsForm = this.fb.group({
            subject: [this.eventEditDetails.title, Validators.required],
            description: [this.eventEditDetails.description],
            attendiesList: [[]],
            calItemTypeCode: [this.eventEditDetails.calendarItemType],
            calendarItemId: [this.eventEditDetails.id],
            calendarScheduleId: ['0'],
            meetingLocation: [this.eventEditDetails.location, Validators.required],
            startTime: [this.eventEditDetails.startTime, Validators.required],
            endTime: [this.eventEditDetails.endTime, Validators.required],
            startDate: [moment(this.eventEditDetails.startDate).format('MM/DD/YYYY'), Validators.required],
            endDate: [moment(this.eventEditDetails.endDate).format('MM/DD/YYYY')],
            isAllday: 0,
            isPublic: this.eventEditDetails.isPublic,
            reqattendiesList: [this.eventEditDetails.required.map((value) => {
              return value.emailId
            }), Validators.required],
            optattendiesList: [this.eventEditDetails.optional.map((value) => {
              return value.emailId
            })],
            reqImagilityattendiesList: [this.eventEditDetails.required],
            optimagilityattendiesList: [this.eventEditDetails.optional],

          });

        }
        // this.eventsForm.patchValue({
        //   reqattendiesList:this.eventEditDetails.required.map((value )=> {
        //       return value.emailId
        //     })
        // })

        // this.eventsForm = this.fb.group({
        //   subject: [this.eventEditDetails.title, Validators.required],
        //   description: [this.eventEditDetails.description],
        //   attendiesList: [[], Validators.required],
        //   calItemTypeCode: [this.eventEditDetails.calendarItemType],
        //   calendarItemId: [this.eventEditDetails.id],
        //   calendarScheduleId: ['0'],
        //   meetingLocation: [this.eventEditDetails.location, Validators.required],
        //   startTime: [this.eventEditDetails.startTime, Validators.required],
        //   endTime: [this.eventEditDetails.endTime, Validators.required],
        //   startDate: [moment(this.eventEditDetails.startDate).format('MM/DD/YYYY'), Validators.required],
        //   endDate: [moment(this.eventEditDetails.endDate).format('MM/DD/YYYY')],
        //   isAllday: 0,
        //   isPublic: 1,
        //   isSeries: 0,
        //   meetingCompanyId: ['272'],
        //   reqattendiesList:[this.eventEditDetails.required.map((value )=> {
        //     return value.emailId
        //   })],
        //   optattendiesList:[this.eventEditDetails.optional.map((value )=> {
        //     return value.emailId
        //   })],
        //   reqImagilityattendiesList:[this.eventEditDetails.required ],
        //   optimagilityattendiesList:[this.eventEditDetails.optional],
        // });
      }
      // let el: HTMLElement = this.eventpageDiv.nativeElement;
      // el.click();
    })
  }
  }


  ngAfterViewInit() {
    let control = this.eventsForm.get('isPublic');
    this.obs = control.valueChanges.subscribe(e => { control.setValue(e, { emitEvent: false }); });
  }

  Upload(event, fileUpload) {
    fileUpload.clear();
    this.uploadedFile = event.files[0];
    this.uploadedFile["fileName"] = event.files[0].name;
    this.payload = new FormData();
    this.payload.append("file", this.uploadedFile, this.uploadedFile.name);

  }

  //  Upload(event,fileUpload) {
  //   //this.uploadDocument();

  //   for(let file of event.files) {
  //     this.uploadedFile = file;
  //     this.uploadedFile["fileName"] = file.name;
  //   }
  //   let payload = new FormData();
  //   payload.append("file", this.uploadedFile , this.uploadedFile.fileName);
  //   fileUpload.clear();
  // }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
      this.payload.append("file", this.uploadedFiles, this.uploadedFile.fileName);
    }
  }

  save() {
    let eventObj = this.eventsForm.value;
    this.addmeetingAttendes();
    this.eventsForm.value.isPublic = parseInt(this.isPublic);
    this.eventsForm.value.startDate = moment(this.eventsForm.get("startDate")?.value).format('YYYY-MM-DD'),
      this.eventsForm.value.endDate = moment(this.eventsForm.get("endDate")?.value).format('YYYY-MM-DD'),
      this.schedulingservice.saveMeeting(eventObj).subscribe(response => {
        console.log('EVENT', response);
        if (response.status == 200) {
          this.toastr.success(response.message);
          this.router.navigate([this.navUrl]);
          this.calenscheduleId = response.data.calendarScheduleId;
          this.schedulingservice.eventUpload(this.payload, this.calenscheduleId).subscribe(res => {
            this.toastr.success(res.message);
            this.router.navigate([this.navUrl]);
            console.log('uploaded');
            error => {
              console.log('error in upload');
            }
          })
        }

      },
        error => {
          console.log('meeting error', error)
        }
      );
  }

  closeEmailpopup() {
    this.required = false;
    this.optional = false;
  }

  serachAttendes(event) {
    let query = event.query;
    this.schedulingservice.getAttendesList1(this.UserToken, query, this.companyId).subscribe(res => {
      this.eventAttendes = res['data'];
      if (res.status == 204) {
        this.empNotfound = true;
      }
      if (this.eventAttendes && this.eventAttendes.length) {
        this.empNotfound = false;
        this.filtered = this.eventAttendes.map(element => {
          var attendesObj = {
            "name": element.firstName + ' ' + element.lastName,
            "emailId": element.email,
            "userId": element.userId,
            "isOptional": 0,

          }
          return attendesObj;
        })
        this.filteredeventAttendes = this.filtered;
      }
    },
      error => {
        console.log(error)
      }
    )
  }
  addmeetingAttendes() {
    let attendesArray = [];
    if (this.eventsForm.value.reqattendiesList.length > 0) {
      const temp = this.eventsForm.value.reqattendiesList.map(val => {
        return {
          "name": null,
          "emailId": val,
          "isOptional": 0,
          "userId": null
        }
      });
      attendesArray = [...attendesArray, ...temp];
      this.eventsForm.value.attendiesList = attendesArray;
    }
    if (this.eventsForm.value.optattendiesList.length > 0) {
      const temp2 = this.eventsForm.value.optattendiesList.map(val => {
        return {
          "name": null,
          "emailId": val,
          "isOptional": 1,
          "userId": null
        }
      });
      attendesArray = [...attendesArray, ...temp2];
      this.eventsForm.value.attendiesList = attendesArray;
    }
    //   if(this.eventsForm.value.reqImagilityattendiesList.length >0){
    //      const temp3 = this.eventsForm.value.reqImagilityattendiesList;
    //      attendesArray = [...attendesArray,...temp3];
    //      this.eventsForm.value.attendiesList = attendesArray;
    //   }
    //   if(this.eventsForm.value.optimagilityattendiesList.length >0){
    //     const temp4 = this.eventsForm.value.optimagilityattendiesList;
    //     attendesArray = [...attendesArray,...temp4];
    //     this.eventsForm.value.attendiesList = attendesArray;
    //  }
    this.eventsForm.value.attendiesList = attendesArray;
    console.log('emailList', attendesArray)
  }
  update() {
    let eventObj = this.eventsForm.value;
    this.eventsForm.value.calItemTypeCode = 'EVENT';
    this.eventsForm.value.isSeries = 1;
    this.eventsForm.value.isPublic = parseInt(this.isPublic);
    this.eventsForm.value.startDate = moment(this.eventsForm.get("startDate")?.value).format('YYYY-MM-DD'),
      this.eventsForm.value.endDate = moment(this.eventsForm.get("endDate")?.value).format('YYYY-MM-DD'),
      this.addmeetingAttendes();
    this.schedulingservice.updateMeeting(eventObj).subscribe(response => {
      console.log('EVENT', response);
      if (response.status == 200) {
        this.router.navigate([this.navUrl]);
        this.toastr.success(response.message);
        this.calenscheduleId = response.data.calendarScheduleId;
        this.schedulingservice.eventUpload(this.payload, this.calenscheduleId).subscribe(res => {
          this.toastr.success(response.message);
          this.router.navigate([this.navUrl]);
          console.log('uploaded');
          error => {
            console.log('error in upload');
          }
        })
      }

    },
      error => {
        console.log('meeting error', error)
      }
    );

  }

  cancel() {
    let cancelpayload = {
      "calendarId": this.eventEditDetails.parentId,
      "calendarItemId": this.eventEditDetails.id,
      "calendarItemType": this.eventEditDetails.calenderItemType,
      "isCancelAllItems": true
    }
    this.schedulingservice.cancelCalendarItem(cancelpayload).subscribe(res => {
      if (res) {
        this.toastr.success(res.message);
        this.router.navigate([this.navUrl]);
      }
    })
  }

  eventcancel() {
    this.router.navigate([this.navUrl]);
  }

  addImagilityEmail() {
    this.optional = true;
    this.eventsForm.patchValue({
      optimagilityattendiesList: null
    });
    this.requiredImgEmails = [];
  }

  requiredImagilityEmail() {
    this.required = true;
    this.eventsForm.patchValue({
      reqImagilityattendiesList: null
    });
    this.optImgEmail = [];
  }

  addEmail() {
    let reuiredemails = this.eventsForm.value.reqImagilityattendiesList.map(val => val.emailId)
    this.requiredEmails = [...this.eventsForm.value.reqattendiesList, ...reuiredemails]
  }
  addoptEmail() {
    let optionalemails = this.eventsForm.value.optimagilityattendiesList.map(val => val.emailId);
    this.optEmail = [...this.eventsForm.value.optattendiesList, ...optionalemails]
  }


  checkEndDateTouched(sd) {
    if (sd.value) {
      this.eventsForm.patchValue({
        endDate: sd.value
      })
    }
  }

  confirmDelete(uploadedFile) {
    this.deleteDoc(uploadedFile);


  }
  deleteDoc(item) {
    const title = 'Confirm';
    const message = 'Do you wish to Delete the File?';
    if (item) {
          this.dialogueInitializer(event, title, message).afterClosed().subscribe(result => {
            if (result) {
              this.schedulingservice.deleteEventDocument(this.eventdocId).subscribe((data: any) => {
                if (data['status'] === 200) {
                  this.toastr.success(data.message);
                  this.schedulingservice.getEventDoc(this.eventEditDetails.parentId).subscribe(res => {
                    this.eventDoc = res['data'];

                  
                  })
                }
              this.removeElement.emit(item);
              })
            }
          })
          

        
      
      
    }
  }

  dialogueInitializer(event, title: string, message: string) {
    const cordinates: MouseEventInit = event;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: ['Cancel', 'Delete']
      }
    });
    return dialogRef;
  }





}
