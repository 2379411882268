import { AfterViewInit, Component, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Form, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
//import { FroalaEditorOptions } from '../../../../../data/constants/froala-editor-options';
import { I140FileUploadConfiguration } from 'app-models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { sync_petitioner_document_code } from '../../../utility/constants/sync-code';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { ImagilityBaseResponse, PrimaryData, TaskStep } from 'app-models';
import { select } from '@ngrx/store';
//import * as  froalaService from 'src/app/modules/utility/froala-service';

import { BeneficiaryWorkExperience } from 'src/app/modules/green-card/green-card-models/beneficiary-work-experience';
import { ExperienceDetail } from 'src/app/modules/green-card/green-card-models/experience-detail';
import * as fromGCSubTask from 'src/app/modules/green-card/green-card-store/reducers/greencard-subtask.reducer';
import * as DirtyForm from 'src/app/app-state/actions/dirty-form.actions';
import { CacheCountryService } from 'src/app/services/cacheCountries.service';
import { Subject } from 'rxjs/internal/Subject';
import { ApiServicesService } from 'src/app/modules/beneficiary/services/api-services.service';
import { getStepDetails, getStepStatusUpdate, loadSubTaskDetails, getSubTaskDetails, updateStepStatus, updateStepStatusToInitialState, getVisaTypeCode } from 'visa-store';
import { I140FileUploadService } from 'src/app/services/i140-file-upload.service';
import { environment } from 'environment-configurations';
import * as errorUtils from "error-message-utility";
import { combineLatest } from 'rxjs';
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';
import { DialogService } from 'primeng/dynamicdialog';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { ResearchContributionService } from './research-contribution.service';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { UploadImageDialogComponent } from "../../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { AppServicesLibraryService } from 'app-services-library';
export const DeleteDialogConfigurations = {
  width: '30%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { "z-index": "1001" }
};

@Component({
  selector: 'app-research-contribution',
  templateUrl: './research-contribution.component.html',
  styleUrls: ['./research-contribution.component.scss']
})
export class ResearchContributionComponent implements OnInit, AfterViewInit {
  @ViewChild("researchcontributionimageupload") public researchcontributionimageupload: UploadImageDialogComponent;
@Output() @ViewChild("researchcontributionkendoeditor") public researchcontributionkendoeditor: EditorComponent;
  researchForm: FormGroup;
  disabledControls: boolean;
  subcriptions: Subscription[] = [];
  formDirtyFromDifferentControls = false;
  isFormValid = false;
  experienceList: ExperienceDetail[];
  permBeneficiaryExperienceDetails: BeneficiaryWorkExperience;
  // public options = FroalaEditorOptions.EditorOptions;
  markStep: string;
  // public options = {
  //   ...FroalaEditorOptions.EditorOptions,
  //   events: {
  //     contentChanged: () => {
  //       this.setFormDirty();
  //     }
  //   }
  // };
  stepDetails: TaskStep;
  countryList: any;
  observableSubscription$ = new Subject();
  stateList: [];
  addEditButtonText: string = 'Add';

  additionalInfo;
  primaryData;
  otherDocumentsFileUploadConfigurations: I140FileUploadConfiguration;
  researchList: any = [];

  task: SubTaskDetails;
  showCompleteButton: boolean;
  tabViewSteps: any;
  extrAblDocEvdCatg = [];
  stepStatus: any;
  currentDate = new Date();
  inputData: PrimaryData;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<fromGCSubTask.State>,
    public dynamicDialogConfig: DynamicDialogConfig,
    private cacheCountyService: CacheCountryService,
    private apiServiceState: ApiServicesService,
    private uploadService: I140FileUploadService,
    private researchService: ResearchContributionService,
    public dialogService: DialogService,
    private toastr: ToastrService,
    public stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    private appServicesLibraryService: AppServicesLibraryService
  ) {
    this.inputData = this.dynamicDialogConfig.data;
    this.researchForm = formBuilder.group({
      meetingPlace: ['', [Validators.required]],
      meetingDate: ['', [Validators.required]],
      id: [null]
    });
  }

  ngOnInit(): void {
    // this.otherDocumentsFileUploadConfigurations = Object.assign({})
    this.getListInfo();
    combineLatest([
      this.store.pipe(select(getSubTaskDetails)),
      this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
    ]).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any[]) => {
        this.task = response[0];
        this.stepStatus = response[1].stepStatusDetails.stepStatusCode;
        this.extrAblDocEvdCatg = this.task.extrAblDocEvdCatg;
        this.tabViewSteps = this.task.extrAblDocEvdList.sort((a, b) => (a['id'] > b['id']) ? 1 : -1);
        this.stepDetails = response[1];
        // if(this.stepStatus === 'COMPLETE') {
        //   this.disabledControls = true;
        // } else {
        //   this.disabledControls = false;
        // }
        this.showCompleteButton = false;
        if (this.tabViewSteps[this.tabViewSteps.length - 1]['code'] == 'RESEARCHCONTRBT') {
          this.showCompleteButton = true;
        }
        this.extrAblDocEvdCatg['infoType'] = "RESEARCHCONTRBT";
        this.otherDocumentsFileUploadConfigurations = Object.assign({}, {
          disableControls: this.disabledControls,
          isDocumentTypeRequired: true,
          isDocumentUploadRequired: true,
          isViewEnabled: true,
          isDownloadEnabled: true,
          isDeleteEnabled: true,
          // getDocTypeApiUrl:`/${this.dynamicDialogConfig.data.visatype}/petitionType/${this.dynamicDialogConfig.data.petitionType}/group/${sync_petitioner_document_code.SYNC_TASK_TYPE_Code_US}/documentTypes`,
          getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${sync_petitioner_document_code.SYNC_TASK_TYPE_Code_RESEARCH}/`,
          postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${sync_petitioner_document_code.SYNC_TASK_TYPE_Code_RESEARCH}/fileCategory/`,
          deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
          documentTypeDropdownCode: 'RESEARCHDOC'

        });
        this.getAdditionalInfo();
      });
    this.subcriptions[this.subcriptions.length] = this.researchForm.statusChanges.subscribe(() => {
      if (this.researchForm.dirty) {
        this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
      } else {
        this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
      }
    });
    let userType = sessionStorage.getItem('userTypeRole');
    if (userType === 'Beneficiary') {
      this.markStep = 'Mark Submit';
    } else {
      this.markStep = 'MARK STEP COMPLETE';
    }
  }

  ngAfterViewInit() {
    this.subcriptions[this.subcriptions.length] = this.researchForm.statusChanges.subscribe(() => {
      if (this.researchForm.dirty) {
        this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
      } else {
        this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
      }
    });
  }
  openresearchcontributionimageuploaddialog() {
    this.researchcontributionimageupload.open();
  }

  onMarkComplete() {
    if (this.stepDetails.stepStatusDetails.stepStatusCode === 'INPROGRESS') {
      if (sessionStorage.getItem('userTypeRole') === 'Beneficiary') {
        this.updateStepStatus('SUBMIT');
      } else {
        this.updateStepStatus('COMPLETE');
      }
    }
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(
        this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      stepId: this.dynamicDialogConfig.data.stepId,
      visaType: this.inputData.caseType === 'I130' ?
        this.inputData.caseType : this.inputData.visatype
    }));
    // this.toggleControlsStatus();
  }

  //Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;

    this.otherDocumentsFileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      // getDocTypeApiUrl:`/${this.dynamicDialogConfig.data.visatype}/petitionType/${this.dynamicDialogConfig.data.petitionType}/group/${sync_petitioner_document_code.SYNC_TASK_TYPE_Code_US}/documentTypes`,
      getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${sync_petitioner_document_code.SYNC_TASK_TYPE_Code_RESEARCH}/`,
      postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${sync_petitioner_document_code.SYNC_TASK_TYPE_Code_RESEARCH}/fileCategory/`,
      deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
      documentTypeDropdownCode: 'RESEARCHDOC'
    });
  }

  saveSummaryInfo() {
    let payload = {
      "additionalText": this.additionalInfo,
      "infoType": this.extrAblDocEvdCatg['infoType'],
      "infoName": "",
      "isDel": 0
    };
    this.researchService.saveSummary(this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType, this.dynamicDialogConfig.data.caseId, payload).subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.getAdditionalInfo();
          this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
          this.toastr.success(response.message);
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            this.updateStepStatus('INPROGRESS');
          }
        }
      });
  }


  getAdditionalInfo() {
    this.researchService.getSummaryInfo(this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType, this.dynamicDialogConfig.data.caseId, this.extrAblDocEvdCatg['infoType']).subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          // this.toastr.success(response.message);
          this.additionalInfo = response['data']['additionalText'];
          //froalaService.insertPageBreak();
        }
      });
  }
  editDocument(document) {
    this.researchForm.patchValue({
      meetingPlace: document.meetingPlace,
      meetingDate: new Date(document.meetingDate),
      id: document.resContributionId
    });
    this.addEditButtonText = 'Update';
  }

  cancelForm() {
    this.researchForm.reset();
  }
  deleteDocument(id) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.researchService.deleteResearch(
          this.dynamicDialogConfig.data.petitionId, id,
        ).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message);
          this.researchForm.reset();
          this.addEditButtonText = 'Add';
          this.getListInfo();
        });
      }
    });
  }


  saveResearch() {
    let formData = this.researchForm.value;
    // const selectedState: any = this.stateList.find((element: any) => element.stateProvinceCode === formData.state)
    let payload = {
      "docEvidenceCode": "RESEARCHCONTRBT",

      meetingPlace: formData.meetingPlace,
      meetingDate: formData.meetingDate ?
        moment(formData.meetingDate).format('YYYY-MM-DD') : null,
      "resContributionId": formData.id,
    };
    this.researchService.saveReserach(this.dynamicDialogConfig.data.petitionId, payload).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.toastr.success(response.message);
        this.getListInfo();
        this.addEditButtonText = 'Add';
        this.researchForm.reset();
        if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
          this.updateStepStatus('INPROGRESS');
        }

      }
    });
  }
  setFormDirty() {
    this.formDirtyFromDifferentControls = true;
    this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
  }


  getListInfo() {
    this.researchService.getList(this.dynamicDialogConfig.data.petitionId).subscribe((response: ImagilityBaseResponse) => {
      console.log('res', response);
      if (response && response['data']) {
        this.researchList = response['data'];
        this.toastr.success(response.message);
      } else {
        this.researchList = [];
      }
    });
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }
  public researchcontributioneditorValueChange(value: string): void {
    this.additionalInfo = value;
    this.setFormDirty();
  }
}
