import { AfterContentInit, Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ApiServicesService } from '../../services/api-services.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as Roles from '../../../../data/constants/roles';
import { AuthenticationService } from '../../../../services/authentication.service';
import { UploadDocumentService } from '../../../../services/upload-document.service';
import { License } from '../../../../data/models/license.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { environment } from '../../../../../environments/environment';
import { ImagilityBaseResponse } from '../../../../data/models/response.model';
import { UtilityServiceService } from '../../services/utility-service.service';
import * as errorUtils from 'src/app/modules/utility/global-utils';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modules/shared/modal/confirmation-modal/confirmation-modal.component';
import { FileDownloadComponent } from 'src/app/modules/shared/file-download/file-download.component';
import { Store } from '@ngrx/store';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'src/app/app-state/actions/dirty-form.actions';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DependentProfileDataService } from '../../services/dependent-profile-data.service';
import { BeneficiaryQuestionnaireService } from 'src/app/modules/beneficiary-questionnaire/beneficiary-questionnaire.service';
import { ActivatedRoute } from '@angular/router';
import { acceptedFileTypes } from 'app-models';

@Component({
  selector: 'app-beneficiary-license',
  templateUrl: './beneficiary-license.component.html',
  styleUrls: ['./beneficiary-license.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class BeneficiaryLicenseComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input()   showLifeStoryTimeLineSection: boolean;
  @Input() mode :string;
  @Input() isViewMode :boolean;
  @Input() caseRequestShowDivs: any;
  @Input() benQuestShowDivs: any;
  iscaseRequest: boolean = false;
  currentItemDisplay: any = [];
  modeVal: boolean = false;
  showEditButton: boolean = true;
  expandPanel = false;
  updateBenProfile = true;
  selectedIndex = 0;
  licForm: NgForm;
  topic;
  license = new License({});
  beneTrainingsForm: FormGroup;
  trainingsDetails;
  beneficiaryId: number;
  categoryName = 'BENLICDOC';
  listUploadedFiles = [];
  infiniteDtFlag = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  hasID = false;
  isLinear = true;
  today: Date = new Date();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  listAddedSubjects: string[] = [];
  envPath: any;
  viewMode = false;
  configurations = {
    inputMultipleFlag: true,
    fileType: acceptedFileTypes,
    isFileListRequired: true,
    categoryName: 'BENLICDOC',
    entityId: 0,
    fileCategory: '',
    listUploadedFiles: [],
    documentTypeRequired: true,
    docummentTypeCode: 'BENLICDOC',
    useCase: 'beneficiary',
    useCaseId: 0,
    useEntityId: false,
    isDeleteEnabled: this.viewMode ? false : true,
    isUploadEnabled: this.viewMode ? false : true,
    familyId: null
  };

  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;

  subDisplayedColumns: string[] = [];
  listSubjects: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  expandedElement: null;
  subjectsColumns = [
    { columnDef: 'subject', header: 'Added Topics', columnType: 'text', customClass: '' },
    { columnDef: 'action', header: 'Action', columnType: 'icon', customClass: '' }
  ];

  columns = [
    { columnDef: 'toggle', header: '', columnType: 'icon', customClass: '' },
    { columnDef: 'name', header: 'Name', columnType: 'text', customClass: '' },
    { columnDef: 'institution', header: 'Organization', columnType: 'text', customClass: '' },
    { columnDef: 'startDate', header: 'Start Date', columnType: 'text', customClass: '' },
    { columnDef: 'endDate', header: 'End Date', columnType: 'text', customClass: '' },
    { columnDef: 'action', header: 'Action', columnType: 'icon', customClass: '' }
  ];

  isListView = false;
  isTimeLineView = true;
  licenseData = [];
  destroy$ = new Subject<void>();
  formChangesSubscriptions: any[] = [];
  dependentIdSubscription: Subscription;
  familyId: any;
  beneficiaryType: any;
  dependentId: any;
  observableSubscription$ = new Subject();
  isSaveAsDraftDisabled: boolean = true;
  isExternalEmployeeId:string;
  isLocked:boolean=false;
  onlyFormBuilder;
  isBenQuestShowDivs: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private apiService: ApiServicesService,
    private toastr: ToastrService,
    private uploadDocumentService: UploadDocumentService,
    private utilityService: UtilityServiceService,
    public dialog: MatDialog,
    protected store: Store<any>,
    private dependentProfileDataService: DependentProfileDataService,
    private benServ: BeneficiaryQuestionnaireService,
    private route: ActivatedRoute,
  ) {
    this.isExternalEmployeeId = sessionStorage.getItem('_isExternalEmployeeId');
    if(this.isExternalEmployeeId){
      this.isLocked=true;
    }
  }

  ngOnInit() {
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
    this.viewMode = sessionStorage.getItem('bene-profile-viewMode') == 'true' ? true : false;
    this.authenticationService.currentUser.pipe(takeUntil(this.observableSubscription$)).subscribe((data) => {
      if (sessionStorage.getItem('userTypeRole') === Roles.ATTORNEY || sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
        this.updateBenProfile = this.utilityService.checkBeneEditPermission();
        this.viewMode = !this.updateBenProfile;
      }
    });
    this.onlyFormBuilder = this.route.snapshot.queryParams.pageFrom;
    if(this.onlyFormBuilder === 'FB') {
      this.beneficiaryId = (sessionStorage.getItem('FbEmployeeId')) ? parseInt(sessionStorage.getItem('FbEmployeeId')) : 0;
    } else {
      this.beneficiaryId = (sessionStorage.getItem('listBeneficiaryId')) ? parseInt(sessionStorage.getItem('listBeneficiaryId')) : this.authenticationService.currentUserValue['beneficiaryId'];
    }
    this.dependentIdSubscription = this.dependentProfileDataService.dependentProfileSubject.subscribe(selectedBeneObj => {
      this.familyId = selectedBeneObj.dependentId;
      this.beneficiaryType = selectedBeneObj.beneficiaryType;
      this.showLifeStoryTimeLineSection = (this.beneficiaryType === 'family' && this.familyId) ? true :
        (this.beneficiaryType === 'self') ? true : false;

      if(this.isBenQuestShowDivs && sessionStorage.getItem('isCaseRequest')){
        if(this.beneficiaryType === 'self'){
          this.iscaseRequest = true;
        } else {
          this.iscaseRequest = false;
        }
      }
      this.getLicense();
    });

    // this.getLicense();
    this.showEditButton = !this.benServ.checkValidity();
    this.dependentProfileDataService.onLifeStorySubTabs.next();

    this.envPath = environment.docs;

    // IM-3066 - remove deleted file.
    this.apiService.deletedfile$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getLicense();
      }
    });
    this.uploadDocumentService.uploaded$.pipe(takeUntil(this.observableSubscription$)).subscribe(status => {
      if (status === true) {
        this.getLicense();
      }
    });

  }

  ngAfterContentInit() {
    this.displayedColumns = this.columns.map(c => c.columnDef);
    this.subDisplayedColumns = this.subjectsColumns.map(c => c.columnDef);
  }

  formCheck(licForm) {
    this.licForm = licForm;
    this.formChangesSubscriptions[0] = licForm.statusChanges.pipe(takeUntil(this.observableSubscription$)).subscribe(() => {
      if (licForm.dirty) {
        this.store.dispatch(new MarkDirtyFormAction({
          dirty: true
        }));
      }
      else {
        this.store.dispatch(new MarkCleanFormAction({
          dirty: false
        }));
      }
    });
    this.formChangesSubscriptions[1] = this.licForm.valueChanges?.pipe(takeUntil(this.observableSubscription$)).subscribe(formValues => {
      this.isSaveAsDraftDisabled = !Object.values(formValues).some((val) => !!val || val === 0);
    });
    // console.log(' this.licForm',  this.licForm);
  }

  onNewTopicEnter(event) {
    this.store.dispatch(new MarkDirtyFormAction({
      dirty: true
    }));
  }

  toggleExpansion() {
    this.expandPanel = !this.expandPanel;
    this.hasID = false;
    this.isLinear = true;
    this.infiniteDtFlag = false;
    this.license = {
      id: 0,
      name: '',
      subjects: '',
      institution: '',
      startDate: '',
      endDate: ''
    };
    this.listUploadedFiles = [];
    this.configurations.listUploadedFiles = [];
    this.configurations.entityId = 0;
    this.configurations.familyId = this.familyId ? this.familyId : null;
    this.configurations = Object.assign({}, this.configurations);
    this.listSubjects = new MatTableDataSource();
  }

  getLicense() {
    this.apiService.getTrainingsDetails(this.beneficiaryId, 'LICENSE', this.familyId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(data => {
        data['profEducation'].map((item) => {
          if (item.subjects) {
            item.subjects = item.subjects.split(',');
          }
        });
        data['profEducation'].map((item) => {
          item.startDate = item.startDate ? moment(item.startDate).format('DD-MMM-YYYY') : '';
          item.endDate = item.endDate ? moment(item.endDate).format('DD-MMM-YYYY') : '';
        });
        this.licenseData = data['profEducation'];
        this.dataSource = new MatTableDataSource(data['profEducation']);
      });
  }

  handleEdit(input) {
    // console.log(input);
    if (this.expandPanel !== true) {
      this.expandPanel = true;
      if (input && input.endDate === '') {
        this.infiniteDtFlag = true;
      }
      this.license = {
        id: input.id,
        name: input.name,
        subjects: input.subjects,
        institution: input.institution,
        startDate: input.startDate ? moment(input.startDate).toISOString() :'',
        endDate: (input.endDate) ? moment(input.endDate).toISOString() : ''
      };
      const obj = [];
      if (input.subjects) {
        input.subjects.forEach((item) => {
          obj.push({ 'subject': item });
        });
      }
      this.hasID = true;
      this.isLinear = false;
      this.listSubjects = new MatTableDataSource(obj);
      this.license.subjects = input.subjects && input.subjects.length ? input.subjects.join() : []
      this.configurations.entityId = input.id;
      this.configurations.useCaseId = this.beneficiaryId;
      this.configurations.familyId = this.familyId ? this.familyId : null;
      this.listUploadedFiles = input.documents ? this.listUploadedFiles.concat(input.documents) : [];
      this.configurations.listUploadedFiles = input.documents ? this.configurations.listUploadedFiles.concat(input.documents) : [];
      this.configurations = Object.assign({}, this.configurations);
    }
  }

  handleDelete(input, licForm) {
    const title = 'Confirm';
    const message = 'Do you wish to remove this item?';
    this.dialogueInitializer(event, title, message).afterClosed().subscribe(result => {
      if (result) {
        this.apiService.deleteTrainingsDetails(this.beneficiaryId, 'LICENSE', input.id, this.familyId)
          .pipe(takeUntil(this.observableSubscription$))
          .subscribe((response: ImagilityBaseResponse) => {
            if (response.status === 200) {
              if (this.license.id === input.id) {
                this.resetForm(licForm);
              }
              this.toastr.success(response.message);
              this.getLicense();
            }
          });
      }
    });
  }

  saveLicenseTrianing(licForm, isDraft:boolean = false) {
    const payload = {
      id: this.license.id || 0,
      name: this.license.name,
      subjects: this.license.subjects,
      institution: this.license.institution,
      startDate: this.license.startDate ? moment(this.license.startDate).format('YYYY-MM-DD') : null,
      endDate: (this.license.endDate) ? moment(this.license.endDate).format('YYYY-MM-DD') : null,
      isSaveAsDraft : isDraft
    };
    // if (sessionStorage.getItem('userTypeRole') === Roles.PETITIONER) {
    //   this.apiService.saveBeneficiaryTrainingLicenseByPetitioner(parseInt(sessionStorage.getItem('petitionId')), 'license', payload).pipe(takeUntil(this.observableSubscription$)).subscribe(response => {
    //     if (response.status === 200) {
    //       this.license.id = response.data['profEducation'][0].id;
    //       this.toastr.success(response.message);
    //       response.data['profEducation'].forEach(element => {
    //         this.configurations.entityId = element.id;
    //         this.configurations.familyId = this.familyId ? this.familyId : null;
    //         this.configurations = Object.assign({}, this.configurations);
    //       });
    //       this.configurations.useCaseId = this.beneficiaryId;
    //       this.getLicense();
    //       this.hasID = true;
    //       this.isLinear = false;
    //       licForm.reset(licForm.value);
    //     } else {
    //       this.toastr.error(response.message);
    //     }
    //   });
    // } else {
    this.apiService.saveTrainingDetails(this.beneficiaryId, 'LICENSE', payload, this.familyId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(response => {
        if (response.status === 200) {
          this.license.id = response.data['profEducation'][0].id;
          this.toastr.success(response.message);
          response.data['profEducation'].forEach(element => {
            this.configurations.entityId = element.id;
            this.configurations.familyId = this.familyId ? this.familyId : null;
            this.configurations = Object.assign({}, this.configurations);
          });
          this.configurations.useCaseId = this.beneficiaryId;
          this.getLicense();
          this.hasID = true;
          this.isLinear = false;
          licForm.reset(licForm.value);
        } else {
          this.toastr.error(response.message);
        }
      });
    // }
  }

  getUpolodedFiles(id) {
    if (this.beneficiaryId && this.categoryName) {
      this.uploadDocumentService.genericGetUploadDocument(
        this.configurations.useCase, this.configurations.useCaseId, this.categoryName,
        this.configurations.entityId, this.configurations.useEntityId, this.configurations.familyId)
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((response) => {
          if (response['status'] === 200) {
            if (response['data'] && response['data'].length > 0) {
              response['data'].forEach(val => {
                if (val.entityId == id) {
                  this.listUploadedFiles.push(val);
                  this.configurations.listUploadedFiles.push(val);
                  this.configurations = Object.assign({}, this.configurations);
                }
              });
              // this.listUploadedFiles = response['data'];
              // this.configurations.listUploadedFiles = response['data'];
            }
          }
          else {
            this.listUploadedFiles = [];
            this.configurations.listUploadedFiles = [];
            this.configurations = Object.assign({}, this.configurations);
          }
        });
    }
  }

  resetForm(licForm: NgForm) {
    licForm.resetForm();
    this.infiniteDtFlag = false;
    this.license = {
      id: 0,
      name: '',
      subjects: '',
      institution: '',
      startDate: '',
      endDate: ''
    };
    this.listUploadedFiles = [];
    this.configurations.listUploadedFiles = [];
    this.configurations.entityId = 0;
    this.configurations.familyId = this.familyId ? this.familyId : null;
    this.configurations = Object.assign({}, this.configurations);
    this.listSubjects = new MatTableDataSource();
    this.hasID = false;
    this.isLinear = true;
  }

  redirectToDocs() {
    this.selectedIndex = 1;
  }

  handleTabChange(tab) {
    this.selectedIndex = tab.index;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add subject
    if ((value || '').trim()) {
      this.listAddedSubjects.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  pasteChips(event: ClipboardEvent) {
    event.preventDefault(); // Prevents the default action
    event.clipboardData
      .getData('Text') // Gets the text pasted
      .split(/,/) // Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach(value => {
        if (value.trim()) {
          this.listAddedSubjects.push(value.trim()); // Push if valid
        }
      });
  }

  remove(fruit: string): void {
    const index = this.listAddedSubjects.indexOf(fruit);

    if (index >= 0) {
      this.listAddedSubjects.splice(index, 1);
    }
  }

  addSubjects() {
    if (!this.license.id) {
      this.toastr.info('Please add the licence details first, then proceed to topics');
      return;
    }
    const payload = {
      id: this.license.id || 0,
      name: this.license.name,
      subjects: '',
      institution: this.license.institution,
      startDate: this.license.startDate ? moment(this.license.startDate).format('YYYY-MM-DD') : null,
      endDate: this.infiniteDtFlag ? null : (this.license.endDate ? moment(this.license.endDate).format('YYYY-MM-DD') : null ),
    };
    const subs: string[] = [];
    if (this.listSubjects) {
      this.listSubjects.data.forEach((item) => {
        subs.push(item.subject);
      });
    }

    payload.subjects = subs.concat(this.listAddedSubjects).join();
    this.apiService.saveTrainingDetails(this.beneficiaryId, 'LICENSE', payload, this.familyId)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe(response => {
        if (response.status === 200) {
          this.toastr.success(response.message);
          const obj = [];
          if (response.data['profEducation'][0]['subjects']) {
            response.data['profEducation'][0]['subjects'].split(',').forEach((item) => {
              obj.push({ 'subject': item });
            });
          }
          this.listSubjects = new MatTableDataSource(obj);
          response.data['profEducation'].forEach(element => {
            this.configurations.entityId = element.id;
            this.configurations = Object.assign({}, this.configurations);
          });
          this.configurations.useCaseId = this.beneficiaryId;
          this.listAddedSubjects = [];
          this.getLicense();
          this.store.dispatch(new MarkCleanFormAction({ dirty: false }));

        } else {
          this.toastr.error(response.message);
        }
      });

  }

  deleteSubject(index) {
    this.listSubjects.data.splice(index, 1);
    this.addSubjects(); // Resolved for IM-5066
  }

  fileUploadSuccess() {
    // this.getLicense();
  }

  closeForm(f) {
    this.resetForm(f);
    this.toggleExpansion();
  }

  toggleListView(val) {
    if (!val) {
      this.isTimeLineView = true;
      this.isListView = false;
    } else {
      this.isTimeLineView = false;
      this.isListView = true;
    }
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  dialogueInitializer(event, title: string, message: string) {
    const cordinates: MouseEventInit = event;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: {
        update: { title, message },
        buttons: ['Cancel', 'Delete']
      }
    });
    return dialogRef;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.mode.currentValue) {
      if(changes.mode.currentValue === 'list') {
        this.modeVal = true;
      }
    }
    if((changes.caseRequestShowDivs && changes.caseRequestShowDivs.currentValue) || (changes.benQuestShowDivs && changes.benQuestShowDivs.currentValue)){
      // this.formValidation();

      if(changes.caseRequestShowDivs && changes.caseRequestShowDivs.currentValue){
        this.iscaseRequest = true;
        changes.caseRequestShowDivs.currentValue.subLevel.forEach(element => {
          this.currentItemDisplay.push(element.code);
        });
      }
      if(changes.benQuestShowDivs && changes.benQuestShowDivs.currentValue){
        this.isBenQuestShowDivs = true;
        changes.benQuestShowDivs.currentValue.subLevel.forEach(element => {
          this.currentItemDisplay.push(element.code);
        });
      }

    } else {
      this.iscaseRequest = false;
    }
  }

  ngAfterViewInit(): void {
    if(this.modeVal) {
      this.showLifeStoryTimeLineSection = true;
      this.toggleListView(true);
    }
  }

  ngOnDestroy() {
    if (this.formChangesSubscriptions) {
      this.formChangesSubscriptions.forEach(item => { item.unsubscribe(); });
    }
    this.destroy$.next();
    this.destroy$.complete();
    if (this.dependentIdSubscription) {
      this.dependentIdSubscription.unsubscribe();
    }
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    this.store.dispatch(new MarkCleanFormAction({ dirty: false }));
  }

  saveAsDraft(event){
    this.saveLicenseTrianing(this.licForm, event);
  }
}
