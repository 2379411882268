import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ImagilityBaseResponse, TaskStep } from 'app-models';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/internal/Subject';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { getStepDetails, updateStepStatus } from 'visa-store';
import { ApiAddressHistoryService } from './api-address-history.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as errorUtils from 'error-message-utility';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { DialogService } from 'primeng/dynamicdialog';
import { MarkCleanFormAction, MarkDirtyFormAction } from 'dirty-check-store';
import * as moment from 'moment';
import { AppServicesLibraryService } from 'app-services-library';

export const DeleteDialogConfigurations = {
  width: '30%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { 'z-index': '1001' }
};

@Component({
  selector: 'app-address-history',
  templateUrl: './address-history.component.html',
  styleUrls: ['./address-history.component.scss']
})
export class AddressHistoryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() primaryData;
  @Input() type: string;
  beneficiaryPersonalDetails: any;
  requiredFields: any;
  disabledControls: boolean;
  stepDetails: TaskStep;
  addressHistoryForm: FormGroup;
  addressList = [];
  addEditButtonText;
  observableSubscription$ = new Subject();
  formChangesSubscription: any;
  toYear: string;
  fromYear: string;
  @Input() userId: number;
  selectedCountryAndStateCode: {
    countryCode: any;
    stateProvinceCode: any;
    stateProvinceName: any;
  };
  currentDate = new Date();
  @Output() scrollTop = new EventEmitter();
  addressFormDisabled: boolean;
  historyLength: string = "five years";
  currentDisable = false;
  required: boolean;

  constructor(
    public store: Store<any>,
    public apiService: ApiAddressHistoryService,
    public toastr: ToastrService,
    public stepStatusUpdatePayloadCostructionService: StepStatusUpdatePayloadService,
    public dialogService: DialogService,
    private appServicesLibraryService: AppServicesLibraryService
    ) {
    this.toYear = moment().format('YYYY');
    this.fromYear = moment().subtract(99, 'years').format('YYYY');
    this.disabledControls = false;
  }

  ngOnInit(): void {
    // Get Specific step details by passing step code
    this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        if (data && data.id) {
          this.stepDetails = data;
          this.toggleControlsStatus();
        }
      });
      this.historyLength = (['SUSPDPRT881'].includes(this.primaryData?.immigrationClassification))? 'ten years' : 'five years';
  }

  ngOnChanges() {
    if (this.primaryData && this.userId) {
      this.addressHistoryForm = new FormGroup({
        id: new FormControl(null),
        dateRangeFrom: new FormControl(null, Validators.required),
        dateRangeTo: new FormControl(null, [this.dateRangeToFieldRequired.bind(this)]),
        hasLivedTogthr: new FormControl(false),
        liveTogetherFromDate: new FormControl(null),
        liveTogetherToDate: new FormControl(null),
        familyId: new FormControl(this.primaryData.familyId === this.userId ? this.primaryData.familyId : null),
        addressHistoryId: new FormControl(null),
        addressTypeCode: new FormControl('CURR'),
        beneficiaryId: new FormControl(this.primaryData.sponsorId),
        sequenceNo: new FormControl(null),
        inCareOfName: new FormControl(null),
        county: new FormControl(null)
      }, [
        this.liveTogetherFromDateFieldRequired,
        this.liveTogetherToDateFieldRequired
      ]);
      this.addressHistoryForm.valueChanges.subscribe(() => {
        if (this.addressHistoryForm.dirty) {
          this.store.dispatch(new MarkDirtyFormAction({
            dirty: true
          }));
        } else {
          this.store.dispatch(new MarkCleanFormAction({
            dirty: false
          }));
        }
      });
      this.getAddressHistoryInformation();
    }
  }

  dateRangeToFieldRequired(control: FormControl): { [s: string]: boolean } {
    if (this.currentDisable === false && !control.value) {
      return { dateRangeToFieldIsRequired: true };
    }
    return null;
  }

  liveTogetherFromDateFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (group.value.hasLivedTogthr &&
      !group.value.liveTogetherFromDate) {
      return { liveTogetherFromDateFieldIsRequired: true };
    }
    return null;
  }

  liveTogetherToDateFieldRequired(group: FormGroup): { [s: string]: boolean } {
    if (group.value.hasLivedTogthr &&
      !group.value.liveTogetherToDate) {
      return { liveTogetherToDateFieldIsRequired: true };
    }
    return null;
  }

  getAddressHistoryInformation() {
    this.apiService.getAddressHistoryInformation(
      this.primaryData.sponsorId ? this.primaryData.sponsorId : this.primaryData.beneficiaryId,
      this.primaryData.familyId === this.userId ? this.primaryData.familyId : this.type === 'family' ? this.userId : null
    ).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        this.addressList = response.data ? response.data : [];
      });
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus({
      payload: this.stepStatusUpdatePayloadCostructionService.payloadConstruction(this.stepDetails, status),
      subTaskId: this.stepDetails.taskId,
      visaType: this.primaryData.caseType,
      stepId: this.primaryData.stepId
    }));
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.addressFormDisabled = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.addressFormDisabled =
    //   this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" ||
    //   this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
    //   this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';

  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  addAddressFormControlToParentForm(addressForm: FormGroup) {
    if (addressForm) {
      this.addressHistoryForm.addControl('address', addressForm);
    }
  }

  saveAddress() {
    this.addressFormDisabled = false;
    this.currentDisable = false;

    const { address, ...payload } = {
      ...this.addressHistoryForm.value,
      ...this.addressHistoryForm.value.address,
      dateRangeFrom: this.addressHistoryForm.value.dateRangeFrom ?
        moment(this.addressHistoryForm.value.dateRangeFrom).format('YYYY-MM-DD') : null,
      dateRangeTo: this.addressHistoryForm.value.dateRangeTo ?
        moment(this.addressHistoryForm.value.dateRangeTo).format('YYYY-MM-DD') : null,
      liveTogetherFromDate: this.addressHistoryForm.value.liveTogetherFromDate ?
        moment(this.addressHistoryForm.value.liveTogetherFromDate).format('YYYY-MM-DD') : null,
      liveTogetherToDate: this.addressHistoryForm.value.liveTogetherToDate ?
        moment(this.addressHistoryForm.value.liveTogetherToDate).format('YYYY-MM-DD') : null,
      address: this.addressHistoryForm.value.address
    };
    payload.hasLivedTogthr = payload.hasLivedTogthr ? 1 : 0;
    payload.familyId = payload.familyId ? payload.familyId : this.type === 'family' ? this.userId : null,
      this.apiService.postAddressHistoryInformation(this.primaryData.sponsorId ? this.primaryData.sponsorId : this.primaryData.beneficiaryId, payload)
        .pipe(takeUntil(this.observableSubscription$))
        .subscribe((response: ImagilityBaseResponse) => {
          if (response) {
            this.toastr.success(response.message);
            if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
              this.updateStepStatus('INPROGRESS');
            }
            this.resetForm();
            this.getAddressHistoryInformation();
          }
        });
  }

  resetForm() {
    this.apiService.emptyStateListOnReset.next();
    this.addressHistoryForm.reset({
      id: null,
      dateRangeFrom: null,
      dateRangeTo: null,
      hasLivedTogthr: false,
      liveTogetherFromDate: null,
      liveTogetherToDate: null,
      familyId: this.primaryData.familyId === this.userId ? this.primaryData.familyId : null,
      addressHistoryId: null,
      addressTypeCode: 'CURR',
      beneficiaryId: this.primaryData.sponsorId,
      sequenceNo: null,
      address: {
        addressLine1: null,
        addressLine2: null,
        city: null,
        stateProvinceCode: null,
        stateProvinceName: null,
        countryCode: null,
        postCode: null
      },
      inCareOfName: null,
      county: null
    });
  }

  deleteDocument(addressHistoryId) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.apiService.deleteAddressHistoryItem(addressHistoryId)
          .pipe(takeUntil(this.observableSubscription$))
          .subscribe((deleteAddressHistoryItemRresponse: ImagilityBaseResponse) => {
            this.toastr.success(deleteAddressHistoryItemRresponse.message);
            this.resetForm();
            this.getAddressHistoryInformation();
          });
      }
    });
  }

  editDocument(doc) {
    if (!doc.dateRangeTo) {
      this.addressFormDisabled = true;
      this.disabledControls = false;
      this.currentDisable = true;
    } else if (doc.dateRangeTo) {
      this.addressFormDisabled = false;
      this.currentDisable = false;
    }

    this.selectedCountryAndStateCode = {
      countryCode: doc.countryCode,
      stateProvinceCode: doc.stateProvinceCode,
      stateProvinceName: doc.stateProvinceName,
    };
    this.addressHistoryForm.reset({
      id: doc.id,
      dateRangeFrom: doc.dateRangeFrom ? new Date(doc.dateRangeFrom) : null,
      dateRangeTo: doc.dateRangeTo ? new Date(doc.dateRangeTo) : null,
      hasLivedTogthr: doc.hasLivedTogthr ? true : false,
      liveTogetherFromDate: doc.liveTogetherFromDate ? new Date(doc.liveTogetherFromDate) : null,
      liveTogetherToDate: doc.liveTogetherToDate ? new Date(doc.liveTogetherToDate) : null,
      familyId: this.primaryData.familyId === this.userId ? this.primaryData.familyId : null,
      addressHistoryId: doc.addressHistoryId,
      addressTypeCode: 'CURR',
      beneficiaryId: this.primaryData.sponsorId,
      sequenceNo: doc.sequenceNo,
      address: {
        addressLine1: doc.addressLine1,
        addressLine2: doc.addressLine2,
        city: doc.city,
        stateProvinceCode: doc.stateProvinceCode,
        stateProvinceName: doc.stateProvinceName,
        countryCode: doc.countryCode,
        postCode: doc.postCode,
      },
      inCareOfName: doc?.inCareOfName,
      county: doc?.county
    });
    this.scrollTop.emit();
  }

  cancelForm() {
    this.addressFormDisabled = false;
    this.currentDisable = false;
    this.resetForm();
  }
}






