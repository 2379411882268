import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { VisaExtendedState } from '../../../utility/interfaces/visa-extended-state';
import { StepStatusUpdatePayloadService } from 'step-status-update-payload-service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { ImagilityBaseResponse, PrimaryData, TaskStep } from 'app-models';
import * as errorUtils from "error-message-utility";
import { NationalInternationalPrizesService } from './national-international-prizes.service';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { DialogService } from 'primeng/dynamicdialog';
import { UploadDocumentService } from 'src/app/services/upload-document.service';
//import { FroalaEditorOptions } from '../../../../../data/constants/froala-editor-options';
import * as DirtyForm from '../../../../../app-state/actions/dirty-form.actions';
import { Subscription } from 'rxjs';
import { combineLatest } from 'rxjs';
import { getStepDetails, getSubTaskDetails, getStepStatusUpdate, loadSubTaskDetails, updateStepStatus, updateStepStatusToInitialState, getVisaTypeCode } from 'visa-store';
import { I140FileUploadConfiguration } from 'app-models';
import { SubTaskDetails } from 'src/app/data/models/subTaskDetails.model';
import { UploadImageDialogComponent } from "../../../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { AppServicesLibraryService } from 'app-services-library';
export const DeleteDialogConfigurations = {
  width: '30%',
  closeOnEscape: false,
  closable: false,
  showHeader: false,
  contentStyle: { "z-index": "1001" }
};

@Component({
  selector: 'app-national-international-prizes',
  templateUrl: './national-international-prizes.component.html',
  styleUrls: ['./national-international-prizes.component.scss']
})

export class NationalInternationalPrizesComponent implements OnInit {
  @ViewChild("nationalprizesupload") public nationalprizesupload: UploadImageDialogComponent;
@Output() @ViewChild("nationalprizeskendoeditor") public nationalprizeskendoeditor: EditorComponent;
  subcriptions: Subscription[] = [];
  prizeAwardsForm: FormGroup;
  disabledControls: boolean;
  stepDetails: TaskStep;
  enableEdit: boolean = false;
  addedDocuments = [];
  hideAddAwardSection: boolean = false;
  fileUploadConfigurations: I140FileUploadConfiguration;
  additionalInfo;
  formDirtyFromDifferentControls = false;
  typeOfButton: string;
  addEditButtonText: string = 'SAVE';
  task: SubTaskDetails;
  extrAblDocEvdCatg = [];
  abilityTypeNational = true;
  inputData: PrimaryData;
  stepStatus;
  markStep: string;

  // public options = {
  //   ...FroalaEditorOptions.EditorOptions,
  //   events: {
  //     contentChanged: () => {
  //       this.setFormDirty();
  //     }
  //   }
  // };

  observableSubscription$ = new Subject();

  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private uploadDocumentService: UploadDocumentService,
    public dialogService: DialogService,
    public stepStatusUpdatePayloadCostruction: StepStatusUpdatePayloadService,
    public dynamicDialogConfig: DynamicDialogConfig,
    private nationalInternationalPrizesService: NationalInternationalPrizesService,
    private store: Store<VisaExtendedState>,
    private appServicesLibraryService: AppServicesLibraryService) {
    this.disabledControls = false;
    this.inputData = this.dynamicDialogConfig.data;
  }

  ngOnInit(): void {
    this.prizeAwardsForm = this.fb.group({
      awardName: ['', Validators.required],
      awardedYear: ['', (Validators.required, Validators.max((new Date()).getFullYear()))],
      awardingInstitution: ['', Validators.required],
      id: ['0']
    });

    // Selector for step status update
    combineLatest([
      this.store.pipe(select(getStepStatusUpdate)),
      this.store.pipe(select(getVisaTypeCode))
    ])
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any[]) => {
        if (response[0] && response[0].status === 200 && response[1]) {
          this.toastr.success(response[0].message);
          this.store.dispatch(loadSubTaskDetails({
            taskId: this.stepDetails.taskId,
            // visaTypeCode: response[1]
            visaTypeCode: this.inputData.getTaskType
          }));
        }
      });

    combineLatest([
      this.store.pipe(select(getSubTaskDetails)),
      this.store.pipe(select(getStepDetails, { id: this.dynamicDialogConfig.data.stepId }))
    ]).pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: any[]) => {
        this.task = response[0];
        this.stepStatus = response[1].stepStatusDetails.stepStatusCode;
        this.extrAblDocEvdCatg = this.task.extrAblDocEvdCatg;

        if (this.extrAblDocEvdCatg['code'] == 'OTHERDOCEVDN') {
          this.abilityTypeNational = true;
          this.extrAblDocEvdCatg['categoryCode'] = "AWRDDOC";
          this.extrAblDocEvdCatg['fileCategoryCode'] = "AWRDCRTFCT";
          this.extrAblDocEvdCatg['infoType'] = "NATIONALAWRD";
          this.extrAblDocEvdCatg['awardType'] = "NATIONALAWRD";
        }
        else {
          this.abilityTypeNational = false;
          this.extrAblDocEvdCatg['categoryCode'] = "INTLAWRDDOC";
          this.extrAblDocEvdCatg['fileCategoryCode'] = "MAJINTLAWRDEVDN";
          this.extrAblDocEvdCatg['infoType'] = "MAJINTLAWRDEVDN";
          this.extrAblDocEvdCatg['awardType'] = "MAJINTLAWRDEVDN";
        }
        this.stepDetails = response[1];
        this.typeOfButton = this.stepDetails.isPetitionerAction === 1 || this.stepDetails.isAttorneyAction === 1 ? "MARK_COMPLETE" : "MARK_SUBMIT";
        this.toggleControlsStatus();
        this.getAddedDocuments();
        this.getAdditionalInfo();
        this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
      });

    // this.subcriptions[this.subcriptions.length] = this.prizeAwardsForm.statusChanges.subscribe(() => {
    //   if (this.prizeAwardsForm.dirty) {
    //     this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
    //   } else {
    //     this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
    //   }
    // });

    let userType = sessionStorage.getItem('userTypeRole');
    if (userType === 'Beneficiary') {
      this.markStep = 'Mark Submit '
    } else {
      this.markStep = 'MARK STEP COMPLETE!';
    }

  }
  opennationalprizesuploaddialog() {
    this.nationalprizesupload.open();
  }
  setFormDirty() {
    this.formDirtyFromDifferentControls = true;
    this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
  }

  ngAfterViewInit() {
    this.subcriptions[this.subcriptions.length] = this.prizeAwardsForm.statusChanges.subscribe(() => {
      if (this.prizeAwardsForm.dirty || this.formDirtyFromDifferentControls) {
        this.store.dispatch(new DirtyForm.MarkDirtyFormAction({ dirty: true }));
      } else {
        this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
      }
    });
  }

  //Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === "NEW" || this.stepDetails.stepStatusDetails.stepStatusCode === "COMPLETE" || this.stepDetails.stepStatusDetails.stepStatusCode === "SUBMIT" ? true : false;
    this.fileUploadConfigurations = Object.assign({}, {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${this.extrAblDocEvdCatg['categoryCode']}/`,
      postApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/category/${this.extrAblDocEvdCatg['categoryCode']}/fileCategory/`,
      deleteApiUrl: `/${this.dynamicDialogConfig.data.visatype}/${this.dynamicDialogConfig.data.caseType}/${this.dynamicDialogConfig.data.caseId}/document/`,
      documentTypeDropdownCode: `${this.extrAblDocEvdCatg['categoryCode']}`
    });
  }

  updateControls(status: boolean) {
    this.enableEdit = status;
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  editDocument(document) {
    //this.updateStepStatus('INPROGRESS');
    this.prizeAwardsForm.patchValue({
      awardName: document.awardName,
      awardedYear: document.awardedYear,
      awardingInstitution: document.awardingInstitution,
      id: document.id
    });
    //this.addEditButtonText = 'Update';
  }

  deleteDocument(id) {

    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        this.nationalInternationalPrizesService.deleteAward(
          this.dynamicDialogConfig.data.petitionId, id,
        ).pipe(takeUntil(this.observableSubscription$)).subscribe((response: ImagilityBaseResponse) => {
          this.toastr.success(response.message);
          this.prizeAwardsForm.patchValue({
            awardName: '',
            awardedYear: '',
            awardingInstitution: '',
            id: '0'
          });
          this.getAddedDocuments();
        });
      }
    });
  }

  toggleAddSection() {
    this.hideAddAwardSection = !this.hideAddAwardSection;
  }

  addAwardDetail() {
    let payload = {};
    payload["awardName"] = this.prizeAwardsForm.get('awardName').value;
    payload["awardedYear"] = this.prizeAwardsForm.get('awardedYear').value;
    payload["awardingInstitution"] = this.prizeAwardsForm.get('awardingInstitution').value;
    payload["docEvidence"] = this.extrAblDocEvdCatg['awardType'];
    payload["id"] = this.prizeAwardsForm.get('id').value ? this.prizeAwardsForm.get('id').value : '0';

    this.nationalInternationalPrizesService.addAward(this.extrAblDocEvdCatg['awardType'], this.dynamicDialogConfig.data.petitionId, payload).subscribe((response: ImagilityBaseResponse) => {
      if (response && response.status === 200) {
        this.toastr.success(response.message);
        this.prizeAwardsForm.reset();
        if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
          //this.stepDetails.stepStatusDetails.stepStatusCode = 'INPROGRESS';
          this.updateStepStatus('INPROGRESS');
        }
        this.addEditButtonText = 'Add';
        this.getAddedDocuments();
      }
    });
  }

  getAddedDocuments() {
    this.nationalInternationalPrizesService.getAwardList(this.extrAblDocEvdCatg['awardType'], this.dynamicDialogConfig.data.petitionId).subscribe((response: ImagilityBaseResponse) => {
      if (response && response['data']) {
        this.addedDocuments = response['data'];
        //this.hideAddAwardSection = true;
      }
      else {
        this.addedDocuments = [];
      }
    });
  }

  onMarkComplete() {
    this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
    if (this.stepDetails.stepStatusDetails.stepStatusCode === 'INPROGRESS') {
      //this.stepDetails.stepStatusDetails.stepStatusCode = 'COMPLETE';
      if (sessionStorage.getItem('userTypeRole') === 'Beneficiary') {
        this.updateStepStatus('SUBMIT');
      } else {
        this.updateStepStatus('COMPLETE');
      }

    }
  }

  updateStepStatus(status) {
    this.store.dispatch(updateStepStatus(
      {
        payload: this.stepStatusUpdatePayloadCostruction.payloadConstruction(this.stepDetails, status),
        subTaskId: this.stepDetails.taskId,
        stepId: this.dynamicDialogConfig.data.stepId,
        visaType: this.inputData.caseType === 'I130' ?
          this.inputData.caseType : this.inputData.visatype
      }));
    // this.toggleControlsStatus();
  }

  saveAdditionalInfo1() {
    let payload = {
      "additionalText": this.additionalInfo,
      "infoType": this.extrAblDocEvdCatg['awardType'],
    };
    this.nationalInternationalPrizesService.saveAdditionalInfo(this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType, this.dynamicDialogConfig.data.caseId, payload).subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          this.toastr.success(response.message);
          this.store.dispatch(new DirtyForm.MarkCleanFormAction({ dirty: false }));
          if (this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW') {
            //this.stepDetails.stepStatusDetails.stepStatusCode = 'INPROGRESS';
            this.updateStepStatus('INPROGRESS');
          }
        }
      });
  }

  getAdditionalInfo() {
    this.nationalInternationalPrizesService.getAdditionalInfo(this.dynamicDialogConfig.data.visatype,
      this.dynamicDialogConfig.data.caseType, this.dynamicDialogConfig.data.caseId, this.extrAblDocEvdCatg['infoType']).subscribe((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          //this.toastr.success(response.message);
          this.additionalInfo = response['data']['additionalText'];

        }
      });
  }

  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
    // Update the Step Status state to empty state
    this.store.dispatch(updateStepStatusToInitialState());
  }
  public nationalprizeseditorValueChange(value: string): void {
    this.additionalInfo = value;
    this.setFormDirty();
  }
}
