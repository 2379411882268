import { Component, OnInit, Input,ViewChild,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SchedulingService } from 'src/app/services/scheduling.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss']
})
export class ReminderComponent implements OnInit {
  @ViewChild('reminderpageDiv') reminderpageDiv: ElementRef<HTMLElement>;
  @Input('obj') reminderObj;
  reminderForm: FormGroup 
  navUrl: any;
  userType: string;
  calendarItemId: any;
  series: any;
  reminderEditDetails: any;
  minimumDate: any;
  reminderpageLoaded: boolean = false;

  constructor(private router: Router,
    private fb: FormBuilder,
    private schedulingservice: SchedulingService,
    private toaster: ToastrService) {
      this.reminderpageLoaded = true;
    this.userType = sessionStorage.getItem("userTypeRole");
    if (this.userType == "Petitioner") {
      this.navUrl = "/petitioner-landing/dashboard/petitioner-dashboard/scheduling/";
    } else if (this.userType == "Attorney") {
      this.navUrl = "/attorney-landing/dashboard/attorney-dashboard/scheduling/";
    }
    this.reminderForm = this.fb.group({
      startDate: ['', Validators.required],
      startTime: ['', Validators.required],
      description: ['', Validators.required],
      calItemTypeCode: "REMINDER",
      calendarItemId: null,
    })
    this.minimumDate = new Date();
    
  }

  ngOnInit(): void {
    if(this.reminderObj) {
    this.calendarItemId = this.reminderObj.id;
    this.series = this.reminderObj.isSeries;
    this.schedulingservice.getCalendarItemDetails(this.calendarItemId, this.series).subscribe(res => {
      this.reminderEditDetails = res['data'];
      if (this.reminderEditDetails) {
        this.reminderForm.patchValue({
          startDate: moment(this.reminderEditDetails.startDate).format('MM/DD/YYYY'),
          startTime: this.reminderEditDetails.startTime,
          description: this.reminderEditDetails.description,
          calItemTypeCode: "REMINDER",
          calendarItemId: this.reminderEditDetails.id,
        })
      }
      
      console.log('item', res);
      let el: HTMLElement = this.reminderpageDiv.nativeElement;
      el.click();

    })
  }
    this.userType = sessionStorage.getItem("userTypeRole");
    if (this.userType == "Petitioner") {
      this.navUrl = "/petitioner-landing/dashboard/petitioner-dashboard/scheduling/";
    } else if (this.userType == "Attorney") {
      this.navUrl = "/attorney-landing/dashboard/attorney-dashboard/scheduling/";
    }
    
  }

  save() {

    let reminderPayload = this.reminderForm.value;
    this.reminderForm.value.startDate = moment(this.reminderForm.get("startDate")?.value).format('YYYY-MM-DD'),
    this.schedulingservice.saveMeeting(reminderPayload).subscribe(res => {
      this.toaster.success(res.message);
      this.router.navigate([this.navUrl]);
    })

  }

  update() {
    let updateobj = this.reminderForm.value;
    this.reminderForm.value.startDate = moment(this.reminderForm.get("startDate")?.value).format('YYYY-MM-DD'),
    this.reminderForm.value.calItemTypeCode = 'REMINDER';
    this.reminderForm.value.isSeries = 1;
    this.schedulingservice.updateMeeting(updateobj).subscribe(response => {
      this.toaster.success(response.message);
      this.router.navigate([this.navUrl]);
    },
      error => {
        console.log('meeting error', error)
      }
    );
  }
  cancelreminder(){
    this.router.navigate([this.navUrl]);
  }
  cancel() {
    let cancelpayload = {
      "calendarId": this.reminderEditDetails.parentId,
      "calendarItemId": this.reminderEditDetails.id,
      "calendarItemType": this.reminderEditDetails.calenderItemType,
      "isCancelAllItems": true
    }
    this.schedulingservice.cancelCalendarItem(cancelpayload).subscribe(res => {
      if (res) {
        this.toaster.success(res.message);
        this.router.navigate([this.navUrl]);
      }
    })

  }
}
