import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { EncryptService } from './http-encrypt.service';
/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(private encryptService: EncryptService){}
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.url.includes('linkTokenId_unique')){
      var x = request.url.split('?');
      if (!/^(http|https):/i.test(x[0])) {
        request = request.clone({ url: environment.baseUrl + x[0] });
      }
    }
    else{
      const encryptUrl = this.encryptService.encryption(request.url);
      const newUrl = encryptUrl == null ? request.url: encryptUrl; 

      if (!/^(http|https):/i.test(newUrl)) {
        request = request.clone({ url: environment.baseUrl + newUrl });
      }
    }
    
    return next.handle(request);
  }
}
