import { Component, OnInit, Input } from '@angular/core';
import { RfeService } from 'src/app/services/rfe.service';
import { ToastrService } from 'ngx-toastr';
import { stepStatus } from '../../../../data/constants/step-status';
import { PetitionerService } from 'src/app/services/petitioner.service';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';

@Component({
  selector: 'app-rfe-prepare-response-step2',
  templateUrl: './rfe-prepare-response-step2.component.html',
  styleUrls: ['./rfe-prepare-response-step2.component.scss']
})
export class RfePrepareResponseStep2Component implements OnInit {
  @Input() rfeTimelineModalInput;
  @Input() rfeNoticeId;
  @Input() currentStepId;
  responseDetails:any[];
  supportingDocumentsForResponse:any[];
  selectedCategoryTypeForResponse : any[] = [];
  displayDocumentPanelForResponse:boolean = false;
  supportingDocumentsForNotification:any[];
  supportingDocuments:any[];
  notificationStatusUpdateForResponse: string = "";
  SubTaskOverView;
  contentAvailable :boolean = false;
  requestEnabled: boolean = false;

  constructor(private petitionerService: PetitionerService,
    private store: Store<fromTask.State>,
    private rfeService:RfeService,private toastr: ToastrService) { }

  ngOnInit() {
    combineLatest([
      this.store.select(fromTask.getRFESubTaskDetails)
    ]).subscribe(([getRFESubTask]) => {
      this.SubTaskOverView = getRFESubTask;
    });
    this.getStatus();
    this.getResponseDetails();
    //this.getSupportingDocumentsForResponse();
    this.getSupportingDocumentsForNotification();
  }

  getSupportingDocumentsForNotification(){
    this.rfeService
    .getSupportingDocuments(this.SubTaskOverView['id'])
    .subscribe((data) => {
      if(data != null)
      this.supportingDocumentsForNotification = data; 
        });

  }

  getResponseDetails(){
    this.rfeService
    .getResponseDetails(this.rfeNoticeId)
    .subscribe((data) => {
      this.responseDetails = data;
      let reponseDetailsTemp = [];
      if(this.responseDetails != undefined){
        this.responseDetails.forEach((element) => {
          if(element.responseText != "")
            reponseDetailsTemp.push(element);
        });
        this.responseDetails = reponseDetailsTemp;
        this.contentAvailable = true;
      var grouped = this.responseDetails.sort((a, b) => a.responseProvidedBy.localeCompare(b.responseProvidedBy));
      this.responseDetails = grouped;
      }
    });

    
   }

   getSupportingDocumentsForResponse(){
    this.rfeService
      .getResponseForAttorney(this.rfeNoticeId)
      .subscribe((data) => {
        if(data != null)
        this.supportingDocumentsForResponse = data["titleSheetDocDtoList"]; 
        if(this.supportingDocumentsForResponse != undefined){
          this.contentAvailable = true;
          var res = this.supportingDocumentsForResponse.reduce((accumalator, current) => {
            if(!accumalator.some(item => item.categoryDtlId === current.categoryDtlId)) {
              accumalator.push(current);
            }
            return accumalator;
        },[]);
    
        this.supportingDocumentsForResponse = res;
    
          this.supportingDocumentsForResponse.forEach((element) => {
            this.selectedCategoryTypeForResponse.push(element);
          });
        }
          });

    
  }

  checkedCheckbox(e){
    if(this.selectedCategoryTypeForResponse.length == 0)
      this.requestEnabled = false;
    else
      this.requestEnabled = true;
  }

  sendNotificationFromResponse(){
    let documents = this.selectedCategoryTypeForResponse;
    let categoryList = [];
    documents.forEach( (element) => {
      if(element.categoryId){
      categoryList.push({
            "categoryDtlId": element.categoryId,
            "categoryName": element.categoryName,
            "notifyTo": element.notifyTo,
            "note": element.notes || ""
            });
          }
      });
      
   
    let payload = {
      "categoryList": categoryList
    };
  
    this.rfeService.sendIndividualNotification(this.rfeNoticeId,payload).subscribe((response: any) => {
      if (response) {
        this.toastr.success(response.message);
        this.getStatus();
        this.displayDocumentPanelForResponse = false;
      }
    });
    
  }

  setDisplayDocumentPanelFromResponse(){
    this.displayDocumentPanelForResponse = true;
    //this.supportingDocumentsForNotification = this.supportingDocuments;
  }

  getStatus(){

    this.rfeService
    .getRFENotificationMessage('RFE_RESPONSE_REQ',this.rfeNoticeId)
    .subscribe((data) => {
      this.notificationStatusUpdateForResponse = data['message'];
    });
   }

   changeStatus(){
    const payload = {
      statusCode: stepStatus.Complete,
      stepNote: ''
    };
    const payload1 = {
      statusCode: stepStatus.Inprogress,
      stepNote: ''
    };
    this.petitionerService.stepStatusUpdateRFE(payload1, this.SubTaskOverView['id'], this.rfeTimelineModalInput["parentStepId"]).subscribe();
    this.petitionerService.stepStatusUpdateRFE(payload, this.SubTaskOverView['id'], this.currentStepId).subscribe((response: any) => {
      if (response) {
        this.toastr.success(response.message);
   //     this.displaySection = "";
      }
    });
  
  }

}
