import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApiServicesService } from '../../beneficiary/services/api-services.service';

@Component({
  selector: 'app-education-timeline',
  templateUrl: './education-timeline.component.html',
  styleUrls: ['./education-timeline.component.scss']
})
export class EducationTimelineComponent implements OnInit {
  @Input() educationData = [];
  constructor() { }

  ngOnInit() {
    console.log('educationData ' + this.educationData)
  }

}
