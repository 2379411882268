import { Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { take } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as Role from '../../../data/constants/roles';
import { environment } from 'src/environments/environment';
import { SwitchAppService } from './switch-app.service';

@Component({
  selector: 'app-switch-app',
  templateUrl: './switch-app.component.html',
  styleUrls: ['./switch-app.component.scss']
})
export class SwitchAppComponent implements OnChanges {
  showSwitchAppSection: boolean;
  presentApp: string;
  @Input() appRoleData?: any[];
  switchAppSuccessMessage: string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    private switchAppService: SwitchAppService
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    this.switchAppSuccessMessage = "App Switched Successfully";
    if (changes.appRoleData.currentValue === null) {
      this.getAppRoleData();
    } else if ('presentApp' in sessionStorage) {
      this.presentApp = sessionStorage.getItem('presentApp');
      if ('startSwichApp' in sessionStorage) {
        this.toastr.success(this.switchAppSuccessMessage);
        sessionStorage.removeItem('startSwichApp');
      }
    }
  }

  getAppRoleData() {
    const companyId = 'companyId' in sessionStorage && !isNaN(Number(sessionStorage.getItem('companyId'))) ? Number(sessionStorage.getItem('companyId')) : null;
    const employeeId = 'loginEmployeeId' in sessionStorage && !isNaN(Number(sessionStorage.getItem('loginEmployeeId'))) ? Number(sessionStorage.getItem('loginEmployeeId')) : null;
    if (companyId && employeeId) {
      this.switchAppService.getUserAppRoleDetails(companyId, employeeId)
        .pipe(take(1))
        .subscribe((data: any) => {
          if (!data) {
            this.appRoleData = null;
            return;
          }
          this.appRoleData = data.appRoleDetails.map(x => {
            return {
              ...x,
              companyApp: {
                ...x.companyApp,
                iconImagePath: environment.docs + x.companyApp.iconImagePath.replace('/var/imagility/uploads/', '')
              }
            };
          });
          if ('presentApp' in sessionStorage) {
            this.presentApp = sessionStorage.getItem('presentApp');
            if ('startSwichApp' in sessionStorage) {
              this.toastr.success(this.switchAppSuccessMessage);
              sessionStorage.removeItem('startSwichApp');
            }
          } else {
            const companyApp = this.appRoleData.find(x => x.isUserDefaultApp).companyApp;
            this.presentApp = companyApp.appName;
            sessionStorage.setItem('presentApp', this.presentApp);
          }
        });
    }
  }

  handleSwitchRole() {
    this.showSwitchAppSection = !this.showSwitchAppSection;
  }

  @HostListener('document:click', ['$event.target'])
  onClickOutside(target: any) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.showSwitchAppSection = false;
    }
  }

  handleDefault(app) {
    this.appRoleData.map(x => x.isUserDefaultApp = x.role.roleId === app.role.roleId);
  }

  handleAppChange(app) {
    let token = localStorage.getItem('UserToken');
    if (token && token !== 'null') {
      const payload = {
        username: localStorage.getItem('userEmail'),
        password: null,
        jwt: 'Bearer ' + token,
        appName: app.companyApp.appName
      };
      // this.authenticationService.logout()
      //   .pipe(take(1))
      //   .subscribe(data => {
      //     if (data && data['status'] === 200) {
      this.authenticationService.clearUserData();
      this.authenticationService.login(payload).subscribe(data => {
        if (data.status === 200) {
          localStorage.setItem('userEmail', payload.username);
          sessionStorage.setItem('presentApp', payload.appName);
          sessionStorage.setItem('startSwichApp', 'true');
          // localStorage.setItem('isFirstLogin', data['data']['isLogingFirst']);
          //localStorage.setItem('policyAcceptanceRequired', data.data['policyAcceptanceRequired']);
          this.redirectToLandingPage(data['data']);

        } else {

          this.authenticationService.logout()
            .pipe(take(1))
            .subscribe(data => {
              if (data && data['status'] === 200) {
                this.authenticationService.clearUserData();
                this.toastr.error(data.message, 'Error');
                this.router.navigate(['/login']);
              }

            });
        }
        // () => { },
        // () => { this.dashboardWidgetsService.setBellIconCountOnHeader(); }

      });
      // }
      // });


    }
  }

  redirectToLandingPage(user) {
    if (('isFBUser' in sessionStorage) && sessionStorage.getItem('isFBUser') === 'true') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/modules-landing/fb/module']);
      });
    } else {
      switch (user.userType) {
        case Role.BENEFICIARY:
          this.router.navigate(['/beneficiary-landing'], { replaceUrl: true });
          break;
        case Role.PETITIONER:
          this.router.navigate(['/petitioner-landing'], { replaceUrl: true });
          break;
        case Role.ATTORNEY:
          this.router.navigate(['/attorney-landing'], { replaceUrl: true });
          break;
        case Role.PLATFORMADMIN:
          this.router.navigate(['/platform-admin-landing'], { replaceUrl: true });
          break;
        default:
          this.router.navigate(['/login'], { replaceUrl: true });
          break;
      }
    }
  }

}
