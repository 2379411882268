import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import {  ToastrService } from 'ngx-toastr';
import { BillingInvoiceService } from 'src/app/services/billing-invoice.service';
import { acceptedFileTypes } from 'app-models';
@Component({
  selector: 'app-invoice-receipt',
  templateUrl: './invoice-receipt.component.html',
  styleUrls: ['./invoice-receipt.component.scss']
})
export class InvoiceReceiptComponent implements OnInit {

  receiptView: boolean = false;
  uploadView: boolean = false;
  uploadedFile: any;
  public payload = new FormData();
  uploadedFiles: any = [];
  invoiceId: number;
  @ViewChild('stepper') stepper: MatStepper;
  currentStep: Number;
  isReceiptgenerated: boolean = false;
  isLinear: boolean = true;
  acceptedTypes  = acceptedFileTypes;    
  constructor(private invoiceService: BillingInvoiceService,
              private router: Router, private toaster: ToastrService
              ) {
                this.isReceiptgenerated = true;
               }

  ngOnInit(): void {
    this.invoiceId = history.state.invoiceId;
    // setTimeout(() => {
    //   this.currentStep = 1;
    // }, 1500);
  }

  ngAfterViewInit() {
    this.stepper.selectedIndex = 2;
}

  generateReceipt(){
    this.receiptView = true;
    this.uploadView = false;
    this.isReceiptgenerated = false;
  }
  receiptUpload(){
this.uploadView = true;
this.receiptView = false;
this.isReceiptgenerated = false;
  }

  UploadReceipt(event, fileUpload){
    fileUpload.clear();
    this.uploadedFile = event.files[0];
    this.uploadedFile["fileName"] = event.files[0].name;
    this.payload = new FormData();
    this.payload.append("file", this.uploadedFile, this.uploadedFile.name);
  }

  saveReceipt(){
  if(this.uploadView == true){
  this.invoiceService.uploadReceipt(this.payload, this.invoiceId).subscribe(res =>{
    // console.log('receipt uploaded', res);
    this.toaster.success(res.message);
  })
}
  this.invoiceService.generatereceiptPdf(this.invoiceId).subscribe(res =>{
    // console.log('receipt pdf', res.message);
    this.toaster.success(res.message);
  })
  }
  backtoInvoiceDashboard(){
    this.router.navigate(["/attorney-landing/attorney-invoice"]);
  }

  // onUpload(event) {
  //   for (let file of event.files) {
  //     this.uploadedFiles.push(file);
  //     this.payload.append(
  //       "file",
  //       this.uploadedFiles,
  //       this.uploadedFile.fileName
  //     );
  //   }
  // }

}
