import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { RfeService } from 'src/app/services/rfe.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import * as fromTask from 'src/app/modules/superTask/task-state/reducers/task.reducer';
import * as Roles from 'src/app/data/constants/roles';
import {ConfirmationService} from 'primeng/api';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-rfe-select-clauses',
  templateUrl: './rfe-select-clauses.component.html',
  styleUrls: ['./rfe-select-clauses.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class RfeSelectClausesComponent implements OnInit {
  @Output() onMovetoSource: EventEmitter<any> = new EventEmitter();   
  @Input() rfeTimelineModalInput;
  taskId: number;
  
  rfeNoticeId: any;

  defaultCatagoryList: any[] = [];
  selectedCatagoryList: any[] = [];
  displayAddCatagoryPanel: boolean =  false;
  newCatagoryName:string = '';
  catagory:any = {};
  SubTaskOverView;
  userType;
  disableFields;
  @Output () notify: EventEmitter<string> = new EventEmitter<string>();

  constructor(private rfeService: RfeService,
    private store: Store<fromTask.State>,
    private toastr: ToastrService,
    private confirmationService:ConfirmationService) {
      this.userType = sessionStorage.getItem('userTypeRole');
  }

  ngOnInit(): void {
    this.getRFEDetails();
    console.log("this.rfeTimelineModalInput",this.rfeTimelineModalInput["stepStatus"]);
  }
  getRFEDetails() {
    combineLatest([
      this.store.select(fromTask.getRFESubTaskDetails)
    ]).subscribe(([getRFESubTask]) => {
      this.SubTaskOverView = getRFESubTask;
      if(this.SubTaskOverView.taskStepDetails[1].stepStatusDetails.stepStatusCode == 'COMPLETE')
        this.disableFields = true;
      else
      this.disableFields = false;
    });

    if(this.userType === Roles.ATTORNEY){
    this.rfeService
      .getRFEDetails(this.SubTaskOverView['id'])
      .subscribe((data) => {
      //  console.log("getdata", data);

        this.rfeNoticeId = data['id'];
        this.getRFECatagoryList(this.rfeNoticeId);
        this.getRfeSelectedCatagoryList(this.rfeNoticeId);

      });
    }
  }

  getRFECatagoryList(rfeNoticeId) {
    this.rfeService.getRfeAttorneyCatagoryList(rfeNoticeId).subscribe((data) => {
     // console.log("getdata", data);
      this.defaultCatagoryList = data ? data : [];
    });

  }

  getRfeSelectedCatagoryList(rfeNoticeId) {
    this.rfeService.getRfeSelectedCatagoryList(rfeNoticeId).subscribe((data) => {
   //   console.log("getdata", data);

      this.selectedCatagoryList = data ? data : [];
      this.selectedCatagoryList.map(obj => {
        if(obj.categoryDescription == null)
        obj.enableDelete = true ;} );
    });

  }

  ngOnChanges(e: SimpleChanges) {
    if (e.isOutsideImagility && e.isOutsideImagility.currentValue) {
      this.getRFEDetails();
    }
  }

  saveData() {
    let payload = this.selectedCatagoryList;
    payload.map(obj => {
      obj.rfeNoticeId = this.rfeNoticeId;
      if(obj.rfeCategoryId) delete obj.rfeCategoryId;
      if(obj.enableDelete) delete obj.enableDelete;

    }

    );

    //this.selectedCatagoryList.map()
    this.rfeService.saveRfeSelectedCatagoryList(this.rfeNoticeId,payload).subscribe((response)=>{
     //  console.log("Saved Data",response);
       if(response.status === 200){
        this.toastr.success(response.message);
        if(this.rfeTimelineModalInput["stepStatus"] ==='NEW'){
          this.notify.emit("INPROGRESS");
        }
        this.getRFECatagoryList(this.rfeNoticeId);
        this.getRfeSelectedCatagoryList(this.rfeNoticeId);
       }

    });
  }

  selectedCatagory(event:any){
    this.selectedCatagoryList.map(obj => {
    // if(obj.rfeCategoryId === event['items'][0].rfeCategoryId) 
    //     obj.enableDelete = true ;
    obj.rfeCategoryMasterId = obj.rfeCategoryId ? obj.rfeCategoryId : obj.rfeCategoryMasterId;
  
   });
  }

  deleteCatagory(catagory) {
    this.selectedCatagoryList = this.selectedCatagoryList.filter(function(item, idx) {
      return item.categoryDetailId !== catagory.categoryDetailId;
   });
   if(catagory && catagory.rfeCategoryId){

   }else{
    this.saveData();
   }
  }

  addCatagory(){
    this.newCatagoryName = '';
    this.displayAddCatagoryPanel = true;
    
  }
  changeStatusToComplete(){
    this.notify.emit("COMPLETE");
  }

  saveNewCatagory(){
    this.catagory.categoryName = this.newCatagoryName;
    this.catagory.sequenceNo = this.selectedCatagoryList ? this.selectedCatagoryList.length : 0;
    this.selectedCatagoryList.push(this.catagory);
    this.saveData();
    this.displayAddCatagoryPanel = false;


  }
  confirmDeleteRfeClause(catagory) {
    this.confirmationService.confirm({
        message: 'Do you want to delete this catagory?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.deleteCatagory(catagory);
           
        },
        reject: () => {
          
        }
    });
  }

}
