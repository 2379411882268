<div class="fixed-height visit-task-widget shadow-sm">
    <div class="card border-0">
        <div class="card-header d-flex justify-content-between align-items-center">
          <div class="col-7 pl-0 pr-2">Recently Visited Task</div>
          <div class="col-5 d-flex pl-2 pr-0 justify-content-end align-items-center">
            <label class="view-label">View By : </label>
             <p-dropdown class="filter-dropdown" appendTo="body" [options]="viewBy" optionValue="value"
            (onChange)="getViewsByOptions($event.value)"  optionLabel="field">
             </p-dropdown>
          </div>
        </div>
        <div class="card-body">
            <div class="card-content">
                <table mat-table *ngIf="allvisitedTasks.length != 0;else elseblock;" [dataSource]="allvisitedTasks" class="mat-elevation-z8 responsive-md responsive-sm">
                    <ng-container matColumnDef="time">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element" class="time_column"> {{element.time}} </td>
                    </ng-container>
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element" class="px-1"> <a (click)="gotToTaskDetails(element)">{{element.id}}</a> </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element" class="px-1"><a class="d-flex"  [ngClass]="{inactive : element.name == '' || element.name == null}" (click)="goToUserProfile(element)">
                        <mat-icon matTooltip="View Beneficiary" class="material-icons">person</mat-icon>
                        <p *ngIf='element.name == "" || element.name == null;else Elseblock;'>N/A</p>
                        <ng-template #Elseblock><p>{{element.name}}</p></ng-template>
                        </a>
                         </td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element" class="px-1">
                       <!--  <a class="d-flex" [ngClass]="{inactive : element.petitionerId == null || element.modeOfCreation == null}" (click)="goToCompanyProfile(element.petitionerId,element.modeOfCreation)"> <mat-icon matTooltip="View Company" class="material-icons"> -->
                        <a class="d-flex" (click)="goToCompanyProfile(element.petitionerId,element.modeOfCreation)"> <mat-icon matTooltip="View Company" class="material-icons">
                          corporate_fare
                        </mat-icon>
                         <span>{{element.companyName}}</span>
                       <!--  <p *ngIf='element.petitionerId == null || element.modeOfCreation == null;else Elseblock;'>{{element.companyName}}</p>
                        <ng-template #Elseblock><p>{{element.name}}</p></ng-template> -->
                         </a>
                         </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  <ng-template #elseblock>
                    <div class="mt-4 text-center">No data available now.</div>
                </ng-template>
            </div>
        </div>
    </div>
</div>