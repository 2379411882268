import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/internal/operators/map';

@Injectable({ providedIn: 'root' })
export class EmploymentHistoryService {
  constructor(
    private http: HttpClient
  ) { }

  syncBeneWorkExperienceAll(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, familyId?) {
    const url = familyId ? `/${visatype}/${caseType}/${caseId}/${synctype}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}&familyId=${familyId}`: `/${visatype}/${caseType}/${caseId}/${synctype}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}`;
    return this.http.post(url, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deletBeneWorkExperience(visaType: string, caseType: string, caseId: number, type: string, superTaskId: number, subTaskId: number, experienceId: number) {
    return this.http.delete(`/${visaType}/${caseType}/${caseId}/EXPERIENCE/${experienceId}?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deletBeneWorkExperienceTools(superTaskId, subTaskId, toolId) {
    return this.http.delete(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/workExperienceDetails/tools/${toolId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deletBeneWorkExperienceDuties(visaType: string, caseType: string, caseId: number, type: string, superTaskId: number, subTaskId: number, experienceId: number, dutyId) {
    return this.http.delete(`/${visaType}/${caseType}/${caseId}/EXPERIENCE/${experienceId}/jobduty/${dutyId}?superTaskId=${superTaskId}&taskId=${subTaskId}`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  deleteBeneWorkExperienceDocuments(visaType: string, caseType: string, caseId: number, documentId: number) {
    return this.http.delete(`/${visaType}/${caseType}/${caseId}/document/${documentId}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && response.status === 200) {
          return response;
        }
      })
    );
  }

  getBeneExperienceInformation(
    visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, familyId?) {
    const url = familyId ? `/${visatype}/${caseType}/${caseId}/${synctype}?superTaskId=${superTaskId}&taskId=${subTaskId}&familyId=${familyId}` : `/${visatype}/${caseType}/${caseId}/${synctype}?superTaskId=${superTaskId}&taskId=${subTaskId}`;
    return this.http.get(url).pipe(
      map((response: ImagilityBaseResponse) => {
        return response.data;
      })
    );
  }

  syncBeneWorkExperience(
    visatype: string, caseType: string, caseId: number, experienceId: number,
    superTaskId: number, subTaskId: number, syncType: string, familyId?) {
    const url = familyId ? `/${visatype}/${caseType}/${caseId}/${syncType}/${experienceId}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}&familyId=${familyId}`:`/${visatype}/${caseType}/${caseId}/${syncType}/${experienceId}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}`;
    return this.http.post(url, {}).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response.status === 200) {
          return response;
        }
      })
    );
  }

  syncI130(visatype: string, caseType: string, caseId: number, synctype: string, superTaskId: number, subTaskId: number, familyId?) {
    let url = `/petition/updateProfile/${caseId}/experience`;
    if(caseType == 'I130') {
      url = `/gcCase/updateProfile/I130/${caseId}/experience?familyId=${familyId}`;
    } else if(caseType === 'I485'){
      url = `/petition/updateProfile/${caseId}/experience?familyId=${familyId}`;
    } else if(visatype == 'I192U' || visatype == 'UQ'){
      url = familyId ? `/${visatype}/${caseType}/${caseId}/${synctype}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}&familyId=${familyId}`: `/${visatype}/${caseType}/${caseId}/${synctype}/sync?superTaskId=${superTaskId}&taskId=${subTaskId}`;
    }
    return this.http.post(url, {});
  }

  mergeI130(visaType: string, caseId: number, caseType: string, superTaskId: number, taskId: number|string, familyId: number, experienceType: string) {
    let url = `/${visaType}/${caseType}/${caseId}/experience/sync?superTaskId=${superTaskId}&taskId=${taskId}&syncType=merge`;
    if(caseType === 'I130') {
      url = `/GC/I130/${caseId}/${experienceType}/sync?superTaskId=${superTaskId}&taskId=${taskId}&familyId=${familyId}&syncType=Merge`;
    } else if(caseType === 'I485') {
      url = `/GCFLY/I485/${caseId}/experience/sync?superTaskId=${superTaskId}&taskId=${taskId}&familyId=${familyId}`;
    }
    return this.http.post(url, {});
  }

  resetI130(visaType: string, caseId: number, caseType: string, superTaskId: number, taskId: number|string, familyId: number, experienceType: string) {
    let url = `/${visaType}/${caseType}/${caseId}/experience/sync?superTaskId=${superTaskId}&taskId=${taskId}&syncType=reset`;
    if(caseType === 'I130') {
      url = `/GC/I130/${caseId}/${experienceType}/sync?superTaskId=${superTaskId}&taskId=${taskId}&familyId=${familyId}&syncType=Reset`;
    } else if(caseType === 'I485') {
      url = `/GCFLY/I485/${caseId}/experience/sync?syncType=Merge&superTaskId=${superTaskId}&taskId=${taskId}&familyId=${familyId}`;
    }
    return this.http.post(url, {});
  }
}

