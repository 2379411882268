import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MarketingService } from '../modules/marketing/common/services/marketing.service';
import { SnoozeService } from '../services/snooze.service';

@Component({
  selector: 'app-snooze',
  templateUrl: './snooze.component.html',
  styleUrls: ['./snooze.component.scss']
})
export class SnoozeComponent implements OnInit {
  loading = false;
  snooze: any;
  leadDetails: any;
  remindAfter: any;
  snoozeDdValues: { value: number; name: string; }[];
  constructor(public dialogRef: MatDialogRef<SnoozeComponent>,
    private snoozeService:SnoozeService,
    public toastr: ToastrService,
    private marketingService: MarketingService,
    @Inject(MAT_DIALOG_DATA) public data,public ngZone: NgZone) { }

  ngOnInit(): void {
    this.snooze = this.data.snoozeDetails;
    // this.marketingService.getLeadById(this.snooze.id).subscribe((response:any)=>{
      this.leadDetails = this.data.leadDetails;
      this.playSound();
    // });
    this.snoozeDdValues = [
      {value:5, name:"5 Minutes"},
      {value:10, name:"10 Minutes"},
      {value:15, name:"15 Minutes"},
      {value:1, name:"1 Hour"},
      {value:2, name:"2 Hours"}
    ];
  }

  playSound() {
    const audio = new Audio('../../assets/notifySound.mp3');
    audio.play();
  }

  closeSnooze(){
    this.snoozeService.triggerSnoozes('Saved snooze Successfully.');
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  updateSnooze(){
    let reminderDateSplitted = this.snooze.reminderDate.split('-');
    this.snooze.reminderDate = reminderDateSplitted[2]+'-'+reminderDateSplitted[0]+'-'+reminderDateSplitted[1];
    let reminderTimeSplitted = this.snooze.reminderTime.split(':');
    this.snooze.reminderTime = parseInt(this.remindAfter.value)<5?
                                (parseInt(reminderTimeSplitted[0])+parseInt(this.remindAfter.value))+':'+reminderTimeSplitted[1]+':'+reminderTimeSplitted[2]:
                                reminderTimeSplitted[0]+':'+(parseInt(reminderTimeSplitted[1])+parseInt(this.remindAfter.value))+':'+reminderTimeSplitted[2];
    this.snoozeService.saveSnooze(this.snooze).subscribe((response: any) => {
      if(response.message === "Saved snooze Successfully."){
      this.toastr.success(response.message);
      this.snoozeService.triggerSnoozes(response.message);

      this.ngZone.run(() => {
        this.dialogRef.close();
      });
    }
    });
  }

}
