import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { I140FileUploadConfiguration, ImagilityBaseResponse, PrimaryData, TaskStep } from 'app-models';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { RequiredDocumentsService } from '../required-documents/required-documents.service';
import { environment } from 'environment-configurations';
import { FileUploadLibraryService } from 'file-upload-library';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogLibraryComponent } from 'delete-dialog-library';
import { DeleteDialogConfigurations } from 'dialog-configuration-library';
import { select, Store } from '@ngrx/store';
import { getStepDetails } from 'visa-store';
import { DEPENDENT_VISAS, BENEFICIARY } from 'app-models';
import { AppServicesLibraryService } from 'app-services-library';

@Component({
  selector: 'vd-family-immigrant-documents',
  templateUrl: './vd-family-immigrant-documents.component.html',
  styleUrls: ['./vd-family-immigrant-documents.component.scss']
})
export class VdFamilyImmigrantDocumentsComponent implements OnDestroy, OnChanges, OnInit {
  @Input()
  primaryData: PrimaryData;
  requiredDocFileUploadConfigurations: I140FileUploadConfiguration;
  availableDocFileUploadConfigurations: I140FileUploadConfiguration;
  disabledControls: boolean;
  availableDocList = [];
  observableSubscription$ = new Subject();
  @Input() userId: number;
  stepDetails: TaskStep;
  @Input() type: string;
  @Input() isPrimaryApplicant: boolean;
  isDependetVisa: boolean;
  notifyDestinationUser: string;
  userType: string;

  constructor(
    public store: Store<any>,
    private apiService: RequiredDocumentsService,
    public uploadService: FileUploadLibraryService,
    public dialogService: DialogService,
    public toasterService: ToastrService,
    private appServicesLibraryService: AppServicesLibraryService) {
    this.userType = sessionStorage.getItem('userTypeRole');
    this.notifyDestinationUser = BENEFICIARY;
  }

  ngOnInit(): void {
    // Get Specific step details by passing step code
    this.isDependetVisa = DEPENDENT_VISAS.includes(this.primaryData.visatype);
    // this.setFileUploadConfigAndDocumentList();
    this.store.pipe(select(getStepDetails, { id: this.primaryData.stepId }))
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((data: TaskStep) => {
        if (data && data.id) {
          this.stepDetails = data;
          this.toggleControlsStatus();
        }
      });
  }

  // Toggle controls status based on step status
  toggleControlsStatus() {
    this.disabledControls = this.appServicesLibraryService.formToBeDisabled(this.stepDetails.stepStatusDetails.stepStatusCode);
    // this.disabledControls = this.stepDetails.stepStatusDetails.stepStatusCode === 'NEW' || this.stepDetails.stepStatusDetails.stepStatusCode === 'COMPLETE' ||
    //   this.stepDetails.stepStatusDetails.stepStatusCode === 'SUBMIT';
    if (this.primaryData && this.userId) {
      this.setFileUploadConfigAndDocumentList();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.primaryData && this.userId) {
      this.setFileUploadConfigAndDocumentList();
    }
  }

  setFileUploadConfigAndDocumentList() {
    this.setFileUploadConfigurations();
    if (DEPENDENT_VISAS.includes(this.primaryData.visatype) || this.primaryData.visatype === 'ASYLUM' || this.primaryData.getTaskType === 'I485') {
      this.getDependentAvailableDocumentsList();
    } else {
      this.getAvailableDocumentsList((this.primaryData.familyId && this.primaryData.familyId === this.userId || this.type === "family") ? 'fimmigration' : 'immigration');
    }

  }

  setFileUploadConfigurations() {
    let docTypeRefGroupCode = (this.primaryData.familyId && this.primaryData.familyId === this.userId || this.type === "family") ? 'BENOTHDOC' : this.primaryData.visatype === 'ASYLUM' ? 'PRIMBENDOC' : 'SPONSOROTHDOC';
    if (this.primaryData.stepName === 'Provide Household Member Details') {
      docTypeRefGroupCode = 'HSHLDMMBROTHDOC';
    }

    let requiredDocFileUploadConfigurations: I140FileUploadConfiguration = {
      disableControls: this.disabledControls,
      isDocumentTypeRequired: true,
      isDocumentUploadRequired: true,
      isViewEnabled: true,
      isDownloadEnabled: true,
      isDeleteEnabled: true,
      getDocTypeApiUrl: `/${this.primaryData.visatype}/task/${this.primaryData.subTaskId}/group/${docTypeRefGroupCode}/documentTypes`,
      getApiUrl: `/${this.primaryData.visatype}/${this.primaryData.caseType}/${this.primaryData.caseId}/document/category/${docTypeRefGroupCode}/`,
      postApiUrl: `/${this.primaryData.visatype}/${this.primaryData.caseType}/${this.primaryData.caseId}/document/category/${docTypeRefGroupCode}/fileCategory/`,
      deleteApiUrl: `/${this.primaryData.visatype}/${this.primaryData.caseType}/${this.primaryData.caseId}/document/`,
      documentTypeDropdownCode: docTypeRefGroupCode

    };

    if (this.primaryData.familyId === this.userId || this.type === "family") {
      requiredDocFileUploadConfigurations = {
        ...requiredDocFileUploadConfigurations,
        familyId: this.type === "family" ? this.userId : this.primaryData.familyId
      };
    }

    this.requiredDocFileUploadConfigurations = Object.assign({}, requiredDocFileUploadConfigurations);
  }

  getAvailableDocumentsList(type) {
    let familyId = this.type === "family" ? this.userId : this.primaryData.familyId;
    this.apiService.getAvailableDocumentsList(
      'GC', this.primaryData.caseType, this.primaryData.caseId, type, 'BENPASSDOC', familyId)
      .pipe(take(1))
      .subscribe((response: ImagilityBaseResponse) => {
        this.availableDocList = response.data ? response.data : [];
      });
  }

  getDependentAvailableDocumentsList() {
    const payload = {
      "beneficiaryId": this.type && this.type === 'family' ? null : this.primaryData.beneficiaryId,
      "familyId": this.userId,
      "groupCodes": this.primaryData.visatype === 'ASYLUM' ? ["BENIMMDOC", "BENPASSDOC", "BENLICDOC"] : ["BENIMMDOC", "BENWEDOC", "BENPASSDOC", "BENLICDOC"],
      "primary": this.isPrimaryApplicant
    }
    this.apiService.getDependentAvailableDocumentList(
      this.primaryData.visatype, this.primaryData.caseType, this.primaryData.caseId, payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        this.availableDocList = response.data ? response.data : [];
      });
  }

  syncDocuments() {
    const payload = {
      "beneficiaryId": this.type && this.type === 'family' ? null : this.primaryData.beneficiaryId,
      "familyId": this.userId,
      "groupCodes": this.primaryData.visatype === 'ASYLUM' ? ["BENIMMDOC", "BENPASSDOC", "BENLICDOC"] : ["BENIMMDOC", "BENWEDOC", "BENPASSDOC", "BENLICDOC"],
      "primary": this.isPrimaryApplicant
    }
    this.apiService.getSyncDocuments(
      this.primaryData.visatype, this.primaryData.caseType, this.primaryData.caseId, payload)
      .pipe(takeUntil(this.observableSubscription$))
      .subscribe((response: ImagilityBaseResponse) => {
        if (response) {
          this.toasterService.success(response.message);
          // this.getDependentAvailableDocumentsList();
          if (DEPENDENT_VISAS.includes(this.primaryData.visatype) || this.primaryData.visatype === 'ASYLUM' || this.primaryData.getTaskType === 'I485') {
            this.getDependentAvailableDocumentsList();
          } else {
            this.getAvailableDocumentsList((this.primaryData.familyId && this.primaryData.familyId === this.userId || this.type === "family") ? 'fimmigration' : 'immigration');
          }
        }
        // this.availableDocList = response.data ? response.data : [];
      });
  }

  openDoc(item) {
    const docPath = item.fileLocation.substring(23);
    window.open(`${environment.docs}${docPath}`, '_blank');
  }

  download(file) {
    this.uploadService.downloadDocuments(environment.docs + file.fileLocation.substring(23), file.fileLocation.substring(23));
  }
  deleteDocumentTypeLis(input) {
    const deleteDialogRef = this.dialogService.open(DeleteDialogLibraryComponent, DeleteDialogConfigurations);
    deleteDialogRef.onClose.pipe(takeUntil(this.observableSubscription$)).subscribe((response: boolean) => {
      if (response) {
        try {
          this.uploadService.deleteUploadDocument(this.requiredDocFileUploadConfigurations.deleteApiUrl + `${input.id}`)
            .pipe(takeUntil(this.observableSubscription$))
            .subscribe((responseUploadedDocs: ImagilityBaseResponse) => {
              this.toasterService.success(responseUploadedDocs.message);
              if ((DEPENDENT_VISAS.includes(this.primaryData.visatype) || this.primaryData.visatype === 'ASYLUM' || this.primaryData.getTaskType === 'I485')) {
                this.getDependentAvailableDocumentsList();
              } else {
                this.getAvailableDocumentsList((this.primaryData.familyId === this.userId || this.type === "family") ? 'fimmigration' : 'immigration');
              }
            });
        } catch (error) {
          console.log("error", error);
        }
      }
    });
  }


  ngOnDestroy() {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();

  }

}
