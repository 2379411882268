<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-right mb-3" >
      <mat-button-toggle-group [value]="selectedProfileType" (change)="onChangeProfile($event)">
          <mat-button-toggle value="simple">Simple Profile</mat-button-toggle>
          <mat-button-toggle value="complete">Complete Profile</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</div>

<ng-container *ngIf="selectedProfileType =='simple'">
  <lib-simple-experience-detail-library [isWizard]="false" [primaryData]="inputData"></lib-simple-experience-detail-library>
</ng-container>


<ng-container *ngIf="selectedProfileType == 'complete'">
  <div class="container-fluid">
    <ng-container [ngClass]="{disabledView : isViewMode}">
      <div class="row" [ngClass]="{disabledView : isViewMode}">
        <button *ngIf="updateBenProfile && !viewMode && showEditButton" class="btn btn-primary mb-2" type="button"
          (click)="toggleExpansion(); resetFormToInitial(benExpForm);stepper?.reset();formCheck(benExpForm['form'] ,clientForm,dutiesForm,toolsAndTech)">
          Add / Edit Work Experience
        </button>
      </div>
  
      <mat-accordion *ngIf="showEditButton">
        <mat-expansion-panel hideToggle [expanded]="expandPanel">
          <mat-card class="mt-4 work-experience-component">
            <mat-horizontal-stepper [linear]="isLinear" #stepper labelPosition="bottom"
              class="pb-mat-stepper-custom-horizontal">
              <ng-template matStepperIcon="done">
                <mat-icon>done</mat-icon>
              </ng-template>
              <!-- <mat-step [stepControl]="benExpForm"> -->
              <mat-step [completed]="hasID">
                <ng-template matStepLabel>Employment</ng-template>
                <div class="row formBox">
                  <form #benExpForm="ngForm" class="w-100" [ngClass]="{disabledView: isViewMode}">
                    <div class="row mt-4">
                      <div class="form-group col-md-12">
                        <mat-checkbox name="isCurrentRole" [(ngModel)]="beneficiaryWorkDetails.isCurrentRole">I am
                          currently
                          working in this role
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-8">
                        <label class="label-cls">Title</label>
                        <input class="im-input mb-2 mw-100"  type="text" name="designation" [(ngModel)]="beneficiaryWorkDetails.designation"
                          placeholder="Enter" #designation="ngModel" />
                        <span class="invalid-message" *ngIf="
                                (designation.touched || benExpForm.submitted) &&
                                designation.errors?.required
                              ">
                          {{ getGlobalErrorMessages("REQUIRED") }}
                        </span>
                      </div>
                      <div class="form-group col-md-4 processing-dropdown">
                        <label class="label-cls ">Employment Type</label>
                        <p-dropdown
                          (onChange)="handleEmploymentTypeChange(beneficiaryWorkDetails.employmentType, benExpForm)"
                          [options]="listEmploymentType" name="employmentType" appendTo="body"
                          [(ngModel)]="beneficiaryWorkDetails.employmentType" 
                          #employmentTy="ngModel" optionValue="code" optionLabel="name" placeholder="Select Type">
                        </p-dropdown>
                        <div *ngIf="(benExpForm.submitted || employmentTy.touched) &&
                                employmentTy.errors?.required" class="invalid-message">
                          {{ getGlobalErrorMessages("REQUIRED") }}
                        </div>
                      </div>
                    </div>
  
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label class="label-cls  d-block">Employed From</label>
                        <mat-form-field appearance="outline" class="mt-0 date-field">
                          <input matInput [matDatepicker]="employedFromPicker" name="startDate"
                            [(ngModel)]="beneficiaryWorkDetails.startDate" placeholder="MM/DD/YYYY" #startDt="ngModel"
                            [max]="maxDate" (click)="employedFromPicker.open()" />
                          <mat-datepicker-toggle matSuffix [for]="employedFromPicker">
                          </mat-datepicker-toggle>
                          <mat-datepicker #employedFromPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
       
                      <div class="form-group col-md-6" *ngIf="!beneficiaryWorkDetails.isCurrentRole">
                        <label class="label-cls d-block">Employed Till</label>
                        <mat-form-field appearance="outline" class="mt-0 date-field">
                          <input matInput [matDatepicker]="employedTillPicker" name="endDate"
                            [(ngModel)]="beneficiaryWorkDetails.endDate" [min]="beneficiaryWorkDetails.startDate"
                            [max]="maxDate" placeholder="MM/DD/YYYY" (click)="employedTillPicker.open()" />
                          <mat-datepicker-toggle matSuffix [for]="employedTillPicker">
                          </mat-datepicker-toggle>
                          <mat-datepicker #employedTillPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="form-group col-md-6">
                        <label class="label-cls processing-dropdown" >Salary</label>
                        <div class="row">
                          <p-dropdown [options]="currencyTypeList" class="mt-1" name="currency" appendTo="body"
                            [(ngModel)]="beneficiaryWorkDetails.currency" styleClass="pt-1"
                            #currency="ngModel" optionValue="id" optionLabel="name" placeholder="Select">
                          </p-dropdown>
                          <input class="im-input mb-2 col phoneInput" name="salary"
                            [(ngModel)]="beneficiaryWorkDetails.salary" placeholder="Enter" type="number"
                            #salary="ngModel" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="position-relative">
                          <mat-divider></mat-divider>
                        </div>
                      </div>
                    </div>
                    <h5 class="labelText mt-3 ml-3">Employer
                      Contact Details</h5>
                    <div class="row">
                      <div class="form-group col-md-5">
                        <label class="label-cls ">Company</label>
                        <input class="im-input mb-2 mw-100" type="text" name="companyName" [(ngModel)]="beneficiaryWorkDetails.companyName"
                          placeholder="Enter" #companyName="ngModel"  />
                        <span class="invalid-message" *ngIf="
                                (companyName.touched || benExpForm.submitted) &&
                                companyName.errors?.required
                              ">
                          {{ getGlobalErrorMessages("REQUIRED") }}
                        </span>
                      </div>
  
                      <div class="form-group col-md-8 processing-dropdown">
                        <label class="label-cls">Office Phone Number</label>
                        <div class="row">
                          <p-dropdown [options]="listCountryOfficePhone" class="mt-1" name="officeCountryCode"
                            [(ngModel)]="beneficiaryWorkDetails.officeCountryCode" appendTo="body"
                            styleClass="pt-1" optionValue="countryCode"
                            optionLabel="labelWithCode" placeholder="Select">
                          </p-dropdown>
                          <input class="im-input mb-2 col phoneInput" name="officeNo" pattern="[0-9]{8,10}"
                            [(ngModel)]="beneficiaryWorkDetails.officeNo" type="text" form placeholder="Office"
                            #officeNo="ngModel" maxlength="10" />
  
                          <span class="invalid-message" *ngIf="
                                  (officeNo.touched || benExpForm.submitted) &&
                                  officeNo.errors?.pattern
                                ">
                            {{ getGlobalErrorMessages("INVALID_NUMBER") }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-8">
                        <label class="label-cls ">Address Line 1</label>
                        <input class="im-input mb-2 mw-100"  type="text" name="addressLine1" [(ngModel)]="beneficiaryWorkDetails.addressLine1"
                          placeholder="Address Line 1" #addressLine1="ngModel"  />
                        <span class="invalid-message" *ngIf="
                                (addressLine1.touched || benExpForm.submitted) &&
                                addressLine1.errors?.required
                              ">
                          {{ getGlobalErrorMessages("REQUIRED") }}
                        </span>
                      </div>
                    </div>
  
                    <div class="row">
                      <div class="form-group col-md-8">
                        <label class="label-cls">Address Line 2</label>
                        <input class="im-input mb-2 mw-100" type="text" name="addressLine2"
                          [(ngModel)]="beneficiaryWorkDetails.addressLine2" placeholder="Address Line 1" />
                      </div>
                    </div>
  
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label class="label-cls processing-dropdown">Country</label>
                        <p-dropdown (onChange)="handleCountryChange($event.value)" [options]="listCountry" name="country"
                          [(ngModel)]="beneficiaryWorkDetails.countryCode" appendTo="body"
                          #country="ngModel" optionValue="countryCode" optionLabel="countryName"
                          placeholder="Select Country">
                        </p-dropdown>
                        <div class="mb-minus-2-5 error pl-1 invalid-message" *ngIf="
                                (country.touched || benExpForm.submitted) &&
                                country.errors?.required
                              ">
                          {{ getGlobalErrorMessages("REQUIRED") }}
                        </div>
                      </div>
                      <div class="form-group col-md-4 processing-dropdown" *ngIf="listStates.length">
                        <label class="label-cls">State</label>
                        <p-dropdown [options]="listStates" name="stateProvinceCode"
                          [(ngModel)]="beneficiaryWorkDetails.stateProvinceCode" appendTo="body"
                          #stateProvinceDropdown="ngModel" optionValue="stateProvinceCode" optionLabel="stateProvinceName"
                          placeholder="Select State">
                        </p-dropdown>
                        <span class="invalid-message" *ngIf="
                                (stateProvinceDropdown.touched || benExpForm.submitted) &&
                                stateProvinceDropdown.errors?.required
                              ">
                          {{ getGlobalErrorMessages("REQUIRED") }}
                        </span>
                      </div>
                      <div class="form-group col-md-4" *ngIf="!listStates.length">
                        <label class="label-cls">State</label>
                        <input class="im-input mb-2 mw-100"  name="stateProviceName" [(ngModel)]="beneficiaryWorkDetails.stateProvinceName"
                          type="text" placeholder="State" #stateProviceName="ngModel" />
                        <span class="invalid-message" *ngIf="
                                (stateProviceName.touched || benExpForm.submitted) &&
                                stateProviceName.errors?.required
                              ">
                          {{ getGlobalErrorMessages("REQUIRED") }}
                        </span>
                      </div>
                      <div class="form-group col-md-4">
                        <label class="label-cls">City</label>
                        <input class="im-input mb-2 mw-100"  name="city" [(ngModel)]="beneficiaryWorkDetails.city" type="text" placeholder="City"
                          #city="ngModel" />
                        <span class="invalid-message" *ngIf="
                                (city.touched || benExpForm.submitted) &&
                                city.errors?.required
                              ">
                          {{ getGlobalErrorMessages("REQUIRED") }}
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label class="label-cls ">Zip Code</label>
                        <input class="im-input mb-2 mw-100"  name="zipCode" maxlength="10" [(ngModel)]="beneficiaryWorkDetails.zipCode" type="text"
                          placeholder="Zip Code" #zipCode="ngModel" pattern="^$|^[A-Za-z0-9]+"  />
                        <span class="invalid-message" *ngIf="
                                (zipCode.touched || benExpForm.submitted) &&
                                zipCode.errors?.required
                              ">
                          {{ getGlobalErrorMessages("REQUIRED") }}
                        </span>
                        <span class="invalid-message" *ngIf="
                                (zipCode.touched || benExpForm.submitted) &&
                                zipCode.errors?.pattern
                              ">
                          {{ getGlobalErrorMessages("ZIPCODE_ONLY") }}
                        </span>
                      </div>
                    </div>
  
  
                    <div class="next-btn">
                      <div class="row paddingTop10 dasheedTopBorder">
                        <div class="form-group col-md-12">
                          <button class="btn btn-success" *ngIf="!viewMode" (click)="saveEmployment(benExpForm)"
                            [disabled]="benExpForm.invalid">
                            Save
                          </button>
                        </div>
                      </div>
                      <hr />
                      <div class="d-flex flex-row-reverse">
                        <div class="p-2">
                          <button class="btn btn-primary" matStepperNext [disabled]="benExpForm.invalid && !hasID">
                            Next
                          </button>
                        </div>
                        <div class="p-2" *ngIf="!modeVal">
                          <button type="button" class="back-button" (click)="closeForm(benExpForm)">
                            Close
                          </button>
                        </div>
                        <div class="p-2 f10 mTop10">
                          (Click on save button to save data)
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </mat-step>
              <!-- Add Clients -->
              <mat-step>
                <ng-template matStepLabel>Clients</ng-template>
                <form (ngSubmit)="(clientForm.form.valid)" #clientForm="ngForm" class="w-100"
                  [ngClass]="{disabledView: isViewMode}">
                  <div class="beneficiary-workexp-clients-box">
                    <h5 class="mt-4 mb-2 labelText">Clients worked with</h5>
                    <div class="row">
                      <div class="form-group col-md-8 mt-2">
                        <label class="label-cls ">Client Name</label>
                        <input class="im-input mw-100" [ngClass]="{
                                'im-input-error':
                                  (clientForm.submitted || clientName.touched) &&
                                  clientName.invalid
                              }" type="text" name="clientName" [(ngModel)]="client.clientName" #clientName="ngModel"
                          placeholder="Enter"  />
                        <div *ngIf="
                                (clientForm.submitted || clientName.touched) &&
                                clientName.invalid
                              " class="invalid-message">
                          <div *ngIf="clientName.errors?.required">
                            {{ getGlobalErrorMessages("REQUIRED") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-end">
                      <div class="form-group col-md-4">
                        <label class="label-cls ">Start Date</label>
                        <mat-form-field appearance="outline" class="mt-0 date-field">
                          <input [(ngModel)]="client.startDate" #clientStartDt="ngModel" name="clientStartDt" matInput
                            [min]="beneficiaryWorkDetails.startDate" [max]="beneficiaryWorkDetails.endDate"
                            [matDatepicker]="clientStartDate"  placeholder="MM/DD/YYYY"
                            (click)="clientStartDate.open()" />
                          <mat-datepicker-toggle matSuffix [for]="clientStartDate">
                          </mat-datepicker-toggle>
                          <mat-datepicker [startAt]="beneficiaryWorkDetails.startDate" #clientStartDate></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="
                                (clientForm.submitted || clientStartDt.touched) &&
                                clientStartDt.invalid
                              " class="invalid-message">
                          <div *ngIf="clientStartDt.errors?.required">
                            {{ getGlobalErrorMessages("REQUIRED") }}
                          </div>
                        </div>
  
                        <span class="invalid-message" *ngIf="
                                (clientForm.submitted || clientStartDt.touched) &&
                                clientStartDt.errors &&
                                !clientStartDt.errors?.required
                              ">
                          {{ getGlobalErrorMessages("INVALID_DATE") }}
                        </span>
                      </div>
                      <!-- IM-2830 - End date issue in work exp -->
                      <div class="form-group col-md-4">
                        <label *ngIf="beneficiaryWorkDetails.isCurrentRole" class="label-cls">End Date
                        </label>
                        <label *ngIf="!beneficiaryWorkDetails.isCurrentRole" class="label-cls mb-1">End Date</label>
                        <mat-form-field appearance="outline" class="mt-0 date-field">
                          <input [(ngModel)]="client.endDate" #clientEndDt="ngModel" name="clientEndDt" matInput
                            [matDatepicker]="clientEndDate" [min]="client.startDate"
                            [max]="beneficiaryWorkDetails.endDate" 
                            placeholder="MM/DD/YYYY" (click)="clientEndDate.open()" />
                          <mat-datepicker-toggle matSuffix [for]="clientEndDate">
                          </mat-datepicker-toggle>
                          <mat-datepicker #clientEndDate></mat-datepicker>
                        </mat-form-field>
                        <span class="invalid-message" *ngIf="
                                (clientForm.submitted || clientEndDt.touched) &&
                                clientEndDt.errors &&
                                !clientEndDt.errors?.required
                              ">
                          {{ getGlobalErrorMessages("INVALID_DATE") }}
                        </span>
                      </div>
                      <div class="form-group col-md-4">
                        <button class="btn btn-success" *ngIf="!viewMode" [disabled]="clientForm.invalid"
                          (click)="addClientDetails(clientForm)">
                          Add Client Details
                        </button>
                      </div>
                    </div>
                    <div class="client-display-box col-md-12">
                      <!----md-12 2ndst   -->
                      <mat-list *ngIf="
                              beneficiaryWorkDetails.clients &&
                              beneficiaryWorkDetails.clients.length > 0
                            " class="client-list-print">
                        <div class="mat-subheader-clients-added" mat-subheader>
                          Clients added
                        </div>
                        <mat-list-item>
                          <div class="clients-list-item-partition">
                            <div mat-line>Client Name</div>
                          </div>
                          <div class="clients-list-item-partition">
                            <div mat-line>From</div>
                          </div>
                          <div class="clients-list-item-partition">
                            <div mat-line>To</div>
                          </div>
                          <div class="clients-list-item-partition">
                            <div mat-line>Action</div>
                          </div>
                        </mat-list-item>
                        <mat-list-item *ngFor="
                                let item of beneficiaryWorkDetails.clients;
                                let i = index;
                                let even = even;
                                let first = first;
                                let last = last
                              " [ngClass]="{
                                evenBg: even,
                                'border-top-radius': first,
                                'border-bottom-radius': last
                              }">
                          <!-- <div class="clients-list-item-partition numberCircle">
                                {{ i + 1 }}
                              </div> -->
  
                          <div class="clients-list-item-partition">
                            <div class="numberCircle float-left mr-1 ml-n1">
                              {{ i + 1 }}
                            </div>
                            <div mat-line class="mt-2">{{ item.clientName }}</div>
                          </div>
  
                          <div class="clients-list-item-partition">
                            <div mat-line>{{ item.startDate | date: 'MM/dd/yyyy' }}</div>
                          </div>
  
                          <div class="clients-list-item-partition">
                            <div mat-line>{{ item.endDate | date: 'MM/dd/yyyy' }}</div>
                          </div>
  
                          <div class="clients-list-item-partition">
                            <div mat-line>
                              <mat-icon class="delete-client-entry" *ngIf="!viewMode" (click)="removeClientItem(item.id)">
                                delete
                              </mat-icon>
                            </div>
                          </div>
                        </mat-list-item>
                      </mat-list>
                    </div>
                  </div>
                </form>
                <div class="row paddingTop10 dasheedTopBorder"></div>
                <hr />
                <div class="row">
                  <div class="col-6">
                    <button type="button" class="back-button" matStepperPrevious>
                      Back
                    </button>
                  </div>
                  <div class="col-6 text-right">
                    <button type="button" *ngIf="!modeVal" class="back-button mr-3"
                      (click)="closeForm(clientForm); stepper.reset()">
                      Close
                    </button>
                    <button type="button" class="btn btn-primary" matStepperNext>
                      Next
                    </button>
                  </div>
                </div>
              </mat-step>
              <!-- Add Duties -->
              <mat-step>
                <ng-template matStepLabel>List of Duties</ng-template>
                <div class="formBox">
                  <div class="row">
                    <h5 class="labelText">List of Duties</h5>
                  </div>
                  <form (ngSubmit)="(dutiesForm.form.valid)" #dutiesForm="ngForm" class="w-100"
                    [ngClass]="{disabledView: isViewMode}">
                    <div class="row mt-2">
                      <div class="col-md-12 mt-2">
                        <label class="labelText ">Duty</label>
                        <input class="im-input mb-4 mw-100"  name="duty" [(ngModel)]="duty.duty" type="text" #mainDuty="ngModel"
                          placeholder="Please enter Duty"  />
                        <div *ngIf="
                                (dutiesForm.submitted || mainDuty.touched) &&
                                mainDuty.invalid
                              " class="invalid-message">
                          <div *ngIf="mainDuty.errors?.required">
                            {{ getGlobalErrorMessages("REQUIRED") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngFor="let subDuty of duty.subDuties; let i = index">
                      <div class="col-md-10">
                        <div class="form-group">
                          <label>Sub-Duty</label>
                          <input class="im-input mb-4" name="{{ i }}" [(ngModel)]="subDuty.subDutyDescription" type="text"
                            placeholder="Ex: Lead Team" />
                        </div>
                      </div>
                      <div class="form-group col-md-1 mt-4 pt-2">
                        <mat-icon aria-hidden="false" *ngIf="duty.subDuties.length > 1" class="icn-delete pl-2"
                          (click)="deleteSubDuties(i)">delete_outline</mat-icon>
                      </div>
                      <div class="form-group col-md-1 mt-4 pt-2" *ngIf="duty.subDuties.length - 1 === i">
                        <mat-icon matTooltip="Add new sub duty" aria-hidden="false" class="icn-add"
                          (click)="addSubDuties()">
                          add_circle_outline
                        </mat-icon>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-12">
                        <button type="button" class="im-btn im-btn-primary im-btn-md" style="margin-top: 30px"
                          [disabled]="dutiesForm.invalid" (click)="addJobDuties(duty); resetJobDuties(dutiesForm)">
                          Add
                        </button>
                      </div>
                    </div>
                  </form>
                  <mat-divider></mat-divider>
  
                  <div *ngIf="
                          beneficiaryWorkDetails.jobDuties &&
                          beneficiaryWorkDetails.jobDuties.length > 0
                        " class="mt-2">
                    <h6>Added Duties</h6>
                    <div class="row col-md-12 mt-2">
                      <mat-card *ngFor="
                              let item of beneficiaryWorkDetails.jobDuties;
                              let i = index
                            " class="w-100">
                        <div class="row mt-2">
                          <div class="col-md-10">
                            <div class="row">
                              <div class="col-md-12">
                                <h5>
                                  <b>{{ i + 1 }}.</b> {{ item.duty }}
                                </h5>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div *ngFor="let subItem of item.subDuties">
                                  <P style="margin-left: 5px">{{ subItem.subDutyDescription }}
                                  </P>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="row">
                              <div class="col-md-1">
                                <i style="cursor: pointer" class="material-icons"
                                  (click)="
                                        resetJobDuties(dutiesForm); editDuty(item);formCheck(benExpForm['form'] ,clientForm,dutiesForm,toolsAndTech)">
                                  edit
                                </i>
                              </div>
                              <div class="col-md-1">
                                <i style="cursor: pointer" class="material-icons" (click)="
                                        removeJobDuty(item.dutyId);
                                        resetJobDuties(dutiesForm)
                                      ">delete_forever</i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-6">
                      <button class="back-button" matStepperPrevious>Back</button>
                    </div>
                    <div class="col-6 text-right">
                      <button class="back-button mr-3" *ngIf="!modeVal" (click)="closeForm(dutiesForm); stepper.reset()">
                        Close
                      </button>
                      <button class="btn btn-success" matStepperNext>Next</button>
                    </div>
                  </div>
                </div>
              </mat-step>
              <!-- Add Tools & Technologies -->
              <mat-step>
                <ng-template matStepLabel>Tools & Technologies</ng-template>
                <div class="formBox" [ngClass]="{disabledView: isViewMode}">
                  <div class="row">
                    <h5 class="labelText">List of Tools & Technologies</h5>
                  </div>
                  <!-- <div class="row"> -->
                  <div class="row mb-10">
                    <form #toolsAndTech="ngForm" class="w-100 list-of-tools-and-technologies">
                      <mat-form-field appearance="outline" class="example-chip-list col chips-contianer">
                        <mat-chip-list #chipList aria-label="Tools selection">
                          <mat-chip *ngFor="let item of listToolsTechnology" [selectable]="selectable"
                            [removable]="removable" (removed)="removeToolsChip(item)">
                            {{ item.skillName }}
                            <mat-icon matTooltip="Remove this item" matChipRemove *ngIf="removable">cancel</mat-icon>
                          </mat-chip>
                          <input placeholder="Eg: JavaScript..." [matChipInputFor]="chipList"
                            [(ngModel)]="toolsAndTechChip" name="toolsAndTechChip" #tlsAdTechChip="ngModel"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                            (paste)="pasteChips($event)" (matChipInputTokenEnd)="add($event)" />
                        </mat-chip-list>
                        <mat-hint class="hint-text mt-2 pl-2">Please enter comma separated values</mat-hint>
                      </mat-form-field>
                      <div class="form-group col-2 mt-2 pt-3">
                        <button type="button" class="im-btn im-btn-primary im-btn-md"
                          [disabled]="listToolsTechnology.length === 0" (click)="addToolsTechnologies(toolsAndTech)">
                          ADD
                        </button>
                      </div>
                    </form>
                  </div>
                 
                  <div *ngIf="
                          beneficiaryWorkDetails.tools &&
                          beneficiaryWorkDetails.tools.length > 0
                        ">
                    <h5>Added Tools & Technologies</h5>
                    <table class="im-card-no-border-table w-100">
                      <tbody>
                        <tr class="col-md-12" *ngFor="
                                let item of beneficiaryWorkDetails.tools;
                                let i = index
                              ">
                          <td class="w40">
                            <div class="circleIndex rounded-circle">
                              {{ i + 1 }}
                            </div>
                          </td>
                          <td class="col-md-10">{{ item.skillName }}</td>
                          <td class="col-md-2" (click)="removeToolsTechnology(item.id)" style="cursor: pointer">
                            <mat-icon matTooltip="Delete">delete_forever</mat-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row paddingTop10 dasheedTopBorder"></div>
                  <hr />
                  <div class="row">
                    <div class="col-6">
                      <button type="button" class="back-button" matStepperPrevious>
                        Back
                      </button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="button" class="back-button mr-3" *ngIf="!modeVal"
                        (click)="closeForm(toolsAndTech); stepper.reset()">
                        Close
                      </button>
                      <button type="button" class="btn btn-success float-right" matStepperNext>
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </mat-step>
              <!-- Upload Documents -->
              <mat-step>
                <ng-template matStepLabel>Upload Documents</ng-template>
                <div class="formBox" [ngClass]="{disabledView: isViewMode}">
                  <div class="row">
                    <p>
                      Selected the related work experience documents and then click
                      upload to save
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <form #docUploadForm="ngForm" class="w-100">
                        <immigration-file-upload [primaryData]="inputData" class="w-100" [configurations]="experienceFileUploadConfigurations" (fileUploadSuccess)="fileUploadSuccess()">
                        </immigration-file-upload>
                      </form>
                    </div>
                  </div>
                  <div class="row paddingTop10 dasheedTopBorder"></div>
                  <hr />
                  <div class="row">
                    <div class="col-4">
                      <button type="button" class="back-button" matStepperPrevious>
                        Back
                      </button>
                    </div>
                    <div class="col-8 text-right">
                      <button type="button" class="back-button mr-3" *ngIf="!modeVal" (click)="closeForm(docUploadForm)">
                        Close
                      </button>
                      <button type="button" *ngIf="!viewMode" class="btn btn-primary" matStepperNext
                        (click)="resetFormToInitial(benExpForm);resetForm();stepper.reset()">
                        Add New Work Experience
                      </button>
                    </div>
                  </div>
                </div>
              </mat-step>
            </mat-horizontal-stepper>
          </mat-card>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
    <mat-card class="row mt-4">
      <table mat-table class="im-card-no-border-table w-100" [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="column.columnDef == 'toggle'" class="toggle-col">
              <mat-icon matTooltip="View more" class="mr-1 icn">expand_more</mat-icon>
            </span>
            <div *ngIf="updateBenProfile && showEditButton">
              <ng-container>
                <span class="column.customClass" *ngIf="column.columnType == 'icon'">
                  <span class="d-block w-max-content">
                    <mat-icon class="mr-1 icn" *ngIf="!viewMode" matTooltip="Edit" class="im-table-verticle-icon"
                      (click)="$event.stopPropagation(); handleEdit(row); formCheck(benExpForm['form'] ,clientForm,dutiesForm,toolsAndTech)">
                      edit
                    </mat-icon>
                    <mat-icon class="mr-1 icn" *ngIf="!viewMode" matTooltip="Delete"
                      (click)="handleDeleteExperience(row); $event.stopPropagation()">delete_forever</mat-icon>
                  </span>
                </span>
              </ng-container>
            </div>
            <span *ngIf="column.columnType == 'text'">
              {{ row[column.columnDef] }}
            </span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
            <div class="example-element-detail" [@detailExpand]="
                      element == expandedElement ? 'expanded' : 'collapsed'
                    ">
              <div class="w100 example-element-description py-1" *ngIf="element.tools && element.tools.length > 0">
                <h6>List of Tools</h6>
                <ng-container>
                  <table class="table table-striped w-100">
                    <tr *ngFor="let item of element.tools; let i = index" class="border border-dark">
                      <td class="w40">
                        <div class="circleIndex rounded-circle">{{ i + 1 }}</div>
                      </td>
                      <td>{{ item.skillName }}</td>
                    </tr>
                  </table>
                </ng-container>
              </div>
              <div class="w100 example-element-description py-1" *ngIf="element.clients && element.clients.length > 0">
                <h6>List of Clients</h6>
                <ng-container>
                  <table class="table table-striped w-100">
                    <tr *ngFor="let item of element.clients; let i = index" class="border border-dark">
                      <td class="w40">
                        <div class="circleIndex rounded-circle">{{ i + 1 }}</div>
                      </td>
                      <td nowrap>{{ item.clientName }}</td>
                      <td nowrap>{{ item.startDate | date : 'MM/dd/yyyy' }}</td>
                      <td nowrap>{{ item.endDate | date : 'MM/dd/yyyy' }}</td>
                    </tr>
                  </table>
                </ng-container>
              </div>
              <div class="w100 example-element-description py-1"
                *ngIf="element.jobDuties && element.jobDuties.length > 0">
                <h6>List of Duties</h6>
                <ng-container>
                  <table class="table table-striped w-100 table-fixed">
                    <tr *ngFor="let item of element.jobDuties; let i = index" class="border border-dark">
                      <td class="w40">
                        <div class="circleIndex rounded-circle">{{ i + 1 }}</div>
                      </td>
                      <td class="w-half-minus-40px">{{ item.duty }}</td>
                      <td class="w-half-minus-40px">
                        <div *ngFor="let subItem of item.subDuties">
                          <P style="margin-left: 5px">{{
                            subItem.subDutyDescription
                            }}</P>
                        </div>
                      </td>
                    </tr>
                  </table>
                </ng-container>
              </div>
              <div class="w100 example-element-description py-1">
                <h6>List of Documents</h6>
                <ng-container>
                  <table class="im-card-no-border-table w-100">
                    <thead>
                      <tr>
                        <th class="w40"></th>
                        <th>Document</th>
                        <th>Document Type</th>
                        <th>
                          <i class="fa fa-download fa-lg icn" aria-hidden="true"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of element.documents; let i = index">
                        <td class="w40" *ngIf="item.isPresent === true">
                          <div class="circleIndex rounded-circle">
                            {{ i + 1 }}
                          </div>
                        </td>
                        <td *ngIf="item.isPresent === true">
                          <strong>{{ item.fileName }}</strong>
                        </td>
                        <td nowrap *ngIf="item.isPresent === true">
                          {{ item?.fileCategory?.code === 'OTHER' ? item?.fileCategoryOtherName : item?.fileCategory?.name
                          }}
                        </td>
                        <td nowrap *ngIf="item.isPresent === true">
                          <button pButton type="button" icon="pi pi-download" pTooltip="Download"
                            (click)="downloadDoc(item)" class="p-button-text p-button-rounded p-3"></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="example-element-row"
          [class.example-expanded-row]="expandedElement === row"
          (click)="expandedElement = expandedElement === row ? null : row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </mat-card>
  </div>
</ng-container>