import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ImagilityWebStorage } from '../../../services/webStorage.service';
import { Router, NavigationError } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChangepasswordComponent } from '../../shared/changepassword/changepassword.component';
import { Store } from '@ngrx/store';
import { MarkCleanFormAction } from 'src/app/app-state/actions/dirty-form.actions';
// import { AuthenticationService } from 'authentication';
/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  dialogueOpen = false;
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private webStorage: ImagilityWebStorage,
    private changePsswdDialog: MatDialog,
    protected store: Store<any>,
    // private authenticationService: AuthenticationService
  ) {
    this.watchRouternavigationError();

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(map((event: HttpEvent<any>) =>
      this.successHandler(event)), catchError(error => this.errorHandler(error)));
  }

  private watchRouternavigationError() {
    this.router.events.subscribe((data) => {
      if (data instanceof NavigationError) {
        if (!(data['url'] && data['url'].endsWith('api/v1/authenticate'))) {
          // this.store.dispatch(new MarkCleanFormAction({ dirty: false, message:''}));
          this.router.navigate(['/page-not-found'], { state: { data } });
        }
      }
    });
  }

  // browser gets 200: Api response gives different  status eg; 500
  private successHandler(response: HttpEvent<any>): HttpEvent<any> {
    this.checkCredentialExpired(response);
    if (response instanceof HttpResponse) {
      switch (parseInt((response.body['status']))) {
        case 403:
          if ((response.body['message'] || '').toLowerCase() !== 'access denied') {
            this.toastr.error(response.body['message'] || '', 'An error occurred');
          }
          break;

        case 404:
          if ((response.body['message'] || '').toLowerCase() !== 'access denied') {
            this.toastr.error(response.body['message'] || '', 'An error occurred');
          }
          break;

        case 500:
          if (response.body['message'] == 'invalid_token') {
            this.webStorage.removeWebStorageItem('currentUser');
            this.store.dispatch(new MarkCleanFormAction({ dirty: false, message: '' }));
            this.router.navigate(['/access-denied']);
          }
          else {
            if (((response && response.body['message']) || '').toLowerCase() !== 'access denied' && (response && response.body['message']) !== 'Unable to fetch Questionaire details!') {
              this.toastr.error((response && response.body['message']) || '', 'An error occurred');
            }
          }
          break;
      }
    }
    return response;
  }

  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    this.checkCredentialExpired(response);
    if (response['status'] && response['status'] === 500) {
      if (response['url'] && response['url'].endsWith('api/v1/authenticate')) {
        // this.authenticationService.logout()
        //   .pipe(take(1))
        //   .subscribe(data => {
        //     if (data && data['status'] === 200) {
        //       this.authenticationService.clearUserData();
        //       this.router.navigate(['/login']);
        //     }
        //   });
        throw response;
      } else {
        this.store.dispatch(new MarkCleanFormAction({ dirty: false, message: '' }));
        this.router.navigate(['/something-went-wrong']);
      }
    }
    else {
      if (response['error'] && (response['error']['message'] || '').toLowerCase() !== 'access denied') {
        this.toastr.error(response['error']['message'] || '', 'An error occurred');
      } else if(response['message']?.toLowerCase() !== 'access denied') {
        this.toastr.error(response['message'], 'An error occurred');
      }
    }

    throw response;
  }

  private ChangePwdDialogueOpen() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'auto';
    dialogConfig.width = '600px';
    dialogConfig.data = { caption: 'Your password expired, please change it now!', expiredCred: true };
    dialogConfig.panelClass = 'changePassword';
    dialogConfig.disableClose = true;
    const dialogRef = this.changePsswdDialog.open(ChangepasswordComponent, dialogConfig).afterClosed().subscribe(result => {
      this.dialogueOpen = false;
    });
  }

  private checkCredentialExpired(response) {
    if (response.body && response.body['isCredentialsExpired'] === true && !this.dialogueOpen) {
      this.ChangePwdDialogueOpen();
      this.dialogueOpen = true;
    }
  }
}
